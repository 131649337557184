
















































































































import { inject, ref } from '@vue/composition-api';

export default {
  name: 'ModuleInstruct',

  setup() {
    const readonly = inject('readonly', false);
    const boilerInstructions = ref([
      'Watch one video after another',
      'Digest, take notes and repeat until complete'
    ]);
    const goal = ref([
      'To watch, learn and understand the details of your employer, their project scope and industry'
    ]);
    function populate() {
      boilerInstructions.value.push('');
    }
    return {
      boilerInstructions,
      populate,
      tutorialDialog: false,
      goal,
      readonly
    };
  }
};
