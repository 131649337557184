




























































































































































































































































































































































































































































































































import {
  defineComponent,
  ref,
  computed,
  PropType,
  toRefs,
  reactive,
  watch,
  watchEffect
} from '@vue/composition-api';
import { loading } from 'pcv4lib/src';
import { ValidationObserver, ValidationProvider } from '@/validation';
import MongoDoc from './types';
import { minBudget, maxBudget, minHours, maxHours } from './const';

// TODO: move this to @pilocity/shared
const enum ProgramActivityStatus {
  NeedSetup = 'need_setup',
  CompleteSetup = 'complete_setup',
  Standard = 'standard'
}

export default defineComponent({
  name: 'ModuleSetup',
  components: {
    ValidationProvider,
    ValidationObserver
  },
  props: {
    value: {
      required: true,
      type: Object as PropType<MongoDoc>
    }
  },
  setup(props, ctx) {
    const externDialog = ref(false);
    const programDoc = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    const index = programDoc.value.data.adks.findIndex(obj => {
      if (obj) {
        return obj.name === 'offer';
      }
      return false;
    });

    function triggerRadio() {
      programDoc.value.data.adks[index].offer[0].externShip = false;
    }

    function openExternDialog() {
      externDialog.value = true;
    }

    function confirmExtern() {
      externDialog.value = false;
      programDoc.value.data.adks[index].offer[0].externShip = true;
    }

    function cancelExtern() {
      programDoc.value.data.adks[index].offer[0].externCredential = false;
      externDialog.value = false;
    }

    const initOfferSetup = {
      offer: [
        {
          internshipProject1: true,
          internshipProject2: false,
          internshipProject3: false,
          licenseRequirement: 0,
          employerRecord: 0,
          intern: true,
          fellow: false,
          eir: false,
          extern: false,
          externShip: false,
          apprentice: false,
          preApprentice: false,
          preInternship: false,
          externCredential: 0,
          continuation: 0,
          compensation1: false,
          compensation2: false,
          compensation3: false,
          compensation4: true,
          compensation5: false,
          compensation6: true,
          minimumBudget: '$1,000',
          maximumBudget: '$5,000',
          internshipStart: '2022-07-01',
          internshipEnd: '2023-06-01',
          minHours: 160,
          maxHours: 220,
          acceptanceDeadline: '2022-12-31',
          required: false
        }
      ]
    };

    const setup = reactive({
      minBudget,
      maxBudget,
      minHours,
      maxHours
    });

    const date = ref('');
    const modal = ref('');
    const date1 = ref('');
    const modal1 = ref('');
    const date2 = ref('');
    const modal2 = ref('');

    programDoc.value.data.adks[index] = {
      ...initOfferSetup,
      ...programDoc.value.data.adks[index]
    };
    // Handle Save

    function populate() {
      programDoc.value.data.adks[index].offer.push(initOfferSetup.offer[0]);
    }

    const learnCredential = ref(false);

    return {
      ...toRefs(setup),
      programDoc,
      index,
      populate,
      ...loading(
        () =>
          programDoc.value.update(() => ({
            adkIndex: index,
            isComplete: true,
            status: ProgramActivityStatus.CompleteSetup
          })),
        'Success',
        'Try again later'
      ),
      date,
      modal,
      date1,
      modal1,
      date2,
      modal2,
      learnCredential,
      openExternDialog,
      externDialog,
      cancelExtern,
      confirmExtern,
      triggerRadio
    };
  }
});
