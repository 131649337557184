














































import { defineComponent, ref } from '@vue/composition-api';
import Table from './TableView.vue';
import MongoDoc from './types';
import Instruct from './ModuleInstruct.vue';

export default defineComponent({
  name: 'ModuleDefault',
  components: {
    Table,
    Instruct
  },
  props: {
    studentDoc: {
      required: true,
      type: Object as () => MongoDoc
    }
  },
  setup(props, ctx) {
    const showInstructions = ref(true);
    const table = ref();

    return {
      showInstructions,
      table
    };
  }
});
