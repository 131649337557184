<template>
  <v-container>
    <validation-provider v-slot="{ errors }" slim rules="required">
      <ASelectInput
        v-model="simpleMeetingInternshipCycle"
        :error-messages="errors"
        rounded
        outlined
        :items="['summer']"
        label="Select internship cycle"
      />
    </validation-provider>

    <div class="text-center mb-8"><h2>Meeting</h2></div>

    <validation-provider v-slot="{ errors }" slim rules="required">
      <ATextInput
        v-model="simpleMeetingName"
        :error-messages="errors"
        rounded
        outlined
        label="Meeting Name"
      />
    </validation-provider>

    <v-container fluid>
      <v-row>
        <v-col cols="12" sm="6" class="pl-0 pr-1">
          <v-menu
            ref="simpleMeetingStartDateRef"
            v-model="simpleMeetingStartDateRef"
            :close-on-content-click="false"
            :return-value.sync="simpleMeetingStartDate"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template #activator="{ on, attrs }">
              <validation-provider v-slot="{ errors }" rules="required">
                <v-text-field
                  v-model="simpleMeetingStartDate"
                  rounded
                  outlined
                  :error-messages="errors"
                  label="Start date"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </validation-provider>
            </template>
            <v-date-picker v-model="simpleMeetingStartDate" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="simpleMeetingStartDateRef = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.simpleMeetingStartDateRef.save(simpleMeetingStartDate)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="6" class="pl-1 pr-0">
          <v-menu
            ref="simpleMeetingEndDateRef"
            v-model="simpleMeetingEndDateRef"
            :close-on-content-click="false"
            :return-value.sync="simpleMeetingEndDate"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template #activator="{ on, attrs }">
              <validation-provider v-slot="{ errors }" rules="required">
                <v-text-field
                  v-model="simpleMeetingEndDate"
                  rounded
                  outlined
                  label="End date"
                  readonly
                  :error-messages="errors"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </validation-provider>
            </template>
            <v-date-picker v-model="simpleMeetingEndDate" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="simpleMeetingEndDateRef = false"> Cancel </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.simpleMeetingEndDateRef.save(simpleMeetingEndDate)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </v-container>

    <validation-provider v-slot="{ errors }" slim rules="required">
      <ASelectInput
        v-model="simpleMeetingTimeZone"
        :error-messages="errors"
        rounded
        outlined
        :items="simpleMeetingTimeZoneList"
        label="Time zone"
      />
    </validation-provider>

    <template>
      <v-sheet class="pa-5">
        <v-row>
          <v-col class="pl-0 pr-0 pt-0">Regular Meeting </v-col
          ><v-col class="pl-0 pr-0 pt-0"
            ><v-switch v-model="isSwitchOn" color="blue" class="mt-0 pt-0"></v-switch></v-col
          ><v-col class="pl-0 pr-0 pt-0"> Recurring Meeting</v-col>
        </v-row>
      </v-sheet>
    </template>

    <div v-if="isSwitchOn">
      <div class="text-start mb-2"><h4>Select recurring days per week</h4></div>
      <v-row class="mt-3 mb-8">
        <div
          v-for="item in simpleMeetingWeekDays"
          :key="item"
          class="d-flex justify-content: center"
        >
          <v-col>
            <a-button
              id="day"
              class="white--text"
              width="10px"
              rounded
              :color="daysPerWeekForSimpleMeeting.includes(item) ? 'blue' : 'grey'"
              @click="
                daysPerWeekForSimpleMeeting.includes(item)
                  ? daysPerWeekForSimpleMeeting.splice(daysPerWeekForSimpleMeeting.indexOf(item), 1)
                  : daysPerWeekForSimpleMeeting.push(item)
              "
              >{{ item.split('')[0] }}</a-button
            >
          </v-col>
        </div>
      </v-row>

      <validation-provider v-slot="{ errors }" slim rules="required">
        <ASelectInput
          v-model="startAndEndTime"
          :error-messages="errors"
          rounded
          outlined
          :items="['10am-11am', '11am-12pm', '12pm-1pm']"
          label="Start & end time"
        />
      </validation-provider>

      <validation-provider v-slot="{ errors }" slim rules="required">
        <ASelectInput
          v-model="simpleMeetingRepeat"
          :error-messages="errors"
          rounded
          outlined
          :items="simpleMeetingRepeatTimes"
          label="Repeat every"
        />
      </validation-provider>

      <validation-provider v-slot="{ errors }" slim rules="required">
        <ASelectInput
          v-model="endsAfter"
          :error-messages="errors"
          rounded
          outlined
          :items="simpleMeetingOccurenceList"
          label="End after"
        />
      </validation-provider>
    </div>
    <div v-else>
      <validation-provider v-slot="{ errors }" slim rules="required">
        <ASelectInput
          v-model="startAndEndTime"
          :error-messages="errors"
          rounded
          outlined
          :items="['10am-11am', '11am-12pm', '12pm-1pm']"
          label="Start & end time"
        />
      </validation-provider>
    </div>

    <!-- <validation-provider v-slot="{ errors }" slim rules="required">
      <ATextInput
        v-model="simpleMeetingConferenceLine"
        :error-messages="errors"
        rounded
        label="Enter Conference line"
        outlined
      />
    </validation-provider> -->

    <validation-provider v-slot="{ errors }" slim rules="required">
      <v-textarea
        v-model="simpleMeetingDescription"
        :error-messages="errors"
        rounded
        outlined
        label="Description"
        hint="Description"
        class="mb-3"
      ></v-textarea>
    </validation-provider>

    <div class="headline font-weight-bold">Who would you like to add to this calendar?</div>
    <v-container fluid>
      <v-checkbox v-model="simpleMeetingSelected" label="Employer" value="employer"></v-checkbox>
      <v-checkbox
        v-model="simpleMeetingSelected"
        label="Current Interns"
        value="intern"
        class="mt-0"
      ></v-checkbox>
    </v-container>
  </v-container>
</template>

<script>
import { useDbState } from '@/store';
import { ref, computed, watch } from '@vue/composition-api';
import ATextInput from '@/components/atoms/ATextInput.vue';
import ASelectInput from '@/components/atoms/ASelectInput.vue';
import AButton from '@/components/atoms/AButton.vue';
import { ValidationProvider } from '@/validation';

export default {
  components: {
    ATextInput,
    ASelectInput,
    AButton,
    ValidationProvider
  },

  setup(props, ctx) {
    const { user } = useDbState(['user']);
    let simpleMeetingWeekDays;
    if (user.value.googleDetails) {
      simpleMeetingWeekDays = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];
    } else if (user.value.outlookDetails) {
      simpleMeetingWeekDays = [
        'SUNDAY',
        'MONDAY',
        'TUESDAY',
        'WEDNESDAY',
        'THURSDAY',
        'FRIDAY',
        'SATURDAY'
      ];
    }
    const simpleMeetingDurationList = ['1 Hour', '2 Hour', '3 Hour'];
    const simpleMeetingRepeatTimes = ['1 Week', '2 Week', '3 Week', '4 Week', '5 Week'];
    const simpleMeetingTimeZoneList = [
      'Africa/Cairo',
      'Africa/Casablanca',
      'Africa/Harare',
      'Africa/Lagos',
      'Africa/Nairobi',
      'Africa/Windhoek',
      'America/Argentina/Buenos_Aires',
      'America/Bogota',
      'America/Caracas',
      'America/Chihuahua',
      'America/Godthab',
      'America/Los_Angeles',
      'America/Managua',
      'America/Manaus',
      'America/Mexico_City',
      'America/Montevideo',
      'America/Noronha',
      'America/Santiago',
      'America/Sao_Paulo',
      'America/Tijuana',
      'Asia/Almaty',
      'Asia/Amman',
      'Asia/Baku',
      'Asia/Bangkok',
      'Asia/Beirut',
      'Asia/Calcutta',
      'Asia/Calcutta',
      'Asia/Dhaka',
      'Asia/Hong_Kong',
      'Asia/Irkutsk',
      'Asia/Jerusalem',
      'Asia/Kabul',
      'Asia/Karachi',
      'Asia/Katmandu',
      'Asia/Kolkata',
      'Asia/Krasnoyarsk',
      'Asia/Kuala_Lumpur',
      'Asia/Kuwait',
      'Asia/Magadan',
      'Asia/Muscat',
      'Asia/Rangoon',
      'Asia/Seoul',
      'Asia/Taipei',
      'Asia/Tbilisi',
      'Asia/Tehran',
      'Asia/Tokyo',
      'Asia/Vladivostok',
      'Asia/Yakutsk',
      'Asia/Yekaterinburg',
      'Asia/Yerevan',
      'Atlantic/Azores',
      'Atlantic/Cape_Verde',
      'Australia/Adelaide',
      'Australia/Brisbane',
      'Australia/Canberra',
      'Australia/Darwin',
      'Australia/Hobart',
      'Australia/Perth',
      'Canada/Atlantic',
      'Canada/Newfoundland',
      'Canada/Saskatchewan',
      'Etc/GMT+12',
      'Etc/Greenwich',
      'Europe/Amsterdam',
      'Europe/Athens',
      'Europe/Belgrade',
      'Europe/Brussels',
      'Europe/Helsinki',
      'Europe/Minsk',
      'Europe/Moscow',
      'Europe/Sarajevo',
      'Pacific Standard Time',
      'Pacific/Auckland',
      'Pacific/Fiji',
      'Pacific/Guam',
      'Pacific/Honolulu',
      'Pacific/Midway',
      'Pacific/Tongatapu',
      'US/Alaska',
      'US/Arizona',
      'US/Central',
      'US/East-Indiana',
      'US/Eastern',
      'US/Mountain',
      'UTC'
    ];
    const simpleMeetingOccurenceList = [
      '1 Occurrences',
      '2 Occurrences',
      '3 Occurrences',
      '4 Occurrences',
      '5 Occurrences',
      '6 Occurrences'
    ];
    const isSwitchOn = ref(false);
    const simpleMeetingStartDateRef = false;
    const simpleMeetingEndDateRef = false;

    const simpleMeetingInternshipCycle = ref('');
    const simpleMeetingName = ref('');
    const simpleMeetingTimeZone = ref('');
    const simpleMeetingStartDate = ref('');
    const simpleMeetingEndDate = ref('');
    const daysPerWeekForSimpleMeeting = ref([]);
    const startAndEndTime = ref('');
    const simpleMeetingRepeat = ref('');
    const endsAfter = ref('');
    const simpleMeetingConferenceLine = ref('');
    const simpleMeetingDescription = ref('');
    const simpleMeetingSelected = ref([]);

    watch(
      [
        simpleMeetingName,
        simpleMeetingStartDate,
        simpleMeetingEndDate,
        simpleMeetingTimeZone,
        daysPerWeekForSimpleMeeting,
        startAndEndTime,
        simpleMeetingRepeat,
        endsAfter,
        simpleMeetingConferenceLine,
        simpleMeetingDescription,
        simpleMeetingSelected
      ],
      ([
        simpleMeetingName,
        simpleMeetingStartDate,
        simpleMeetingEndDate,
        simpleMeetingTimeZone,
        daysPerWeekForSimpleMeeting,
        startAndEndTime,
        simpleMeetingRepeat,
        endsAfter,
        simpleMeetingConferenceLine,
        simpleMeetingDescription,
        simpleMeetingSelected
      ]) => {
        const simpleMeetingObject = {
          simpleMeetingName: simpleMeetingName || undefined,
          simpleMeetingStartDate: simpleMeetingStartDate || undefined,
          simpleMeetingEndDate: simpleMeetingEndDate || undefined,
          simpleMeetingTimeZone: simpleMeetingTimeZone || undefined,
          daysPerWeekForSimpleMeeting: daysPerWeekForSimpleMeeting || undefined,
          startAndEndTime: startAndEndTime || undefined,
          simpleMeetingRepeat: simpleMeetingRepeat || undefined,
          endsAfter: endsAfter || undefined,
          simpleMeetingConferenceLine: simpleMeetingConferenceLine || undefined,
          simpleMeetingDescription: simpleMeetingDescription || undefined,
          simpleMeetingSelected: simpleMeetingSelected || undefined
        };
        ctx.emit('simpleMeetingEmit', simpleMeetingObject);
      }
    );
    return {
      simpleMeetingWeekDays,
      simpleMeetingDurationList,
      simpleMeetingOccurenceList,
      simpleMeetingRepeatTimes,
      simpleMeetingTimeZone,
      isSwitchOn,
      simpleMeetingInternshipCycle,
      simpleMeetingStartDateRef,
      simpleMeetingEndDateRef,
      simpleMeetingName,
      simpleMeetingStartDate,
      simpleMeetingEndDate,
      daysPerWeekForSimpleMeeting,
      startAndEndTime,
      simpleMeetingRepeat,
      endsAfter,
      simpleMeetingTimeZoneList,
      simpleMeetingConferenceLine,
      simpleMeetingDescription,
      simpleMeetingSelected
    };
  }
};
</script>

<style>
#day {
  min-width: 36px;
}
</style>
