
























































































































import {
  defineComponent,
  PropType,
  toRefs,
  reactive,
  ref,
  computed,
  inject
} from '@vue/composition-api';
import { loading } from 'pcv4lib/src';
// import Instruct from './ModuleInstruct.vue';
import { group, required, deliverable, endEarly, minimumHours } from './const';
import MongoDoc from './types';
// import gql from 'graphql-tag';

export default defineComponent({
  name: 'ModulePresets',

  props: {
    value: {
      required: true,
      type: Object as PropType<MongoDoc>
    }
    // userType: {
    //   required: true,
    //   type: String
    //   // participant: '',
    //   // organizer: '',
    //   // stakeholder: ''
    // },
    // teamDoc: {
    //   required: true,
    //   type: Object as PropType<MongoDoc | null>,
    //   default: () => {}
    // }
  },
  setup(props, ctx) {
    const readonly = inject('readonly', false);
    // const teamDocument = getModMongoDoc(props, ctx.emit, {}, 'teamDoc', 'inputTeamDoc');
    const programDoc = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    const index = programDoc.value.data.adks.findIndex(obj => {
      if (obj) {
        return obj.name === 'tinker';
      }
      return false;
    });

    const initPracticePresets = {
      minimumHoursNow: '3 Hours',
      defaultActivity: {
        groupActivity: 'Screening',
        requiredActivity: 'Yes',
        deliverableActivity: 'No',
        endEarlyActivity: 'Yes',
        required: false
      }
    };
    programDoc.value.data.adks[index] = {
      ...initPracticePresets,
      ...programDoc.value.data.adks[index]
    };

    const presets = reactive({
      group,
      required,
      deliverable,
      endEarly,
      minimumHours
    });

    // console.log(teamDocument.value);

    function save() {
      console.log(programDoc.value.data.adks[index].minimumHoursNow);
      return new Promise((resolve, reject) => {
        programDoc.value.update();

        resolve(true);
      });
    }

    function minuteCheck() {
      console.log(programDoc.value.data.adks[index].minimumHoursNow);
    }

    const setupInstructions = ref({
      description: '',
      instructions: ['', '', '']
    });

    return {
      minuteCheck,
      setupInstructions,
      // studentDocument,
      // teamDocument,
      // adkData,
      ...toRefs(presets),
      ...loading(save, 'Success', 'Try again later'),
      index,
      programDoc,
      save,
      group,
      required,
      deliverable,
      endEarly,
      minimumHours,
      readonly
    };
  }
  // setup() {
  //   const minutes = ref('');
  //   const setupInstructions = ref({
  //     description: '',
  //     instructions: ['', '', '']
  //   });
  //   return {
  //     minutes,
  //     setupInstructions
  //   };
  // }
});
