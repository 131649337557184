
















































































































import { ref, defineComponent, inject } from '@vue/composition-api';

export default defineComponent({
  name: 'ModuleInstruct',

  setup() {
    const readonly = inject('readonly', false);
    // const programDoc = computed({
    //   get: () => props.value,
    //   set: newVal => {
    //     ctx.emit('input', newVal);
    //   }
    // });
    const boilerInstructions = ref([
      'Watch the interview question',
      'Answer with a video answer',
      'Repeat until all interview questions are completed'
    ]);
    const goal = ref([
      'To interview with the employers to win internships, contracts & opportunities'
    ]);
    // function populate() {
    //   boilerInstructions.value.push('');
    // }

    return { boilerInstructions, tutorialDialog: false, goal, readonly };
  }
  // setup() {
  //   const demoInstructions = ref(['']);
  //   const goal = ref(['']);
  //   function populate() {
  //     demoInstructions.value.push('');
  //   }
  //   return { demoInstructions, populate, goal };
  // }
});
