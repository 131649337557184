




























































































































import {
  defineComponent,
  computed,
  PropType,
  reactive,
  toRefs,
  inject
} from '@vue/composition-api';
import { TeamDoc } from './types';

export default defineComponent({
  name: 'ManageTable',

  props: {
    teamDoc: {
      required: true,
      type: Object as PropType<TeamDoc>
    },
    viewer: {
      required: true,
      type: Object
    },
    userType: {
      required: true,
      type: String
    }
  },
  setup(props, ctx) {
    const readonly = inject('readonly', false);
    const state = reactive({
      selectedMember: null as null | Record<string, any>,
      removeMemberDialog: false,
      changeOwnerDialog: false
    });
    const isOwner = (item: Record<string, any>) => {
      return item._id.equals(props.teamDoc.data.owner);
    };
    const tableHeaders = computed(() => {
      const headers = [
        { text: '', align: 'start', value: 'avatar', width: '5%' },
        { text: 'Name', align: 'start', value: 'name', width: '50%' },
        { text: 'Access', align: 'start', value: 'access', sortable: false, width: '20%' },
        { text: 'Action', align: 'start', value: 'action', sortable: false, width: '25%' }
      ];
      if (props.viewer.data._id.equals(props.teamDoc.data.owner)) return headers;
      return headers.filter(column => column.value !== 'action');
    });
    const teamMembers = computed(() => {
      return props.teamDoc.data.members.slice().sort(member => {
        return isOwner(member) ? -1 : 1;
      });
    });
    const teamMembersExceptOwner = computed(() => {
      return props.teamDoc.data.members.filter(member => {
        return !isOwner(member);
      });
    });
    const removeMember = () => {
      ctx.emit('removeMember', state.selectedMember!._id);
      state.removeMemberDialog = false;
      state.selectedMember = null;
    };
    const changeOwner = () => {
      if (state.selectedMember) {
        ctx.emit('changeOwner', state.selectedMember._id);
        state.changeOwnerDialog = false;
        state.selectedMember = null;
      }
    };
    return {
      tableHeaders,
      teamMembers,
      teamMembersExceptOwner,
      isOwner,
      removeMember,
      changeOwner,
      readonly,
      ...toRefs(state)
    };
  }
});
