var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"pa-0"},[(_vm.isSignoffCompleted)?[_c('v-progress-linear',{staticClass:"mt-3",attrs:{"color":"#f79961","height":"2","value":"100","buffer-value":"100","stream":""}}),_c('div',{staticClass:"justify-center d-flex"},[_c('h2',{staticClass:"orange--text font-weight-bold mt-12"},[_vm._v(" Thank you for the exit interview!"),_c('br')])]),_c('div',{staticClass:"\n          module-default__statement4\n          headline\n          font-weight-medium\n          justify-center\n          mt-8\n          ml-12\n          mr-12\n          mb-12\n        ",class:_vm.$vuetify.breakpoint.xs ? 'ml-6 mr-6' : ''},[_vm._v(" Last step is to await signoff from your employer for completion of your internship. You will receive a text message once you've been signed off and are officially complete! ")]),(_vm.videoAskResponse)?_c('iframe',{staticStyle:{"border":"none","border-radius":"0px"},attrs:{"src":_vm.videoAskResponse && _vm.videoAskResponse.contact && _vm.videoAskResponse.contact.share_url,"allow":"autoplay *; encrypted-media *; fullscreen *;","width":"100%","height":"600px"}}):_vm._e(),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn',_vm._g(_vm._b({staticClass:"module-default__log-btn mt-12",attrs:{"ripple":false,"rounded":"","depressed":"","x-large":"","outlined":""},on:{"click":function($event){_vm.redoSignoff = false}}},'v-btn',attrs,false),on),[_vm._v("Redo Exit Interview")])],1)]}}],null,false,4095636536),model:{value:(_vm.redoSignoff),callback:function ($$v) {_vm.redoSignoff=$$v},expression:"redoSignoff"}},[_c('v-card',[_c('v-card-title',{staticClass:"d-flex flex-column justify-center"},[_c('div',{staticClass:"overline font-weight-bold d-flex justify-center"},[_vm._v(" Are you sure you want to delete & redo your exit interview? ")])]),_c('v-divider'),_c('v-container',{staticClass:"d-flex flex-column justify-center"},[_c('div',{staticClass:"d-flex flex-row justify-center mt-4 mb-6"},[_c('v-btn',{staticClass:"ma-2",attrs:{"disabled":_vm.readonly,"outlined":"","x-large":"","rounded":"","depressed":""},on:{"click":function($event){_vm.redoSignoff = false}}},[_vm._v("Cancel")]),_c('v-btn',{staticClass:"ma-2",attrs:{"disabled":_vm.readonly,"color":"red","x-large":"","dark":"","rounded":"","depressed":"","loading":_vm.loaded},on:{"click":function($event){return _vm.deleteProcess()}}},[_vm._v("Delete & Redo")])],1)])],1)],1)]:_c('div',[_c('div',{staticClass:"module-default__instructions"},[_c('v-expansion-panels',{staticClass:"module-default__instructions",attrs:{"flat":""},model:{value:(_vm.showInstructions),callback:function ($$v) {_vm.showInstructions=$$v},expression:"showInstructions"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{directives:[{name:"show",rawName:"v-show",value:(_vm.showInstructions),expression:"showInstructions"}],staticClass:"pa-0",attrs:{"hide-actions":""},on:{"click":function($event){_vm.showInstructions = true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var open = ref.open;
return [_c('v-scroll-y-transition',{attrs:{"hide-on-leave":""}},[(!open)?_c('div',{staticClass:"d-flex flex-column justify-center"},[_c('v-icon',{staticClass:"d-flex justify-center",attrs:{"color":"black"}},[_vm._v(" mdi-chevron-down ")]),_c('div',{staticClass:"module-default__collapse-title"},[_vm._v("INSTRUCTIONS")])],1):_vm._e()])]}}])}),_c('v-expansion-panel-content',[_c('Instruct',{attrs:{"readonly":""}}),_c('div',{on:{"click":function($event){_vm.showInstructions = true}}},[_c('div',{staticClass:"module-default__collapse-title"},[_vm._v("CLOSE")]),_c('v-icon',{staticClass:"d-flex justify-center",attrs:{"color":"black"}},[_vm._v(" mdi-chevron-up ")])],1)],1)],1)],1)],1),_c('v-progress-linear',{staticClass:"mt-3",attrs:{"color":"#dedede","height":"2","value":"100","buffer-value":"100","stream":""}}),(!_vm.isSignoffCompleted)?_c('div',{staticClass:"d-flex justify-center mt-4 mb-4"},[_c('div',[_c('h5',{staticClass:"orange--text"},[_c('i',[_vm._v("Yeeeeeehawww!")]),_vm._v(" 🤠 "),_c('i',[_vm._v("Once exit interview is done, please await signoff by your employer")])])])]):_vm._e(),_c('iframe',{staticStyle:{"border":"none","border-radius":"0px"},attrs:{"src":("https://www.videoask.com/fusu136aw#contact_email=" + (encodeURI(
          _vm.userDoc.data.email
        )) + "&contact_name=" + (encodeURI(
          _vm.userDoc.data.firstName + ' ' + _vm.userDoc.data.lastName
        )) + "&contact_phone_number=" + (encodeURI(_vm.userDoc.data.phoneNumber)) + "&program_id=" + (encodeURI(
          _vm.programDoc.data._id
        ))),"allow":"camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;","width":"100%","height":"600"}}),_c('h3',{staticClass:"text-center pa-4 mt-3"},[_vm._v(" Once exit interview is done, wait 1-minute, then click submit ")]),_c('dir',{staticClass:"d-flex flex-column align-center justify-center"},[_c('v-btn',{staticClass:"ma-2 mt-4",attrs:{"color":"#f79961","x-large":"","rounded":"","depressed":"","dark":"","loading":_vm.isFetching},on:{"click":_vm.fetchStatus}},[_vm._v(" Submit 1-min after completion ")])],1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }