
















































































































import { ref } from '@vue/composition-api';

export default {
  name: 'ModuleInstruct',

  setup() {
    // const programDoc = computed({
    //   get: () => props.value,
    //   set: newVal => {
    //     ctx.emit('input', newVal);
    //   }
    // });
    const boilerInstructions = ref([
      'Write your one sentence pitch in a tweet or less',
      'Write your elevator pitch as if you were to verbally present your idea',
      'Save your drafts, iterate and make into final draft when ready'
    ]);
    const goal = ref([
      'To effectively communicate your project in a one-sentence and written elevator pitch as if you were to walk into the elevator with your employer, customer or user'
    ]);
    // function populate() {
    //   boilerInstructions.value.push('');
    // }

    return { boilerInstructions, tutorialDialog: false, goal };
  }
};
