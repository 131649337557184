




































































































































































































































































































































































































/* eslint-disable no-unused-expressions */
import {
  reactive,
  toRefs,
  PropType,
  computed,
  defineComponent,
  inject
} from '@vue/composition-api';
import { loading } from 'pcv4lib/src';
import { ValidationObserver, ValidationProvider } from '@/validation';
import { deliverablesValue, chips, items } from './const';
import MongoDoc from './types';

// TODO: move this to @pilocity/shared
const enum ProgramActivityStatus {
  NeedSetup = 'need_setup',
  CompleteSetup = 'complete_setup',
  Standard = 'standard'
}

export default defineComponent({
  name: 'ModuleSetup',

  components: {
    ValidationObserver,
    ValidationProvider
  },
  props: {
    value: {
      required: true,
      type: Object as PropType<MongoDoc>
    }
  },

  setup(props, ctx) {
    const readonly = inject('readonly', false);
    const programDoc = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    const index = programDoc.value.data.adks.findIndex(obj => {
      if (obj) {
        return obj.name === 'scope';
      }
      return false;
    });

    const initScopeSetup = {
      employerWebsite: '',
      projectScope: '',
      introVideo: '',
      aboutOrg: '',
      outcome: ['Build portfolio project', 'Qualify to win work experiences to execute on project'],
      deliverable: ['Ideate', 'Pitch', 'Make', 'Demonstration'],
      projectReq: '',
      resourceWeb: '',
      resourceInsta: '',
      resourceLinkedIn: '',
      resourceFacebook: '',
      resourceYouTube: '',
      resourceTwitter: '',
      resourceDrive: '',
      interviewProblem: '',
      interviewSolution: '',
      interviewOpportunity: '',
      interviewChallenge: '',
      interviewReqeust: '',
      required: false
    };

    programDoc.value.data.adks[index] = {
      ...initScopeSetup,
      ...programDoc.value.data.adks[index]
    };

    // const status = ref('');
    const setup = reactive({
      outcomes: [
        'Build portfolio project',
        'Qualify to win work experiences to execute on project'
      ],
      // outcomesValue,
      deliverables: ['Ideate', 'Pitch', 'Make', 'Demo'],
      deliverablesValue,
      chips,
      items
    });

    return {
      ...toRefs(setup),
      // status,
      index,
      programDoc,
      readonly,
      ...loading(
        () =>
          programDoc.value.update(() => ({
            isComplete: true,
            adkIndex: index,
            status: ProgramActivityStatus.CompleteSetup
          })),
        'Success',
        'Try again later'
      )
    };
  }
});
