

















































































































import { defineComponent, ref, computed, PropType, inject } from '@vue/composition-api';
import { loading, getModAdk } from 'pcv4lib/src';
import MongoDoc from './types';
import { items, HEADER } from './const';
import Instruct from './ModuleInstruct.vue';

export default defineComponent({
  name: 'ModuleDefault',

  components: {
    Instruct
  },
  props: {
    value: {
      required: true,
      type: Object as PropType<MongoDoc>
    },
    userType: {
      required: true,
      type: String
      // participant: '',
      // organizer: '',
      // stakeholder: ''
    },
    studentDoc: {
      required: true,
      type: Object as () => MongoDoc
    }
  },
  setup(props, ctx) {
    const readonly = inject('readonly', false);
    // props
    console.log('other test');
    const researchData = computed(() =>
      props.value.data.adks.find(obj => obj && obj.name === 'research')
    );

    const { adkData: researchAdk, adkIndex } = getModAdk(
      props,
      ctx.emit,
      'research',
      {
        researchProgress: (researchData.value!.researchLinks as any[]).map((obj: any) => ({
          ...obj,
          viewed: false,
          completed: false
        }))
      },
      'studentDoc',
      'inputStudentDoc'
    );
    const { researchProgress } = researchAdk.value;
    // layout
    const setupInstructions = ref({
      description: '',
      instructions: ['', '', '']
    });
    const showInstructions = ref(true);
    const finishButtonDisabled = ref(1);
    function itemComplete() {
      const finish = researchProgress.every(
        (item: any) => !item.required || (item.viewed && item.completed)
      );
      if (finish) {
        finishButtonDisabled.value = 0;
      } else {
        finishButtonDisabled.value = 1;
      }
    }
    return {
      header: HEADER,
      items,
      setupInstructions,
      finishButtonDisabled,
      itemComplete,
      showInstructions,
      researchProgress,
      researchData,
      readonly,
      ...loading(
        () =>
          props.studentDoc.update(() => ({
            isComplete: true,
            adkIndex
          })),
        'Success',
        'Try again later'
      )
    };
  }
});
