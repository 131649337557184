












































































import { ref, computed, reactive, toRefs } from '@vue/composition-api';
import { useDbState } from '@/store';
import { ObjectId } from 'bson';
import axios from 'axios';
import Table from '@/views/Guide/activities/make/components/TableView.vue';
import { TableItem } from '@/views/Guide/activities/make/components/types';

export default {
  components: {
    Table
  },

  props: {
    viewOnly: {
      type: Boolean,
      default: false
    },
    data: {
      type: [Object, Array],
      default: () => {}
    },
    upload: {
      required: false,
      type: Function,
      default: () => {}
    },
    userType: {
      required: true,
      type: String
    }
  },

  setup(props, ctx) {
    const fileInput = ref(null);
    const { user } = useDbState(['user']);
    const isLoading = ref(false);
    const isRemoving = ref(false);
    const successMsg = ref(false);
    const errorMsg = ref(false);
    const msg = ref('');

    const state = reactive({
      logInput: '',
      logError: '',
      images: [] as File[],
      adkData: null as null | Record<string, any>,
      teamAdkIndex: -1,
      userId: null as null | ObjectId,
      studentAdkData: null as null | Record<string, any>,
      logMilestoneLoading: false
    });

    const makeAdk = computed(() => {
      return props.data.adks?.filter(adk => adk.name.toLowerCase() === 'make')[0];
    });

    state.adkData = makeAdk;
    state.userId = user.value?._id;

    async function updateLogs() {
      isLoading.value = true;
      try {
        const data = {
          adks: props.data.adks
        };
        console.log(data);
        const ENDPOINT_API = process.env.VUE_APP_DELIVERABLE;
        const res = await axios.patch(`${ENDPOINT_API}?deliverable_id=${props.data._id}`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        });
        if (res.data.data) {
          successMsg.value = true;
          msg.value = res.data.message;
          isLoading.value = false;
        }
      } catch (error) {
        console.log(error);
        errorMsg.value = true;
        msg.value = 'Something went wrong';
        isLoading.value = false;
      }
    }

    const logMilestone = async () => {
      if (!state.images.length && !state.logInput.length) {
        state.logError = 'Describe and attach an image to document your milestone';
        return;
      }
      if (!state.images.length) {
        state.logError = 'Attach milestone screenshot, image or photo';
        return;
      }
      if (!state.logInput.length) {
        state.logError = 'Describe your milestone';
        return;
      }
      state.logMilestoneLoading = true;

      const proof = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const image of state.images) {
        // eslint-disable-next-line no-await-in-loop
        const data = await props.upload({
          item: image as unknown as File
        });
        proof.push({
          name: image.name,
          url: data.Location
        });
      }

      const log = {
        id: new ObjectId(),
        log: state.logInput,
        time: new Date(),
        author: user.value?._id.toString(),
        avatar: user.value?.profile ? user.value?.profile.small : '',
        proof
      };

      state.adkData!.logs.unshift(log);

      state.images = [];
      state.logInput = '';
      state.logError = '';
      await updateLogs();
      state.logMilestoneLoading = false;
    };

    const removeMilestone = async (id: string) => {
      isRemoving.value = true;
      state.adkData!.logs = state.adkData!.logs.filter((item: TableItem) => {
        return item.id.toString() !== id;
      });

      await updateLogs();
      isRemoving.value = false;
    };

    const removeFile = (file: string) => {
      state.images = state.images.filter((image: File) => image.name !== file);
    };

    const logsLeft = computed(() => {
      return Math.max((state.adkData!.minLogs || 3) - makeAdk.value!.logs.length, 0);
    });

    const onFilesAdded = (event: Event) => {
      const target = event.target as HTMLInputElement;
      const files = target.files as FileList;
      for (let i = 0; i < files.length; i += 1) {
        const file = files[i];
        state.images.push(file);
      }
    };

    return {
      ...toRefs(state),
      fileInput,
      makeAdk,
      logMilestone,
      state,
      user,
      removeMilestone,
      removeFile,
      logsLeft,
      onFilesAdded,
      successMsg,
      errorMsg,
      msg,
      updateLogs,
      isRemoving
    };
  }
};
