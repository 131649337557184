










































































































































































/* eslint-disable no-prototype-builtins */
/* eslint-disable vue/no-mutating-props */
/* eslint-disable no-param-reassign */
import { ObjectId } from 'bson';
import { computed, defineComponent, inject, ref } from '@vue/composition-api';
import { loading, getModAdk } from 'pcv4lib/src';
import axios from 'axios';
import { useUserGetters } from '@/store';
import type MongoDoc from './types';
import Instruct from './ModuleInstruct.vue';

export default defineComponent({
  name: 'ModuleDefault',
  components: {
    Instruct
  },
  props: {
    value: {
      required: true,
      type: Object as () => MongoDoc
    },
    studentDoc: {
      required: true,
      type: Object as () => MongoDoc
    },
    userType: {
      required: true,
      type: String
    },
    fetchProgram: {
      required: true,
      type: [Object, Function]
    }
  },
  setup(props, ctx) {
    const readonly = inject('readonly', false);
    const { adkData: trainAdk, adkIndex } = getModAdk(
      props,
      ctx.emit,
      'train',
      {
        trainProgress: (
          props.value.data?.adks?.find(obj => obj && obj.name === 'train')?.videoLinks as any[]
        ).map((obj: any) => ({
          ...obj,
          unlocked: false,
          completed: false
        }))
      },
      'studentDoc',
      'inputStudentDoc'
    );
    const trainAdkData = ref(trainAdk.value);
    trainAdk.value.trainProgress[0].unlocked = true;
    trainAdk.value.trainProgress[0].newCandidate = false;
    console.log(trainAdk.value.trainProgress[0].newCandidate);
    const panel = trainAdk.value.trainProgress.findIndex(
      (obj: any) => obj.unlocked && !obj.completed
    );
    const setupInstructions = ref({
      description: '',
      instructions: ['', '', '']
    });
    const showInstructions = ref(true);
    // Questions Array
    const newAPIData = ref([]);
    const {
      loading: saveLoading,
      process: saveProcess,
      message: saveMessage,
      error: saveError,
      success: saveSuccess
    } = loading(() => props.studentDoc.update(), 'Success', 'Try again later');
    const finishButtonDisabled = ref(
      trainAdk.value.trainProgress.every((item: any) => (item.completed ? true : null))
    );
    function videoComplete(index: number) {
      trainAdk.value.trainProgress[index].newCandidate = false;
      if (trainAdk.value.trainProgress[index + 1]) {
        trainAdk.value.trainProgress[index + 1].unlocked =
          !trainAdk.value.trainProgress[index + 1].unlocked;
      }
      if (
        !trainAdk.value.trainProgress[index].completed &&
        trainAdk.value.trainProgress[index + 1]
      ) {
        for (let i = index; i < trainAdk.value.trainProgress.length - 1; i += 1) {
          trainAdk.value.trainProgress[i + 1].unlocked = false;
          trainAdk.value.trainProgress[i + 1].completed = false;
        }
      }
      const lastVideoLink = trainAdk.value.trainProgress[trainAdk.value.trainProgress.length - 1];
      if (lastVideoLink.completed && lastVideoLink.unlocked) {
        finishButtonDisabled.value = true;
      } else {
        finishButtonDisabled.value = false;
      }
    }
    const isLoading = ref(false);
    async function fetchFormData() {
      isLoading.value = true;
      const API_ENDPOINT = process.env.VUE_APP_TRAINADK_NEW_BADGE;
      const dataValue = props.studentDoc.data._id.toString();
      try {
        const res = await axios.post(
          `${API_ENDPOINT}?program_id=${ctx.root.$route.params.programId}&student_id=${dataValue}`,
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );
        if (res) {
          props.fetchProgram();
        }
        trainAdk.value.trainProgress[0].unlocked = true;
        trainAdk.value.trainProgress[0].newCandidate = false;
        isLoading.value = false;
      } catch (err) {
        console.log(err);
      }
    }
    fetchFormData();

    const videoAskLink = ref('');
    function getIframeUrl(url: string) {
      const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      const match = url.match(regExp);

      if (match && match[7].length === 11) {
        return `https://www.youtube.com/embed/${match[7]}`;
      }
      return url;
    }

    if (
      trainAdk.value.trainProgress.filter(obj => obj.completed === true).length > 0 &&
      trainAdk.value.trainProgress.filter(obj => obj.completed === true).length !==
        trainAdk.value.trainProgress.length
    ) {
      const trainDataValue = trainAdk.value.trainProgress.filter(
        obj => obj.completed === true
      ).length;

      trainAdk.value.trainProgress[trainDataValue].unlocked = true;
    }

    const saveSuccessMsg = ref('');
    const saveErrorMsg = ref('');
    const saveloadingstatus = ref(false);

    async function saveProcessData() {
      try {
        if (props.studentDoc.data.adks[adkIndex].hasOwnProperty('activityEndedOn')) {
          props.studentDoc.data.adks[adkIndex].activityModifiedOn = new Date();
        }

        await props.studentDoc.update();
        saveSuccessMsg.value = 'Success';
        saveErrorMsg.value = '';
        saveloadingstatus.value = false;
      } catch (err) {
        saveSuccessMsg.value = '';
        saveErrorMsg.value = 'Try again later';
        saveloadingstatus.value = false;
      }
    }

    const successmsg = ref('');
    const errormsg = ref('');
    const loadingstatus = ref(false);

    async function saveData() {
      loadingstatus.value = true;
      try {
        if (props.studentDoc.data.adks[adkIndex].hasOwnProperty('activityEndedOn')) {
          props.studentDoc.data.adks[adkIndex].activityModifiedOn = new Date();
        } else {
          props.studentDoc.data.adks[adkIndex].activityEndedOn = new Date();
        }
        await props.studentDoc.update(() => ({
          isComplete: true,
          adkIndex
        }));
        ctx.emit('calculate-timer');
        successmsg.value = 'Success';
        errormsg.value = '';
        loadingstatus.value = false;
      } catch (err) {
        successmsg.value = '';
        errormsg.value = 'Try again later';
        loadingstatus.value = false;
      }
    }

    return {
      finishButtonDisabled,
      trainAdk,
      showInstructions,
      setupInstructions,
      fetchFormData,
      saveMessage,
      saveError,
      saveLoading,
      isLoading,
      saveSuccess,
      trainAdkData,
      saveProcess,
      getIframeUrl,
      videoComplete,
      successmsg,
      errormsg,
      loadingstatus,
      saveData,
      panel,
      saveProcessData,
      saveSuccessMsg,
      saveErrorMsg,
      saveloadingstatus,
      readonly,
      ...loading(
        () =>
          props.studentDoc.update(() => ({
            isComplete: true,
            adkIndex
          })),
        'Success',
        'Try again later'
      )
    };
  }
});
