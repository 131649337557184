var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{staticClass:"text-center mb-8"},[_c('h2',[_vm._v("Meet regularly with interns")])]),_c('validation-provider',{attrs:{"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ATextInput',{attrs:{"error-messages":errors,"rounded":"","outlined":"","label":"Meeting Name"},model:{value:(_vm.meetingName),callback:function ($$v) {_vm.meetingName=$$v},expression:"meetingName"}})]}}])}),_c('div',{staticClass:"text-start mb-2"},[_c('h4',[_vm._v("Select days & meeting cadence with interns")])]),_c('v-row',{staticClass:"mt-5 mb-5"},_vm._l((_vm.daysPerWeekList),function(item){return _c('div',{key:item,staticClass:"d-flex justify-content: center"},[_c('v-col',[_c('a-button',{staticClass:"white--text font-weight-bold",attrs:{"id":"day","depressed":"","width":"10px","rounded":"","color":_vm.daysPerWeek.includes(item) ? 'blue' : 'grey'},on:{"click":function($event){_vm.daysPerWeek.includes(item)
              ? _vm.daysPerWeek.splice(_vm.daysPerWeek.indexOf(item), 1)
              : _vm.daysPerWeek.push(item)}}},[_vm._v(_vm._s(item.split('')[0]))])],1)],1)}),0),_c('validation-provider',{attrs:{"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('ASelectInput',{attrs:{"error-messages":errors,"rounded":"","outlined":"","items":_vm.repeatTimes,"label":"Repeat every"},model:{value:(_vm.repeat),callback:function ($$v) {_vm.repeat=$$v},expression:"repeat"}})]}}])}),_c('div',{staticClass:"text-start mb-7"},[_c('h4',[_vm._v("Day & Time")])]),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pl-0 pr-1",attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{staticClass:"date-time-selector",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('vuetify-time-select',{attrs:{"outlined":"","rounded":"","error-messages":errors},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}})]}}])})],1),_c('v-col',{staticClass:"pl-1 pr-0",attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('ASelectInput',{attrs:{"error-messages":errors,"rounded":"","outlined":"","items":_vm.durationList,"label":"Duration"},model:{value:(_vm.duration),callback:function ($$v) {_vm.duration=$$v},expression:"duration"}})]}}])})],1)],1)],1),_c('v-menu',{ref:"weeklyMeetingDate",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"rounded":"","outlined":"","label":"Start on or after this cohort start date","readonly":"","error-messages":errors},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}]),model:{value:(_vm.weeklyMeetingDate),callback:function ($$v) {_vm.weeklyMeetingDate=$$v},expression:"weeklyMeetingDate"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"input":function($event){return _vm.$refs.weeklyMeetingDate.save(_vm.date)}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c('validation-provider',{attrs:{"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('ASelectInput',{attrs:{"error-messages":errors,"rounded":"","outlined":"","items":_vm.timeZoneList,"label":"Time zone"},model:{value:(_vm.timeZone),callback:function ($$v) {_vm.timeZone=$$v},expression:"timeZone"}})]}}])}),_c('div',{staticClass:"text-start mb-7"},[_c('h4',[_vm._v("Meeting Agenda")])]),_c('validation-provider',{attrs:{"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-textarea',{staticClass:"mb-3",attrs:{"error-messages":errors,"rounded":"","outlined":"","label":"Agenda","hint":"Enter standard items on the meeting agenda with interns"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})]}}])}),(_vm.user.googleDetails)?_c('validation-provider',{attrs:{"rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-combobox',{attrs:{"items":[
        '3 days before',
        '2 days before',
        '1 day before',
        '8 hours before',
        '1 hour before'
      ],"error-messages":errors,"rounded":"","label":"Notification","multiple":"","small-chips":"","outlined":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Press "),_c('kbd',[_vm._v("enter")]),_vm._v(" to add reward ")])],1)],1)]},proxy:true},{key:"selection",fn:function(ref){
      var attrs = ref.attrs;
      var item = ref.item;
      var parent = ref.parent;
      var selected = ref.selected;
return [_c('v-chip',_vm._b({class:'start-adk-career-options',attrs:{"input-value":selected,"label":"","small":""},on:{"click":function($event){return parent.selectItem(item)}}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(item)+" ")]),_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-close ")])],1)]}}],null,true),model:{value:(_vm.selectedValue),callback:function ($$v) {_vm.selectedValue=$$v},expression:"selectedValue"}})]}}],null,false,816151282)}):_vm._e(),(_vm.user.outlookDetails)?_c('validation-provider',{attrs:{"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('ASelectInput',{attrs:{"error-messages":errors,"rounded":"","outlined":"","items":[
        '3 days before',
        '2 days before',
        '1 day before',
        '8 hours before',
        '1 hour before'
      ],"label":"Notification"},model:{value:(_vm.reminderMinutes),callback:function ($$v) {_vm.reminderMinutes=$$v},expression:"reminderMinutes"}})]}}],null,false,1546323307)}):_vm._e(),[_c('v-sheet',{staticClass:"pa-5 switch-wrapper"},[_c('v-row',[_c('v-col',{staticClass:"pl-0 pr-0 pt-0"},[_c('h4',[_vm._v("Custom Location")])]),_c('v-col',{staticClass:"pl-0 pr-0 pt-0 switch-toggle-wrapper"},[_c('v-switch',{staticClass:"mt-0 pt-0 mr-10",attrs:{"disabled":_vm.user.googleDetails &&
              _vm.programDoc.data.adks[_vm.index].events &&
              _vm.programDoc.data.adks[_vm.index].events.weekly_meeting,"color":"blue"},model:{value:(_vm.isSwitchOn),callback:function ($$v) {_vm.isSwitchOn=$$v},expression:"isSwitchOn"}})],1),_c('v-col',{staticClass:"pl-0 pr-0 pt-0"},[_c('h4',[_vm._v("Digital Location")])])],1)],1)],_c('div',[(_vm.isSwitchOn)?_c('div',[(_vm.user.googleDetails)?_c('div',[_c('v-checkbox',{attrs:{"disabled":_vm.programDoc.data.adks[_vm.index].events &&
            _vm.programDoc.data.adks[_vm.index].events.weekly_meeting,"label":"Add Google Meet (Default, Recommended)","value":"Google Meet"},model:{value:(_vm.googleMeet),callback:function ($$v) {_vm.googleMeet=$$v},expression:"googleMeet"}}),(
            _vm.isGoogleMeet ||
            (_vm.programDoc.data.adks[_vm.index].events &&
              _vm.programDoc.data.adks[_vm.index].events.weekly_meeting)
          )?_c('div',[_c('ATextInput',{attrs:{"error-messages":_vm.errors,"rounded":"","outlined":"","disabled":"true","label":"Digital Location"},model:{value:(_vm.digitalLocation),callback:function ($$v) {_vm.digitalLocation=$$v},expression:"digitalLocation"}})],1):_c('div',[_c('validation-provider',{attrs:{"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('ATextInput',{attrs:{"error-messages":errors,"rounded":"","outlined":"","label":"Digital Location"},model:{value:(_vm.digitalLocation),callback:function ($$v) {_vm.digitalLocation=$$v},expression:"digitalLocation"}})]}}],null,false,2492310201)})],1)],1):(_vm.user.outlookDetails)?_c('div',[_c('validation-provider',{attrs:{"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('ATextInput',{attrs:{"error-messages":errors,"rounded":"","outlined":"","label":"Digital Location"},model:{value:(_vm.digitalLocation),callback:function ($$v) {_vm.digitalLocation=$$v},expression:"digitalLocation"}})]}}],null,false,2492310201)})],1):_vm._e()]):_c('div',[_c('validation-provider',{attrs:{"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('a-autocomplete',{attrs:{"is-rounded":"","error-message":errors,"placeholder":"Search Location","type":"street","label":"Custom Location"},on:{"val":_vm.setStreetValue},model:{value:(_vm.physicalLocation),callback:function ($$v) {_vm.physicalLocation=$$v},expression:"physicalLocation"}})]}}])})],1)]),_c('div',{staticClass:"text-center mb-8 mt-8"},[_c('h2',[_vm._v("Add attendees")])]),_c('div',[_c('v-checkbox',{attrs:{"label":"All employer hosts","value":"employer"},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-checkbox',{staticClass:"mt-0",attrs:{"label":"All current interns","value":"intern"},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }