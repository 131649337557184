





























































































import {
  defineComponent,
  PropType,
  reactive,
  toRefs,
  computed,
  inject
} from '@vue/composition-api';
import { TeamDoc } from './types';

export default defineComponent({
  name: 'TeamRow',

  props: {
    team: {
      required: true,
      type: Object as PropType<TeamDoc>
    },
    maxTeamMembers: {
      required: true,
      type: Number
    },
    userType: {
      required: true,
      type: String
      // participant: '',
      // organizer: '',
      // stakeholder: ''
    }
  },
  setup(props, ctx) {
    const readonly = inject('readonly', false);
    const state = reactive({
      joinTeamDialog: false,
      password: '',
      error: ''
    });
    const joinTeam = () => {
      if (state.password === props.team.data.password) ctx.emit('joinTeam', props.team.data._id);
      else state.error = 'That password did not work out =/';
    };
    const numMembers = computed(() => props.team.data.members.length);
    return { joinTeam, numMembers, ...toRefs(state), readonly };
  }
});
