


















































































































































































import { computed, defineComponent, PropType, ref, Ref } from '@vue/composition-api';
import type { Comment, CommentAuthor, CommentType } from '@/@types/comment.d';
import UserAvatar from '@/components/UserAvatar.vue';
import moment from 'moment';
import { ObjectId } from 'bson';

export default defineComponent({
  name: 'Comments',

  components: {
    UserAvatar
  },

  props: {
    comments: {
      required: true,
      type: Array as PropType<Comment[]>
    },
    currentUser: {
      required: true,
      type: Object as PropType<CommentAuthor>
    },
    type: {
      required: false,
      type: String as PropType<CommentType>,
      default: 'team'
    },
    availableTypes: {
      required: false,
      type: Array as PropType<CommentType[]>,
      default: () => ['team', 'organizers', 'stakeholders']
    }
  },

  setup(props, ctx) {
    const mappedComments = computed(() => {
      return props.comments.map(comment => {
        return {
          ...comment,
          createdByCurrentUser:
            comment.author?._id.toHexString() === props.currentUser._id.toHexString()
        };
      });
    });

    const newComment = ref('');

    const isNewCommentEmpty = computed(() => {
      return newComment.value.trim().length === 0;
    });

    const addComment = () => {
      if (isNewCommentEmpty.value) return;
      ctx.emit('add-comment', newComment.value);
      newComment.value = '';
    };

    const formatTime = (time: Date) => {
      const now = moment();
      const created = moment(time);

      if (now.diff(created, 'days') < 1) {
        return created.fromNow();
      }
      return created.format('MMM D, YYYY');
    };

    const selectedMenu = ref(props.type);

    const labels = {
      stakeholders: 'Stakeholders Only',
      organizers: 'Organizers Only',
      team: 'Team Only'
    };

    const icons = {
      stakeholders: 'mdi-account-group',
      organizers: 'mdi-account-cog',
      team: 'mdi-account-multiple'
    };

    const selectedMenuLabel = computed(() => {
      return labels[selectedMenu.value];
    });

    const selectedMenuIcon = computed(() => {
      return icons[selectedMenu.value];
    });

    const menus = computed(() => {
      return props.availableTypes.map(type => {
        return {
          label: labels[type],
          value: type,
          icon: icons[type]
        };
      });
    });

    const handleCommentModeChange = (type: CommentType) => {
      selectedMenu.value = type;
      ctx.emit('comment-mode-changed', type);
    };

    const commentBeingEditedId: Ref<ObjectId | undefined> = ref(undefined);
    const commentBeingEditedContent: Ref<string | undefined> = ref(undefined);
    const commentBeingDeletedId: Ref<ObjectId | undefined> = ref(undefined);
    const showDeleteConfirmation = ref(false);

    const handleCommentActionClick = (action: 'edit' | 'delete', comment: Comment) => {
      if (action === 'edit') {
        commentBeingEditedId.value = comment._id;
      } else if (action === 'delete') {
        commentBeingDeletedId.value = comment._id;
        showDeleteConfirmation.value = true;
      }
    };

    const cancelEditComment = () => {
      commentBeingEditedId.value = undefined;
      commentBeingEditedContent.value = undefined;
    };

    const handleCommentChange = (newComment: string) => {
      commentBeingEditedContent.value = newComment;
    };

    const saveComment = (comment: Comment) => {
      if (commentBeingEditedId.value) {
        ctx.emit('edit-comment', {
          _id: comment._id,
          content: commentBeingEditedContent.value
        });
      }
      cancelEditComment();
    };

    const isCommentEdited = (comment: Comment) => {
      return comment.created?.getTime() !== comment.updated?.getTime();
    };

    const handleConfirmDeleteComment = () => {
      console.log(commentBeingDeletedId.value);
      ctx.emit('delete-comment', commentBeingDeletedId.value);
      commentBeingDeletedId.value = undefined;
      showDeleteConfirmation.value = false;
    };

    return {
      mappedComments,
      addComment,
      newComment,
      formatTime,
      selectedMenu,
      selectedMenuLabel,
      selectedMenuIcon,
      menus,
      handleCommentModeChange,
      handleCommentActionClick,
      commentBeingEditedId,
      cancelEditComment,
      handleCommentChange,
      commentBeingEditedContent,
      saveComment,
      isCommentEdited,
      showDeleteConfirmation,
      handleConfirmDeleteComment,
      isNewCommentEmpty
    };
  }
});
