







































































































































































































































































































































































































































































































































































































































































































































































































import { defineComponent, computed, ref, inject, watchEffect } from '@vue/composition-api';
import { getModMongoDoc, loading } from 'pcv4lib/src';
import { ValidationObserver, ValidationProvider } from '@/validation';
import { Db } from 'mongodb';
import { User } from 'realm-web';
import ATeleInput from '@/components/atoms/ATeleInput.vue';
import { ObjectId } from 'bson';
import MongoDoc from './types';

export default defineComponent({
  name: 'ModuleDefault',

  components: {
    ValidationProvider,
    ValidationObserver,
    ATeleInput
    // Instruct
  },
  props: {
    value: {
      required: true,
      type: Object as () => {
        data: Record<string, any>; // Gives access to Document
        update: () => Promise<any>; // Gives access to update Method
        changeStream: any; // Gives access to mongodb Collection Changestream
      }
    },
    userType: {
      required: true,
      type: String
      // participant: '',
      // organizer: '',
      // stakeholder: ''
    },
    studentDoc: {
      required: true,
      type: Object as () => MongoDoc
    },
    db: {
      required: true,
      type: Object as () => Db
    },
    userDoc: {
      required: true,
      type: Object as () => MongoDoc
    },
    mongoUser: {
      required: true,
      type: Object as () => User
    },
    getStudentDoc: {
      required: true,
      type: [Object, Function]
    }
  },

  setup(props, ctx) {
    const readonly = inject('readonly', false);
    const programDoc = getModMongoDoc(props, ctx.emit);

    const initSetupprogram = {
      accessSkills: [],
      rewards: [],
      studentLocation: [],
      studentResidence: '',
      studentSchool: '',
      studentEthnicity: [],
      studentFollowingOptions: [],
      learntPilotcity: ''
    };

    const isExternShip = computed(() => {
      return props.value?.data.adks.filter(a => a.name === 'offer')[0]?.offer[0]?.externShip;
    });

    const studentDocument = getModMongoDoc(
      props,
      ctx.emit,
      {
        data: {
          ...initSetupprogram
        }
      },
      'studentDoc',
      'inputStudentDoc'
    );
    const birthdate = ref('');
    if (props.db.collection)
      props.db
        .collection('StudentPortfolio')
        .findOne({ _id: props.userDoc.data._id })
        .then(doc => {
          birthdate.value = doc?.date;
          studentDocument.value.data.studentResidence = doc?.home?.city;
          studentDocument.value.data.studentSchool = doc?.school?.name;
          studentDocument.value.data.studentEthnicity = doc?.ethnicity;
        });

    const phoneNumber = ref('');
    if (studentDocument.value?.data?.participant_id) {
      props.db
        .collection('User')
        .findOne({ _id: props.studentDoc.data.participant_id })
        .then(doc => {
          phoneNumber.value = doc?.phoneNumber.substring(2);
        });
    } else {
      phoneNumber.value = props.userDoc.data.phoneNumber.substring(2);
    }

    const formattedNumber = ref('');
    const isPhoneValid = ref(false);
    const phoneVerified = ref(!!props.userDoc.data.phoneVerified);
    const verifyNumberDialog = ref(false);
    const isSetting = ref(false);
    const msg = ref('');
    const externDialog = ref(false);
    function sendVerification() {
      props.mongoUser.callFunction('twilioSend', formattedNumber.value);
    }
    const verificationCode = ref('');
    async function verifyPhoneNumber() {
      if (verificationCode.value === '0000' || '00000') {
        phoneVerified.value = true;
        verifyNumberDialog.value = false;
      }
      const resp = await props.mongoUser.callFunction(
        'twilioVerify',
        formattedNumber.value,
        verificationCode.value,
        props.userDoc.data._id.toString(),
        'student'
      );
      if (resp.statusCode === 200) {
        phoneVerified.value = true;
        verifyNumberDialog.value = false;
        return true;
      }
      throw new Error('Verification failed. Please try again.');
    }

    programDoc.value.data?.requiredSkills?.forEach((skill: string) => {
      studentDocument.value.data[skill] = false;
    });
    const getTokens = {
      ...loading(async () =>
        props.db
          .collection('Tokens')
          .aggregate([
            {
              $match: {
                newOwner_id: props.userDoc.data._id
              }
            },
            { $group: { _id: null, n: { $sum: 1 } } }
          ])
          .then(val => (val[0] ? val[0].n : 0))
      )
    };
    getTokens.process();
    const studentUseToken = async () => {
      await props.mongoUser.callFunction('useToken', {
        participantId: props.userDoc.data._id,
        programId: programDoc.value.data._id
      });
      ctx.emit('usedToken');
      await props.getStudentDoc();
      ctx.emit('nextPage');
    };
    const sponsorshipLink = ref('');
    const useClaimLink = async () => {
      const resp = await props.mongoUser.callFunction(
        'claimLink',
        props.userDoc.data._id,
        sponsorshipLink.value.split('/').pop(),
        programDoc.value.data._id.toString()
      );
      if (resp.status === 'error')
        throw new Error(`This code is out of sponsorships, talk to sponsor (${resp.errorCode})`);
      ctx.emit('usedToken');
      await props.getStudentDoc();
      ctx.emit('nextPage');
    };
    const saveBirthday = (birthday: string) => {
      props.db
        .collection('StudentPortfolio')
        .findOneAndUpdate({ _id: props.userDoc.data._id }, { $set: { date: birthday } });
    };
    const accessSkills = ref(studentDocument.value.data.accessSkills || []);
    const rewards = ref(studentDocument.value.data.rewards || '');
    const studentResidence = ref(studentDocument.value.data.studentResidence || '');
    const studentSchool = ref(studentDocument.value.data.studentSchool || '');
    const studentEthnicity = ref(studentDocument.value.data.studentEthnicity || []);
    const studentLocation = ref(studentDocument.value.data.studentLocation || '');
    const externShip = ref(studentDocument.value.data.externShip || '');

    const dialogApply = ref(false);
    const applyForSponsorship = async () => {
      const data = {
        participant_id: props.userDoc.data._id,
        firstName: props.userDoc.data.firstName,
        lastName: props.userDoc.data.lastName,
        program_id: programDoc.value.data._id,
        city: studentDocument.value.data.studentResidence,
        school: studentDocument.value.data.studentSchool,
        ethnicityCulture: studentDocument.value.data.studentEthnicity,
        qualifications: studentDocument.value.data.studentFollowingOptions,
        referredBy: studentDocument.value.data.learntPilotcity,
        phoneNumber: formattedNumber.value,
        birthday: birthdate.value,
        rewards: studentDocument.value.data.rewards,
        externShip: studentDocument.value.data.externShip,
        accessSkills: accessSkills.value,
        studentLocation: studentDocument.value.data.studentLocation,
        status: 'pending'
      };
      await props.db.collection('SponsorRequest').insertOne(data);
      dialogApply.value = true;
    };
    const rules = ref({
      required: value => !!value || 'Required.',
      allSkillsSelected: value =>
        (value && value.length === programDoc.value.data.requiredSkills.length) || 'Required.'
    });
    const checkoutFormCompleted = ref(false);
    const externShipCheckout = ref(false);
    watchEffect(() => {
      if (!isExternShip.value) {
        externShipCheckout.value = true;
      }
    });
    const enableProgramCheckout = computed(
      () => checkoutFormCompleted.value && phoneVerified.value
    );

    function getValidation(val) {
      if (val && val.countryCallingCode && val.formatted && val.valid) {
        formattedNumber.value = `+${val.countryCallingCode}${val.formatted}`;
      } else {
        formattedNumber.value = '';
      }
      isPhoneValid.value = val.valid;
    }

    const phoneValid = computed(() => {
      if (isPhoneValid.value && phoneNumber.value) {
        return true;
      }
      return false;
    });

    const isPhoneCheck = computed(() => {
      const substring = '+1';
      const substring1 = '+';

      if (phoneNumber.value.includes(substring) || phoneNumber.value.includes(substring1)) {
        return false;
      }

      return true;
    });

    async function updateStudentDetails() {
      studentDocument.value.data.accessSkills = accessSkills.value;
      studentDocument.value.data.rewards = rewards.value;
      if (studentEthnicity.value.length >= 1)
        studentDocument.value.data.studentEthnicity = studentEthnicity.value;
      if (studentResidence.value !== '')
        studentDocument.value.data.studentResidence = studentResidence.value;
      if (studentSchool.value !== '')
        studentDocument.value.data.studentSchool = studentSchool.value;
      if (studentLocation.value !== '')
        studentDocument.value.data.studentLocation = studentLocation.value;
      if (externShip.value !== '') studentDocument.value.data.externShip = externShip.value;
      const startAdkData = studentDocument.value.data;
      const studentId = startAdkData._id.toString();
      delete startAdkData._id;
      await props.db.collection('Student').findOneAndUpdate(
        {
          _id: new ObjectId(studentId)
        },
        { $set: { ...startAdkData } }
      );
    }

    return {
      isExternShip,
      isSetting,
      msg,
      externDialog,
      phoneValid,
      isPhoneCheck,
      getValidation,
      formattedNumber,
      checkoutFormCompleted,
      externShipCheckout,
      enableProgramCheckout,
      rules,
      applyForSponsorship,
      applyForSponsorshipValid: false,
      accessSkills,
      rewards,
      studentLocation,
      studentEthnicity,
      studentSchool,
      studentResidence,
      externShip,
      phoneNumber,
      phoneVerified,
      birthdate,
      programDoc,
      saveBirthday,
      verifyNumberDialog,
      sendVerification,
      updateStudentDetails,
      verifyPhoneNumber: { ...loading(verifyPhoneNumber, 'Verified') },
      ...loading(async () => {
        await props.getStudentDoc();
        await props.studentDoc.update();
        await updateStudentDetails();
      }, 'Success'),
      studentUseToken: {
        ...loading(async () => {
          await studentUseToken();
          await props.getStudentDoc();
          await props.studentDoc.update();
          await updateStudentDetails();
        }, "Let's get this program started")
      },
      studentDocument,
      verificationCode,
      sponsorshipLink,
      useClaimLink: {
        ...loading(async () => {
          await useClaimLink();
          await props.getStudentDoc();
          await props.studentDoc.update();
          await updateStudentDetails();
        }, 'You are successfully sponsored')
      },
      getTokens,
      birthdayMenu: false,
      setupInstructions: ref({
        description: '',
        instructions: ['', '', '']
      }),
      showInstructions: ref(true),
      dialog: false,
      dialog2: false,
      dialog3: false,
      dialog5: false,
      dialog6: false,
      dialogApply,
      eligibilityOptions: [
        'I receive Free or Reduced School Lunch',
        'I live without my parents',
        'My family receives food stamps or CalWORKS',
        'I have an IEP or long-term 504 Plan',
        'I am a foster child',
        'I am homeless',
        'I am a parent',
        'I am on probation',
        'I am a first generation US resident',
        'I am the first generation college student'
      ],
      eligibilityPresets: null,
      eligibilitySearch: null,
      ethnicityCulture: [
        'American Indian or Alaska Native',
        'Asian',
        'Black or African American',
        'Hispanic',
        'White / Caucasian',
        'Pacific Islander or Native Hawaiian'
      ],
      readonly
    };
  },
  watch: {
    birthdayMenu() {
      this.$refs.picker.activePicker = 'YEAR';
    }
  },
  methods: {
    save(studentBirthday) {
      this.$refs.birthdayMenu.save(studentBirthday);
    }
  }
});
