





































































































































































































































































































































































/* eslint-disable no-prototype-builtins */
/* eslint-disable vue/no-mutating-props */
/* eslint-disable no-param-reassign */
import { ref, PropType, defineComponent, computed } from '@vue/composition-api';
import { useDbState } from '@/store';
import axios from 'axios';
import * as Realm from 'realm-web';
import MPresentation from '@/components/molecules/deliverable/m-presentation.vue';
import MUserInterview from '@/components/molecules/deliverable/m-user-interview.vue';
import MProjectImplementation from '@/components/molecules/deliverable/m-project-implementation.vue';
import MTasks from '@/components/molecules/deliverable/m-tasks.vue';
import MDigitalResume from '@/components/molecules/deliverable/m-digital-resume.vue';
import MUserFaq from '@/components/molecules/deliverable/m-user-faq.vue';
import MCaseStudy from '@/components/molecules/deliverable/m-case-study.vue';
import MProjectPlan from '@/components/molecules/deliverable/m-project-plan.vue';
import MCreateDeliverable from '@/components/molecules/deliverable/m-create-deliverable.vue';
import MDeliverableTable from '@/components/molecules/deliverable/m-deliverable-team.vue';
import Instruct from './ModuleInstruct.vue';
import { MongoDoc } from './types';

export default defineComponent({
  name: 'ModuleDefault',
  components: {
    Instruct,
    MCreateDeliverable,
    MDeliverableTable,
    MProjectPlan,
    MCaseStudy,
    MTasks,
    MPresentation,
    MUserFaq,
    MDigitalResume,
    MProjectImplementation,
    MUserInterview
  },
  props: {
    value: {
      required: true,
      type: Object as PropType<MongoDoc>
    },
    teamDoc: {
      required: true,
      type: Object as PropType<MongoDoc>
    },
    userType: {
      required: true,
      type: String
    },
    currentUser: {
      required: true,
      type: Object as PropType<Realm.User>
    },
    studentDoc: {
      required: false,
      type: Object as PropType<MongoDoc>,
      default: () => {}
    },
    uploadItem: {
      required: false,
      type: Function as PropType<(item: { item: File }) => Promise<any>>,
      default: () => {}
    },
    mongoUser: {
      required: true,
      type: Object as () => Realm.User
    }
  },
  setup(props, ctx) {
    const deliverable = ref('');
    const activeTab: any = ref('');
    const { user } = useDbState(['user']);
    const currentUserId = user.value?._id.toString();
    const teamList: any = ref([]);
    const setupInstructions = ref({
      description: '',
      instructions: ['', '', '']
    });
    const showInstructions = ref(true);
    const isLoading = ref(false);
    const isFetching = ref(false);
    const isSubmitting = ref(false);
    const currentTabData = ref({});
    const onlyView = ref(false);
    const projectPlan = ref('');
    const adminSettings: any = ref();

    const programDocAdk = computed(() => {
      const adkIndex = props.value.data?.adks?.findIndex(a => a.name === 'deliverable');
      return props.value.data?.adks[adkIndex]?.settings;
    });

    function viewOnly() {
      const isMember = currentTabData.value?.members?.filter(m => m._id === currentUserId);
      if (
        currentUserId === currentTabData.value?.ownerId ||
        (isMember.length && !isMember[0].isMembershipPending)
      ) {
        onlyView.value = false;
      } else {
        onlyView.value = true;
      }
    }

    async function fetchDeliverableData(val) {
      if (val.ownerId && val.programId) {
        isFetching.value = true;
        try {
          const ENDPOINT_API = process.env.VUE_APP_DELIVERABLE;
          const res = await axios.get(
            `${ENDPOINT_API}?deliverable_id=${val._id}&user_id=${val.ownerId}&program_id=${val.programId}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
              }
            }
          );
          if (res.data.data) {
            currentTabData.value = res.data.data;
            isFetching.value = false;
            viewOnly();
          }
        } catch (error) {
          console.log(error);
          isFetching.value = false;
        }
      }
    }

    async function fetchDeliverableAdminSetting() {
      try {
        isLoading.value = true;
        const ENDPOINT_API = process.env.VUE_APP_DELIVERABLE_ADMIN;

        const res = await axios.get(`${ENDPOINT_API}?type=deliverable_setting`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        });
        if (res.data.data) {
          adminSettings.value = res.data.data.deliverableSettings;
          isLoading.value = false;
        }
      } catch (error) {
        console.log(error);
        isLoading.value = false;
      }
    }

    async function fetchDeliverables() {
      try {
        teamList.value = [];
        teamList.value.push({
          _id: 0,
          name: 'New',
          icon: 'mdi-note-plus-outline'
        });
        const tab = activeTab.value;
        isLoading.value = true;
        const ENDPOINT_API = process.env.VUE_APP_DELIVERABLE_ALL;

        const res = await axios.get(`${ENDPOINT_API}?program_id=${ctx.root.$route.query.program}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        });
        if (res.data.data) {
          await fetchDeliverableAdminSetting();
          activeTab.value = tab;
          const list = res.data.data;
          list.forEach(ele => {
            if (ele.ownerId === currentUserId) {
              teamList.value.splice(1, 0, ele);
            } else {
              teamList.value.push(ele);
            }
          });
          await fetchDeliverableData(teamList.value[1]);
          activeTab.value = 1;
          isLoading.value = false;
        }
      } catch (error) {
        console.log(error);
        isLoading.value = false;
      }
    }

    fetchDeliverables();

    return {
      setupInstructions,
      fetchDeliverableData,
      showInstructions,
      deliverable,
      teamList,
      activeTab,
      adminSettings,
      fetchDeliverables,
      isLoading,
      user,
      currentUserId,
      currentTabData,
      isFetching,
      viewOnly,
      onlyView,
      projectPlan,
      isSubmitting,
      fetchDeliverableAdminSetting,
      programDocAdk
    };
  }
});
