
















































































































import { ref, defineComponent, inject } from '@vue/composition-api';

export default defineComponent({
  name: 'ModuleInstruct',

  setup() {
    const readonly = inject('readonly', false);

    const boilerInstructions = ref([
      'Connect your Google or Outlook calendar using your email',
      'View regular meeting calendar invites sent to you by your employer',
      'Attend regular meetings with employer & intern peers'
    ]);
    const goal = ref([
      'To connect your Google or Outlook calendar, view regular meetings sent to you & attend the meetings with employers & intern peers'
    ]);

    return { boilerInstructions, tutorialDialog: false, goal, readonly };
  }
});
