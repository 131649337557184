var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"position":"relative"}},[_c('div',{staticClass:"module-default__instructions"},[_c('v-expansion-panels',{staticClass:"module-default__instructions",attrs:{"flat":""},model:{value:(_vm.showInstructions),callback:function ($$v) {_vm.showInstructions=$$v},expression:"showInstructions"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{directives:[{name:"show",rawName:"v-show",value:(_vm.showInstructions),expression:"showInstructions"}],staticClass:"pa-0",attrs:{"hide-actions":""},on:{"click":function($event){_vm.showInstructions = true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var open = ref.open;
return [_c('v-scroll-y-transition',{attrs:{"hide-on-leave":""}},[(!open)?_c('div',{staticClass:"d-flex flex-column justify-center"},[_c('v-icon',{staticClass:"d-flex justify-center",attrs:{"color":"grey"}},[_vm._v(" mdi-chevron-down ")]),_c('div',{staticClass:"module-default__collapse-title"},[_vm._v("INSTRUCTIONS")])],1):_vm._e()])]}}])}),_c('v-expansion-panel-content',[_c('Instruct',{attrs:{"readonly":""},model:{value:(_vm.setupInstructions),callback:function ($$v) {_vm.setupInstructions=$$v},expression:"setupInstructions"}}),_c('div',{on:{"click":function($event){_vm.showInstructions = true}}},[_c('div',{staticClass:"module-default__collapse-title"},[_vm._v("CLOSE")]),_c('v-icon',{staticClass:"d-flex justify-center",attrs:{"color":"grey"}},[_vm._v(" mdi-chevron-up ")])],1)],1)],1)],1)],1),_c('v-progress-linear',{staticClass:"mt-3",attrs:{"color":"#dedede","height":"2","value":"100","buffer-value":"100","stream":""}}),(_vm.fetching)?_c('v-overlay',{staticClass:"mt-16",staticStyle:{"height":"600px","width":"100%"},attrs:{"absolute":"","z-index":100,"opacity":0.8}},[_c('v-progress-circular',{attrs:{"size":"80","width":"5","indeterminate":"","color":"red"}})],1):_vm._e(),(_vm.isClockOut)?_c('iframe',{staticStyle:{"border":"none","border-radius":"0px"},attrs:{"src":("https://www.videoask.com/fcuxb204g#contact_email=" + (encodeURI(
      _vm.user.email
    )) + "&contact_name=" + (encodeURI(
      _vm.user.firstName + ' ' + _vm.user.lastName
    )) + "&contact_phone_number=" + (encodeURI(_vm.user.phoneNumber)) + "&program_id=" + (encodeURI(
      _vm.$route.params.programId
    )) + "&participant_id=" + (encodeURI(_vm.user._id))),"allow":"camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;","width":"100%","height":"600px"}}):_c('iframe',{staticStyle:{"border":"none","border-radius":"0px"},attrs:{"src":("https://www.videoask.com/fst8l23lr#contact_email=" + (encodeURI(
      _vm.user.email
    )) + "&contact_name=" + (encodeURI(
      _vm.user.firstName + ' ' + _vm.user.lastName
    )) + "&contact_phone_number=" + (encodeURI(_vm.user.phoneNumber)) + "&program_id=" + (encodeURI(
      _vm.$route.params.programId
    )) + "&participant_id=" + (encodeURI(_vm.user._id))),"allow":"camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;","width":"100%","height":"600px"}}),(_vm.isTimeSheetAvailable)?_c('div',{staticClass:"d-flex justify-center mt-4"},[(_vm.isClockOut)?_c('div',{staticClass:"d-flex flex-column justify-center"},[_vm._m(0),_c('div',{staticClass:"d-flex justify-center"},[_c('v-icon',{attrs:{"color":"green","x-large":""}},[_vm._v("mdi-clock-check")])],1)]):_vm._e(),(_vm.isClockIn)?_c('div',{staticClass:"d-flex flex-column justify-center"},[_vm._m(1),_c('div',{staticClass:"d-flex justify-center"},[_c('v-icon',{attrs:{"color":"red","x-large":""}},[_vm._v("mdi-clock-remove-outline")])],1)]):_vm._e()]):_vm._e(),_c('Table',{ref:"table",attrs:{"student":_vm.studentDoc,"check-status":_vm.checkStatus}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex justify-center mb-4"},[_c('h3',[_vm._v("You're clocked in")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex justify-center mb-4"},[_c('h3',[_vm._v("You're clocked out")])])}]

export { render, staticRenderFns }