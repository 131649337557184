










































































































/* eslint-disable no-prototype-builtins */
/* eslint-disable vue/no-mutating-props */
/* eslint-disable no-param-reassign */
import { ref, PropType, defineComponent, computed, watchEffect } from '@vue/composition-api';
import * as Realm from 'realm-web';
// import ABtn from '@/components/atoms/AButton.vue';
// import ATextField from '@/components/atoms/ATextInput.vue';
// eslint says it can't find these modules from @types/node
// can be fixed by removing "./node_modules/@types" from typeRoots in tsconfig.json
// but then you get errors for unit test related imports
// anyway this doesn't matter since it will be server-side
import Instruct from './ModuleInstruct.vue';
import { MongoDoc } from './types';

export default defineComponent({
  name: 'ModuleDefault',
  components: {
    Instruct
  },
  props: {
    value: {
      required: true,
      type: Object as PropType<MongoDoc>
    },
    studentDoc: {
      required: true,
      type: Object as PropType<MongoDoc>
    },
    userType: {
      required: true,
      type: String
    },
    currentUser: {
      required: true,
      type: Object as PropType<Realm.User>
    }
  },
  setup(props, ctx) {
    const welcome = ref('');
    const programDoc: any = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    const index = programDoc.value.data.adks.findIndex(obj => {
      if (obj) {
        return obj.name === 'welcome';
      }
      return false;
    });
    let studentDocData: any = computed({
      get: () => props.studentDoc,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    let studentDocIndex = studentDocData.value?.data?.adks.findIndex(obj => {
      if (obj) {
        return obj.name === 'welcome';
      }
      return false;
    });
    const isDisabled = ref(false);
    const isWelcomeAdkExists = ref(false);
    watchEffect(() => {
      if (props.studentDoc) {
        studentDocData = computed({
          get: () => props.studentDoc,
          set: newVal => {
            ctx.emit('input', newVal);
          }
        });
        studentDocIndex = studentDocData.value?.data?.adks.findIndex(obj => {
          if (obj) {
            return obj.name === 'welcome';
          }
          return false;
        });
        if (studentDocIndex > 0 && studentDocData.value.data.adks[studentDocIndex].completed) {
          isWelcomeAdkExists.value = true;
        }
      }
    });
    if (studentDocIndex > 0 && studentDocData.value.data.adks[studentDocIndex].completed) {
      isWelcomeAdkExists.value = true;
    }

    const isLoading = ref(false);

    async function openGetStartedFolder() {
      let link;
      if (programDoc.value.data.adks[index].driveLink.includes('https://')) {
        link = programDoc.value.data.adks[index].driveLink;
      } else {
        link = `https://${programDoc.value.data.adks[index].driveLink}`;
      }
      window.open(link, '_blank');
    }
    const setupInstructions = ref({
      description: '',
      instructions: ['', '', '']
    });
    const showInstructions = ref(true);

    return {
      setupInstructions,
      showInstructions,
      welcome,
      programDoc,
      index,
      studentDocData,
      studentDocIndex,
      isLoading,
      isDisabled,
      openGetStartedFolder,
      isWelcomeAdkExists
    };
  }
});
