
























































































import { computed, ref, watchEffect } from '@vue/composition-api';
import * as Realm from 'realm-web';
import { Video } from '@/views/Guide/activities/demo/components/types';
import ABtn from '@/components/atoms/AButton.vue';
import ATextField from '@/components/atoms/ATextInput.vue';
import axios from 'axios';

export default {
  components: {
    ABtn,
    ATextField
  },

  props: {
    viewOnly: {
      type: Boolean,
      default: false
    },
    mongoUser: {
      required: true,
      type: Object as () => Realm.User
    },
    data: {
      type: [Object, Array],
      default: () => {}
    },
    programDoc: {
      type: Object,
      default: () => {}
    }
  },

  setup(props, ctx) {
    const submittedVideo: any = ref({});
    const successMsg = ref(false);
    const errorMsg = ref(false);
    const isCreating = ref(false);
    const msg = ref('');
    const verifyLoading = ref(false);
    const apiErrors = ref<Array<string>>([]);

    const presentationLink = ref(
      props.data.projectPresentation ? `https://youtu.be/${props.data.projectPresentation.id}` : ''
    );

    const programIndex = props.programDoc?.data?.adks?.findIndex(obj => {
      if (obj) {
        return obj.name === 'deliverable';
      }
      return false;
    });

    const maxMinutes = computed(() => {
      return props.programDoc?.data?.adks[programIndex]?.settings?.presentationSettings?.maxMins;
    });

    async function savePresentation() {
      isCreating.value = true;
      try {
        const data = {
          projectPresentation: submittedVideo.value
        };
        const ENDPOINT_API = process.env.VUE_APP_DELIVERABLE;
        const res = await axios.patch(`${ENDPOINT_API}?deliverable_id=${props.data._id}`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        });
        if (res.data.data) {
          successMsg.value = true;
          msg.value = res.data.message;
          isCreating.value = false;
        }
      } catch (error) {
        console.log(error);
        errorMsg.value = true;
        msg.value = 'Something went wrong';
        isCreating.value = false;
      }
    }

    async function verifyLink() {
      verifyLoading.value = true;
      const res: {
        statusCode: number;
        error?: string;
        body?: { submittedVideo: Video };
      } = await props.mongoUser?.callFunction('ADK_Demo', {
        operation: 'submitResponse',
        payload: {
          videoLink: presentationLink.value,
          videoMaxLength: maxMinutes.value || 60
        }
      });
      if (res.statusCode === 200) {
        submittedVideo.value = res.body!.submittedVideo;
        if (submittedVideo.value) await savePresentation();
        verifyLoading.value = false;
      } else if (res.error) {
        apiErrors.value.push(res.error);
        verifyLoading.value = false;
      }
    }

    watchEffect(() => {
      if (props.data.projectPresentation) {
        submittedVideo.value = props.data.projectPresentation;
      }
    });

    return {
      successMsg,
      msg,
      errorMsg,
      submittedVideo,
      presentationLink,
      verifyLoading,
      apiErrors,
      verifyLink,
      savePresentation,
      isCreating,
      maxMinutes
    };
  }
};
