














































































































































































import { defineComponent, ref, onMounted, computed } from '@vue/composition-api';
import axios from 'axios';
import moment from 'moment';
import TABLE_HEADER from './const';

export default defineComponent({
  name: 'TableView',
  props: {
    student: {
      required: true
    },
    checkStatus: {
      type: Function,
      required: true
    },
    isMonitor: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const headers = ref(TABLE_HEADER);
    const isFetching = ref(false);
    const isAbsolute = ref(true);
    const isLoading = ref(false);
    const isTimeSheet = ref(true);
    const clockOuts = ref(false);
    const clockIns = ref(false);
    const color = ref('');
    const tableContents = ref([]);
    const timeLog = ref([]);
    const interactionData = ref();
    const receiptUrlDialog = ref(false);
    const receiptUrl = ref('');
    const isDeleting = ref(false);
    const confirmDelete = ref(false);
    const currentPage = ref(1);

    const selectData = ref([
      { name: 'Current Interns', value: 'current_interns' },
      { name: 'Past Interns', value: 'past_interns' },
      { name: 'Future Interns', value: 'future_interns' }
    ]);

    const minHours = computed(() => {
      return props.student?.data?.offerDetails?.minHours;
    });

    function openReceiptDialog(val) {
      receiptUrl.value = '';
      receiptUrlDialog.value = true;
      receiptUrl.value = val;
    }

    function totalHours() {
      timeLog.value = [];
      const list = tableContents.value.map(a => a.timesheetFilledAt).reverse();
      for (let i = 0; i < list.length; i += 2) {
        const dateOne = moment(list[i]);
        const dateTwo = moment(list[i + 1]);
        const minutes = dateTwo.diff(dateOne, 'minutes');
        if (dateOne._f && dateTwo._f) timeLog.value.push(minutes);
      }
    }

    const hours = computed(() => {
      let total;
      if (timeLog.value.length) {
        total = timeLog.value.reduce((a, b) => a + b, 0);
        return `${Math.floor(total / 60)}:${(total % 60).toString().padStart(2, '0')}`;
      }
      return '00:00';
    });

    const progressValue = computed(() => {
      const minHr = props.student?.data?.offerDetails?.minHours * 60;
      return (timeLog.value.reduce((a, b) => a + b, 0) / minHr) * 100;
    });

    const getTableData = () => {
      const API_ENDPOINT = process.env.VUE_APP_FILL_TIMESHEET_DB;
      const id = ctx.root._route.params.programId;
      isFetching.value = true;
      axios
        .get(`${API_ENDPOINT}?student_id=${props.student?.data?._id}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        })
        .then(res => {
          isLoading.value = false;
          isFetching.value = false;
          tableContents.value = res.data.data;
          if (tableContents.value.length >= 1) totalHours();
        })
        .catch(error => console.log(error));
    };

    async function getInteractionList() {
      try {
        const API_ENDPOINT = process.env.VUE_APP_TIMESHEET_INT;
        const res = await axios.get(
          `${API_ENDPOINT}?program_id=${ctx.root._route.params.programId}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
            }
          }
        );
        interactionData.value = res.data.data;
      } catch (error) {
        console.log(error);
      }
    }

    async function deleteEntry() {
      try {
        isDeleting.value = true;
        const data = {
          student_id: props.student?.data?._id.toString()
        };
        const API_ENDPOINT = process.env.VUE_APP_TIMESHEET_DEL;
        const res = await axios.delete(`${API_ENDPOINT}?student_id=${props.student?.data?._id}`);

        if (res.data.data) {
          await props.checkStatus();
          getTableData();
          isDeleting.value = false;
          confirmDelete.value = false;
        }
      } catch (error) {
        console.log(error);
        isDeleting.value = false;
      }
    }

    onMounted(() => {
      getTableData();
      getInteractionList();
    });

    function paginate(val) {
      currentPage.value = val;
    }

    return {
      totalHours,
      currentPage,
      paginate,
      confirmDelete,
      isDeleting,
      deleteEntry,
      openReceiptDialog,
      getInteractionList,
      interactionData,
      isTimeSheet,
      headers,
      tableContents,
      isLoading,
      isAbsolute,
      isFetching,
      selectData,
      color,
      moment,
      hours,
      progressValue,
      getTableData,
      minHours,
      clockOuts,
      clockIns,
      receiptUrl,
      receiptUrlDialog,
      peerClockIns: ref(false),
      peerClockOuts: ref(false)
    };
  }
});
