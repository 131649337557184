









































































import { defineComponent } from '@vue/composition-api';
// import Instruct from './ModuleInstruct.vue';
import {
  group,
  required,
  lockOrder,
  deliverable,
  notifications,
  accessibility,
  endEarly
} from './const';
// import gql from 'graphql-tag';

export default defineComponent({
  name: 'ModulePresets',
  components: {
    // Instruct
  },
  data() {
    return {
      group,
      required,
      lockOrder,
      deliverable,
      notifications,
      accessibility,
      endEarly,
      minutes: '',
      groupActivity: 'Project',
      requiredActivity: 'Yes',
      lockOrderActivity: '',
      deliverableActivity: 'Yes',
      notificationsActivity: '',
      accessibilityActivity: '',
      endEarlyActivity: 'No',
      setupInstructions: {
        description: '',
        instructions: ['', '', '']
      }
    };
  }
  // setup() {
  //   const presets = reactive({
  //     group,
  //     required,
  //     lockOrder,
  //     deliverable,
  //     notifications,
  //     accessibility,
  //     endEarly
  //   });
  //   const defaultActivity = reactive({
  //     minutes: '',
  //     groupActivity: 'Project',
  //     requiredActivity: 'Yes',
  //     lockOrderActivity: '',
  //     deliverableActivity: 'Yes',
  //     notificationsActivity: '',
  //     accessibilityActivity: '',
  //     endEarlyActivity: 'No'
  //   });
  //   const setupInstructions = ref({
  //     description: '',
  //     instructions: ['', '', '']
  //   });
  //   return {
  //     ...toRefs(presets),
  //     setupInstructions,
  //     ...toRefs(defaultActivity)
  //   };
  // }
});
