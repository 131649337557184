































import { ref, PropType, inject } from '@vue/composition-api';
import { Image } from './types';

export default {
  name: 'ProofPreview',

  props: {
    images: {
      type: Array as PropType<Image[]>,
      required: true
    }
  },
  setup() {
    const readonly = inject('readonly', false);
    const showDialog = ref(false);
    return {
      showDialog,
      readonly
    };
  }
};
