











































































































































































import {
  ref,
  reactive,
  toRefs,
  PropType,
  defineComponent,
  computed,
  watch,
  watchEffect,
  onMounted
} from '@vue/composition-api';
import { getModAdk } from 'pcv4lib/src';
import { useUserActions } from '@/store';
import axios from 'axios';
import Instruct from './ModuleInstruct.vue';
import { MongoDoc } from './types';

const API_ENDPOINT = 'https://discord.com/api/users/@me';
const CHANNEL_URL = 'https://discord.com/channels/688863898686062675';

export default defineComponent({
  name: 'ModuleDefault',
  components: {
    Instruct
  },
  props: {
    value: {
      required: true,
      type: Object as PropType<MongoDoc>
    },
    userDoc: {
      required: true,
      type: Object as PropType<MongoDoc>
    },
    fetchProgram: {
      required: true,
      type: [Object, Function]
    },
    studentDoc: {
      required: false,
      type: Object as PropType<MongoDoc>,
      default: () => {}
    },
    teamDoc: {
      required: false,
      type: Object as PropType<MongoDoc>,
      default: () => {}
    },
    userType: {
      required: true,
      type: String
      // participant: '',
      // organizer: '',
      // stakeholder: ''
    },
    mongoUser: {
      required: false,
      type: Object,
      default: () => {}
    }
  },
  setup(props: any, ctx) {
    const communityIndex = props.studentDoc.data.adks.findIndex(obj => obj.name === 'community');
    const communityObj = props.studentDoc.data.adks[communityIndex];
    const workspaceIndex = props.value?.data?.adks?.findIndex(obj => obj.name === 'workspace');
    const workspaceObj = props.value?.data?.adks[workspaceIndex];
    const { createDiscordChannels } = useUserActions(['createDiscordChannels']);
    const { addMembersToDiscordRole } = useUserActions(['addMembersToDiscordRole']);
    const { getAdkIndexByName } = useUserActions(['getAdkIndexByName']);
    const showSkipConfirmation = ref(false);
    const studentDocument = computed({
      get: () => props.studentDoc,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    const isDisconnected = ref(false);
    const isFormShow = ref(false);
    const isPersistent = ref(false);
    const isToken = computed(() => {
      if (communityObj.discordInfo && communityObj.discordInfo.discordAccessToken) {
        return true;
      }
      return false;
    });
    const state = reactive({
      avatarSource: '',
      discordUsername: 'Username #0000',
      accessToken: '',
      studentAdkIndex: -1
    });
    const isFetching = ref(false);
    const DISCORD_AUTH = `https://discord.com/api/oauth2/authorize?response_type=code&client_id=${process.env.VUE_APP_DISCORD_CLIENT_ID}&redirect_uri=${process.env.VUE_APP_DISCORD_REDIRECT}&response_type=code&scope=identify`;
    const DISCORD_REGISTER = `https://discord.com/register?redirect_to=%2Foauth2%2Fauthorize%3Fclient_id%3D${process.env.VUE_APP_DISCORD_CLIENT_ID}%26redirect_uri%3D${process.env.VUE_APP_DISCORD_REDIRECT}%26response_type%3Dcode%26scope%3Didentify%2520guilds.join%2520gdm.join%2520email`;

    if (props.studentDoc) {
      const { adkIndex: studentAdkIndex } = getModAdk(
        props,
        ctx.emit,
        'community',
        {},
        'studentDoc',
        'inputStudentDoc'
      );
      state.studentAdkIndex = studentAdkIndex;
    }

    async function storeProgramId() {
      localStorage.setItem('programId', props.value.data._id.toString());
      localStorage.setItem('adkIndexValue', await getAdkIndexByName('workspace'));
    }
    const employerChannelId: any = computed(() => {
      return workspaceObj?.discordData?.channel.id;
    });
    const discordData = ref();
    const getUser = async () => {
      isFetching.value = true;
      try {
        const res = await axios.get(API_ENDPOINT, {
          headers: {
            Authorization: `Bearer ${props.studentDoc.data.adks[communityIndex].discordInfo.discordAccessToken}`
          }
        });
        state.avatarSource =
          res.data.avatar !== null
            ? `https://cdn.discordapp.com/avatars/${res.data.id}/${res.data.avatar}.png`
            : '';
        state.discordUsername = `${res.data.username} #${res.data.discriminator}`;
        studentDocument.value.data.adks[communityIndex].discordInfo.discordId = res.data.id;
        studentDocument.value.data.adks[communityIndex].discordInfo.discordUsername =
          res.data.username;
        studentDocument.value.data.adks[communityIndex].discordInfo.avatarSrc = res.data.avatar;
        studentDocument.value.update();
        if (!workspaceObj || (workspaceObj && !workspaceObj.discordData)) {
          const result = await createDiscordChannels({
            program_id: props.value.data._id.toString(),
            program_name: props.value.data.programName,
            access_token: props.studentDoc.data.adks[communityIndex].discordInfo.discordAccessToken,
            discord_id: res.data.id,
            user_type: 'Student'
          });
          props.fetchProgram();
          if (result) {
            discordData.value = result.data.data;
          }
        }
        await addMembersToDiscordRole({
          program_id: props.value.data._id.toString(),
          discord_access_token:
            props.studentDoc.data.adks[communityIndex].discordInfo.discordAccessToken,
          discord_user_name: res.data.username,
          discord_id: res.data.id
        });
        isFetching.value = false;
      } catch (err: any) {
        if (err.response.status === 401) {
          props.mongoUser?.functions
            .callFunction(
              'refreshDiscordAccessToken',
              props.studentDoc.data._id.toString(),
              props.studentDoc.data.adks[communityIndex].discordInfo.discordRefreshToken
            )
            .then(async discordResponse => {
              if (discordResponse.status === 'success') {
                const {
                  data: { id, avatar, username, discriminator }
                } = await axios.get(API_ENDPOINT, {
                  headers: {
                    Authorization: `Bearer ${discordResponse.data.discordAccessToken}`
                  }
                });
                state.avatarSource =
                  avatar !== null ? `https://cdn.discordapp.com/avatars/${id}/${avatar}.png` : '';
                state.discordUsername = `${username} #${discriminator}`;
                studentDocument.value.data.adks[communityIndex].discordInfo.discordId = id;
                studentDocument.value.data.adks[communityIndex].discordInfo.discordUsername =
                  username;
                studentDocument.value.data.adks[communityIndex].discordInfo.avatarSrc = avatar;
                studentDocument.value.update();
                if (!workspaceObj || (workspaceObj && !workspaceObj.discordData)) {
                  const resp = await createDiscordChannels({
                    program_id: props.value.data._id.toString(),
                    program_name: props.value.data.programName,
                    access_token:
                      props.studentDoc.data.adks[communityIndex].discordInfo.discordAccessToken,
                    discord_id: id,
                    user_type: 'Student'
                  });
                  props.fetchProgram();
                  if (resp) {
                    discordData.value = resp.data.data;
                  }
                }
                await addMembersToDiscordRole({
                  program_id: props.value.data._id.toString(),
                  discord_access_token:
                    props.studentDoc.data.adks[communityIndex].discordInfo.discordAccessToken,
                  discord_user_name: username,
                  discord_id: id
                });
                isFetching.value = false;
              } else {
                isFetching.value = false;
                isDisconnected.value = true;
              }
            });
        } else {
          isFetching.value = false;
          isDisconnected.value = true;
        }
      }
    };

    if (isToken.value) {
      isFormShow.value = false;
      isPersistent.value = false;
      getUser();
    } else {
      isFormShow.value = true;
      isPersistent.value = true;
    }

    const isEmployerChannel = ref(false);
    const isInternsChannel = ref(false);

    async function redirectToEmployerChannel() {
      isEmployerChannel.value = true;
      setTimeout(function () {
        window.open(
          `${CHANNEL_URL}/${employerChannelId?.value || discordData?.value?.channel.id}`,
          '_blank'
        );
        isEmployerChannel.value = false;
      }, 2000);
    }
    async function redirectToInternsChannel() {
      isInternsChannel.value = true;
      const currentInternsChannelId = '1006082509592862760';
      setTimeout(function () {
        window.open(`${CHANNEL_URL}/${currentInternsChannelId}`, '_blank');
        isInternsChannel.value = false;
      }, 2000);
    }

    const setupInstructions = ref({
      description: '',
      instructions: ['', '', '']
    });
    const showInstructions = ref(true);
    return {
      ...toRefs(state),
      DISCORD_AUTH,
      showSkipConfirmation,
      DISCORD_REGISTER,
      isFetching,
      setupInstructions,
      communityObj,
      showInstructions,
      storeProgramId,
      communityIndex,
      studentDocument,
      isToken,
      isDisconnected,
      isInternsChannel,
      isEmployerChannel,
      redirectToInternsChannel,
      redirectToEmployerChannel,
      isFormShow,
      isPersistent,
      discordData
    };
  }
});
