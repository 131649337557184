






/* eslint-disable no-prototype-builtins */
/* eslint-disable vue/no-mutating-props */
/* eslint-disable no-param-reassign */
import {
  ref,
  PropType,
  defineComponent,
  watchEffect,
  watch,
  onMounted
} from '@vue/composition-api';
import * as Realm from 'realm-web';
// eslint says it can't find these modules from @types/node
// can be fixed by removing "./node_modules/@types" from typeRoots in tsconfig.json
// but then you get errors for unit test related imports
// anyway this doesn't matter since it will be server-side
import { useDbState } from '@/store';
import { MongoDoc } from './types';
import Table from './TableView.vue';

export default defineComponent({
  name: 'ModuleMonitor',
  components: {
    Table
  },
  props: {
    value: {
      required: true,
      type: Object as PropType<MongoDoc>
    },
    teamDoc: {
      required: true,
      type: Object as PropType<MongoDoc>
    },
    studentDoc: {
      required: true,
      type: Object as PropType<MongoDoc>
    },
    userType: {
      required: true,
      type: String
    },
    currentUser: {
      required: true,
      type: Object as PropType<Realm.User>
    },
    getTimeline: {
      type: Function
    }
  },
  setup(props, ctx) {
    const showInstructions = ref(true);
    const { user } = useDbState(['user']);
    const setupInstructions = ref({
      description: '',
      instructions: ['', '', '']
    });
    const isClockIn = ref(false);
    const isClockOut = ref(false);
    const table = ref();

    onMounted(() => {
      watch(isClockIn, val => {
        if (val) {
          table.value.getTableData();
          table.value.getInteractionList();
        }
      });
      watch(isClockOut, val => {
        if (val) {
          table.value.getTableData();
          table.value.getInteractionList();
        }
      });
    });

    const fetching = ref(false);

    const isTimeSheetAvailable = ref(false);
    watchEffect(() => {
      if (props.studentDoc && props.studentDoc?.data?.adks) {
        const Index = props.studentDoc?.data?.adks?.findIndex(a => a.name === 'timesheet');
        if (Index !== -1) {
          const data = props.studentDoc?.data?.adks[Index];
          if (data?.timesheetDetails) isTimeSheetAvailable.value = true;
        }
      }
    });

    return {
      showInstructions,
      isTimeSheetAvailable,
      setupInstructions,
      user,
      isClockIn,
      isClockOut,
      fetching,
      table
    };
  }
});
