




















































































































































































































































































/* eslint-disable no-prototype-builtins */
/* eslint-disable vue/no-mutating-props */
/* eslint-disable no-param-reassign */
import {
  computed,
  reactive,
  ref,
  toRefs,
  defineComponent,
  PropType,
  inject,
  watch
} from '@vue/composition-api';
import { getModAdk, getModMongoDoc } from 'pcv4lib/src';
import * as Realm from 'realm-web';
import '../styles/module.scss';
import Monitor from './ModuleMonitor.vue';
import Setup from './ModuleSetup.vue';
import Presets from './ModulePresets.vue';
import Default from './ModuleDefault.vue';
import { MongoDoc } from './types';

export default defineComponent({
  name: 'Demo',

  components: {
    'module-monitor': Monitor,
    'module-setup': Setup,
    'module-presets': Presets,
    'module-preview': Default
  },

  props: {
    value: {
      required: true,
      type: Object as PropType<MongoDoc>
    },
    teamDoc: {
      required: true,
      type: Object as PropType<MongoDoc | null>
    },
    userType: {
      required: true,
      type: String
      // participant: '',
      // organizer: '',
      // stakeholder: ''
    },
    currentUser: {
      required: true,
      type: Object as PropType<Realm.User>
    },
    operationMode: {
      type: Number
    }
  },
  setup(props, ctx) {
    const readonly = inject('readonly', false);

    const programDoc = getModMongoDoc(props, ctx.emit);
    getModAdk(props, ctx.emit, 'demo', {
      videoMaxLength: 5,
      groupActivity: 'Project',
      requiredActivity: 'Yes',
      deliverableActivity: 'Yes',
      endEarlyActivity: 'Creator has not allowed participants to end early after this activity'
    });

    const moduleName = ref('Demo');
    const page = reactive({
      subpages: ['Setup', 'Presets'],
      currentPage: 'Setup'
    });
    if (props.userType === 'organizer' && props.operationMode === 1) {
      page.currentPage = 'Presets';
    } else {
      page.currentPage = 'preview';
      page.subpages = [];
    }
    const getComponent = computed(() => {
      return `module-${page.currentPage.toLowerCase()}`;
    });
    const color = reactive({
      pilotcityColors: [
        ['#6eba80', '#3c9dcd', '#ea6764'],
        ['#eda1bf', '#fec34b', '#bdbdbd'],
        ['#ae90b0', '#f79961', '#000000']
      ],
      // ENTER ACTIVITY COLOR
      selectedColor: '#eda1bf'
    });
    const getColor = computed(() => {
      return color.selectedColor.substring(0, 7);
    });
    const config = ref({
      description: '',
      instruct: ['']
    });
    const menu = ref(false);
    // timeline
    const timelineData = reactive({
      events: [] as {
        id: number;
        text: string;
        time: string;
      }[],
      input: '',
      nonce: 0
    });
    const timeline = computed(() => {
      return timelineData.events.slice().reverse();
    });
    function comment() {
      const time = new Date().toTimeString();
      timelineData.events.push({
        id: timelineData.nonce,
        text: timelineData.input,
        time: time.replace(/:\d{2}\sGMT-\d{4}\s\((.*)\)/, (match, contents) => {
          return ` ${contents
            .split(' ')
            .map((v: string) => v.charAt(0))
            .join('')}`;
        })
      });
      timelineData.input = '';
    }

    const userRole = computed(() => {
      return props.userType;
    });

    const durationFormat = ref('');

    const calculateTime = (startTime: any, Time2: any) => {
      let diff = (Time2 - startTime) / 1000;
      diff = Math.abs(Math.floor(diff));

      const days = Math.floor(diff / (24 * 60 * 60));
      let leftSec = diff - days * 24 * 60 * 60;

      const hrs = Math.floor(leftSec / (60 * 60));
      leftSec -= hrs * 60 * 60;

      const min = Math.floor(leftSec / 60);
      leftSec -= min * 60;
      return `${days}d:${hrs}h:${min}m`;
    };

    function calculateTimer() {
      const adkIndex = props.teamDoc?.data.adks.findIndex(obj => obj.name === 'demo') || 0;
      if (
        userRole.value === 'participant' &&
        props.teamDoc?.data.adks[adkIndex].hasOwnProperty('activityEndedOn')
      ) {
        const startTime = props.teamDoc.data.adks[adkIndex].activityStartedOn;
        const endTime = props.teamDoc.data.adks[adkIndex].activityEndedOn;

        durationFormat.value = calculateTime(startTime, endTime);
      } else if (
        userRole.value === 'participant' &&
        !props.teamDoc?.data.adks[adkIndex].hasOwnProperty('activityStartedOn')
      ) {
        const startTime = new Date();
        props.teamDoc.data.adks[adkIndex].activityStartedOn = startTime;
        props.teamDoc.update();
        durationFormat.value = calculateTime(startTime, startTime);
        const x = setInterval(() => {
          const currentTime = new Date();
          let diff = (currentTime - startTime) / 1000;
          diff = Math.abs(Math.floor(diff));

          const days = Math.floor(diff / (24 * 60 * 60));
          let leftSec = diff - days * 24 * 60 * 60;

          const hrs = Math.floor(leftSec / (60 * 60));
          leftSec -= hrs * 60 * 60;

          const min = Math.floor(leftSec / 60);
          leftSec -= min * 60;
          durationFormat.value = `${days}d:${hrs}h:${min}m`;
          if (props.teamDoc?.data.adks[adkIndex].hasOwnProperty('activityEndedOn')) {
            clearInterval(x);
          }
        }, 60000);
      } else if (
        userRole.value === 'participant' &&
        props.teamDoc?.data.adks[adkIndex].hasOwnProperty('activityStartedOn')
      ) {
        durationFormat.value = calculateTime(
          new Date(),
          props.teamDoc.data.adks[adkIndex].activityStartedOn
        );
        const x = setInterval(() => {
          const currentTime = new Date();
          let diff = (currentTime - props.teamDoc?.data.adks[adkIndex].activityStartedOn) / 1000;
          diff = Math.abs(Math.floor(diff));

          const days = Math.floor(diff / (24 * 60 * 60));
          let leftSec = diff - days * 24 * 60 * 60;

          const hrs = Math.floor(leftSec / (60 * 60));
          leftSec -= hrs * 60 * 60;

          const min = Math.floor(leftSec / 60);
          leftSec -= min * 60;
          durationFormat.value = `${days}d:${hrs}h:${min}m`;
          if (props.teamDoc?.data.adks[adkIndex].hasOwnProperty('activityEndedOn')) {
            clearInterval(x);
          }
        }, 60000);
      }
    }

    if (userRole.value === 'participant' && props.teamDoc?.data.adks !== undefined) {
      if (props.teamDoc.data.adks.findIndex(obj => obj.name === 'demo') === -1) {
        props.teamDoc.data.adks.push({ name: 'demo' });
        props.teamDoc.update().then(() => {
          calculateTimer();
        });
      } else {
        calculateTimer();
      }
    } else if (userRole.value === 'participant') {
      watch(
        () => props.teamDoc,
        newData => {
          if (
            newData?.data.adks.findIndex((objdata: { name: string }) => objdata.name === 'demo')
          ) {
            calculateTimer();
          } else {
            props.teamDoc?.data.adks.push({ name: 'demo' });
            props.teamDoc?.update().then(() => {
              calculateTimer();
            });
          }
        }
      );
    }

    return {
      ...toRefs(color),
      ...toRefs(page),
      config,
      moduleName,
      menu,
      programDoc,
      getComponent,
      getColor,
      durationFormat,
      userRole,
      calculateTimer,
      ...toRefs(timelineData),
      timeline,
      comment,
      readonly
    };
  }
});
