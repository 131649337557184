































































































import { defineComponent, inject, PropType } from '@vue/composition-api';
import { loading, getModAdk, getModMongoDoc } from 'pcv4lib/src';
import { MongoDoc } from './types';

export default defineComponent({
  name: 'ModulePresets',

  props: {
    value: {
      required: true,
      type: Object as PropType<MongoDoc>
    },
    teamDoc: {
      required: true,
      type: Object as PropType<MongoDoc>
    },
    db: {
      required: true,
      type: Object as PropType<any>
    }
  },
  setup(props, ctx) {
    const readonly = inject('readonly', false);
    const maxTeamMemberItems = [...Array(7).keys()].map(i => i + 1);

    const { adkData } = getModAdk(props, ctx.emit, 'team');
    const programDoc = getModMongoDoc(props, ctx.emit);
    // const presets = reactive({
    //     group: ['Setup', 'Project', 'Screening', 'Internship'],
    //     required: ['Creator requires this activity', 'Yes', 'No'],
    //     lockOrder: ['Creator locked activity group and placement order', 'Yes', 'No'],
    //     deliverable: ['Yes', 'No'],
    //     notifications: ['Creator turned on by default', 'Turn on', 'Turn off'],
    //     accessibility: [
    //       'Creator has turned off accessibility anytime',
    //       'Creator has turned on accessibility anytime',
    //       'Yes',
    //       'No'
    //     ],
    //     items: ['1', '2', '3', '4', '5', '6', '7'],
    // endEarly: [
    //   'Creator has not allowed participants to end early after this activity',
    //   'Creator has allow end early option only at preset order placement',
    //   'Yes',
    //   'No'
    //   ]
    // });
    //   const setupInstructions = ref({
    //     description: '',
    //     instructions: ['', '', '']
    //   });
    return {
      adkData,
      maxTeamMemberItems,
      readonly,
      ...loading(programDoc.value.update, 'Success', 'Try again later')
    };
  }
});
