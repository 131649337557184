

























import { defineComponent, ref, computed, PropType } from '@vue/composition-api';
import { loading } from 'pcv4lib/src';
import { timePreset } from './const';
import MongoDoc from './types';

export default defineComponent({
  name: 'ModulePresets',

  props: {
    programDoc: {
      required: true,
      type: Object as PropType<MongoDoc>
    }
  },

  setup(props: any, ctx: any) {
    const programDocCollection = computed({
      get: () => props.programDoc,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    let index = programDocCollection.value.data.adks.findIndex(function findautoapplyobj(obj) {
      return obj.name === 'interview';
    });

    if (index === -1) {
      const initInterviewObject = {
        name: 'interview'
      };
      programDocCollection.value.data.adks.push(initInterviewObject);

      index = programDocCollection.value.data.adks.findIndex(function findautoapplyobj(obj) {
        return obj.name === 'interview';
      });
    }

    const selectedTimePreset = ref(
      programDocCollection.value.data?.adks[index]?.timedPresetDays ?? 0
    );

    const timePresetOptions = computed(() =>
      timePreset.map(time => {
        return {
          text: time === 1 ? `${time} day` : `${time} days`,
          value: time
        };
      })
    );

    const handleSave = async () => {
      programDocCollection.value.data.adks[index].timedPresetDays = selectedTimePreset.value;
      await programDocCollection.value.update();
    };

    return {
      selectedTimePreset,
      timePresetOptions,
      handleSave,
      ...loading(handleSave, 'Success', 'Try again later')
    };
  }
});
