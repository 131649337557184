










































































































































import { defineComponent, ref, computed, onMounted, watch } from '@vue/composition-api';
import axios from 'axios';
import TABLE_HEADER from './const';
import PreferencePreview from './PreferencePreview.vue';

export default defineComponent({
  name: 'TableView',
  components: {
    PreferencePreview
  },
  setup(props, ctx) {
    const headers = ref(TABLE_HEADER);

    const isFetching = ref(false);
    const isAbsolute = ref(true);
    const isLoading = ref(false);
    const color = ref('');
    const selectedInternFilter = ref({ name: 'Current Interns', value: 'current_interns' });

    const selectData = ref([
      { name: 'Current Interns', value: 'current_interns' },
      { name: 'Past Interns', value: 'past_interns' },
      { name: 'Incoming Interns', value: 'future_interns' }
    ]);

    const tableContents = ref([]);
    const getTableData = () => {
      const API_ENDPOINT = process.env.VUE_APP_ADK_DIRECTORY_GET_USER;
      const id = ctx.root._route.params.programId || ctx.root._route.query.program;
      isFetching.value = true;
      axios
        .get(`${API_ENDPOINT}?program_id=${id}&date_filter=${selectedInternFilter.value.value}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        })
        .then(res => {
          isLoading.value = false;
          isFetching.value = false;
          tableContents.value = res.data;
        })
        .catch(error => console.log(error));
    };

    watch(selectedInternFilter, val => {
      if (val) {
        getTableData();
      }
    });

    onMounted(() => {
      getTableData();
    });

    const selectedFilter = ref('All');

    const userList = computed(() => {
      const list = tableContents.value || [];
      if (selectedFilter.value === 'Employer') {
        return list.filter(user => user.role === 'employer');
      }
      if (selectedFilter.value === 'Intern') {
        return list.filter(user => user.role === 'student');
      }
      return list;
    });

    const getDialogData = ref();
    const showDialog = ref(false);
    function getPreferenceData(val) {
      console.log(val);
      getDialogData.value = val;
      showDialog.value = true;
    }

    return {
      headers,
      tableContents,
      getDialogData,
      isLoading,
      isAbsolute,
      isFetching,
      selectData,
      userList,
      selectedFilter,
      color,
      selectedInternFilter,
      getPreferenceData,
      showDialog,
      getTableData
    };
  }
});
