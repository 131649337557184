


























































































































































































































































































































































import { computed, defineComponent, ref, onMounted, onUnmounted } from '@vue/composition-api';
import { loading, getModAdk, getModMongoDoc } from 'pcv4lib/src';
import axios from 'axios';
import _, { forEach } from 'lodash';
import MongoDoc from './types';
import loader from './loaderSvg.vue';

// TODO: move this to @pilocity/shared
const enum ProgramActivityStatus {
  NeedSetup = 'need_setup',
  CompleteSetup = 'complete_setup',
  Standard = 'standard'
}

export default defineComponent({
  name: 'ModuleSetup',
  components: {
    loader
  },
  props: {
    value: {
      required: true,
      type: Object as () => MongoDoc
    },
    studentDoc: {
      required: false,
      type: Object as () => MongoDoc,
      default: Function as () => unknown
    },
    userDoc: {
      required: true,
      type: Object
    },
    fetchProgram: {
      required: true,
      type: [Object, Function]
    }
  },

  setup(props, ctx) {
    const programDoc = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    // Video Ask dialog handling
    const VlogDialog = ref(false);
    const VideoAskDialog = ref(false);
    const disabledButton = ref(true);
    const currentVlog = ref(null);
    const isDeleting = ref(false);
    const deleteLinkDialog = ref(false);
    const VlogOptions = [
      {
        name: 'Approach (Required)',
        link: 'https://www.videoask.com/f1brvchqm',
        nameDesc: 'Approach. How would you approach this problem? How would you start the project?'
      },

      {
        name: 'Brand (Required)',
        link: 'https://www.videoask.com/fovn030lr',
        nameDesc: 'Brand. Tell us more about your brand, culture and values as an organization. '
      },
      {
        name: 'Demo (Required)',
        link: 'https://www.videoask.com/fsu809j60',
        nameDesc: 'Demo. Could you demo your recommended product, service or process we will use?'
      },

      {
        name: 'Examples (Required)',
        link: 'https://www.videoask.com/fcv7txdhc',
        nameDesc:
          'Examples. Could you share an example? A related project, a use case or case study?'
      },
      {
        name: 'Goals (Required)',
        link: 'https://www.videoask.com/f3e4aafga',
        nameDesc:
          'Goals. What are short, medium and long-term goals for this project? How do I fit in?'
      },
      {
        name: 'Obstacles (Required)',
        link: 'https://www.videoask.com/fwzral58k',
        nameDesc: 'Obstacles. What do you anticipate the challenges, barriers & issues being? '
      },

      {
        name: 'Priority (Required)',
        link: 'https://www.videoask.com/fko6utfh4',
        nameDesc:
          'Priority. How would you recommend myself or my team prioritize our time on the project?'
      },
      {
        name: 'Talent (Required)',
        link: 'https://www.videoask.com/ftjz2q2ex',
        nameDesc:
          "Talent. Are there any particular skills or characteristics you're looking for in a candidate?"
      },
      {
        name: 'User (Required)',
        link: 'https://www.videoask.com/fudpl3wim',
        nameDesc:
          'Users. Who should we build our solutions for? Which users, testers or customers? Why?'
      },
      {
        name: 'Access',
        link: 'https://www.videoask.com/fo4k42l5i',
        nameDesc:
          'Access. In order to use your recommended tech or tools, how might I get access to it?'
      },

      {
        name: 'Caution',
        link: 'https://www.videoask.com/fkp9vhbdt',
        nameDesc:
          'Caution. Is there anything I should avoid, pay careful attention to or approach with caution?'
      },
      {
        name: 'Choice',
        link: 'https://www.videoask.com/frwyco660',
        nameDesc:
          'Choice. I heard there are choices for project types, could you list & describe their needs?'
      },
      {
        name: 'Coding',
        link: 'https://www.videoask.com/f82oatvpv',
        nameDesc:
          'Coding. What coding languages do I have to know? Is there documentation? Where can I find it?'
      },
      {
        name: 'Content',
        link: 'https://www.videoask.com/ftxtyeog2',
        nameDesc:
          'Content. What kind of content do you have available and ready-to-share? How do I access it?'
      },
      {
        name: 'Data',
        link: 'https://www.videoask.com/f60180qep',
        nameDesc:
          'Data. Where can I get my hands on the data I will use? What sources? Are they free or open?'
      },

      {
        name: 'Design',
        link: 'https://www.videoask.com/f4s0z3t4a',
        nameDesc:
          "Design. Is there a particular process you'd recommend for designing or prototyping?"
      },
      {
        name: 'Equity',
        link: 'https://www.videoask.com/fiwgoyof4',
        nameDesc:
          'Equity. Is there an equity mission to the project? What populations are we looking to serve?'
      },

      {
        name: 'Fundraise',
        link: 'https://www.videoask.com/feer3ciu2',
        nameDesc:
          'Fundraise. This project requires funding on my side. What fundraising recommendations might you have?'
      },

      {
        name: 'Innovation',
        link: 'https://www.videoask.com/flu9xixf0',
        nameDesc:
          "Innovation. What's unique to highlight? What is the differentiation or innovation?"
      },
      {
        name: 'Invest',
        link: 'https://www.videoask.com/ffjc5d1v0',
        nameDesc:
          'Invest. Would there be opportunities for future funding of solutions of interest to you?'
      },
      {
        name: 'Languages',
        link: 'https://www.videoask.com/fdog2gal2',
        nameDesc:
          'Languages. Are there any language needs for the project? If so, what are they in priority?'
      },
      {
        name: 'Mission',
        link: 'https://www.videoask.com/fl3lzmpll',
        nameDesc:
          'Mission. What priorities does your organization have in social, environmental & economic impact?'
      },

      {
        name: 'Patent',
        link: 'https://www.videoask.com/failpnk3g',
        nameDesc:
          'Patent. Could you dive deeper into the patented tech, process or research of your offering?'
      },
      {
        name: 'Places',
        link: 'https://www.videoask.com/f4fj37ucr',
        nameDesc: 'Places. Which residents, communities or places would you like us to focus on?'
      },

      {
        name: 'Sensitive',
        link: 'https://www.videoask.com/fg4q5mjds',
        nameDesc:
          'Sensitive. Are there any sensitive topics or language I should be aware of? How might I adapt?'
      },
      {
        name: 'Social',
        link: 'https://www.videoask.com/fb04sm8lv',
        nameDesc:
          'Social. What does your social media presence look like now? How can we be of help?'
      },

      {
        name: 'Tips',
        link: 'https://www.videoask.com/fwa4nq7ea',
        nameDesc:
          'Tips. Any additional tips, tricks & recommendations before I embark on my journey?'
      }
    ];
    const { adkData } = getModAdk(props, ctx.emit, 'train', {});
    const index = programDoc.value.data.adks.findIndex(obj => {
      if (obj) {
        return obj.name === 'train';
      }
      return false;
    });
    // Reactivity Handling
    const body = ref(0);
    const saveButton = async () => {
      await props.fetchProgram();
      if (adkData.value) {
        adkData.value.videoLinks.forEach(async element => {
          if (element.is_processing) {
            disabledButton.value = true;
          } else {
            disabledButton.value = false;
          }
        });
      }
    };
    function openVlogDialog() {
      VlogDialog.value = true;
      saveButton();
    }
    function populate(val) {
      const train1 = ref({
        name: val.nameDesc,
        link: '',
        videoask_question_url: val.link,
        required: false,
        is_processing: true
      });
      if (!programDoc.value.data.adks[index]?.videoLinks) {
        programDoc.value.data.adks[index] = {
          ...programDoc.value.data.adks[index],
          videoLinks: [],
          name: 'train'
        };
      }
      programDoc.value.data.adks[index].videoLinks.push(train1.value);
      body.value += 0;
      const program = getModMongoDoc(props, ctx.emit, {});
      program.value.update();
    }

    function populateYoutubeVideo() {
      const train1 = ref({
        name: '',
        link: '',
        videoask_question_url: '',
        required: false,
        is_processing: false
      });
      if (!programDoc.value.data.adks[index]?.videoLinks) {
        programDoc.value.data.adks[index] = {
          ...programDoc.value.data.adks[index],
          videoLinks: [],
          name: 'train'
        };
      }
      programDoc.value.data.adks[index].videoLinks.push(train1.value);
      body.value += 0;
      const program = getModMongoDoc(props, ctx.emit, {});
      program.value.update();
    }

    const currentLinkIndex = ref(null);
    function openDelLinkDialog(indx) {
      currentLinkIndex.value = indx;
      deleteLinkDialog.value = true;
    }

    function removeIndex() {
      isDeleting.value = true;
      programDoc.value.data.adks[index].videoLinks.splice(currentLinkIndex.value, 1);
      body.value += 1;
      const program = getModMongoDoc(props, ctx.emit, {});
      program.value.update();
      currentLinkIndex.value = null;
      deleteLinkDialog.value = false;
      isDeleting.value = false;
    }

    function openVideoAskDialog(item) {
      populate(item);
      currentVlog.value = item;
      VlogDialog.value = false;
      VideoAskDialog.value = true;
    }

    const initTrainSetup = {
      videoLinks: []
    };
    programDoc.value.data.adks[index] = {
      ...initTrainSetup,
      ...programDoc.value.data.adks[index]
    };

    // Fetch Metainfo
    const isSearching = ref(false);
    const currentIndex = ref(-1);
    const fetchMetaInfo = _.debounce(async (val, indx) => {
      currentIndex.value = indx;
      const API_ENDPOINT = process.env.VUE_APP_META_GRABBER_ENDPOINT;
      try {
        isSearching.value = true;
        const resp = await axios.get(API_ENDPOINT, {
          params: {
            url: val
          },
          headers: {
            'Content-Type': 'application/json'
          }
        });
        if (resp.data.data) {
          programDoc.value.data.adks[index].videoLinks[indx].name = resp.data.data.title;
          const program = getModMongoDoc(props, ctx.emit, {});
          program.value.update();
        }
        isSearching.value = false;
      } catch (err) {
        console.log(err);
        isSearching.value = false;
      }
    }, 500);

    function saveUploadUrl() {
      populateYoutubeVideo();
      VlogDialog.value = false;
    }
    // Handle Save
    const isFetchingProgram = ref(false);
    const closeRecordModal = async () => {
      isFetchingProgram.value = false;
      VideoAskDialog.value = false;
    };

    const closeRecordVideoModal = () => {
      const removeItem = adkData.value.videoLinks.slice(-1)[0];
      const index = adkData.value.videoLinks.lastIndexOf(removeItem);
      adkData.value.videoLinks.splice(index, 1);
      body.value += 0;
      const program = getModMongoDoc(props, ctx.emit, {});
      program.value.update();
      isFetchingProgram.value = false;
      VideoAskDialog.value = false;
    };

    let intervalId;
    onMounted(() => {
      intervalId = setInterval(() => {
        saveButton();
      }, 10000);
    });

    onUnmounted(() => clearInterval(intervalId));

    return {
      populate,
      openVlogDialog,
      populateYoutubeVideo,
      index,
      programDoc,
      fetchMetaInfo,
      VlogOptions,
      isSearching,
      openVideoAskDialog,
      saveUploadUrl,
      deleteLinkDialog,
      isDeleting,
      currentVlog,
      saveButton,
      VlogDialog,
      VideoAskDialog,
      disabledButton,
      closeRecordModal,
      closeRecordVideoModal,
      currentLinkIndex,
      openDelLinkDialog,
      currentIndex,
      body,
      removeIndex,
      adkData,
      processingMsg: 'Processing ....',
      isFetchingProgram,
      ...loading(
        () =>
          programDoc.value.update(() => ({
            props,
            isComplete: true,
            adkIndex: index,
            status: ProgramActivityStatus.CompleteSetup
          })),
        'Success',
        'Try again later'
      )
    };
  },

  beforeMount() {
    this.saveButton();
  }
});
