






























































import { defineComponent, inject, PropType } from '@vue/composition-api';
import { ObjectId } from 'bson';
import { commentIsFlagged, commentIsLiked } from './helpers';
import { Comment } from './types';

export default defineComponent({
  name: 'Comment',

  props: {
    questionId: {
      required: true,
      type: Object as PropType<ObjectId>
    },
    comment: {
      required: true,
      type: Object as PropType<Comment>
    },
    studentAdkData: {
      required: true,
      type: Object
    },
    userType: {
      required: true,
      type: String
      // participant: '',
      // organizer: '',
      // stakeholder: ''
    }
  },
  setup() {
    const readonly = inject('readonly', false);
    return { commentIsFlagged, commentIsLiked, readonly };
  }
});
