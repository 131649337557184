const TABLE_HEADER = [
  { text: 'Info', value: 'directoryPreference', sortable: false, align: 'start', width: '5%' },
  {
    text: 'Name',
    align: 'start',
    sortable: false,
    value: 'first_name',
    width: '20%'
  },
  { text: 'Email', value: 'email', sortable: false, align: 'start', width: '40%' },
  { text: 'Phone', value: 'phone_number', sortable: false, align: 'start', width: '30%' },
  { text: 'Role', value: 'role', sortable: false, align: 'start', width: '5%' }
];

export default TABLE_HEADER;
