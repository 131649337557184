


































































































































































































































































































































































































































































































































































































































































































































































































































































































import { ObjectId } from 'bson';
import {
  ref,
  defineComponent,
  computed,
  Ref,
  onUnmounted,
  watchEffect
} from '@vue/composition-api';
import axios from 'axios';
import { useUserGetters, useDbGetters } from '@/store';
import { StudentStatus } from '@/views/Monitor/studentStatus';
import type { OperationModeType } from '@/@types/operationMode.d';
import { OperationMode } from '@/constants/operationMode';
import ATeleInput from '@/components/atoms/ATeleInput.vue';
import moment from 'moment';
import { ITimeLineItem, ProgramActivityStatus } from '../Guide.vue';
import activitiesList from '../const';

export default defineComponent({
  name: 'GuideBar',
  components: {
    ATeleInput
  },
  props: {
    timeline: {
      required: true,
      type: Array as () => ITimeLineItem[]
    },
    value: {
      required: true,
      type: Number
    },
    title: {
      default: 'Employer Name',
      type: String
    },
    fetchProgram: {
      type: Function,
      default: () => {}
    },
    operationMode: {
      required: true,
      type: Number as () => OperationModeType
    },
    studentDoc: {
      required: false,
      type: Object,
      default: () => {}
    },
    userDoc: {
      required: false,
      type: Object,
      default: () => {}
    },
    programDoc: {
      required: false,
      type: Object,
      default: () => {}
    },
    stakeholderIds: {
      required: true,
      type: Array
    },
    needsetup: {
      required: true,
      type: Array
    },
    studentStatus: {
      required: false,
      default: null
    },
    previewEnabled: {
      required: false,
      type: Boolean,
      default: false
    },
    adksWithPreviewMode: {
      required: false,
      type: Array,
      default() {
        return [];
      }
    },
    canPublishProgram: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  setup(
    props: {
      value?: any;
      timeline: ITimeLineItem[];
      programDoc?: any;
      stakeholderIds?: any;
      studentDoc?: any;
      adksWithPreviewMode?: string[];
      operationMode: OperationModeType;
      needsetup: string[];
    },
    ctx
  ) {
    const vertical = ref(true);
    const isPublished = ref(false);
    const expand = window.innerWidth >= 991;
    const closeBar = window.innerWidth <= 991;
    const { collection } = useDbGetters(['collection']);
    const { getObjectId } = useUserGetters(['getObjectId']);
    const showPreInternshipSteps = ref(true);
    const showInternshipSteps = ref(true);
    const newOrganizerEmail = ref('');
    const validation = ref('');
    const inviteType = ref('Email');
    const phoneNumber = ref('');
    const formattedNumber = ref('');
    const isPhoneValid = ref(false);
    const dialogManageAccess = ref(false);
    const orgMsg = ref('');

    function closeModal() {
      dialogManageAccess.value = false;
      phoneNumber.value = '';
      formattedNumber.value = '';
      newOrganizerEmail.value = '';
      orgMsg.value = '';
      validation.value.reset();
    }

    function getValidation(val) {
      if (val && val.countryCallingCode && val.formatted && val.valid) {
        formattedNumber.value = `+${val.countryCallingCode}${val.formatted}`;
      } else {
        formattedNumber.value = '';
      }
      isPhoneValid.value = val.valid;
    }

    const phoneValid = computed(() => {
      if (isPhoneValid.value && phoneNumber.value) {
        return true;
      }
      if (inviteType.value === 'Email') {
        return true;
      }
      return false;
    });

    const isPhoneCheck = computed(() => {
      const substring = '+1';
      const substring1 = '+';

      if (phoneNumber.value.includes(substring) || phoneNumber.value.includes(substring1)) {
        return false;
      }

      if (inviteType.value === 'Email') {
        return true;
      }

      return true;
    });

    const isProgram = computed(() => {
      if (props.title) {
        return true;
      }
      return false;
    });

    const isEmployer = computed(() => {
      if (props.userDoc.data.userTypes.filter(obj => obj === 'Employer')) {
        return true;
      }
      return false;
    });

    const projectItems = computed(() => {
      const { timeline } = props;
      return timeline.filter(item => item.category === 'project');
    });

    const internshipItems = computed(() => {
      const { timeline } = props;
      return timeline.filter(item => item.category === 'internship');
    });

    collection.value!('Program')
      .findOne(
        {
          _id: new ObjectId(ctx.root.$route.params.programId)
        },
        {
          projection: {
            _id: 0,
            published: 1
          }
        }
      )
      .then(res => {
        isPublished.value = res.published;
      })
      .catch(e => {
        // most likely this key doesn't exist in the db
        // TODO: send error report
        console.log(e);
      });

    const activeStep = computed({
      get: () => props.value,
      set: newPage => {
        ctx.emit('input', newPage);
      }
    });
    const list = ref([]);
    const isSending = ref(false);
    const isFetching = ref(false);
    const errorMsg = ref('');
    const organizerList = () => {
      list.value = [];
      props.programDoc.data.organizers.forEach(ele => {
        collection.value!('User')
          .findOne({ _id: new ObjectId(ele.toString()) })
          .then(res => {
            list.value.push(res);
            isSending.value = false;
          });
      });
    };
    organizerList();
    async function addOrganizer() {
      errorMsg.value = '';
      orgMsg.value = '';
      const API_ENDPOINT = process.env.VUE_APP_ADD_ORGANIZER;
      isSending.value = true;
      const data = {
        program_id: ctx.root.$route.params.programId,
        invitee: inviteType.value === 'Email' ? newOrganizerEmail.value : formattedNumber.value,
        user_type: 'Employer'
      };
      try {
        const res = await axios
          .post(API_ENDPOINT, data, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(async res => {
            await props.fetchProgram();
            await organizerList();
            if (res.status === 200) {
              orgMsg.value = 'User added succesfully';
            }
          });
        newOrganizerEmail.value = '';
        phoneNumber.value = '';
        formattedNumber.value = '';
        validation.value.reset();
      } catch (err) {
        console.log(err.response);
        if (err.response.data) {
          errorMsg.value = err?.response?.data?.error?.description;
        }
        isSending.value = false;
      }
    }

    function openManageAccess() {
      errorMsg.value = '';
      dialogManageAccess.value = true;
    }

    const actions = computed(() => {
      const icons = [
        {
          name: 'community',
          icon: 'discord',
          fn: 'my-community'
        },
        {
          name: 'team',
          icon: 'account-supervisor-circle',
          fn: 'my-team'
        },

        {
          name: 'tinker',
          icon: 'timetable',
          fn: 'my-tinker'
        },

        {
          name: 'forum',
          icon: 'comment-text-multiple-outline',
          fn: 'my-forum'
        },

        {
          name: 'make',
          icon: 'feather',
          fn: 'my-make'
        }
      ];
      return icons.filter(action => {
        const timelineObj = props.timeline.find(obj => obj.step === action.name);
        return timelineObj && timelineObj.unlocked;
      });
    });

    const unlockedStep = computed(() => {
      let whichStep = 0;
      for (let i = props.timeline.length - 1; i > 0; i -= 1) {
        if (props.timeline[i].unlocked) {
          whichStep = i;
          break;
        }
      }
      return whichStep;
    });

    const changePublishStatus = async () => {
      const progPublished = !isPublished.value;
      const deliverableObj = {
        teamSettings: {
          required: true
        },
        projectPlanSettings: {
          required: true
        },
        projectTaskSettings: {
          required: true
        },
        caseStudySettings: {
          required: true
        },
        presentationSettings: {
          required: true
        },
        userInterviewSettings: {
          required: true
        },
        projectImplementationSettings: {
          required: true
        },
        digitalResumeSettings: true,
        projectFAQSettings: true
      };

      collection.value!('Program')
        .findOneAndUpdate(
          {
            _id: new ObjectId(ctx.root.$route.params.programId)
          },
          { $set: { published: !isPublished.value } }
        )
        .then(async res => {
          isPublished.value = !isPublished.value;
          const isDeliverable = res?.adks?.filter(a => a.name.toLowerCase() === 'deliverable')[0];
          if (progPublished && !isDeliverable) {
            await collection.value!('Program').findOneAndUpdate(
              {
                _id: new ObjectId(ctx.root.$route.params.programId)
              },
              {
                $push: {
                  adks: { name: 'deliverable', settings: deliverableObj }
                }
              }
            );
          } else if (progPublished && isDeliverable.name && !isDeliverable.settings) {
            await collection.value!('Program').findOneAndUpdate(
              {
                _id: new ObjectId(ctx.root.$route.params.programId),
                'adks.name': 'deliverable'
              },
              { $set: { 'adks.$.settings': deliverableObj } }
            );
          }
        });
    };

    // Share Dialog Logic

    const stakeholders: Ref<Record<string, any>[]> = ref([]);
    const newStakeholderEmail = ref('');
    (async () => {
      // fetch sponsors. if a stakeholder is here, they must have autoMonitor access
      const sponsors = await collection.value!('Monitor').find({
        'monkey.userID': getObjectId.value
      });
      const sponsorIds = sponsors.map(sponsor => sponsor.owner);
      // fetch stakeholders based on either their email or id
      const { stakeholderIds } = props;
      stakeholderIds.push(...sponsorIds);
      stakeholders.value = await collection.value!('User').find({
        $or: [{ _id: { $in: stakeholderIds } }, { email: { $in: stakeholderIds } }]
      });
      const stakeholderEmails = stakeholders.value.map(stakeholder => stakeholder.email);
      // add monitorType field to stakeholders
      stakeholders.value = stakeholders.value.map(stakeholder => {
        let monitorType = 'Monitor';
        if (sponsorIds.some(id => id.equals(stakeholder._id))) monitorType = 'Sponsor';
        else if (props.programDoc.data.organizers.some(id => id.equals(stakeholder._id)))
          monitorType = 'Organizer';
        return {
          ...stakeholder,
          monitorType
        };
      });
      // add emails not yet associated with a user back to stakeholders array
      stakeholderIds.forEach(email => {
        if (typeof email === 'string' && !stakeholderEmails.includes(email))
          stakeholders.value.push({ email });
      });
    })();
    const addStakeholderError = ref(false);
    const addNewStakeholder = async () => {
      if (newStakeholderEmail.value.length) {
        // check if email is already added
        if (
          stakeholders.value.some(stakeholder => stakeholder.email === newStakeholderEmail.value)
        ) {
          addStakeholderError.value = true;
          return;
        }
        addStakeholderError.value = false;
        const user = await collection.value!('User').findOne({
          email: newStakeholderEmail.value
        });
        const { studentDoc } = props;
        if (!studentDoc.data.stakeholders) {
          studentDoc.data.stakeholders = [];
        }
        if (user) {
          // push user id if user exists
          studentDoc.data.stakeholders.push(user._id);
          stakeholders.value.push(user);
        } else {
          // push email if user doesn't exist
          studentDoc.data.stakeholders.push(newStakeholderEmail.value);
          stakeholders.value.push({ email: newStakeholderEmail.value });
        }
        studentDoc.update();
        newStakeholderEmail.value = '';
      }
    };
    const isDeleting = ref(false);
    const deleteIndex = ref(-1);
    const changeOrgPerm = async (action: string, id: string, indx: number) => {
      console.log(id);
      if (action === 'Remove') {
        deleteIndex.value = indx;
        const API_ENDPOINT = process.env.VUE_APP_DELETE_ORG;
        try {
          isDeleting.value = true;
          const resp = await axios.delete(API_ENDPOINT, {
            params: {
              user_id: id.toString(),
              program_id: ctx.root.$route.params.programId
            }
          });
          if (resp) {
            list.value.splice(
              list.value.findIndex(i => {
                return i._id.toString() === id.toString();
              }),
              1
            );
            isDeleting.value = false;
            orgMsg.value = resp.data.message;
          }
        } catch (err) {
          console.log(err);
          isDeleting.value = false;
        }
      }
    };

    const changeStakeholderPerm = (action: string, email: string) => {
      if (action === 'Remove') {
        const { studentDoc } = props;
        const idToRemove =
          stakeholders.value.find(stakeholder => stakeholder.email === email)?._id ?? email;
        // remove stakeholder from student doc
        console.log(props);
        studentDoc.data.stakeholders = studentDoc.data.stakeholders.filter(id => {
          if (typeof id === 'string') return id !== idToRemove;
          return !id.equals(idToRemove);
        });
        studentDoc.update();
        // remove stakeholder from stakeholder array
        stakeholders.value = stakeholders.value.filter(stakeholder => stakeholder.email !== email);
      }
    };

    const nextUnlockedAdk = computed(() => {
      let nextUnlockedAdk: ITimeLineItem | undefined;

      // Find the first incomplete adk in the list
      const firstUnlockedAdk = props.timeline.find(adk => !adk.unlocked);

      if (firstUnlockedAdk) {
        nextUnlockedAdk = firstUnlockedAdk;
      }
      return nextUnlockedAdk;
    });

    const nextUnlockedAdkIndex = computed(() => {
      const result = props.timeline.findIndex(adk => adk.step === nextUnlockedAdk.value?.step);

      if (result > -1) return result;

      return 0;
    });
    const changeStep = (step: number, type: 'pre' | 'post') => {
      let newStep: ITimeLineItem | null = null;
      if (type === 'pre') {
        newStep = projectItems.value?.[step];
      } else if (type === 'post') {
        const internshipItemsStepIndex = step - projectItems.value.length;
        newStep = internshipItems.value?.[internshipItemsStepIndex];
      }

      const { operationMode } = props;
      if (operationMode === OperationMode.Setup && newStep?.unlocked) {
        activeStep.value = step;
      } else if (
        operationMode !== OperationMode.Setup &&
        (newStep?.unlocked ||
          step === nextUnlockedAdkIndex?.value ||
          (props.adksWithPreviewMode?.includes(newStep?.step ?? '') && props.previewEnabled))
      ) {
        activeStep.value = step;
      }
    };

    const toggleCategory = (type: 'pre' | 'post') => {
      if (type === 'pre') {
        showPreInternshipSteps.value = !showPreInternshipSteps.value;
      } else {
        showInternshipSteps.value = !showInternshipSteps.value;
      }
    };

    const isStepCompleted = (adkObject: ITimeLineItem) => {
      const { operationMode, needsetup } = props;
      const stepRequiresSetup = needsetup.includes(adkObject.step);

      if (operationMode === OperationMode.Setup && stepRequiresSetup) {
        return adkObject.status === ProgramActivityStatus.CompleteSetup;
      }
      if (operationMode === OperationMode.Setup && !stepRequiresSetup) {
        return false;
      }

      return !!adkObject.unlocked;
    };

    const getIconColor = (adkIndex: number, adkObject: ITimeLineItem) => {
      const green = '#6fba7f';
      const yellow = '#fdd35a';
      const gray = '#00000061';

      if (isStepCompleted(adkObject)) {
        return green;
      }

      if (activeStep.value === adkIndex) {
        return yellow;
      }

      return gray;
    };

    const getCompletedIcon = () => {
      return '$complete';
    };

    const studentDocIndex = props.studentDoc?.data?.adks?.findIndex(obj => {
      if (obj) {
        return obj.name === 'timesheet';
      }
      return false;
    });

    const timer = ref('00:00:00');
    const timerBtnColor = ref('green');

    const isClockIn = ref(false);
    const isClockOut = ref(false);
    const lastClockingTime = ref();

    async function checkStatus() {
      try {
        const API_ENDPOINT = process.env.VUE_APP_TIMESHEET_STATUS;
        const res = await axios.get(
          `${API_ENDPOINT}?student_id=${props.studentDoc?._id.toString()}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
            }
          }
        );
        const status = res?.data?.data;
        lastClockingTime.value = res?.data?.time;
        if (status === 'clock_in') {
          isClockOut.value = true;
          isClockIn.value = false;
        }
        if (status === 'clock_out') {
          lastClockingTime.value = undefined;
          isClockIn.value = true;
          isClockOut.value = false;
        }
      } catch (error) {
        console.log(error);
      }
    }

    const checkStatusInterval = window.setInterval(() => {
      checkStatus();
      watchEffect(async () => {
        let timerInterval;
        if (isClockIn.value === false && isClockOut.value === true) {
          let diff;
          timerInterval = setInterval(function () {
            let timerValue;
            if (lastClockingTime.value !== undefined) {
              diff = moment(moment().utc()).diff(moment(lastClockingTime.value), 'seconds');
              timerValue = moment.utc(diff * 1000).format('HH:mm:ss');
              if (diff >= 28800) {
                clearInterval(timerInterval);
                timer.value = '00:00:00';
                timerBtnColor.value = 'green';
              } else {
                timer.value = timerValue;
                timerBtnColor.value = 'red';
              }
            } else {
              clearInterval(timerInterval);
              timer.value = '00:00:00';
              timerBtnColor.value = 'green';
            }
          }, 1000);
        } else {
          clearInterval(timerInterval);
          timer.value = '00:00:00';
          timerBtnColor.value = 'green';
        }
      });
    }, 15000);

    onUnmounted(() => {
      clearInterval(checkStatusInterval);
    });
    function goToTimesheetActivity() {
      let index = 0;
      if (activitiesList.includes('timesheet')) {
        index = activitiesList.indexOf('timesheet');
      }
      ctx.root.$router.push(`/guide/${props.programDoc.data._id.toString()}/${index}`);
    }

    return {
      stakeholders,
      orgMsg,
      closeModal,
      phoneNumber,
      formattedNumber,
      phoneValid,
      isPhoneCheck,
      inviteType,
      isSending,
      isProgram,
      isEmployer,
      newStakeholderEmail,
      addNewStakeholder,
      isDeleting,
      addStakeholderError,
      changeOrgPerm,
      deleteIndex,
      changeStakeholderPerm,
      expand,
      closeBar,
      activeStep,
      list,
      actions,
      vertical,
      dialog: ref(false),
      getHelpDialog: ref(false),
      dialogManageAccess,
      dialog2: ref(false),
      unlockedStep,
      isPublished,
      changePublishStatus,
      nextUnlockedAdkIndex,
      isFetching,
      changeStep,
      errorMsg,
      shareAccess: ['Monitor', 'Remove'],
      manageAccess: ['Employer', 'Remove'],
      manageInput: ['Email', 'Phone'],
      toggleCategory,
      showPreInternshipSteps,
      validation,
      showInternshipSteps,
      projectItems,
      internshipItems,
      StudentStatus,
      OperationMode,
      getIconColor,
      getCompletedIcon,
      isStepCompleted,
      organizerList,
      addOrganizer,
      openManageAccess,
      newOrganizerEmail,
      getValidation,
      timer,
      timerBtnColor,
      isClockIn,
      isClockOut,
      goToTimesheetActivity,
      studentDocIndex
    };
  },
  data() {
    return {
      organizers: []
    };
  },
  methods: {
    openGuideBar() {
      this.expand = !this.expand;
    },

    mobileExpand() {
      if (window.innerWidth <= 991) {
        this.closeBar = !this.closeBar;
        if (!this.closeBar) {
          this.expand = false;
        }
        if (!this.expand) {
          this.closeBar = true;
        }
      }
    }
  }
});
