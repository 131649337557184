var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"module-default__manage"},[_c('div',{staticClass:"headline font-weight-black mb-3"},[_vm._v(_vm._s(_vm.teamDoc.data.name))]),_c('ManageTable',_vm._g({staticClass:"module-default__table-view",attrs:{"team-doc":_vm.teamDoc,"viewer":_vm.viewer,"user-type":_vm.userType}},_vm.$listeners))],1),(_vm.viewerIsOwner)?_c('div',{staticClass:"mt-6"},[_c('div',{staticClass:"headline font-weight-black mb-8 mt-12"},[_vm._v("Settings")]),_c('div',{staticClass:"d-flex mt-3 mb-3",class:_vm.$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'},[_c('v-text-field',{staticClass:"module-default__text-field",attrs:{"readonly":_vm.userType === 'stakeholder' || _vm.readonly,"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPassword ? 'text' : 'password',"rounded":"","label":"View, copy or change password","outlined":""},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('v-dialog',{attrs:{"persistent":"","max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"module-default__log-btn mb-8",attrs:{"disabled":_vm.userType === 'stakeholder' || _vm.readonly,"ripple":false,"rounded":"","depressed":"","x-large":"","outlined":""}},'v-btn',attrs,false),on),[_vm._v("Set Password")])]}}],null,false,2597186800),model:{value:(_vm.changePasswordDialog),callback:function ($$v) {_vm.changePasswordDialog=$$v},expression:"changePasswordDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"overline font-weight-bold"},[_vm._v(" Are you sure you want to change the password? ")])]),_c('v-divider'),_c('v-container',{staticClass:"d-flex flex-column justify-center"},[_c('div',{staticClass:"d-flex flex-row justify-center mt-4 mb-6"},[_c('v-btn',{staticClass:"ma-2",attrs:{"disabled":_vm.readonly,"outlined":"","x-large":"","rounded":"","depressed":""},on:{"click":function($event){_vm.changePasswordDialog = false}}},[_vm._v("Cancel")]),_c('v-btn',{staticClass:"ma-2",attrs:{"disabled":_vm.readonly,"color":"green","x-large":"","dark":"","rounded":"","depressed":""},on:{"click":_vm.changePassword}},[_vm._v("Confirm")])],1)])],1)],1)],1),_c('div',{staticClass:"d-flex mt-3 mb-3",class:_vm.$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'},[_c('v-text-field',{staticClass:"module-default__text-field",attrs:{"disabled":_vm.userType === 'stakeholder',"placeholder":_vm.teamDoc.data.name,"rounded":"","label":"Rename team name","outlined":""},model:{value:(_vm.newTeamName),callback:function ($$v) {_vm.newTeamName=$$v},expression:"newTeamName"}}),_c('v-dialog',{attrs:{"persistent":"","max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"module-default__log-btn mb-8",attrs:{"disabled":_vm.userType === 'stakeholder' || _vm.readonly,"ripple":false,"rounded":"","depressed":"","x-large":"","outlined":""}},'v-btn',attrs,false),on),[_vm._v("Rename Team")])]}}],null,false,1340752160),model:{value:(_vm.renameTeamDialog),callback:function ($$v) {_vm.renameTeamDialog=$$v},expression:"renameTeamDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"overline font-weight-bold"},[_vm._v("Are you sure you want to rename the team?")])]),_c('v-divider'),_c('v-container',{staticClass:"d-flex flex-column justify-center"},[_c('div',{staticClass:"d-flex flex-row justify-center mt-4 mb-6"},[_c('v-btn',{staticClass:"ma-2",attrs:{"disabled":_vm.readonly,"outlined":"","x-large":"","rounded":"","depressed":""},on:{"click":function($event){_vm.renameTeamDialog = false}}},[_vm._v("Cancel")]),_c('v-btn',{staticClass:"ma-2",attrs:{"disabled":_vm.readonly,"color":"green","x-large":"","dark":"","rounded":"","depressed":""},on:{"click":_vm.renameTeam}},[_vm._v("Confirm")])],1)])],1)],1)],1)]):_vm._e(),_c('div',{staticClass:"d-flex flex-row justify-start mt-6"},[_c('v-dialog',{attrs:{"persistent":"","max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.userType === 'stakeholder' || _vm.readonly || _vm.viewerIsOwner,"rounded":"","x-large":"","color":"red white--text","dark":_vm.readonly,"depressed":""}},'v-btn',attrs,false),on),[_vm._v("Leave Team")])]}}]),model:{value:(_vm.leaveTeamDialog),callback:function ($$v) {_vm.leaveTeamDialog=$$v},expression:"leaveTeamDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"overline font-weight-bold"},[_vm._v("Are you sure you want to leave the team?")])]),_c('v-divider'),_c('v-container',{staticClass:"d-flex flex-column justify-center"},[_c('div',{staticClass:"d-flex flex-row justify-center mt-4 mb-6"},[_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","x-large":"","rounded":"","depressed":"","disabled":_vm.readonly},on:{"click":function($event){_vm.leaveTeamDialog = false}}},[_vm._v("Cancel")]),_c('v-btn',{staticClass:"ma-2",attrs:{"dark":_vm.selectedMember !== null || _vm.teamMembers.length < 1 || !_vm.viewerIsOwner,"disabled":(_vm.viewerIsOwner && !_vm.selectedMember && _vm.teamMembers.length >= 1) || _vm.readonly,"color":"red","x-large":"","rounded":"","depressed":""},on:{"click":_vm.leaveTeam}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-hand-peace")]),_vm._v("Confirm")],1)],1)])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }