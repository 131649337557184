






























































































































import { reactive, ref, toRefs, PropType, defineComponent } from '@vue/composition-api';
import { getModAdk, getModMongoDoc, loading } from 'pcv4lib/src';
// import Instruct from './ModuleInstruct.vue';
import { group, required, deliverable, endEarly, maxMinutes } from './const';
import { MongoDoc } from './types';

export default defineComponent({
  name: 'ModulePresets',
  components: {
    // Instruct
  },
  props: {
    value: {
      required: true,
      type: Object as PropType<MongoDoc>
    }
  },
  setup(props, ctx) {
    const { adkData } = getModAdk(props, ctx.emit, 'demo');
    const programDoc = getModMongoDoc(props, ctx.emit);

    const presets = reactive({
      maxMinutes,
      group,
      required,
      deliverable,
      endEarly
    });
    const setupInstructions = ref({
      description: '',
      instructions: ['', '', '']
    });
    return {
      ...toRefs(presets),
      setupInstructions,
      ...loading(programDoc.value.update, 'Success', 'Try again later'),
      adkData
    };
  }
});
