

















































































import { defineComponent, inject, reactive, toRefs } from '@vue/composition-api';

export default defineComponent({
  name: 'CreateTeam',

  props: {
    userType: {
      required: true,
      type: String
      // participant: '',
      // organizer: '',
      // stakeholder: ''
    }
  },
  setup(_props, ctx) {
    const readonly = inject('readonly', false);
    const state = reactive({
      name: '',
      password: '',
      createTeamDialog: false
    });
    const createTeam = () => {
      ctx.emit('createTeam', state.name, state.password);
      state.createTeamDialog = false;
      state.name = '';
    };
    return { ...toRefs(state), createTeam, readonly };
  }
});
