


































































































































































































/* eslint-disable no-prototype-builtins */
/* eslint-disable vue/no-mutating-props */
/* eslint-disable no-param-reassign */
import {
  defineComponent,
  ref,
  computed,
  PropType,
  reactive,
  toRefs,
  inject
} from '@vue/composition-api';
import { getModAdk, loading } from 'pcv4lib/src';
import MongoDoc from '../../views/Guide/activities/autoApply/components/types';

export default defineComponent({
  name: 'ModuleDefault',

  props: {
    value: {
      required: true,
      type: Object as PropType<MongoDoc>
    },
    isOpen: {
      required: true,
      type: Boolean
    },
    userType: {
      required: true,
      type: String
    },
    studentDoc: {
      required: true,
      type: Object as PropType<MongoDoc>,
      default: () => {}
    }
  },
  setup(props, ctx) {
    const readonly = inject('readonly', false);
    const isOpenDialog = computed({
      get: () => props.isOpen,
      set: newVal => {
        ctx.emit('toggle');
      }
    });

    const modal1 = ref('');

    const initAutoapplydefault = {
      vacationDates: [],
      name: 'autoapply',
      estimatedhours: '',
      summerDates: [],
      summerHours: '',
      summerVacation: false,
      summerVacationDates: [],
      summerJob: false,
      summerClasses: false,
      summerClassesDates: [],
      setupAuto: false,
      autoApply: false,
      endEarly: false,
      setupEndEarly: false,
      setUpAutoapply: true,
      cancelApplication: false,
      startTime: null,
      hasReceivedInterviewConfirmationNotification: false
    };

    const { adkData, adkIndex } = getModAdk(
      props,
      ctx.emit,
      'interview',
      {
        autoapply: {
          ...initAutoapplydefault
        }
      },
      'studentDoc',
      'inputStudentDoc'
    );
    const state = reactive({
      ...adkData.value.autoapply
    });
    const dateVacation = ref(false);
    const dateSummer = ref(false);

    function saveSummerDates() {
      dateSummer.value = true;
      while (state.summerDates.length > 0) {
        state.summerDates.pop();
      }
      state.summerDates.push(...state.summerClassesDates);

      // console.log(state.summerDates);
    }

    function saveVacationDates() {
      dateVacation.value = true;
      while (state.vacationDates.length > 0) {
        state.vacationDates.pop();
      }
      state.vacationDates.push(...state.summerVacationDates);
      // console.log(adkData.value.vacationDates);
    }
    // menus

    function process() {
      state.setupAuto = true;
      state.setUpAutoapply = false;
      state.autoApply = false;
      isOpenDialog.value = false;

      // Tell the user they've auto-applied and let them continue to the next section.
      const presetDays =
        props.value.data.adks.find((adk: any) => adk.name === 'interview')?.timedPresetDays ?? null;
      const currentDate = new Date();
      let startTime = currentDate.getTime();
      if (presetDays) {
        currentDate.setDate(currentDate.getDate() + presetDays);
        startTime = currentDate.getTime();
      }
      state.startTime = startTime;
      state.hasReceivedInterviewConfirmationNotification = false;

      adkData.value.autoapply = {
        ...adkData.value,
        ...state
      };

      if (props.studentDoc.data.adks[adkIndex].autoapply.hasOwnProperty('activityEndedOn')) {
        props.studentDoc.data.adks[adkIndex].autoapply.activityModifiedOn = new Date();
      }

      return props.studentDoc.update(() => ({
        adkIndex
      }));
    }

    function changeThanks() {
      state.cancelApplication = false;
      state.setupAuto = false;
      state.endEarly = false;
      state.setUpAutoapply = false;
      state.setupEndEarly = true;
      state.startTime = null;
      adkData.value = {
        ...adkData.value,
        ...state
      };

      if (props.studentDoc.data.adks[adkIndex].autoapply.hasOwnProperty('activityEndedOn')) {
        props.studentDoc.data.adks[adkIndex].autoapply.activityModifiedOn = new Date();
      } else {
        props.studentDoc.data.adks[adkIndex].autoapply.activityEndedOn = new Date();
      }

      if (
        props.studentDoc.data.adks[adkIndex].autoapply.hasOwnProperty('activityEndedOn') &&
        !props.studentDoc.data.adks[adkIndex].autoapply.hasOwnProperty('activityModifiedOn')
      ) {
        ctx.emit('calculate-timer');
      }

      return props.studentDoc.update(() => ({
        isComplete: true,
        adkIndex
      }));
    }

    const showInstructions = ref(true);

    return {
      isOpenDialog,
      showInstructions,
      modal1,
      adkData,
      ...toRefs(state),
      summerVacationMenu: false,
      summerClassesMenu: false,
      saveSummerDates,
      saveVacationDates,
      changeThanks,
      ...loading(process, 'Success', 'Try again later'),
      state,
      dateVacation,
      dateSummer,
      readonly
    };
  }
});
