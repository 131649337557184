



































































































import { ref, defineComponent, PropType, computed, onMounted } from '@vue/composition-api';
import { useDbState, useDbGetters, useUserActions } from '@/store';
import MInternsBadge from '@/components/molecules/MInternsBadge.vue';
import { ObjectId } from 'bson';
import momentTimezone from 'moment-timezone';
import moment from 'moment';
import MongoDoc from './types';

export default defineComponent({
  name: 'ModuleDefault',
  components: {
    MInternsBadge
  },
  props: {
    value: {
      required: true,
      type: Object as PropType<MongoDoc>
    },
    db: {
      required: true,
      type: Object as PropType<any>
    },
    fetchProgram: {
      required: true,
      type: [Object, Function]
    }
  },
  setup(props, ctx) {
    /** actions */
    const { user }: any = useDbState(['user']);
    const { collection } = useDbGetters(['collection']);
    const { updateCalendar } = useUserActions(['updateCalendar']);
    const { refreshCalendarTokenData } = useUserActions(['refreshCalendarTokenData']);
    const connectWithGoogleProgress = ref(false);
    const connectWithOutlookProgress = ref(false);
    const programDoc: any = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    function momentFormat(date) {
      return moment(date).format('MMMM Do, YYYY @ h:mma');
    }

    const index = programDoc.value.data.adks.findIndex(obj => {
      if (obj) {
        return obj.name === 'meet';
      }
      return false;
    });
    const eventDetails = props.value.data?.adks[index]?.events;

    console.log(eventDetails);

    async function updateAttendees(eventId, email, google, eventData, events) {
      const userByEmail = await collection.value!('User').findOne({
        email
      });
      let userAccessToken;
      let refreshedAccessTokenData;
      let oldRefreshToken;
      if (google) {
        userAccessToken = userByEmail.googleDetails.access_token;
        oldRefreshToken = userByEmail.googleDetails.refresh_token;
      } else {
        userAccessToken = userByEmail.outlookDetails.access_token;
        oldRefreshToken = userByEmail.outlookDetails.refresh_token;
      }
      const response = await updateCalendar({
        eventId,
        refreshToken: oldRefreshToken,
        accessToken: userAccessToken,
        google,
        eventData
      });
      if (!response.error) {
        programDoc.value.data.adks[index].events = events;
        programDoc.value.update();
        props.fetchProgram();
      } else if (response.error && response.error.code === 'UNAUTHORIZED') {
        if (google) {
          refreshedAccessTokenData = await refreshCalendarTokenData({
            refreshToken: userByEmail.googleDetails.refresh_token,
            type: 'google'
          });
          await updateCalendar({
            eventId,
            refreshToken: oldRefreshToken,
            accessToken: refreshedAccessTokenData.access_token,
            google,
            eventData
          });
        } else {
          refreshedAccessTokenData = await refreshCalendarTokenData({
            refreshToken: userByEmail.outlookDetails.refresh_token,
            type: 'outlook'
          });
          await updateCalendar({
            eventId,
            refreshToken: oldRefreshToken,
            accessToken: refreshedAccessTokenData.access_token,
            google,
            eventData
          });
          programDoc.value.data.adks[index].events = events;
          programDoc.value.update();
          props.fetchProgram();
        }
      }
    }

    /** Created */
    onMounted(async () => {
      const isInternAlreadyRemoved = await collection.value!('Student').findOne({
        program_id: new ObjectId(programDoc.value.data._id.toString()),
        participant_id: new ObjectId(user.value._id.toString()),
        'adks.name': 'meet',
        'adks.removedFromCalendar': true
      });
      if (!isInternAlreadyRemoved) {
        if (user.value.googleDetails || user.value.outlookDetails) {
          const weekltMeetingEvents = props.value.data.adks[index]?.events;
          console.log(weekltMeetingEvents);
          if (
            weekltMeetingEvents &&
            weekltMeetingEvents?.weekly_meeting &&
            weekltMeetingEvents?.weekly_meeting_participants &&
            weekltMeetingEvents?.weekly_meeting_participants.includes('intern')
          ) {
            const organizerUser = await collection.value!('User').findOne({
              email: weekltMeetingEvents?.created_by
            });
            let attendeesEmail;
            if (user.value.googleDetails && user.value.googleDetails.email) {
              attendeesEmail = user.value.googleDetails.email;
            } else if (user.value.outlookDetails && user.value.outlookDetails.email) {
              attendeesEmail = user.value.outlookDetails.email;
            } else {
              attendeesEmail = user.value.email;
            }
            if (organizerUser.googleDetails) {
              const weeklyMeetingAttendees = weekltMeetingEvents.weekly_meeting?.attendees.map(
                attendee => attendee?.email
              );
              if (!weeklyMeetingAttendees.includes(attendeesEmail)) {
                const newAttendees: any = weekltMeetingEvents.weekly_meeting.attendees.filter(
                  value => value
                );
                newAttendees.push({
                  email: attendeesEmail
                });
                const google = true;
                weekltMeetingEvents.weekly_meeting.attendees = newAttendees;
                delete weekltMeetingEvents.weekly_meeting.sequence;
                updateAttendees(
                  weekltMeetingEvents.weekly_meeting.id,
                  organizerUser.email,
                  google,
                  weekltMeetingEvents.weekly_meeting,
                  weekltMeetingEvents
                );
              }
            } else if (organizerUser.outlookDetails) {
              const weeklyMeetingAttendees = weekltMeetingEvents.weekly_meeting?.attendees.map(
                attendee => attendee?.emailAddress?.address
              );
              if (!weeklyMeetingAttendees.includes(attendeesEmail)) {
                const newAttendees: any = weekltMeetingEvents.weekly_meeting.attendees.filter(
                  value => value
                );

                newAttendees.push({
                  emailAddress: {
                    address: attendeesEmail,
                    name: user.value?.firstName + user.value?.lastName || ''
                  },
                  type: 'required'
                });
                const google = false;
                weekltMeetingEvents.weekly_meeting.attendees = newAttendees;
                updateAttendees(
                  weekltMeetingEvents.weekly_meeting.id,
                  organizerUser.email,
                  google,
                  weekltMeetingEvents.weekly_meeting,
                  weekltMeetingEvents
                );
              }
            }
          }
        }
      }
    });

    return {
      user,
      programDoc,
      connectWithGoogleProgress,
      connectWithOutlookProgress,
      eventDetails,
      momentFormat
    };
  }
});
