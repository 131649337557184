

























































































































































/* eslint-disable no-prototype-builtins */
/* eslint-disable vue/no-mutating-props */
/* eslint-disable no-param-reassign */
import { ref, PropType, defineComponent } from '@vue/composition-api';
import * as Realm from 'realm-web';
// eslint says it can't find these modules from @types/node
// can be fixed by removing "./node_modules/@types" from typeRoots in tsconfig.json
// but then you get errors for unit test related imports
// anyway this doesn't matter since it will be server-side
import { getModAdk } from 'pcv4lib/src';
import Instruct from './ModuleInstruct.vue';
import { MongoDoc, Video } from './types';

export default defineComponent({
  name: 'ModuleDefault',
  components: {
    Instruct
  },
  props: {
    value: {
      required: true,
      type: Object as PropType<MongoDoc>
    },
    teamDoc: {
      required: true,
      type: Object as PropType<MongoDoc>
    },
    userType: {
      required: true,
      type: String
      // participant: '',
      // organizer: '',
      // stakeholder: ''
    },
    currentUser: {
      required: true,
      type: Object as PropType<Realm.User>
    }
  },
  setup(props, ctx) {
    const { adkData } = getModAdk(props, ctx.emit, 'demo');
    const { adkData: adkTeamData, adkIndex } =
      getModAdk(props, ctx.emit, 'demo', { submittedVideo: null }, 'teamDoc', 'inputTeamDoc') ??
      null;
    const link = ref(
      adkTeamData.value.submittedVideo
        ? `https://youtu.be/${adkTeamData.value.submittedVideo.id}`
        : ''
    );
    // TODO: when teamDoc works, add submitted link from there if it exists
    const submittedVideo = ref<Video | null>(adkTeamData?.value.submittedVideo);
    const setupInstructions = ref({
      description: '',
      instructions: ['', '', '']
    });
    const showInstructions = ref(true);

    const apiErrors = ref<Array<string>>([]);

    const verifyLoading = ref(false);
    async function verifyLink() {
      if (!adkTeamData?.value && props.userType !== 'organizer') {
        apiErrors.value.push('not in team');
        return;
      }
      verifyLoading.value = true;
      const user: Realm.User = props.currentUser as Realm.User;
      const res: {
        statusCode: number;
        error?: string;
        body?: { submittedVideo: Video };
      } = await user.callFunction('ADK_Demo', {
        operation: 'submitResponse',
        payload: {
          videoLink: link.value,
          // TODO: probably want to move this part to server side
          videoMaxLength: adkData.value.videoMaxLength
        }
      });
      if (res.statusCode === 200) {
        submittedVideo.value = res.body!.submittedVideo;
        if (props.userType !== 'organizer') {
          adkTeamData.value.submittedVideo = submittedVideo.value;
          if (props.teamDoc.data.adks[adkIndex].hasOwnProperty('activityEndedOn')) {
            props.teamDoc.data.adks[adkIndex].activityModifiedOn = new Date();
          } else {
            props.teamDoc.data.adks[adkIndex].activityEndedOn = new Date();
          }
          props.teamDoc?.update(() => ({
            isComplete: true,
            adkIndex
          }));
          if (
            props.teamDoc.data.adks[adkIndex].hasOwnProperty('activityEndedOn') &&
            !props.teamDoc.data.adks[adkIndex].hasOwnProperty('activityModifiedOn')
          ) {
            ctx.emit('calculate-timer');
          }
        }
      } else if (res.error) {
        apiErrors.value.push(res.error);
      }
      verifyLoading.value = false;
    }

    return {
      setupInstructions,
      showInstructions,
      link,
      adkData,
      adkTeamData,
      submittedVideo,
      apiErrors,
      verifyLoading,
      verifyLink
    };
  }
});
