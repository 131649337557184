



























import { defineComponent, reactive, toRefs, computed, PropType } from '@vue/composition-api';
import { TeamDoc } from './types';
import TeamRow from './TeamRow.vue';

const MAX_TEAMS_PER_PAGE = 10;

export default defineComponent({
  name: 'JoinTeam',
  components: { TeamRow },
  props: {
    teams: {
      required: true,
      type: Array as PropType<Array<TeamDoc>>
    },
    userType: {
      required: true,
      type: String
    },
    maxTeamMembers: {
      required: true,
      type: Number
    }
  },
  setup(props, ctx) {
    const state = reactive({
      page: 1
    });
    const numPages = computed(() => Math.ceil(props.teams.length / MAX_TEAMS_PER_PAGE));
    const visibleTeams = computed(() =>
      props.teams.slice(
        (state.page - 1) * MAX_TEAMS_PER_PAGE,
        (state.page - 1) * MAX_TEAMS_PER_PAGE + MAX_TEAMS_PER_PAGE
      )
    );
    return {
      ...toRefs(state),
      visibleTeams,
      numPages
    };
  }
});
