var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{attrs:{"slim":""}},[_c('v-container',{staticClass:"module-default pa-0"},[_c('v-overlay',{staticClass:"border-r",staticStyle:{"width":"100%"},attrs:{"z-index":100,"absolute":"","value":_vm.isLoading || _vm.isFetching,"opacity":0.15}},[_c('v-progress-circular',{attrs:{"size":"80","width":"5","indeterminate":"","color":"green"}})],1),_c('div',{staticClass:"module-default__instructions"},[_c('v-expansion-panels',{staticClass:"module-default__instructions",attrs:{"flat":""},model:{value:(_vm.showInstructions),callback:function ($$v) {_vm.showInstructions=$$v},expression:"showInstructions"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{directives:[{name:"show",rawName:"v-show",value:(_vm.showInstructions),expression:"showInstructions"}],staticClass:"pa-0",attrs:{"hide-actions":""},on:{"click":function($event){_vm.showInstructions = true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var open = ref.open;
return [_c('v-scroll-y-transition',{attrs:{"hide-on-leave":""}},[(!open)?_c('div',{staticClass:"d-flex flex-column justify-center"},[_c('v-icon',{staticClass:"d-flex justify-center",attrs:{"color":"grey"}},[_vm._v(" mdi-chevron-down ")]),_c('div',{staticClass:"module-default__collapse-title"},[_vm._v("INSTRUCTIONS")])],1):_vm._e()])]}}])}),_c('v-expansion-panel-content',[_c('Instruct',{attrs:{"readonly":""}}),_c('div',{on:{"click":function($event){_vm.showInstructions = true}}},[_c('div',{staticClass:"module-default__collapse-title"},[_vm._v("CLOSE")]),_c('v-icon',{staticClass:"d-flex justify-center",attrs:{"color":"grey"}},[_vm._v(" mdi-chevron-up ")])],1)],1)],1)],1)],1),_c('v-progress-linear',{staticClass:"mt-3",attrs:{"color":"#dedede","height":"2","value":"100","buffer-value":"100","stream":""}}),_c('div',{staticStyle:{"width":"100%"}},[_c('v-card',{attrs:{"tile":"","depressed":""}},[_c('v-tabs',{attrs:{"slider-color":"green","next-icon":"mdi-chevron-double-right","prev-icon":"mdi-chevron-double-left","grow":""},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},_vm._l((_vm.teamList),function(item,index){return _c('v-tab',{key:item._id,on:{"click":function($event){return _vm.fetchDeliverableData(item)}}},[(item._id === 0)?_c('v-icon',{attrs:{"color":_vm.activeTab === item._id ? "green" : '',"left":""}},[_vm._v(" "+_vm._s(item.icon)+" ")]):(item.ownerId === _vm.currentUserId)?_c('v-icon',{attrs:{"color":"yellow","left":""}},[_vm._v(" mdi-star ")]):_c('div',[(
                    item.members.filter(function (m) { return m._id === _vm.currentUserId && !m.isMembershipPending; })
                      .length
                  )?_c('v-icon',{attrs:{"color":_vm.activeTab === index ? "green" : '',"left":""}},[_vm._v(" mdi-pencil ")]):_c('v-icon',{attrs:{"color":_vm.activeTab === index ? "green" : '',"left":""}},[_vm._v(" mdi-eye ")])],1),_c('span',{staticClass:"font-weight-bold",class:_vm.activeTab === index ? "green--text" : ''},[_vm._v(" "+_vm._s(item.name)+" ")])],1)}),1),_c('v-tabs-items',{model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},_vm._l((_vm.teamList),function(item){return _c('v-tab-item',{key:item._id},[_c('v-card',{attrs:{"flat":""}},[(_vm.activeTab === 0)?_c('m-create-deliverable',{attrs:{"student":_vm.studentDoc,"fetch-deliverables":_vm.fetchDeliverables,"team-name":_vm.teamDoc.data.name}}):_vm._e(),(item._id !== 0)?_c('v-expansion-panels',{attrs:{"accordion":""}},[(
                      _vm.adminSettings.teamSetting === 'global_on' ||
                      (_vm.programDocAdk &&
                        !!_vm.programDocAdk.teamSettings.required &&
                        _vm.adminSettings.team !== 'global_on' &&
                        _vm.adminSettings.team !== 'global_off')
                    )?_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"pa-6"},[_c('h2',{staticClass:"font-weight-bold"},[_c('v-icon',{staticClass:"mr-4",attrs:{"color":"green","large":"","left":""}},[_vm._v("mdi-account-group")]),_vm._v("Team ")],1)]),_c('v-expansion-panel-content',[(_vm.currentTabData)?_c('m-deliverable-table',{attrs:{"data":_vm.currentTabData,"view-only":_vm.onlyView,"student":_vm.studentDoc,"fetch-deliverables":_vm.fetchDeliverables,"fetch-deliverable":_vm.fetchDeliverableData},on:{"set-tab":function($event){_vm.activeTab = 0}}}):_vm._e()],1)],1):_vm._e(),(
                      _vm.adminSettings.projectPlanSetting === 'global_on' ||
                      (_vm.programDocAdk &&
                        !!_vm.programDocAdk.projectPlanSettings.required &&
                        _vm.adminSettings.projectPlan !== 'global_on' &&
                        _vm.adminSettings.projectPlan !== 'global_off')
                    )?_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"pa-6"},[_c('h2',{staticClass:"font-weight-bold"},[_c('v-icon',{staticClass:"mr-4",attrs:{"color":"green","large":"","left":""}},[_vm._v("mdi-file-document")]),_vm._v("Project Plan ")],1)]),_c('v-expansion-panel-content',[(_vm.currentTabData)?_c('m-project-plan',{ref:"projectPlan",refInFor:true,attrs:{"upload":_vm.uploadItem,"mongo-user":_vm.mongoUser,"adk-list":_vm.currentTabData.adks,"fetch-deliverables":_vm.fetchDeliverables,"view-only":_vm.onlyView,"data":_vm.currentTabData}}):_vm._e()],1)],1):_vm._e(),(
                      _vm.adminSettings.myTasksSetting === 'global_on' ||
                      (_vm.programDocAdk &&
                        !!_vm.programDocAdk.projectTaskSettings.required &&
                        _vm.adminSettings.myTasks !== 'global_on' &&
                        _vm.adminSettings.myTasks !== 'global_off')
                    )?_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"pa-6"},[_c('h2',{staticClass:"font-weight-bold"},[_c('v-icon',{staticClass:"mr-4",attrs:{"color":"green","large":"","left":""}},[_vm._v("mdi-format-list-checks")]),_vm._v("My Tasks ")],1)]),_c('v-expansion-panel-content',[_c('v-container',{staticClass:"pa-0"},[_c('m-tasks',{attrs:{"data":_vm.currentTabData,"view-only":_vm.onlyView}})],1)],1)],1):_vm._e(),(
                      _vm.adminSettings.FAQSetting === 'global_on' ||
                      (_vm.programDocAdk &&
                        !!_vm.programDocAdk.projectFAQSettings &&
                        _vm.adminSettings.FAQ !== 'global_on' &&
                        _vm.adminSettings.FAQ !== 'global_off')
                    )?_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"pa-6"},[_c('h2',{staticClass:"font-weight-bold"},[_c('v-icon',{staticClass:"mr-4",attrs:{"color":"green","large":"","left":""}},[_vm._v("mdi-chat-question")]),_vm._v("Frequently Asked Questions (FAQ) ")],1)]),_c('v-expansion-panel-content',[_c('v-container',{staticClass:"pa-6"},[_c('m-user-faq',{attrs:{"data":_vm.currentTabData,"view-only":_vm.onlyView}})],1)],1)],1):_vm._e(),(
                      _vm.adminSettings.digitalResumeSetting === 'global_on' ||
                      (_vm.programDocAdk &&
                        !!_vm.programDocAdk.digitalResumeSettings &&
                        _vm.adminSettings.digitalResume !== 'global_on' &&
                        _vm.adminSettings.digitalResume !== 'global_off')
                    )?_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"pa-6"},[_c('h2',{staticClass:"font-weight-bold"},[_c('v-icon',{staticClass:"mr-4",attrs:{"color":"green","large":"","left":""}},[_vm._v("mdi-file-document-multiple")]),_vm._v("Digital Resume ")],1)]),_c('v-expansion-panel-content',[_c('v-container',{staticClass:"pa-6"},[_c('m-digital-resume',{attrs:{"data":_vm.currentTabData,"view-only":_vm.onlyView}})],1)],1)],1):_vm._e(),(
                      _vm.adminSettings.finalPresentationSetting === 'global_on' ||
                      (_vm.programDocAdk &&
                        !!_vm.programDocAdk.presentationSettings.required &&
                        _vm.adminSettings.finalPresentation !== 'global_on' &&
                        _vm.adminSettings.finalPresentation !== 'global_off')
                    )?_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"pa-6"},[_c('h2',{staticClass:"font-weight-bold"},[_c('v-icon',{staticClass:"mr-4",attrs:{"color":"green","large":"","left":""}},[_vm._v("mdi-presentation")]),_vm._v("Final Presentation ")],1)]),_c('v-expansion-panel-content',[_c('v-container',{},[_c('m-presentation',{attrs:{"data":_vm.currentTabData,"program-doc":_vm.value,"mongo-user":_vm.mongoUser,"view-only":_vm.onlyView}})],1)],1)],1):_vm._e(),(
                      _vm.adminSettings.marketingBlogSetting === 'global_on' ||
                      (_vm.programDocAdk &&
                        !!_vm.programDocAdk.caseStudySettings.required &&
                        _vm.adminSettings.marketingBlog !== 'global_on' &&
                        _vm.adminSettings.marketingBlog !== 'global_off')
                    )?_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"pa-6"},[_c('h2',{staticClass:"font-weight-bold"},[_c('v-icon',{staticClass:"mr-4",attrs:{"color":"green","large":"","left":""}},[_vm._v("mdi-lead-pencil")]),_vm._v("Marketing Blog ")],1)]),_c('v-expansion-panel-content',[_c('v-container',{staticClass:"pa-6 pb-12"},[_c('m-case-study',{attrs:{"data":_vm.currentTabData,"view-only":_vm.onlyView}})],1)],1)],1):_vm._e(),(
                      _vm.adminSettings.projectDocumentationSetting === 'global_on' ||
                      (_vm.programDocAdk &&
                        !!_vm.programDocAdk.projectImplementationSettings.required &&
                        _vm.adminSettings.projectDocumentationSetting !== 'global_on' &&
                        _vm.adminSettings.projectDocumentationSetting !== 'global_off')
                    )?_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"pa-6"},[_c('h2',{staticClass:"font-weight-bold"},[_c('v-icon',{staticClass:"mr-4",attrs:{"color":"green","large":"","left":""}},[_vm._v("mdi-wrench-clock")]),_vm._v("Project Documentation ")],1)]),_c('v-expansion-panel-content',[_c('v-container',{staticClass:"pa-6"},[_c('m-project-implementation',{attrs:{"upload":_vm.uploadItem,"data":_vm.currentTabData,"view-only":_vm.onlyView,"user-type":_vm.userType}})],1)],1)],1):_vm._e(),(
                      _vm.adminSettings.userInterviewSetting === 'global_on' ||
                      (_vm.programDocAdk &&
                        !!_vm.programDocAdk.userInterviewSettings.required &&
                        _vm.adminSettings.userInterview !== 'global_on' &&
                        _vm.adminSettings.userInterview !== 'global_off')
                    )?_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"pa-6"},[_c('h2',{staticClass:"font-weight-bold"},[_c('v-icon',{staticClass:"mr-4",attrs:{"color":"green","large":"","left":""}},[_vm._v("mdi-chat-processing")]),_vm._v("User Interviews ")],1)]),_c('v-expansion-panel-content',[_c('m-user-interview',{attrs:{"upload":_vm.uploadItem,"data":_vm.currentTabData,"view-only":_vm.onlyView}})],1)],1):_vm._e(),_c('v-expansion-panel',{attrs:{"disabled":""}},[_c('v-expansion-panel-header',{staticClass:"pa-6"},[_c('h2',{staticClass:"font-weight-bold"},[_c('v-icon',{staticClass:"mr-4",attrs:{"color":"green","large":"","left":""}},[_vm._v("mdi-alert-decagram")]),_vm._v("Additional coming soon... ")],1)]),_c('v-expansion-panel-content',[_c('v-container',{staticClass:"pa-6"})],1)],1)],1):_vm._e()],1)],1)}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }