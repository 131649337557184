<template>
  <v-container>
    <div class="text-center mb-8"><h2>Meet regularly with interns</h2></div>
    <validation-provider v-slot="{ errors }" slim rules="required">
      <ATextInput
        v-model="meetingName"
        :error-messages="errors"
        rounded
        outlined
        label="Meeting Name"
      />
    </validation-provider>

    <!-- <div class="headline font-weight-bold ml-2 mt-2 mb-2">Select recurring days per week</div> -->
    <div class="text-start mb-2">
      <h4>Select days & meeting cadence with interns</h4>
    </div>
    <v-row class="mt-5 mb-5">
      <div v-for="item in daysPerWeekList" :key="item" class="d-flex justify-content: center">
        <v-col>
          <a-button
            id="day"
            depressed
            class="white--text font-weight-bold"
            width="10px"
            rounded
            :color="daysPerWeek.includes(item) ? 'blue' : 'grey'"
            @click="
              daysPerWeek.includes(item)
                ? daysPerWeek.splice(daysPerWeek.indexOf(item), 1)
                : daysPerWeek.push(item)
            "
            >{{ item.split('')[0] }}</a-button
          >
        </v-col>
      </div>
    </v-row>

    <validation-provider v-slot="{ errors }" slim rules="required">
      <ASelectInput
        v-model="repeat"
        :error-messages="errors"
        rounded
        outlined
        :items="repeatTimes"
        label="Repeat every"
      />
    </validation-provider>

    <div class="text-start mb-7"><h4>Day & Time</h4></div>

    <v-container fluid>
      <v-row>
        <v-col cols="12" sm="6" class="pl-0 pr-1">
          <!-- <v-menu
            ref="weeklyMeetingTime"
            v-model="weeklyMeetingTime"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="time"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          > -->
          <validation-provider v-slot="{ errors }" class="date-time-selector" rules="required">
            <vuetify-time-select v-model="time" outlined rounded :error-messages="errors" />
          </validation-provider>
          <!-- <v-time-picker
              v-if="weeklyMeetingTime"
              v-model="time"
              full-width
              @click:minute="$refs.weeklyMeetingTime.save(time)"
            ></v-time-picker>
          </v-menu> -->

          <!-- <ATextInput
              v-model="date"
              outlined
              rounded
              type="date"
              :error-messages="errors"
              label="Select date"
            /> -->
        </v-col>
        <v-col cols="12" sm="6" class="pl-1 pr-0">
          <validation-provider v-slot="{ errors }" slim rules="required">
            <ASelectInput
              v-model="duration"
              :error-messages="errors"
              rounded
              outlined
              :items="durationList"
              label="Duration"
            />
          </validation-provider>

          <!-- <validation-provider v-slot="{ errors }" rules="required">
            <ATextInput
              v-model="time"
              outlined
              rounded
              type="time"
              :error-messages="errors"
              label="Select time"
            />
          </validation-provider> -->
        </v-col>
      </v-row>
    </v-container>

    <v-menu
      ref="weeklyMeetingDate"
      v-model="weeklyMeetingDate"
      :close-on-content-click="false"
      :return-value.sync="date"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template #activator="{ on, attrs }">
        <validation-provider v-slot="{ errors }" rules="required">
          <v-text-field
            v-model="date"
            rounded
            outlined
            label="Start on or after this cohort start date"
            readonly
            v-bind="attrs"
            :error-messages="errors"
            v-on="on"
          ></v-text-field>
        </validation-provider>
      </template>
      <v-date-picker
        v-model="date"
        no-title
        scrollable
        @input="$refs.weeklyMeetingDate.save(date)"
      />
    </v-menu>

    <!-- <validation-provider v-slot="{ errors }" slim rules="required">
      <ASelectInput
        v-model="weeklyMeetingOccurence"
        :error-messages="errors"
        rounded
        outlined
        :items="weeklyMeetingOccurenceList"
        label="End after"
      />
    </validation-provider> -->

    <!-- <validation-provider v-slot="{ errors }" slim rules="required">
      <ATextInput
        v-model="conferenceLine"
        :error-messages="errors"
        rounded
        label="Enter Conference line"
        outlined
      />
    </validation-provider> -->

    <validation-provider v-slot="{ errors }" slim rules="required">
      <ASelectInput
        v-model="timeZone"
        :error-messages="errors"
        rounded
        outlined
        :items="timeZoneList"
        label="Time zone"
      />
    </validation-provider>

    <div class="text-start mb-7"><h4>Meeting Agenda</h4></div>

    <validation-provider v-slot="{ errors }" slim rules="required">
      <v-textarea
        v-model="description"
        :error-messages="errors"
        rounded
        outlined
        label="Agenda"
        hint="Enter standard items on the meeting agenda with interns"
        class="mb-3"
      ></v-textarea>
    </validation-provider>

    <validation-provider v-if="user.googleDetails" v-slot="{ errors }" rules="required" slim>
      <v-combobox
        v-model="selectedValue"
        :items="[
          '3 days before',
          '2 days before',
          '1 day before',
          '8 hours before',
          '1 hour before'
        ]"
        :error-messages="errors"
        rounded
        label="Notification"
        multiple
        small-chips
        outlined
      >
        <template #no-data>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                Press <kbd>enter</kbd> to add reward
                <!-- <strong>{{ rewardSearch }}</strong
                    >". -->
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template #selection="{ attrs, item, parent, selected }">
          <v-chip
            v-bind="attrs"
            :input-value="selected"
            label
            small
            :class="'start-adk-career-options'"
            @click="parent.selectItem(item)"
          >
            <span class="pr-2">
              {{ item }}
            </span>
            <v-icon small> mdi-close </v-icon>
          </v-chip>
        </template>
      </v-combobox>
    </validation-provider>

    <validation-provider v-if="user.outlookDetails" v-slot="{ errors }" slim rules="required">
      <ASelectInput
        v-model="reminderMinutes"
        :error-messages="errors"
        rounded
        outlined
        :items="[
          '3 days before',
          '2 days before',
          '1 day before',
          '8 hours before',
          '1 hour before'
        ]"
        label="Notification"
      />
    </validation-provider>

    <template>
      <v-sheet class="pa-5 switch-wrapper">
        <v-row>
          <v-col class="pl-0 pr-0 pt-0"><h4>Custom Location</h4></v-col
          ><v-col class="pl-0 pr-0 pt-0 switch-toggle-wrapper"
            ><v-switch
              v-model="isSwitchOn"
              :disabled="
                user.googleDetails &&
                programDoc.data.adks[index].events &&
                programDoc.data.adks[index].events.weekly_meeting
              "
              color="blue"
              class="mt-0 pt-0 mr-10"
            ></v-switch></v-col
          ><v-col class="pl-0 pr-0 pt-0"><h4>Digital Location</h4></v-col>
        </v-row>
      </v-sheet>
    </template>

    <div>
      <div v-if="isSwitchOn">
        <div v-if="user.googleDetails">
          <v-checkbox
            v-model="googleMeet"
            :disabled="
              programDoc.data.adks[index].events &&
              programDoc.data.adks[index].events.weekly_meeting
            "
            label="Add Google Meet (Default, Recommended)"
            value="Google Meet"
          ></v-checkbox>
          <div
            v-if="
              isGoogleMeet ||
              (programDoc.data.adks[index].events &&
                programDoc.data.adks[index].events.weekly_meeting)
            "
          >
            <ATextInput
              v-model="digitalLocation"
              :error-messages="errors"
              rounded
              outlined
              disabled="true"
              label="Digital Location"
            />
          </div>
          <div v-else>
            <validation-provider v-slot="{ errors }" slim rules="required">
              <ATextInput
                v-model="digitalLocation"
                :error-messages="errors"
                rounded
                outlined
                label="Digital Location"
              />
            </validation-provider>
          </div>
        </div>
        <div v-else-if="user.outlookDetails">
          <validation-provider v-slot="{ errors }" slim rules="required">
            <ATextInput
              v-model="digitalLocation"
              :error-messages="errors"
              rounded
              outlined
              label="Digital Location"
            />
          </validation-provider>
        </div>
      </div>
      <div v-else>
        <validation-provider v-slot="{ errors }" slim rules="required">
          <a-autocomplete
            v-model="physicalLocation"
            is-rounded
            :error-message="errors"
            placeholder="Search Location"
            type="street"
            label="Custom Location"
            @val="setStreetValue"
          />
        </validation-provider>
      </div>
    </div>

    <div class="text-center mb-8 mt-8"><h2>Add attendees</h2></div>
    <div>
      <v-checkbox v-model="selected" label="All employer hosts" value="employer"></v-checkbox>
      <v-checkbox
        v-model="selected"
        label="All current interns"
        value="intern"
        class="mt-0"
      ></v-checkbox>
    </div>
  </v-container>
</template>

<script>
import { ref, watch, onMounted, computed } from '@vue/composition-api';
import { useDbState, useUserActions } from '@/store';
import ATextInput from '@/components/atoms/ATextInput.vue';
import ASelectInput from '@/components/atoms/ASelectInput.vue';
import AButton from '@/components/atoms/AButton.vue';
import { ValidationProvider } from '@/validation';
import AAutocomplete from '@/components/atoms/AAutocomplete.vue';
import moment from 'moment';
import VuetifyTimeSelect from 'vuetify-time-select';

export default {
  components: {
    ATextInput,
    ASelectInput,
    AButton,
    AAutocomplete,
    VuetifyTimeSelect,
    ValidationProvider
  },
  props: {
    value: {
      required: true,
      type: Object
    }
  },
  setup(props, ctx) {
    const { user } = useDbState(['user']);
    const { getInternshipBadge } = useUserActions(['getInternshipBadge']);
    const programDoc = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    const selectedValue = ref([
      '3 days before',
      '2 days before',
      '1 day before',
      '8 hours before',
      '1 hour before'
    ]);
    const index = programDoc.value.data.adks.findIndex(obj => {
      if (obj) {
        return obj.name === 'meet';
      }
      return false;
    });
    const offerAdkIndex = programDoc.value.data.adks.findIndex(obj => {
      if (obj) {
        return obj.name === 'offer';
      }
      return false;
    });
    const physicalLocation = ref('');
    const digitalLocation = ref('');
    const isSwitchOn = ref(true);
    watch(
      () => physicalLocation.value,
      v => {
        console.log(v, 'physicalLocation');
      }
    );
    /** selection field */
    let daysPerWeekList;
    if (user.value.googleDetails) {
      daysPerWeekList = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];
    } else if (user.value.outlookDetails) {
      daysPerWeekList = [
        'SUNDAY',
        'MONDAY',
        'TUESDAY',
        'WEDNESDAY',
        'THURSDAY',
        'FRIDAY',
        'SATURDAY'
      ];
    }
    const durationList = ['30 Minutes', '1 Hour', '2 Hours', '3 Hours'];
    const timeZoneList = [
      'Africa/Cairo',
      'Africa/Casablanca',
      'Africa/Harare',
      'Africa/Lagos',
      'Africa/Nairobi',
      'Africa/Windhoek',
      'America/Argentina/Buenos_Aires',
      'America/Bogota',
      'America/Caracas',
      'America/Chihuahua',
      'America/Godthab',
      'America/Los_Angeles',
      'America/Managua',
      'America/Manaus',
      'America/Mexico_City',
      'America/Montevideo',
      'America/Noronha',
      'America/Santiago',
      'America/Sao_Paulo',
      'America/Tijuana',
      'Asia/Almaty',
      'Asia/Amman',
      'Asia/Baku',
      'Asia/Bangkok',
      'Asia/Beirut',
      'Asia/Calcutta',
      'Asia/Calcutta',
      'Asia/Dhaka',
      'Asia/Hong_Kong',
      'Asia/Irkutsk',
      'Asia/Jerusalem',
      'Asia/Kabul',
      'Asia/Karachi',
      'Asia/Katmandu',
      'Asia/Kolkata',
      'Asia/Krasnoyarsk',
      'Asia/Kuala_Lumpur',
      'Asia/Kuwait',
      'Asia/Magadan',
      'Asia/Muscat',
      'Asia/Rangoon',
      'Asia/Seoul',
      'Asia/Taipei',
      'Asia/Tbilisi',
      'Asia/Tehran',
      'Asia/Tokyo',
      'Asia/Vladivostok',
      'Asia/Yakutsk',
      'Asia/Yekaterinburg',
      'Asia/Yerevan',
      'Atlantic/Azores',
      'Atlantic/Cape_Verde',
      'Australia/Adelaide',
      'Australia/Brisbane',
      'Australia/Canberra',
      'Australia/Darwin',
      'Australia/Hobart',
      'Australia/Perth',
      'Canada/Atlantic',
      'Canada/Newfoundland',
      'Canada/Saskatchewan',
      'Etc/GMT+12',
      'Etc/Greenwich',
      'Europe/Amsterdam',
      'Europe/Athens',
      'Europe/Belgrade',
      'Europe/Brussels',
      'Europe/Helsinki',
      'Europe/Minsk',
      'Europe/Moscow',
      'Europe/Sarajevo',
      'Pacific Standard Time',
      'Pacific/Auckland',
      'Pacific/Fiji',
      'Pacific/Guam',
      'Pacific/Honolulu',
      'Pacific/Midway',
      'Pacific/Tongatapu',
      'US/Alaska',
      'US/Arizona',
      'US/Central',
      'US/East-Indiana',
      'US/Eastern',
      'US/Mountain',
      'UTC'
    ];
    const repeatTimes = ['1 Week', '2 Weeks'];
    const weeklyMeetingOccurenceList = [
      '1 Occurrences',
      '2 Occurrences',
      '3 Occurrences',
      '4 Occurrences',
      '5 Occurrences',
      '6 Occurrences'
    ];
    const weeklyMeetingDate = false;
    const weeklyMeetingTime = false;
    const meetingName = ref(`${props.value.data.programName} Interns Weekly Meeting`);
    const timeZone = ref('America/Los_Angeles');
    let daysPerWeek;
    if (user.value.googleDetails) {
      daysPerWeek = ref(['WE']);
    } else if (user.value.outlookDetails) {
      daysPerWeek = ref(['WEDNESDAY']);
    }
    const date = ref('');
    const deadlineDate = ref('');
    const time = ref('16:00');
    const duration = ref('30 Minutes');
    const repeat = ref('1 Week');
    const weeklyMeetingOccurence = ref('');
    const conferenceLine = ref('');
    const isGoogleMeet = ref(true);
    const googleMeet = ref(['Google Meet']);
    watch(
      () => googleMeet.value,
      meetOn => {
        if (meetOn.length < 1) {
          isGoogleMeet.value = false;
        } else {
          isGoogleMeet.value = true;
        }
      }
    );
    const description = ref(
      `Agenda:\n 1) Vibe check, announcements & project updates,\n 2) Addressing barriers, issues & blockers, \n 3) Sidebars & coworking \n 4) Calendaring & next steps`
    );
    const selected = ref(['employer', 'intern']);
    const reminderMinutes = ref('3 days before');
    watch(
      () => isSwitchOn.value,
      isSwitchValue => {
        if (!programDoc.value.data.adks[index].events) {
          if (isSwitchValue) {
            physicalLocation.value = '';
          } else {
            digitalLocation.value = '';
          }
        }
      }
    );
    function fetchReminderMinutes(minutes) {
      if (minutes === 4320) {
        reminderMinutes.value = '3 days before';
      } else if (minutes === 2880) {
        reminderMinutes.value = '2 days before';
      } else if (minutes === 1440) {
        reminderMinutes.value = '1 day before';
      } else if (minutes === 480) {
        reminderMinutes.value = '8 hours before';
      } else if (minutes === 60) {
        reminderMinutes.value = '1 hour before';
      }
    }
    onMounted(async () => {
      // const response = await getInternshipBadge(props.value.data._id.toString());
      // const startDateFilter = response.map(date => date.startDate);
      // const currentDate = moment().format('YYYY-MM-DD');
      // const mapedStartDate = response.filter(data => {
      //   let earliestStartDate;
      //   if (moment(currentDate).isBetween(data.startDate, data.deadlineDate, null, '[]')) {
      //     earliestStartDate = data.startDate;
      //   }
      //   return earliestStartDate;
      // });
      // console.log(mapedStartDate, 'mapedStartDate');
      // const startDateMoments = startDateFilter.map(x => moment(x));
      // date.value =
      //   mapedStartDate.length >= 1
      //     ? mapedStartDate[0].startDate
      //     : moment.max(startDateMoments).format('YYYY-MM-DD');
      // const deadlineDateFilter = response.map(date => date.deadlineDate);
      // const deadlineDateMoments = deadlineDateFilter.map(x => moment(x));
      // deadlineDate.value = moment.max(deadlineDateMoments).format('YYYY-MM-DD');
      if (offerAdkIndex > 0) {
        date.value = programDoc.value.data.adks[offerAdkIndex]?.offer[0]?.internshipStart;
        deadlineDate.value = programDoc.value.data.adks[offerAdkIndex]?.offer[0]?.internshipEnd;
      }
      if (
        programDoc.value.data.adks[index].events &&
        programDoc.value.data.adks[index].events?.weekly_meeting
      ) {
        const meetingEvent = programDoc.value.data.adks[index].events?.weekly_meeting;
        selected.value = programDoc.value.data.adks[index].events?.weekly_meeting_participants;
        if (user.value.googleDetails) {
          meetingName.value = meetingEvent.summary;
          timeZone.value = meetingEvent.start.timeZone;
          const recurrence = meetingEvent.recurrence[0]?.split(';');
          const byDay = recurrence[3].split('=')[1].split(',');
          daysPerWeek.value = byDay;
          const startDate = programDoc.value.data.adks[index].events.startDateTime.split('T')[0];
          const splitedTime = programDoc.value.data.adks[index].events.startDateTime
            .split('T')[1]
            .split(':');
          date.value = startDate;
          time.value = `${splitedTime[0]}:${splitedTime[1]}`;
          const repeated = `${recurrence[2].split('=')[1]} Week`;
          const durationValue = `${moment(meetingEvent.end.dateTime).diff(
            moment(meetingEvent.start.dateTime),
            'hours'
          )}`;
          if (durationValue < 1) {
            duration.value = `${moment(meetingEvent.end.dateTime).diff(
              moment(meetingEvent.start.dateTime),
              'minutes'
            )} Minutes`;
          } else {
            duration.value = `${durationValue} Hour`;
          }
          console.log(duration.value, durationValue, time.value, 'time');
          repeat.value = repeated;
          description.value = meetingEvent.description;
          const regex =
            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;
          if (meetingEvent.location.match(regex)) {
            isSwitchOn.value = true;
            googleMeet.value = [];
            isGoogleMeet.value = false;
            digitalLocation.value = meetingEvent.location;
          } else {
            isSwitchOn.value = false;
            physicalLocation.value = meetingEvent.location;
          }
          if (!meetingEvent.location && meetingEvent.hangoutLink) {
            isSwitchOn.value = true;
            googleMeet.value = ['Google Meet'];
            isGoogleMeet.value = true;
          }
        } else if (user.value.outlookDetails) {
          meetingName.value = meetingEvent.subject;
          timeZone.value = meetingEvent.originalStartTimeZone;
          console.log(timeZone.value);
          daysPerWeek.value = meetingEvent.recurrence.pattern.daysOfWeek.map(day =>
            day.toUpperCase()
          );
          console.log(daysPerWeek, meetingEvent.recurrence.pattern.interval);
          const startDate = meetingEvent.start.dateTime.split('T')[0];
          const splitedTime = meetingEvent.start.dateTime.split('T')[1].split(':');
          date.value = startDate;
          time.value = `${splitedTime[0]}:${splitedTime[1]}`;
          description.value = meetingEvent.bodyPreview;
          const regex =
            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;
          if (meetingEvent.location.displayName.match(regex)) {
            isSwitchOn.value = true;
            digitalLocation.value = meetingEvent.location.displayName;
          } else {
            isSwitchOn.value = false;
            physicalLocation.value = meetingEvent.location.displayName;
          }
          const durationValue = `${moment(meetingEvent.end.dateTime).diff(
            moment(meetingEvent.start.dateTime),
            'hours'
          )}`;
          fetchReminderMinutes(meetingEvent.reminderMinutesBeforeStart);
          if (durationValue < 1) {
            duration.value = `${moment(meetingEvent.end.dateTime).diff(
              moment(meetingEvent.start.dateTime),
              'minutes'
            )} Minutes`;
          } else {
            duration.value = `${durationValue} Hour`;
          }
          repeat.value = `${meetingEvent.recurrence.pattern.interval} Week`;
        }
      }
    });
    watch(
      [
        meetingName,
        timeZone,
        daysPerWeek,
        date,
        time,
        deadlineDate,
        duration,
        repeat,
        weeklyMeetingOccurence,
        conferenceLine,
        description,
        selected,
        selectedValue,
        reminderMinutes,
        digitalLocation,
        physicalLocation,
        googleMeet
      ],
      ([
        meetingName,
        timeZone,
        daysPerWeek,
        date,
        time,
        deadlineDate,
        duration,
        repeat,
        weeklyMeetingOccurence,
        conferenceLine,
        description,
        selected,
        selectedValue,
        reminderMinutes,
        digitalLocation,
        physicalLocation,
        googleMeet
      ]) => {
        const weeklyMeetingObject = {
          meetingName: meetingName || undefined,
          timeZone: timeZone || undefined,
          daysPerWeek: daysPerWeek || undefined,
          date: date || undefined,
          time: time || undefined,
          deadlineDate: deadlineDate || undefined,
          duration: duration || undefined,
          repeat: repeat || undefined,
          weeklyMeetingOccurence: weeklyMeetingOccurence || undefined,
          conferenceLine: conferenceLine || undefined,
          description: description || undefined,
          selected: selected || undefined,
          selectedValue: selectedValue || undefined,
          reminderMinutes: reminderMinutes || undefined,
          digitalLocation: digitalLocation || undefined,
          physicalLocation: physicalLocation || undefined,
          googleMeet: googleMeet || undefined
        };
        ctx.emit('weeklyMeetingEmit', weeklyMeetingObject);
      }
    );
    function setStreetValue(e) {
      if (e.target) {
        physicalLocation.value = e.target.value;
      } else if (e.name) {
        physicalLocation.value = e.formatted_address;
      }
    }
    return {
      daysPerWeekList,
      durationList,
      repeatTimes,
      timeZoneList,
      weeklyMeetingDate,
      weeklyMeetingTime,
      weeklyMeetingOccurenceList,
      meetingName,
      timeZone,
      daysPerWeek,
      date,
      time,
      deadlineDate,
      duration,
      repeat,
      weeklyMeetingOccurence,
      conferenceLine,
      description,
      selected,
      selectedValue,
      reminderMinutes,
      user,
      physicalLocation,
      digitalLocation,
      isSwitchOn,
      setStreetValue,
      isGoogleMeet,
      googleMeet,
      programDoc,
      index
    };
  }
};
</script>

<style lang="scss">
#day {
  min-width: 36px;
}

.date-time-selector {
  .v-input {
    .v-input__slot {
      .time-hours {
        margin-right: 10px;
      }
      > div {
        &:nth-of-type(2) {
          display: none;
        }
      }

      .time-minutes {
        margin-right: 10px;
      }
    }
  }
}

.switch-wrapper {
  max-width: 430px;
  .switch-toggle-wrapper {
    width: 45px;
    min-width: 45px;
    max-width: 45px;
    margin: 0 30px;
  }
}
</style>
