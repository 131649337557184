
























































































































































/* eslint-disable no-prototype-builtins */
/* eslint-disable vue/no-mutating-props */
/* eslint-disable no-param-reassign */
import {
  computed,
  reactive,
  ref,
  toRefs,
  defineComponent,
  PropType,
  inject,
  watch,
  onMounted,
  Ref
} from '@vue/composition-api';
import '../styles/module.scss';
import Setup from './ModuleSetup.vue';
import Presets from './ModulePresets.vue';
import Default from './ModuleDefault.vue';
import MongoDoc from './types';

export default defineComponent({
  name: 'Ideate',

  components: {
    'module-setup': Setup,
    'module-presets': Presets,
    'module-preview': Default
  },
  props: {
    value: {
      required: true,
      type: Object as PropType<MongoDoc>
    },
    userDoc: {
      required: true,
      type: Object as PropType<MongoDoc | null>,
      default: () => {}
    },
    getTeamDoc: {
      required: false,
      type: Function,
      default: () => {}
    },
    teamDoc: {
      required: false,
      type: Object as PropType<MongoDoc | null>,
      default: () => {}
    },
    studentDoc: {
      required: false,
      type: Object as PropType<MongoDoc | null>,
      default: () => {}
    },
    userType: {
      required: true,
      type: String
    },
    readonly: {
      required: false,
      default: false
    },
    operationMode: {
      type: Number
    }
  },

  setup(props, ctx) {
    const readonlyInjected = inject('readonly', false);
    const readonlyProps = props.readonly;
    const readOnly = readonlyInjected || readonlyProps;
    const programDoc = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    props.getTeamDoc();
    const index = programDoc.value.data.adks.findIndex(obj => {
      if (obj) {
        return obj.name === 'ideate';
      }
      return false;
    });
    if (index === -1) {
      const initIdeate = {
        name: 'ideate'
      };
      programDoc.value.data.adks.push(initIdeate);
    }

    const moduleName = ref('Ideate');
    const page = reactive({
      subpages: ['Setup', 'Presets'],
      currentPage: 'Setup'
    });
    if (props.userType === 'organizer' && props.operationMode === 1) {
      page.currentPage = 'Presets';
    } else {
      page.currentPage = 'preview';
    }
    const getComponent = computed(() => {
      return `module-${page.currentPage.toLowerCase()}`;
    });
    const color = reactive({
      pilotcityColors: [
        ['#6eba80', '#3c9dcd', '#ea6764'],
        ['#eda1bf', '#fec34b', '#bdbdbd'],
        ['#ae90b0', '#f79961', '#000000']
      ],
      // ENTER ACTIVITY COLOR
      selectedColor: '#fec34b'
    });
    const getColor = computed(() => {
      return color.selectedColor.substring(0, 7);
    });
    const config = ref({
      description: '',
      instruct: ['']
    });
    const menu = ref(false);

    const testUser = ref({
      id: '5e9f8f8f8f8f8f8f8f8f8f8',
      name: 'Test User',
      avatar: 'http://placekitten.com/1200/1200'
    });
    // timeline
    const timelineData = reactive({
      events: [
        {
          id: 1,
          text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur congue tincidunt ultricies. Maecenas bibendum nisl vel mi viverra, nec ultrices orci euismod.',
          time: '1:00',
          author: {
            id: Math.random(),
            name: 'John Doe',
            avatar: 'http://placekitten.com/1200/1200'
          }
        },
        {
          id: 2,
          text: 'Nulla non laoreet sapien. Aenean eu imperdiet dui. Cras tempus, metus at sollicitudin ornare, turpis elit pellentesque risus, nec iaculis dui nunc quis justo. Donec ultrices fermentum nisl et pretium. Fusce tincidunt ullamcorper lacus, sed venenatis ex placerat at',
          time: '1:00',
          author: {
            id: Math.random(),
            name: 'John Doe',
            avatar: 'http://placekitten.com/1200/1200'
          }
        },
        {
          id: 4,
          text: 'Curabitur eu fermentum augue, quis suscipit tellus.',
          time: '1:00',
          author: {
            id: Math.random(),
            name: 'John Doe',
            avatar: 'http://placekitten.com/1200/1200'
          }
        },
        {
          id: 5,
          text: 'Sed vitae risus in augue vulputate pharetra ut at justo. Vivamus scelerisque iaculis dui, volutpat molestie ante venenatis vitae. Sed commodo pellentesque volutpat',
          time: '1:00',
          author: {
            id: Math.random(),
            name: 'John Doe',
            avatar: 'http://placekitten.com/1200/1200'
          }
        },
        {
          id: 6,
          text: 'In pretium erat ut nulla scelerisque, nec laoreet ante cursus. Etiam volutpat eu libero sit amet vestibulum. Etiam sit amet arcu tempor, interdum arcu vitae, iaculis ipsum. Morbi tristique, nibh non sodales lacinia, neque turpis malesuada velit, eu molestie justo elit eu nisi. Nam sagittis ultricies justo, vitae fringilla sem auctor non. Donec velit nisl, dapibus eu iaculis eget, posuere vitae sem. Suspendisse semper, velit eu bibendum efficitur, ipsum mauris gravida neque, non scelerisque tellus est ut mauris. Curabitur ac convallis elit. Etiam ligula odio, interdum in mollis quis, consequat efficitur justo.',
          time: '1:00',
          author: {
            id: Math.random(),
            name: 'John Doe',
            avatar: 'http://placekitten.com/1200/1200'
          }
        }
      ],
      input: '',
      nonce: 0
    });
    const timeline = computed(() => {
      return timelineData.events.slice().reverse();
    });

    const userRole = computed(() => {
      return props.userType;
    });

    const durationFormat = ref('');

    const calculateTime = (startTime: any, Time2: any) => {
      let diff = (Time2 - startTime) / 1000;
      diff = Math.abs(Math.floor(diff));

      const days = Math.floor(diff / (24 * 60 * 60));
      let leftSec = diff - days * 24 * 60 * 60;

      const hrs = Math.floor(leftSec / (60 * 60));
      leftSec -= hrs * 60 * 60;

      const min = Math.floor(leftSec / 60);
      leftSec -= min * 60;
      return `${days}d:${hrs}h:${min}m`;
    };

    function calculateTimer() {
      const adkIndex = props.teamDoc.data.adks.findIndex(obj => obj.name === 'Ideate');
      if (
        userRole.value === 'participant' &&
        props.teamDoc.data.adks[adkIndex].hasOwnProperty('activityEndedOn')
      ) {
        const startTime = props.teamDoc.data.adks[adkIndex].activityStartedOn;
        const endTime = props.teamDoc.data.adks[adkIndex].activityEndedOn;

        durationFormat.value = calculateTime(startTime, endTime);
      } else if (
        userRole.value === 'participant' &&
        !props.teamDoc.data.adks[adkIndex].hasOwnProperty('activityStartedOn')
      ) {
        const startTime = new Date();
        props.teamDoc.data.adks[adkIndex].activityStartedOn = startTime;
        // props.teamDoc?.update();
        durationFormat.value = calculateTime(startTime, startTime);
        const x = setInterval(() => {
          const currentTime = new Date();
          let diff = (currentTime - startTime) / 1000;
          diff = Math.abs(Math.floor(diff));

          const days = Math.floor(diff / (24 * 60 * 60));
          let leftSec = diff - days * 24 * 60 * 60;

          const hrs = Math.floor(leftSec / (60 * 60));
          leftSec -= hrs * 60 * 60;

          const min = Math.floor(leftSec / 60);
          leftSec -= min * 60;
          durationFormat.value = `${days}d:${hrs}h:${min}m`;
          if (props.teamDoc.data.adks[adkIndex].hasOwnProperty('activityEndedOn')) {
            clearInterval(x);
          }
        }, 60000);
      } else if (
        userRole.value === 'participant' &&
        props.teamDoc.data.adks[adkIndex].hasOwnProperty('activityStartedOn')
      ) {
        durationFormat.value = calculateTime(
          new Date(),
          props.teamDoc.data.adks[adkIndex].activityStartedOn
        );
        const x = setInterval(() => {
          const currentTime = new Date();
          let diff = (currentTime - props.teamDoc.data.adks[adkIndex].activityStartedOn) / 1000;
          diff = Math.abs(Math.floor(diff));

          const days = Math.floor(diff / (24 * 60 * 60));
          let leftSec = diff - days * 24 * 60 * 60;

          const hrs = Math.floor(leftSec / (60 * 60));
          leftSec -= hrs * 60 * 60;

          const min = Math.floor(leftSec / 60);
          leftSec -= min * 60;
          durationFormat.value = `${days}d:${hrs}h:${min}m`;
          if (props.teamDoc.data.adks[adkIndex].hasOwnProperty('activityEndedOn')) {
            clearInterval(x);
          }
        }, 60000);
      }
    }

    if (userRole.value === 'participant' && props.teamDoc.data.adks !== undefined) {
      if (props.teamDoc.data.adks.findIndex(obj => obj.name === 'Ideate') === -1) {
        props.teamDoc.data.adks.push({ name: 'Ideate' });
        // props.teamDoc?.update().then(() => {
        //   calculateTimer();
        // });
      } else {
        // calculateTimer();
      }
    } else if (userRole.value === 'participant') {
      watch(
        () => props.teamDoc,
        newData => {
          if (
            newData.data.adks.findIndex((objdata: { name: string }) => objdata.name === 'Ideate')
          ) {
            // calculateTimer();
          } else {
            props.teamDoc.data.adks.push({ name: 'Ideate' });
            // props.teamDoc?.update().then(() => {
            //   calculateTimer();
            // });
          }
        }
      );
    }

    return {
      ...toRefs(color),
      ...toRefs(page),
      config,
      moduleName,
      menu,
      getComponent,
      getColor,
      ...toRefs(timelineData),
      durationFormat,
      userRole,
      calculateTimer,
      timeline,
      programDoc,
      readOnly
    };
  }
});
