
















































































































import { ref, defineComponent, inject } from '@vue/composition-api';

export default defineComponent({
  name: 'ModuleInstruct',

  setup() {
    const readonly = inject('readonly', false);
    const boilerInstructions = ref([
      'Document your milestone or process in the form of an image, screenshot or photo',
      'Upload and describe your documented image, screenshot or photo',
      'Repeat as frequently as necessary to document your process'
    ]);
    const goal = ref([
      'To design, prototype and go through the iteration of making your project by logging your milestones and process through screenshots and descriptions'
    ]);
    // function populate() {
    //   boilerInstructions.value.push('');
    // }

    return { boilerInstructions, tutorialDialog: false, goal, readonly };
  }
});
