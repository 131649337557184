
















































































































import { inject, ref } from '@vue/composition-api';

export default {
  name: 'ModuleInstruct',

  setup() {
    const readonly = inject('readonly', false);
    const boilerInstructions = ref(['Explore teams to join or create a team', 'Manage your team']);
    const goal = ref(['To join, create or manage your project team']);

    return { boilerInstructions, tutorialDialog: false, goal, readonly };
  }
};
