










import { reactive, toRefs } from '@vue/composition-api';
// import gql from 'graphql-tag';
export default {
  name: 'ModuleSetup',
  setup() {
    const setup = reactive({});
    return {
      ...toRefs(setup)
    };
  }
};
