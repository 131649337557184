var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.header,"items":_vm.tableContents,"sort-by":"resource","items-per-page":100,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.complete",fn:function(ref){
var item = ref.item;
return [_c('ValidationProvider',{attrs:{"type":"checkbox","rules":{ required: { allowFalse: false } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-row',{staticClass:"mx-auto"},[_c('v-checkbox',{attrs:{"error":!!errors.length,"disabled":_vm.disabled},on:{"change":function($event){_vm.$emit(
              'validation-event',
              _vm.tableContents.some(function (item) { return !item.complete; })
            )}},model:{value:(item.complete),callback:function ($$v) {_vm.$set(item, "complete", $$v)},expression:"item.complete"}}),_c('span',{staticClass:"pt-4 error--text"},[_vm._v(_vm._s(errors.length ? '*' : ''))])],1)]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }