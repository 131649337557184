













































































































































































import {
  ref,
  defineComponent,
  watch,
  watchEffect,
  PropType,
  computed,
  onMounted
} from '@vue/composition-api';
import { useDbState, useUserActions, useDbGetters } from '@/store';
import MCalendar from '@/components/molecules/MCalendar.vue';
import MInternsBadge from '@/components/molecules/MInternsBadge.vue';
import { ObjectId } from 'bson';
import Instruct from './ModuleInstruct.vue';
import MongoDoc from './types';

export default defineComponent({
  name: 'ModuleDefault',
  components: {
    Instruct,
    MCalendar,
    MInternsBadge
  },
  props: {
    value: {
      required: true,
      type: Object as PropType<MongoDoc>
    },
    db: {
      required: true,
      type: Object as PropType<any>
    },
    fetchProgram: {
      required: true,
      type: [Object, Function]
    }
  },
  setup(props, ctx) {
    /** actions */
    const { user }: any = useDbState(['user']);
    const { getAuthUrl } = useUserActions(['getAuthUrl']);
    const { updateCalendar } = useUserActions(['updateCalendar']);
    const { fetchUser } = useUserActions(['fetchUser']);
    const { collection } = useDbGetters(['collection']);
    const { refreshCalendarTokenData } = useUserActions(['refreshCalendarTokenData']);
    const connectWithGoogleProgress = ref(false);
    const connectWithOutlookProgress = ref(false);
    const programDoc: any = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    const index = programDoc.value.data.adks.findIndex(obj => {
      if (obj) {
        return obj.name === 'meet';
      }
      return false;
    });
    function goToCalendar() {
      if (user.value.outlookDetails) {
        window.open('https://outlook.live.com/calendar', '_blank');
      } else {
        window.open('https://calendar.google.com/calendar', '_blank');
      }
    }
    const isScopeUnchecked = ref(false);
    watchEffect(() => {
      if (ctx.root.$route.query.isScopeUnchecked) {
        isScopeUnchecked.value = true;
      }
    });
    function filterAccessTokenAndType() {
      let accessToken;
      let google;
      if (user.value.googleDetails) {
        accessToken = user.value.googleDetails.access_token;
        google = true;
      } else if (user.value.outlookDetails) {
        accessToken = user.value.outlookDetails.access_token;
        google = false;
      }
      return { accessToken, google };
    }
    const isDisconnect = ref(false);
    async function disconnect() {
      isDisconnect.value = true;
      if (user.value.googleDetails) {
        await props.db.collection('User').findOneAndUpdate(
          {
            _id: new ObjectId(user.value._id)
          },
          {
            $unset: { googleDetails: '' }
          }
        );
      } else if (user.value.outlookDetails) {
        await props.db.collection('User').findOneAndUpdate(
          {
            _id: new ObjectId(user.value._id)
          },
          {
            $unset: { outlookDetails: '' }
          }
        );
      }
      setTimeout(function () {
        isDisconnect.value = false;
      }, 2000);
      await fetchUser(localStorage.getItem('apollo-token'));
    }
    const url = ref();
    /** Methods */
    async function refreshAccessToken(refreshToken, type) {
      const response = await refreshCalendarTokenData({ refreshToken, type });
      return response;
    }

    async function updateAttendees(eventId, email, google, eventData, events) {
      const userByEmail = await collection.value!('User').findOne({
        email
      });
      let userAccessToken;
      let refreshedAccessTokenData;
      let oldRefreshToken;
      if (google) {
        userAccessToken = userByEmail.googleDetails.access_token;
        oldRefreshToken = userByEmail.googleDetails.refresh_token;
      } else {
        userAccessToken = userByEmail.outlookDetails.access_token;
        oldRefreshToken = userByEmail.outlookDetails.refresh_token;
      }
      const response = await updateCalendar({
        eventId,
        refreshToken: oldRefreshToken,
        accessToken: userAccessToken,
        google,
        eventData
      });
      if (!response.error) {
        programDoc.value.data.adks[index].events = events;
        programDoc.value.update();
        props.fetchProgram();
      } else if (response.error && response.error.code === 'UNAUTHORIZED') {
        if (google) {
          refreshedAccessTokenData = await refreshCalendarTokenData({
            refreshToken: userByEmail.googleDetails.refresh_token,
            type: 'google'
          });
          await updateCalendar({
            eventId,
            refreshToken: oldRefreshToken,
            accessToken: refreshedAccessTokenData.access_token,
            google,
            eventData
          });
        } else {
          refreshedAccessTokenData = await refreshCalendarTokenData({
            refreshToken: userByEmail.outlookDetails.refresh_token,
            type: 'outlook'
          });
          await updateCalendar({
            eventId,
            refreshToken: oldRefreshToken,
            accessToken: refreshedAccessTokenData.access_token,
            google,
            eventData
          });
          programDoc.value.data.adks[index].events = events;
          programDoc.value.update();
          props.fetchProgram();
        }
      }
    }

    async function connectWithGoogleOrOutlook(type) {
      if (type === 'google') {
        connectWithGoogleProgress.value = true;
      } else if (type === 'outlook') {
        connectWithOutlookProgress.value = true;
      }
      const response = await getAuthUrl(type);
      localStorage.setItem('programId', props.value.data._id.toString());
      localStorage.setItem('guideAdkIndex', ctx.root.$route.path.split('/')[3]);
      localStorage.setItem('connectionType', type);
      url.value = response;
      if (type === 'google') {
        connectWithGoogleProgress.value = false;
      } else if (type === 'outlook') {
        connectWithOutlookProgress.value = false;
      }
    }

    /** Watcher */
    watch(
      () => url.value,
      urlData => {
        if (urlData !== '') {
          window.open(urlData, '_self');
          url.value = '';
        }
      }
    );

    /** Created */
    onMounted(async () => {
      const isInternAlreadyRemoved = await collection.value!('Student').findOne({
        program_id: new ObjectId(programDoc.value.data._id.toString()),
        participant_id: new ObjectId(user.value._id.toString()),
        'adks.name': 'meet',
        'adks.removedFromCalendar': true
      });
      if (!isInternAlreadyRemoved) {
        if (user.value.googleDetails || user.value.outlookDetails) {
          const weekltMeetingEvents = props.value.data.adks[index]?.events;
          if (
            weekltMeetingEvents &&
            weekltMeetingEvents?.weekly_meeting &&
            weekltMeetingEvents?.weekly_meeting_participants &&
            weekltMeetingEvents?.weekly_meeting_participants.includes('intern')
          ) {
            const organizerUser = await collection.value!('User').findOne({
              email: weekltMeetingEvents?.created_by
            });
            let attendeesEmail;
            if (user.value.googleDetails && user.value.googleDetails.email) {
              attendeesEmail = user.value.googleDetails.email;
            } else if (user.value.outlookDetails && user.value.outlookDetails.email) {
              attendeesEmail = user.value.outlookDetails.email;
            } else {
              attendeesEmail = user.value.email;
            }
            if (organizerUser.googleDetails) {
              const weeklyMeetingAttendees = weekltMeetingEvents.weekly_meeting?.attendees.map(
                attendee => attendee?.email
              );
              if (!weeklyMeetingAttendees.includes(attendeesEmail)) {
                const newAttendees: any = weekltMeetingEvents.weekly_meeting.attendees.filter(
                  value => value
                );
                newAttendees.push({
                  email: attendeesEmail
                });
                const google = true;
                weekltMeetingEvents.weekly_meeting.attendees = newAttendees;
                delete weekltMeetingEvents.weekly_meeting.sequence;
                updateAttendees(
                  weekltMeetingEvents.weekly_meeting.id,
                  organizerUser.email,
                  google,
                  weekltMeetingEvents.weekly_meeting,
                  weekltMeetingEvents
                );
              }
            } else if (organizerUser.outlookDetails) {
              const weeklyMeetingAttendees = weekltMeetingEvents.weekly_meeting?.attendees.map(
                attendee => attendee?.emailAddress?.address
              );
              if (!weeklyMeetingAttendees.includes(attendeesEmail)) {
                const newAttendees: any = weekltMeetingEvents.weekly_meeting.attendees.filter(
                  value => value
                );

                newAttendees.push({
                  emailAddress: {
                    address: attendeesEmail,
                    name: user.value?.firstName + user.value?.lastName || ''
                  },
                  type: 'required'
                });
                const google = false;
                weekltMeetingEvents.weekly_meeting.attendees = newAttendees;
                updateAttendees(
                  weekltMeetingEvents.weekly_meeting.id,
                  organizerUser.email,
                  google,
                  weekltMeetingEvents.weekly_meeting,
                  weekltMeetingEvents
                );
              }
            }
          }
        }
      }
      /*       if (weekltMeetingEvents && weekltMeetingEvents.coworking_participants.includes('intern')) {
          if (weekltMeetingEvents?.coworking) {
            for (let i = 0; i < weekltMeetingEvents?.coworking.length; i++) {
              const event = weekltMeetingEvents?.coworking[i];
              const coworkingAttendees = event.attendees.map(attendee => attendee?.email);
              if (!coworkingAttendees.includes(user.value.email)) {
                const { accessToken, google } = filterAccessTokenAndType();
                const newAttendees = event.attendees.filter(value => value);
                newAttendees.push({
                  email: user.value.email
                });
                weekltMeetingEvents.coworking[i].attendees = newAttendees;
                delete weekltMeetingEvents.coworking.sequence;
                updateAttendees(
                  event.id,
                  accessToken,
                  google,
                  weekltMeetingEvents.coworking[i],
                  weekltMeetingEvents
                );
              }
            }
          }
        } */
      /*       if (weekltMeetingEvents && weekltMeetingEvents.coworking_participants.includes('intern')) {
          if (weekltMeetingEvents?.coworking) {
            for (let i = 0; i < weekltMeetingEvents?.coworking.length; i++) {
              const event = weekltMeetingEvents?.coworking[i];
              const coworkingAttendees = event.attendees.map(
                attendee => attendee?.emailAddress?.address
              );
              if (!coworkingAttendees.includes(user.value.email)) {
                const { accessToken, google } = filterAccessTokenAndType();
                const newAttendees: any = event.attendees.filter(value => value);
                newAttendees.push({
                  emailAddress: {
                    address: user.value.email,
                    name: user.value?.firstName + user.value?.lastName || ''
                  },
                  type: 'required'
                });
                weekltMeetingEvents.coworking[i].attendees = newAttendees;
                updateAttendees(
                  event.id,
                  accessToken,
                  google,
                  weekltMeetingEvents.coworking[i],
                  weekltMeetingEvents
                );
              }
            }
          }
        } */
    });

    const showInstructions = ref(true);
    return {
      showInstructions,
      user,
      programDoc,
      index,
      connectWithGoogleProgress,
      connectWithOutlookProgress,
      goToCalendar,
      connectWithGoogleOrOutlook,
      isScopeUnchecked,
      disconnect,
      isDisconnect
    };
  }
});
