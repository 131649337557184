const group = ['Setup', 'Project', 'Screening', 'Internship'];
const required = ['Creator requires this activity', 'Yes', 'No'];
const lockOrder = ['Creator locked activity group and placement order', 'Yes', 'No'];
const deliverable = ['Yes', 'No'];
const notifications = ['Creator turned on by default', 'Turn on', 'Turn off'];
const accessibility = [
  'Creator has turned off accessibility anytime',
  'Creator has turned on accessibility anytime',
  'Yes',
  'No'
];
const endEarly = [
  'Creator has not allowed participants to end early after this activity',
  'Creator has allow end early option only at preset order placement',
  'Yes',
  'No'
];
const maxMinutes = [2, 3, 5, 7, 10];
const teamSetting1 = [
  'Everyone can view, owners must approve for edit (Recommended)',
  'Everyone can view & edit ',
  'Everyone can only view & edit own'
];
const teamSetting2 = ['test 1', 'test 2', 'test 3'];
const teamSetting3 = ['test 1', 'test 2', 'test 3'];

const planSetting1 = [
  'Automatically pre-populate pre-internship data (Recommended)',
  'No, have students start fresh'
];
const planSetting2 = ['plan1', 'plan2', 'plan3'];
const planSetting3 = ['plan1', 'plan2', 'plan3'];

const interviewSetting1 = ['0', '1', '3', '5 (Recommended)', '7', '10', '15', '20', '25', '30'];
const interviewSetting2 = ['int1', 'int2', 'int3'];
const interviewSetting3 = ['int1', 'int2', 'int3'];

const implementationSetting1 = ['0', '1', '2', '3 (Recommended)', '5', '7', '10'];
const implementationSetting2 = ['1', '2', '3'];
const implementationSetting3 = ['4', '5', '6'];

const debriefSetting1 = ['d1', 'd2', 'd3'];
const debriefSetting2 = ['d1', 'd2', 'd3'];
const debriefSetting3 = ['d1', 'd2', 'd3'];

const storySetting1 = [
  'Written (Articles, Blog, etc)',
  'Video (Shorts, Tour, Demo, etc)',
  'Graphic (Infographics, social post, etc)'
];
const storySetting2 = ['s1', 's2', 's3'];
const storySetting3 = ['s1', 's2', 's3'];

const taskSetting1 = [
  'High-Low Priority, then Easy - Hard Difficulty (default)',
  'High-Low Priority, then Hard - Easy Difficulty',
  'Custom (Unsorted, based on entry with drag & drop)'
];
const taskSetting2 = ['t1', 't2', 't3'];
const taskSetting3 = ['t1', 't2', 't3'];

const presentationSetting1 = ['5', '10', '15 (Default)', '20', '30', '45', '60', '90', '120'];
const presentationSetting2 = ['p1', 'p2', 'p3'];
const presentationSetting3 = ['p1', 'p2', 'p3'];

export {
  group,
  required,
  lockOrder,
  deliverable,
  notifications,
  accessibility,
  endEarly,
  maxMinutes,
  teamSetting1,
  teamSetting2,
  teamSetting3,
  planSetting1,
  planSetting2,
  planSetting3,
  interviewSetting1,
  interviewSetting2,
  interviewSetting3,
  implementationSetting1,
  implementationSetting2,
  implementationSetting3,
  debriefSetting1,
  debriefSetting2,
  debriefSetting3,
  storySetting1,
  storySetting2,
  storySetting3,
  taskSetting1,
  taskSetting2,
  taskSetting3,
  presentationSetting1,
  presentationSetting2,
  presentationSetting3
};
