
















































































































/* eslint-disable no-prototype-builtins */
/* eslint-disable vue/no-mutating-props */
/* eslint-disable no-param-reassign */
import {
  ref,
  reactive,
  toRefs,
  PropType,
  defineComponent,
  computed,
  inject
} from '@vue/composition-api';
import { getModMongoDoc, getModAdk } from 'pcv4lib/src';
import { ObjectId } from 'bson';
import { MongoDoc, TableItem } from './types';
import Instruct from './ModuleInstruct.vue';
import Table from './TableView.vue';

export default defineComponent({
  name: 'ModuleDefault',

  components: {
    Instruct,
    Table
  },
  props: {
    value: {
      required: true,
      type: Object as PropType<MongoDoc>
    },
    userType: {
      required: true,
      type: String
    },
    teamDoc: {
      required: false,
      type: Object as PropType<MongoDoc>,
      default: () => {}
    },
    studentDoc: {
      required: false,
      type: Object as PropType<MongoDoc>,
      default: () => {}
    },
    userDoc: {
      required: false,
      type: Object as PropType<MongoDoc>,
      default: () => {}
    },
    uploadItem: {
      required: false,
      type: Function as PropType<(item: { item: File }) => Promise<any>>,
      default: () => {}
    }
  },
  setup(props, ctx) {
    const readonly = inject('readonly', false);
    const fileInput = ref(null);
    const showInstructions = ref(true);
    const setupInstructions = ref({
      description: '',
      instructions: ['', '', '']
    });

    const state = reactive({
      logInput: '',
      logError: '',
      images: [] as File[],
      programDoc: null as any,
      teamDocument: null as any,
      studentDocument: null as any,
      userId: null as null | ObjectId,
      adkData: null as null | Record<string, any>,
      teamAdkData: null as null | Record<string, any>,
      teamAdkIndex: -1,
      studentAdkData: null as null | Record<string, any>,
      logMilestoneLoading: false
    });

    state.programDoc = getModMongoDoc(props, ctx.emit);
    const { adkData } = getModAdk(props, ctx.emit, 'make');
    state.adkData = adkData;
    if (props.teamDoc) {
      state.teamDocument = getModMongoDoc(props, ctx.emit, {}, 'teamDoc', 'inputTeamDoc');
      const { adkData: teamAdkData, adkIndex: teamAdkIndex } = getModAdk(
        props,
        ctx.emit,
        'make',
        {},
        'teamDoc',
        'inputTeamDoc'
      );
      state.teamAdkData = teamAdkData;
      state.teamAdkIndex = teamAdkIndex;
    }
    if (props.studentDoc) {
      state.studentDocument = getModMongoDoc(props, ctx.emit, {}, 'studentDoc', 'inputStudentDoc');
      const { adkData: studentAdkData } = getModAdk(
        props,
        ctx.emit,
        'make',
        {},
        'studentDoc',
        'inputStudentDoc'
      );
      state.studentAdkData = studentAdkData;
    }

    state.userId = props.userDoc?.data._id;

    const onFilesAdded = (event: Event) => {
      const target = event.target as HTMLInputElement;
      const files = target.files as FileList;
      for (let i = 0; i < files.length; i += 1) {
        const file = files[i];
        state.images.push(file);
      }
    };

    const saveActivityEndModified = () => {
      if (state.teamAdkData.hasOwnProperty('activityEndedOn')) {
        state.teamAdkData.activityModifiedOn = new Date();
      } else if (!state.teamAdkData.hasOwnProperty('activityEndedOn')) {
        state.teamAdkData.activityEndedOn = new Date();
      }
    };

    const activityModified = () => {
      if (state.teamAdkData.hasOwnProperty('activityEndedOn')) {
        state.teamAdkData.activityModifiedOn = new Date();
      }
    };

    const logMilestone = async () => {
      if (!state.images.length && !state.logInput.length) {
        state.logError = 'Describe and attach an image to document your milestone';
        return;
      }
      if (!state.images.length) {
        state.logError = 'Attach milestone screenshot, image or photo';
        return;
      }
      if (!state.logInput.length) {
        state.logError = 'Describe your milestone';
        return;
      }
      state.logMilestoneLoading = true;

      const proof = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const image of state.images) {
        // eslint-disable-next-line no-await-in-loop
        const data = await props.uploadItem({
          item: image as unknown as File
        });
        proof.push({
          name: image.name,
          url: data.Location
        });
      }

      const log = {
        id: new ObjectId(),
        log: state.logInput,
        time: new Date(),
        author: props.userDoc?.data._id,
        avatar: props.userDoc?.data.profile ? props.userDoc?.data.profile.small : '',
        proof
      };

      state.teamAdkData!.logs.unshift(log);
      state.teamDocument?.update();

      state.images = [];
      state.logInput = '';
      state.logError = '';

      if (state.teamAdkData?.logs.length >= state.adkData!.minLogs) {
        saveActivityEndModified();
        state.teamDocument?.update(() => ({
          isComplete: true,
          adkIndex: state.teamAdkIndex
        }));
        ctx.emit('calculate-timer');
      }
      state.logMilestoneLoading = false;
    };

    const removeMilestone = (id: string) => {
      state.teamAdkData!.logs = state.teamAdkData!.logs.filter((item: TableItem) => {
        return item.id.toString() !== id;
      });
      activityModified();
      state.teamDocument!.update();
    };

    const removeFile = (file: string) => {
      state.images = state.images.filter((image: File) => image.name !== file);
    };

    const logsLeft = computed(() => {
      if (!props.teamDoc) return state.adkData!.minLogs || 3;
      return Math.max((state.adkData!.minLogs || 3) - state.teamAdkData!.logs.length, 0);
    });

    return {
      ...toRefs(state),
      fileInput,
      onFilesAdded,
      logMilestone,
      removeMilestone,
      removeFile,
      setupInstructions,
      showInstructions,
      logsLeft,
      readonly
    };
  }
});
