
































































































































































































/* eslint-disable no-prototype-builtins */
import { defineComponent, computed, PropType, ref, inject } from '@vue/composition-api';
import moment from 'moment';
import { getModAdk, getModMongoDoc } from 'pcv4lib/src';
import Instruct from './ModuleInstruct.vue';
import MongoDoc, { TableItem } from './types';
import { HEADER } from './const';

export default defineComponent({
  name: 'ModuleDefault',

  components: {
    Instruct
  },
  props: {
    value: {
      required: true,
      type: Object as PropType<MongoDoc>
    },
    userType: {
      required: true,
      type: String
    },
    userDoc: {
      required: true,
      type: Object as PropType<MongoDoc>,
      default: () => {}
    },
    teamDoc: {
      required: true,
      type: Object as PropType<MongoDoc | null>,
      default: () => {}
    },
    loggedNum: {
      type: Number,
      default: 0
    }
  },
  setup(props, ctx) {
    const readonly = inject('readonly', false);
    const programDoc = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    const index = programDoc.value.data.adks.findIndex(obj => {
      if (obj) {
        return obj.name === 'tinker';
      }
      return false;
    });

    const teamDocument = getModMongoDoc(props, ctx.emit, {}, 'teamDoc', 'inputTeamDoc');

    const initPracticeDefault = {
      practiceLog: [],
      minimumHoursNow: programDoc.value?.data?.adks[index]?.minimumHoursNow
    };
    const { adkData: teamAdkData, adkIndex } = getModAdk(
      props,
      ctx.emit,
      'tinker',
      initPracticeDefault,
      'teamDoc',
      'inputTeamDoc'
    );

    const isPracticeLog = computed(() => {
      return !!teamAdkData?.value?.practiceLog && teamAdkData?.value?.practiceLog.length > 0;
    });

    const minutes = ref(0);
    const validation = ref('');
    const requiredMinutes = ref();
    const loadingBtn = ref(false);
    const success = ref(false);
    const error = ref(false);

    const isValid = computed(() => {
      if (minutes.value > 0) {
        return true;
      }
      return false;
    });

    const adkData = ref(teamAdkData.value);

    if (parseInt(programDoc.value.data.adks[index].minimumHoursNow, 10) > 0) {
      requiredMinutes.value = parseInt(programDoc.value.data.adks[index].minimumHoursNow, 10) * 60;
    } else {
      const initPracticePresets = {
        minimumHoursNow: '3 Hours',
        defaultActivity: {
          groupActivity: 'Screening',
          requiredActivity: 'Yes',
          deliverableActivity: 'No',
          endEarlyActivity: 'Yes',
          required: false
        }
      };
      programDoc.value.data.adks[index] = {
        ...initPracticePresets,
        ...programDoc.value.data.adks[index]
      };
      requiredMinutes.value = parseInt(programDoc.value.data.adks[index].minimumHoursNow, 10) * 60;
    }

    const totalMinutes = ref(0);

    if (teamAdkData.value.practiceLog.length > 0) {
      teamAdkData.value.practiceLog.forEach(event => {
        totalMinutes.value += Number(event.minutes);
      });
    }

    const activityModifiedEndedOn = () => {
      if (teamAdkData.value.hasOwnProperty('activityEndedOn')) {
        teamAdkData.value.activityModifiedOn = new Date();
      } else {
        teamAdkData.value.activityEndedOn = new Date();
      }
    };

    const activityModifiedOn = () => {
      if (teamAdkData.value.hasOwnProperty('activityEndedOn')) {
        teamAdkData.value.activityModifiedOn = new Date();
      }
    };

    const isLog = computed(() => {
      if (teamAdkData.value?.practiceLog) {
        return true;
      }
      return false;
    });

    async function logMinutes() {
      const teamIndex = props.teamDoc?.data.adks.findIndex(function findOfferObj(obj) {
        return obj.name === 'tinker';
      });

      if (teamIndex === -1) {
        const initPractice = {
          name: 'tinker'
        };
        teamDocument.value.data.adks.push(initPractice);
      }
      if (!isLog.value) {
        teamAdkData.value.practiceLog = [];
        teamAdkData.value.minimumHoursNow = programDoc.value?.data?.adks[index]?.minimumHoursNow;
      }

      loadingBtn.value = true;
      success.value = false;
      setTimeout(() => {
        loadingBtn.value = false;
        success.value = true;
      }, 3000);

      const timestamp = new Date();

      const log = ref({
        minutes: minutes.value,
        timestamp,
        name: `${props.userDoc.data.firstName} ${props.userDoc.data.lastName}`,
        user_id: props.userDoc.data._id,
        avatar: props.userDoc?.data.profile ? props.userDoc?.data.profile.small : ''
      });
      // eslint-disable-next-line no-plusplus
      teamAdkData.value.practiceLog.push(log.value);
      // eslint-disable-next-line no-plusplus
      minutes.value = 0;

      // for the final value logging
      totalMinutes.value = 0;
      validation.value.reset();

      if (teamAdkData.value.practiceLog.length > 0) {
        teamAdkData.value.practiceLog.forEach((event: { minutes: any }) => {
          totalMinutes.value += Number(event.minutes);
        });
      }

      if (totalMinutes.value >= parseInt(teamAdkData.value.minimumHoursNow, 10) * 60) {
        activityModifiedEndedOn();
        return props.teamDoc?.update(() => ({
          isComplete: true,
          adkIndex
        }));
      }
      await props.teamDoc!.update();
      ctx.emit('calculate-timer');
      return null;
    }

    async function undo() {
      loadingBtn.value = true;
      setTimeout(() => {
        loadingBtn.value = false;
      }, 3000);
      if (teamAdkData.value.practiceLog.length > 0) {
        totalMinutes.value -= parseInt(
          teamAdkData.value.practiceLog[teamAdkData.value.practiceLog.length - 1].minutes,
          10
        );

        teamAdkData.value.practiceLog.pop();

        activityModifiedOn();
        await props.teamDoc?.update();
      }
    }

    const filter = ref('Personal');
    const tableItems = computed(() => {
      return teamAdkData?.value?.practiceLog?.filter((item: TableItem) => {
        if (filter.value === 'Personal') return item.user_id.equals(props.userDoc.data._id);
        return true;
      });
    });

    const formatDate = (date: Date) => {
      return moment(date).fromNow();
    };

    const setupInstructions = ref({
      description: '',
      instructions: ['', '', '']
    });

    return {
      totalMinutes,
      setupInstructions,
      showInstructions: 'true',
      isLog,
      teamAdkData,
      undo,
      teamDocument,
      logMinutes,
      header: ref(HEADER),
      minutes,
      isPracticeLog,
      isValid,
      tableItems,
      filter,
      programDoc,
      index,
      requiredMinutes,
      loadingBtn,
      success,
      error,
      formatDate,
      validation,
      readonly
    };
  }
});
