var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{attrs:{"slim":""}},[_c('v-container',{staticClass:"module-default pa-0"},[_c('div',{staticClass:"module-default__instructions"},[_c('v-expansion-panels',{staticClass:"module-default__instructions",attrs:{"flat":""},model:{value:(_vm.showInstructions),callback:function ($$v) {_vm.showInstructions=$$v},expression:"showInstructions"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{directives:[{name:"show",rawName:"v-show",value:(_vm.showInstructions),expression:"showInstructions"}],staticClass:"pa-0",attrs:{"hide-actions":""},on:{"click":function($event){_vm.showInstructions = true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var open = ref.open;
return [_c('v-scroll-y-transition',{attrs:{"hide-on-leave":""}},[(!open)?_c('div',{staticClass:"d-flex flex-column justify-center"},[_c('v-icon',{staticClass:"d-flex justify-center",attrs:{"color":"grey"}},[_vm._v(" mdi-chevron-down ")]),_c('div',{staticClass:"module-default__collapse-title"},[_vm._v("INSTRUCTIONS")])],1):_vm._e()])]}}])}),_c('v-expansion-panel-content',[_c('Instruct',{attrs:{"readonly":""}}),_c('div',{on:{"click":function($event){_vm.showInstructions = true}}},[_c('div',{staticClass:"module-default__collapse-title"},[_vm._v("CLOSE")]),_c('v-icon',{staticClass:"d-flex justify-center",attrs:{"color":"grey"}},[_vm._v(" mdi-chevron-up ")])],1)],1)],1)],1)],1),_c('v-progress-linear',{staticClass:"mt-3",attrs:{"color":"#dedede","height":"2","value":"100","buffer-value":"100","stream":""}}),_c('div',{staticStyle:{"width":"100%"}},[_c('div',[(!_vm.programDoc.data.adks[_vm.index].signInVideoAskResponse)?_c('iframe',{attrs:{"width":"100%","height":"380px","src":"https://www.youtube.com/embed/HnEdGFhizHg","title":"YouTube video player","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}}):_c('iframe',{staticStyle:{"border":"none","border-radius":"0px"},attrs:{"src":_vm.programDoc.data.adks[_vm.index].signInVideoAskResponse &&
            _vm.programDoc.data.adks[_vm.index].signInVideoAskResponse.contact &&
            _vm.programDoc.data.adks[_vm.index].signInVideoAskResponse.contact.share_url,"allow":"autoplay *; encrypted-media *; fullscreen *;","width":"100%","height":"600px"}})]),(_vm.programDoc.data.adks[_vm.index].driveLink)?_c('div',{staticClass:"d-flex flex-column pa-12"},[_c('div',{staticClass:"d-flex justify-center mb-4"},[_c('h3',[_vm._v("Get Started")])]),_c('div',[_c('v-text-field',{staticClass:"justify-center align-center mb-4",attrs:{"label":"Copy & open get started folder","x-large":"","rounded":"","outlined":"","hide-details":"","value":_vm.programDoc.data.adks[_vm.index].driveLink,"readonly":""}},[(!_vm.copied)?_c('v-tooltip',{attrs:{"slot":"append","top":""},slot:"append",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-auto mb-auto",attrs:{"icon":""},on:{"click":_vm.doCopy}},on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,false,2410691547)},[_c('span',[_vm._v("Copy content")])]):_c('v-tooltip',{attrs:{"slot":"append","top":""},slot:"append",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-auto mb-auto",attrs:{"icon":""}},on),[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-bold")])],1)]}}],null,false,3648222325)},[_c('span',[_vm._v("Link copied")])])],1)],1),_c('div',{},[_c('v-btn',{staticClass:"welcome__full-width-btn font-weight-bold",attrs:{"rounded":"","dark":"","color":"yellow","x-large":"","depressed":""},on:{"click":_vm.openGetStartedFolder}},[_vm._v("Open Get Started Folder")])],1)]):_vm._e(),_c('div',{staticClass:"ml-12 mr-12 d-flex flex-column"},[(_vm.programDoc.data.adks[_vm.index].driveLink)?_c('div',{staticClass:"d-flex justify-center"},[_c('v-checkbox',{attrs:{"readonly":_vm.checkboxReadonly,"label":"I have reviewed the welcome video & get started folder"},model:{value:(_vm.checkboxChecked),callback:function ($$v) {_vm.checkboxChecked=$$v},expression:"checkboxChecked"}})],1):_c('div',{staticClass:"d-flex justify-center"},[_c('v-checkbox',{attrs:{"readonly":_vm.checkboxReadonly,"label":"I have reviewed the welcome video"},model:{value:(_vm.checkboxChecked),callback:function ($$v) {_vm.checkboxChecked=$$v},expression:"checkboxChecked"}})],1),_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"white--text",attrs:{"color":"black","disabled":_vm.isWelcomeAdkExists ||
              _vm.isDisabled ||
              (_vm.studentDocIndex > 0 && _vm.studentDocData.data.adks[_vm.studentDocIndex].completed),"rounded":"","depressed":"","x-large":"","loading":_vm.isLoading},on:{"click":function($event){return _vm.process()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-check-bold")]),_vm._v("Done")],1)],1)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }