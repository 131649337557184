
















































































































import { ref } from '@vue/composition-api';

export default {
  name: 'ModuleInstruct',

  setup() {
    // const programDoc = computed({
    //   get: () => props.value,
    //   set: newVal => {
    //     ctx.emit('input', newVal);
    //   }
    // });
    const boilerInstructions = ref([
      'Start practicing and applying your learning',
      'Log time spent',
      'Come back to activity to log additional time spent on project'
    ]);
    const goal = ref(['To spend time practicing, applying and logging time spent on your project']);
    function populate() {
      boilerInstructions.value.push('');
    }

    return { boilerInstructions, populate, tutorialDialog: false, goal };
  }
};
