export const minimumHours = ['180 Hours', '120 Hours', '100 Hours'];

export const maximumHours = ['240 Hours', '220 Hours', '200 Hours'];

const TABLE_HEADER = [
  { text: 'Type', value: 'timesheetFor', sortable: false, align: 'start', width: '10%' },
  {
    text: 'Timestamp',
    align: 'start',
    sortable: false,
    value: 'timesheetFilledAt',
    width: '50%'
  },

  // { text: 'Time', value: 'timesheetFilledAt', sortable: false, align: 'start' },

  { text: 'Todo/Toda', value: 'transcription', sortable: false, align: 'start', width: '30%' },
  { text: 'Receipt', value: 'shareUrl', sortable: false, align: 'center', width: '3%' },
  { text: '', value: 'interactionId', sortable: false, align: 'center', width: '3%' }
];

export default { minimumHours, maximumHours, TABLE_HEADER };
