




















































import { defineComponent, ref, PropType, computed, inject } from '@vue/composition-api';
import { ObjectId } from 'bson';
import { MongoDoc, Question } from './types';
import Comment from './Comment.vue';

export default defineComponent({
  name: 'CommentSection',
  components: { Comment },

  props: {
    question: {
      required: true,
      type: Object as PropType<Question>
    },
    studentAdkData: {
      required: true,
      type: Object
    },
    userType: {
      required: true,
      type: String
      // participant: '',
      // organizer: '',
      // stakeholder: ''
    },
    userDoc: {
      required: false,
      type: Object as PropType<MongoDoc>,
      default: () => {}
    }
  },
  setup(props, ctx) {
    const readonly = inject('readonly', false);
    const commentInput = ref('');
    const numComments = ref(5);

    const visibleComments = computed(() => {
      const comments = props.question.comments.slice();
      comments.sort((a, b) => b.likes - a.likes);
      return comments.slice(0, numComments.value);
    });

    const comment = () => {
      if (commentInput.value.length > 0) {
        const time = new Date().toTimeString();
        ctx.emit('postComment', props.question._id, {
          _id: new ObjectId(),
          text: commentInput.value,
          time: time.replace(/:\d{2}\sGMT-\d{4}\s\((.*)\)/, (match, contents) => {
            return ` ${contents
              .split(' ')
              .map((v: string) => v.charAt(0))
              .join('')}`;
          }),
          avatar: props.userDoc.data.profile ? props.userDoc.data.profile.small : '',
          likes: 0,
          flags: 0
        });
        commentInput.value = '';
      }
    };

    return {
      commentInput,
      numComments,
      visibleComments,
      comment,
      readonly
    };
  }
});
