

















































































































import { ref, defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ModuleInstruct',

  setup() {
    // const programDoc = computed({
    //   get: () => props.value,
    //   set: newVal => {
    //     ctx.emit('input', newVal);
    //   }
    // });
    const boilerInstructions = ref([
      'Review information',
      'Click on provided resources',
      'Finish when complete'
    ]);
    const goal = ref([
      'To review the employer project scope, mission, specifications and initial resources'
    ]);
    // function populate() {
    //   boilerInstructions.value.push('');
    // }

    return { boilerInstructions, tutorialDialog: false, goal };
  }
  // setup() {
  //   const demoInstructions = ref(['']);
  //   const goal = ref(['']);
  //   function populate() {
  //     demoInstructions.value.push('');
  //   }
  //   return { demoInstructions, populate, goal };
  // }
});
