



































































































































import { defineComponent, computed, PropType, toRefs, reactive, ref } from '@vue/composition-api';
import { loading, getModAdk, getModMongoDoc } from 'pcv4lib/src';
// import Instruct from './ModuleInstruct.vue';
import { group, required, deliverable, endEarly, maxCharacters } from './const';
import MongoDoc from './types';
// import gql from 'graphql-tag';

export default defineComponent({
  name: 'ModulePresets',
  components: {
    // Instruct
  },
  props: {
    value: {
      required: true,
      type: Object as PropType<MongoDoc>
    }
  },

  setup(props, ctx) {
    // const studentDocument = getModMongoDoc(props, ctx.emit, {}, 'studentDoc', 'inputStudentDoc');
    const programDoc = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    const index = programDoc.value.data.adks.findIndex(obj => {
      if (obj) {
        return obj.name === 'ideate';
      }
      return false;
    });

    // // const presetsInstructions = ref({
    // //   description: '',
    // //   instructions: ['', '', '']
    // // });
    const initIdeatePresets = {
      maxCharacters: '280',
      defaultActivity: {
        groupActivity: 'Screening',
        requiredActivity: 'Yes',
        deliverableActivity: 'No',
        endEarlyActivity: 'Yes',
        required: false
      }
    };
    programDoc.value.data.adks[index] = {
      ...initIdeatePresets,
      ...programDoc.value.data.adks[index]
    };

    console.log(programDoc.value.data.adks[index]);
    const presets = reactive({
      group,
      required,
      deliverable,
      endEarly,
      maxCharacters
    });

    // function minuteCheck() {
    //   console.log(programDoc.data.adks[index].maxCharacters);
    // }

    const setupInstructions = ref({
      description: '',
      instructions: ['', '', '']
    });

    // console.log(programDoc);
    // console.log(adkData.value.maxCharacters);

    function save() {
      console.log(programDoc.value.data.adks[index].maxCharacters);
      return new Promise((resolve, reject) => {
        programDoc.value.update();

        resolve(true);
      });
    }

    return {
      programDoc,
      ...loading(save, 'Success', 'Try again later'),
      ...toRefs(presets),
      setupInstructions,
      index,
      save
      // adkData
      // index
    };
  }
});
