var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-container',{staticClass:"module-edit"},[_c('div',{ref:_vm.body,staticClass:"module-edit__body"},[_c('div',{staticClass:"module-edit__container"}),_c('div',{staticClass:"d-flex justify-center mb-6 flex-column"},[_c('div',{staticClass:"mb-3"},[_c('h3',{staticClass:"text-center"},[_vm._v(" Insert links & resources for students to research. Please use unique resource links for each row ")])])]),_vm._l((_vm.programDoc.data.adks[_vm.index].researchLinks),function(i,itemIndex){return _c('div',{key:itemIndex,staticClass:"module-edit__inputs"},[_c('div',{staticClass:"module-edit__inputs-link",staticStyle:{"margin-right":"3%","margin-left":"0"}},[_c('validation-provider',{attrs:{"rules":{
              required: true,
              regex:
                /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
            },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Enter Link","outlined":"","rounded":""},on:{"change":function($event){return _vm.fetchMetaInfo(i.link, itemIndex)}},model:{value:(i.link),callback:function ($$v) {_vm.$set(i, "link", $$v)},expression:"i.link"}})]}}],null,true)})],1),_c('div',{staticClass:"module-edit__inputs-video"},[_c('validation-provider',{attrs:{"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{staticClass:"student-research-link-input",attrs:{"error-messages":errors,"label":"Resource Name","outlined":"","rounded":""},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('loader',{directives:[{name:"show",rawName:"v-show",value:(_vm.isSearching && _vm.currentIndex === itemIndex),expression:"isSearching && currentIndex === itemIndex"}],staticStyle:{"position":"absolute","right":"16px","margin-top":"-3px"}})]},proxy:true}],null,true),model:{value:(i.resource),callback:function ($$v) {_vm.$set(i, "resource", $$v)},expression:"i.resource"}})]}}],null,true)})],1),_c('div',{staticClass:"module-edit__inputs-required"},[_c('v-checkbox',{class:_vm.$vuetify.breakpoint.xs ? 'mt-0' : ' ',attrs:{"label":"Required?"},model:{value:(i.required),callback:function ($$v) {_vm.$set(i, "required", $$v)},expression:"i.required"}})],1),_c('div',{staticClass:"module-edit__inputs-required"},[_c('v-btn',{attrs:{"disabled":itemIndex === 0,"rounded":"","x-large":"","outlined":""},on:{"click":function($event){return _vm.removeItem(itemIndex)}}},[_vm._v("Delete")])],1)])}),_c('div',{staticClass:"module-edit__add"},[_c('v-btn',{staticClass:"module-edit__add-button white--text font-weight-bold",attrs:{"disabled":invalid,"ripple":false,"x-large":"","rounded":"","depressed":"","color":"red"},on:{"click":function($event){return _vm.populate()}}},[_c('v-icon',{staticClass:"module-edit__add-icon font-weight-bold mr-1"},[_vm._v(" mdi-plus")]),_vm._v("Add New Link ")],1)],1),_c('div',[_c('v-btn',{staticClass:"mt-12 setup-adk-research",attrs:{"disabled":invalid,"loading":_vm.loading,"x-large":"","rounded":"","outlined":"","depressed":""},on:{"click":function($event){return _vm.process()}}},[_vm._v("Save")])],1),(_vm.success || _vm.error)?_c('v-alert',{staticClass:"mt-3 white--text presets__alert",attrs:{"color":_vm.success ? 'green' : 'red',"dense":""}},[_vm._v(_vm._s(_vm.message))]):_vm._e()],2)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }