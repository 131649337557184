export const outcomesValue = [
  'Build portfolio project',
  'Qualify for internship to execute on project'
];
// export const deliverables = [
//   'Business Model Canvas',
//   'One Sentence Pitch',
//   'Elevator Pitch',
//   'Design & Prototype Log',
//   'Prototype Demo',
//   'Presentation Deck'
// ];
export const deliverablesValue = [
  'Business Model Canvas',
  'One Sentence Pitch',
  'Elevator Pitch',
  'Design & Prototype Log',
  'Prototype Demo',
  'Presentation Deck'
];
export const chips = ['Must use employer product'];
export const items = [
  'Must use employer product',
  'Must use employer service',
  'Must use employer recommended process'
];

const group = ['Setup', 'Project', 'Screening', 'Internship'];
const required = ['Creator requires this activity', 'Yes', 'No'];
const lockOrder = ['Creator locked activity group and placement order', 'Yes', 'No'];
const deliverable = ['Yes', 'No'];
const notifications = ['Creator turned on by default', 'Turn on', 'Turn off'];
const accessibility = [
  'Creator has turned off accessibility anytime',
  'Creator has turned on accessibility anytime',
  'Yes',
  'No'
];
const endEarly = [
  'Creator has not allowed participants to end early after this activity',
  'Creator has allow end early option only at preset order placement',
  'Yes',
  'No'
];

export { group, required, lockOrder, deliverable, notifications, accessibility, endEarly };
