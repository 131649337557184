
































































import moment from 'moment';
import { computed, defineComponent, ref, PropType } from '@vue/composition-api';

import { MONITOR_HEADER } from '@/constants/monitor';

export default defineComponent({
  name: 'TableView',
  props: {
    selectedStudent: {
      required: true,
      type: Object
    },
    studentAdks: {
      required: true,
      type: Array as PropType<Array<Record<string, any>>>
    },
    teamAdks: {
      required: true,
      type: Array as PropType<Array<Record<string, any>>>
    },
    programAdks: {
      required: true,
      type: Array as PropType<Array<Record<string, any>>>
    },
    activityFilter: {
      required: true,
      type: String
    }
  },
  setup(props, context) {
    const adks = [
      'start',
      'scope',
      'community',
      'team',
      'train',
      'research',
      'tinker',
      'Ideate',
      'Pitch',
      'forum',
      'make',
      'demo',
      'interview',
      'offer'
    ];
    const internShipActivity = [
      'welcome',
      'contact',
      'meet',
      'timesheet',
      'deliverable',
      'workspace',
      'signoff'
    ];
    const teamAdks = ['tinker', 'ideate', 'pitch', 'forum', 'make', 'demo'];
    const filteredActivities = computed(() => {
      const studentData = adks.map((programAdk, index) => {
        let adkListToLookIn;
        if (teamAdks.includes(programAdk)) adkListToLookIn = props.teamAdks;
        else adkListToLookIn = props.studentAdks;
        const studentAdk = adkListToLookIn.find(
          adk => adk.name && adk.name.toLowerCase() === programAdk
        );
        return {
          index: index + 1,
          activity: programAdk,
          unlocked: studentAdk !== undefined || programAdk === 'start',
          completion:
            (studentAdk && (studentAdk.completed || studentAdk.isComplete)) ||
            studentAdk?.name === 'contact' ||
            false,
          completedTime:
            (studentAdk && studentAdk.completed) ||
            (studentAdk?.name === 'contact' && studentAdk?.activityStartedOn) ||
            ''
        };
      });
      if (!props.activityFilter) return studentData;
      const index =
        studentData.findIndex(
          item => item.activity.toLowerCase() === props.activityFilter.toLowerCase()
        ) + 1;
      return studentData.slice(0, index >= 0 ? index : studentData.length - 1);
    });

    const isOfferCompleted = computed(() => {
      const index = props.studentAdks?.findIndex(a => a.name.toLowerCase() === 'offer');
      return props.studentAdks[index]?.offerStatus;
    });

    function pushInternshipAdk() {
      internShipActivity.forEach(ele => {
        if (ele) {
          adks.push(ele);
        }
      });
    }

    if (isOfferCompleted.value) pushInternshipAdk();

    const lastUnlockedIndex = computed(() => {
      let lastUnlockedIndex = 0;
      adks.forEach((programAdk, index) => {
        const studentAdk = props.studentAdks.find(
          adk => adk.name && adk.name.toLowerCase() === programAdk
        );
        if (studentAdk !== undefined) lastUnlockedIndex = index;
      });
      return lastUnlockedIndex;
    });

    const formatDate = (date: Date) => {
      return moment(date).fromNow();
    };

    const handleMonitorButtonClick = item => {
      context.emit('monitor-click', {
        page: item.index - 1
      });
    };

    return {
      header: ref(MONITOR_HEADER),
      filteredActivities,
      lastUnlockedIndex,
      formatDate,
      handleMonitorButtonClick,
      internShipActivity,
      isOfferCompleted,
      pushInternshipAdk
    };
  }
});
