













































import { ref } from '@vue/composition-api';
import ABtn from '@/components/atoms/AButton.vue';
import ATextField from '@/components/atoms/ATextInput.vue';
import axios from 'axios';

export default {
  components: {
    ABtn,
    ATextField
  },

  props: {
    student: {
      required: false,
      type: Object,
      default: () => {}
    },
    fetchDeliverables: {
      type: Function,
      default: () => {}
    },
    teamName: {
      type: String,
      default: ''
    },
    isMonitor: {
      type: Boolean,
      default: false
    }
  },

  setup(props, ctx) {
    const successMsg = ref(false);
    const errorMsg = ref(false);
    const msg = ref('');
    const isCreating = ref(false);

    const deliverableName = ref('');

    if (props.teamName) {
      deliverableName.value = props.teamName;
    }

    async function createDeliverable() {
      try {
        isCreating.value = true;
        const payload = {
          program_id: ctx.root.$route.params.programId,
          student_id: props.student.data._id.toString(),
          name: deliverableName.value
        };
        const ENDPOINT_API = process.env.VUE_APP_DELIVERABLE;
        const res = await axios.post(ENDPOINT_API, payload, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        });
        if (res.data) {
          successMsg.value = true;
          msg.value = res.data.message;
          await props.fetchDeliverables();
          isCreating.value = false;
        }
      } catch (error) {
        console.log(error);
        isCreating.value = false;
        errorMsg.value = true;
        msg.value = 'Something went wrong';
      }
    }

    return { successMsg, errorMsg, msg, deliverableName, createDeliverable, isCreating };
  }
};
