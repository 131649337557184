




























































































































































import { ref, PropType, defineComponent, computed, watchEffect } from '@vue/composition-api';
import { copyText } from 'vue3-clipboard';
import Instruct from './ModuleInstruct.vue';
import { MongoDoc } from './types';

export default defineComponent({
  name: 'ModuleDefault',
  components: {
    Instruct
    // ABtn,
    // ATextField
  },
  props: {
    value: {
      required: true,
      type: Object as PropType<MongoDoc>
    },
    studentDoc: {
      required: true,
      type: Object as PropType<MongoDoc>
    }
  },
  setup(props, ctx) {
    const welcome = ref('');
    const programDoc: any = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    const index = programDoc.value.data.adks.findIndex(obj => {
      if (obj) {
        return obj.name === 'welcome';
      }
      return false;
    });
    const checkboxReadonly = ref(false);
    let studentDocData: any = computed({
      get: () => props.studentDoc,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    let studentDocIndex = studentDocData.value?.data?.adks.findIndex(obj => {
      if (obj) {
        return obj.name === 'welcome';
      }
      return false;
    });
    const checkboxChecked = ref(false);
    const isDisabled = ref(false);
    const isWelcomeAdkExists = ref(false);
    watchEffect(() => {
      if (props.studentDoc) {
        studentDocData = computed({
          get: () => props.studentDoc,
          set: newVal => {
            ctx.emit('input', newVal);
          }
        });
        studentDocIndex = studentDocData.value?.data?.adks.findIndex(obj => {
          if (obj) {
            return obj.name === 'welcome';
          }
          return false;
        });
        if (studentDocIndex > 0 && studentDocData.value.data.adks[studentDocIndex].completed) {
          checkboxChecked.value = true;
          checkboxReadonly.value = true;
          isWelcomeAdkExists.value = true;
        }
        if (checkboxChecked.value) {
          isDisabled.value = false;
        } else {
          isDisabled.value = true;
        }
      }
    });
    if (studentDocIndex > 0 && studentDocData.value.data.adks[studentDocIndex].completed) {
      checkboxChecked.value = true;
      checkboxReadonly.value = true;
      isWelcomeAdkExists.value = true;
    }
    watchEffect(() => {
      if (checkboxChecked.value) {
        isDisabled.value = false;
      } else {
        isDisabled.value = true;
      }
    });
    const isLoading = ref(false);
    async function process() {
      isLoading.value = true;
      if (studentDocIndex < 0) {
        studentDocData.value.data.adks.push({ name: 'welcome', completed: new Date() });
        studentDocData.value.update();
        checkboxChecked.value = true;
        isDisabled.value = true;
        isWelcomeAdkExists.value = true;
        checkboxReadonly.value = true;
      }
      setTimeout(function () {
        isLoading.value = false;
      }, 2000);
    }
    async function openGetStartedFolder() {
      let link;
      if (programDoc.value.data.adks[index].driveLink.includes('https://')) {
        link = programDoc.value.data.adks[index].driveLink;
      } else {
        link = `https://${programDoc.value.data.adks[index].driveLink}`;
      }
      window.open(link, '_blank');
    }
    const copied = ref(false);
    const doCopy = () => {
      copyText(programDoc.value.data.adks[index].driveLink, undefined, (error, event) => {
        if (error) {
          console.log(error);
        } else {
          copied.value = true;
          setTimeout(() => {
            copied.value = false;
          }, 5000);
        }
      });
    };
    const setupInstructions = ref({
      description: '',
      instructions: ['', '', '']
    });
    const showInstructions = ref(true);

    return {
      setupInstructions,
      showInstructions,
      welcome,
      programDoc,
      index,
      checkboxChecked,
      studentDocData,
      studentDocIndex,
      process,
      isLoading,
      isDisabled,
      openGetStartedFolder,
      checkboxReadonly,
      isWelcomeAdkExists,
      doCopy,
      copied
    };
  }
});
