






























































































import { defineComponent, ref, toRefs, computed, PropType } from '@vue/composition-api';
// import Instruct from './ModuleInstruct.vue';
import * as presets from './const';
import MongoDoc from './types';
// import { group, required, deliverable, endEarly } from './const';
// import { group, required, deliverable, endEarly } from './const';
// import gql from 'graphql-tag';

export default defineComponent({
  name: 'ModulePresets',
  components: {
    // Instruct
  },

  props: {
    value: {
      required: true,
      type: Object as PropType<MongoDoc>
    }
  },

  setup(props, ctx) {
    const programDoc = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    const index = programDoc.value.data.adks.findIndex(obj => {
      if (obj) {
        return obj.name === 'offer';
      }
      return false;
    });

    const initOfferPresets = {
      defaultActivity: {
        groupActivity: 'Internship',
        requiredActivity: 'No',
        deliverableActivity: 'No',
        endEarlyActivity: 'Yes',
        required: false
      }
    };

    programDoc.value.data.adks[index] = {
      ...initOfferPresets,
      ...programDoc.value.data.adks[index]
    };

    const loading = ref(false);
    const errormsg = ref('');
    async function save() {
      loading.value = true;
      try {
        await programDoc.value.update();
        errormsg.value = '';
      } catch (err) {
        errormsg.value = 'Could not save';
      }
      loading.value = false;
    }
    const setupInstructions = ref({
      description: '',
      instructions: ['', '', '']
    });
    return {
      ...toRefs(presets),
      setupInstructions,
      loading,
      save,
      errormsg,
      index,
      programDoc
    };
  }
});
