


















import { PropType, defineComponent, computed, ref, watchEffect } from '@vue/composition-api';
import MongoDoc from './types';

export default defineComponent({
  name: 'ModulePresets',
  props: {
    programDoc: {
      required: true,
      type: Object as PropType<MongoDoc>
    }
  },

  setup(props, ctx) {
    const programDocument = computed({
      get: () => props.programDoc,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    const creating = ref(false);

    async function createSignOff() {
      creating.value = true;
      const initSignOff = {
        name: 'signoff'
      };
      programDocument.value.data.adks.push(initSignOff);
      programDocument.value.update();
      creating.value = false;
    }
    return { programDocument, createSignOff, creating };
  }
});
