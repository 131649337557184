var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{},[(!_vm.submittedVideo.id)?_c('div',{staticClass:"d-flex headline font-weight-bold justify-center align-center module-default__youtube"},[_vm._v(" No video yet ")]):_vm._e(),(_vm.submittedVideo && _vm.submittedVideo.snippet)?_c('a-btn',{staticClass:"mb-4 mt-4 video-name overline font-weight-bold",style:({
      width: _vm.$vuetify.breakpoint.xs ? '100% !important' : '100% !important',
      height: 'fit-content',
      fontSize: _vm.$vuetify.breakpoint.xs ? '16px !important' : ' ',
      padding: '5px 12px'
    }),attrs:{"href":("https://youtu.be/" + (_vm.submittedVideo.id)),"rounded":"","color":"green","dark":"","depressed":"","small":"","label":""}},[_vm._v(" "+_vm._s(_vm.submittedVideo.snippet.title)+" "),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v("mdi-open-in-new")])],1):_vm._e(),(_vm.submittedVideo.id)?_c('iframe',{staticClass:"module-default__youtube",attrs:{"src":("https://www.youtube.com/embed/" + (_vm.submittedVideo.id)),"width":"100%","height":"330","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}}):_vm._e(),_c('validation-observer',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var invalid = ref.invalid;
return [_c('v-container',{staticClass:"d-flex pt-6 pl-0 pr-0",class:_vm.$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'},[_c('validation-provider',{staticClass:"module-default__youtube-verify-button",attrs:{"rules":{
          regex:
            /^((?:https?:)?\/\/)((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/,
          required: true
        },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('a-text-field',{attrs:{"readonly":_vm.viewOnly,"error-messages":errors.concat(_vm.apiErrors),"label":"Enter YouTube Video","rounded":"","outlined":"","placeholder":"https://youtu.be/yourvideocode","prepend-inner-icon":"mdi-youtube"},model:{value:(_vm.presentationLink),callback:function ($$v) {_vm.presentationLink=$$v},expression:"presentationLink"}})]}}],null,true)}),_c('a-btn',{staticClass:"module-default__youtube-verify-button ml-3 white--text perform-adk-demo-verify",attrs:{"rounded":"","disabled":invalid || _vm.viewOnly,"loading":_vm.verifyLoading,"x-large":"","outlined":"","color":"green","depressed":""},on:{"click":_vm.verifyLink}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-youtube")]),_vm._v("Save & Verify Link")],1)],1)]}}])}),(_vm.successMsg || _vm.errorMsg)?_c('v-alert',{staticClass:"mt-3 white--text presets__alert",attrs:{"color":_vm.successMsg ? 'green' : 'red',"dense":""}},[_vm._v(" "+_vm._s(_vm.msg)+" ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }