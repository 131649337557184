<template>
  <div>
    <div class="justify-center d-flex">
      <h2 class="mb-6">Key Result</h2>
    </div>
    <div class="pa-3">
      <div v-for="(item, index) in keyResults" :key="index" class="d-flex flex-row">
        <a-text-field
          v-model="item.key"
          :readonly="viewOnly"
          class="student-research-link-input"
          label="Project Key Result"
          outlined
          rounded
        />
        <a-btn
          class="mt-3"
          :disabled="viewOnly"
          v-if="keyResults.length > 1"
          icon
          rounded
          large
          @click="removeItem(index)"
        >
          <v-icon color="red">mdi-trash-can</v-icon>
        </a-btn>
      </div>

      <div class="module-edit__add">
        <a-btn
          x-large
          :disabled="viewOnly"
          rounded
          class="module-edit__add-button white--text font-weight-bold"
          depressed
          outlined
          color="green"
          @click="populate()"
        >
          <v-icon class="module-edit__add-icon font-weight-bold mr-1">mdi-plus</v-icon>
          Add Project Key Result
        </a-btn>
      </div>
    </div>
    <div class="d-flex justify-center mt-10"><h2>Risks</h2></div>
    <div class="pa-3 mt-6">
      <div v-for="(item, index) in projectRisks" :key="index" class="d-flex flex-row">
        <a-text-field
          v-model="item.risk"
          :readonly="viewOnly"
          class="student-research-link-input"
          label="Risk"
          outlined
          rounded
        />
        <a-btn
          class="mt-3"
          :disabled="viewOnly"
          v-if="projectRisks.length > 1"
          icon
          rounded
          large
          @click="removeRisk(index)"
        >
          <v-icon color="red">mdi-trash-can</v-icon>
        </a-btn>
      </div>

      <div class="module-edit__add">
        <a-btn
          x-large
          :disabled="viewOnly"
          rounded
          class="module-edit__add-button white--text font-weight-bold"
          depressed
          outlined
          color="green"
          @click="populateRisk()"
        >
          <v-icon class="module-edit__add-icon font-weight-bold mr-1">mdi-plus</v-icon>
          Add Risk
        </a-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, watchEffect } from '@vue/composition-api';
import ABtn from '@/components/atoms/AButton.vue';
import ATextField from '@/components/atoms/ATextInput.vue';

export default {
  components: {
    ABtn,
    ATextField
  },

  props: {
    data: {
      type: [Object, Array],
      default: () => {}
    },
    viewOnly: {
      type: Boolean,
      default: false
    }
  },

  setup(props, ctx) {
    const keyResults = ref([{ key: '' }]);

    const projectRisks = ref([{ risk: '' }]);

    function populate() {
      const obj = { key: '' };
      keyResults.value.push(obj);
    }

    function removeItem(index) {
      console.log(index);
      keyResults.value.splice(index, 1);
    }

    function populateRisk() {
      const obj = { risk: '' };
      projectRisks.value.push(obj);
    }

    function removeRisk(index) {
      projectRisks.value.splice(index, 1);
    }

    watch(
      keyResults,
      val => {
        ctx.emit('set-project-keys', keyResults.value);
      },
      { deep: true }
    );

    watch(
      projectRisks,
      val => {
        ctx.emit('set-project-risks', projectRisks.value);
      },
      { deep: true }
    );

    watchEffect(() => {
      if (props.data.projectKeys) {
        keyResults.value = props.data.projectKeys;
      }
      if (props.data.projectKeys) {
        projectRisks.value = props.data.projectRisks;
      }
    });

    return { keyResults, populate, removeItem, projectRisks, populateRisk, removeRisk };
  }
};
</script>
