












































































































/* eslint-disable no-prototype-builtins */
/* eslint-disable vue/no-mutating-props */
/* eslint-disable no-param-reassign */
import {
  ref,
  PropType,
  defineComponent,
  watchEffect,
  onUnmounted,
  watch,
  onMounted
} from '@vue/composition-api';
import * as Realm from 'realm-web';
import axios from 'axios';
// eslint says it can't find these modules from @types/node
// can be fixed by removing "./node_modules/@types" from typeRoots in tsconfig.json
// but then you get errors for unit test related imports
// anyway this doesn't matter since it will be server-side
import { useDbState } from '@/store';
import Instruct from './ModuleInstruct.vue';
import { MongoDoc } from './types';
import Table from './TableView.vue';

export default defineComponent({
  name: 'ModuleDefault',
  components: {
    Instruct,
    Table
  },
  props: {
    value: {
      required: true,
      type: Object as PropType<MongoDoc>
    },
    teamDoc: {
      required: true,
      type: Object as PropType<MongoDoc>
    },
    studentDoc: {
      required: true,
      type: Object as PropType<MongoDoc>
    },
    userType: {
      required: true,
      type: String
    },
    currentUser: {
      required: true,
      type: Object as PropType<Realm.User>
    },
    getTimeline: {
      type: Function
    }
  },
  setup(props, ctx) {
    const showInstructions = ref(true);
    const { user } = useDbState(['user']);
    const setupInstructions = ref({
      description: '',
      instructions: ['', '', '']
    });
    const isClockIn = ref(false);
    const isClockOut = ref(false);
    const table = ref();

    async function checkStatus() {
      try {
        const API_ENDPOINT = process.env.VUE_APP_TIMESHEET_STATUS;
        const res = await axios.get(
          `${API_ENDPOINT}?student_id=${props.studentDoc?._id.toString()}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
            }
          }
        );
        const status = res?.data?.data;
        if (status === 'clock_in') {
          isClockOut.value = true;
          isClockIn.value = false;
          props.getTimeline();
        }
        if (status === 'clock_out') {
          isClockIn.value = true;
          isClockOut.value = false;
        }
      } catch (error) {
        console.log(error);
      }
    }

    onMounted(() => {
      watch(isClockIn, val => {
        if (val) {
          table.value.getTableData();
          table.value.getInteractionList();
        }
      });
      watch(isClockOut, val => {
        if (val) {
          table.value.getTableData();
          table.value.getInteractionList();
        }
      });
    });

    const fetching = ref(false);

    watchEffect(async () => {
      if (props.studentDoc?._id) {
        fetching.value = true;
        await checkStatus();
        fetching.value = false;
      }
    });

    const intervalId = window.setInterval(() => {
      checkStatus();
    }, 15000);

    onUnmounted(() => {
      clearInterval(intervalId);
    });

    const isTimeSheetAvailable = ref(false);
    watchEffect(() => {
      if (props.studentDoc && props.studentDoc?.data?.adks) {
        const Index = props.studentDoc?.data?.adks?.findIndex(a => a.name === 'timesheet');
        if (Index !== -1) {
          const data = props.studentDoc?.data?.adks[Index];
          if (data?.timesheetDetails) isTimeSheetAvailable.value = true;
        }
      }
    });

    return {
      showInstructions,
      isTimeSheetAvailable,
      setupInstructions,
      user,
      checkStatus,
      isClockIn,
      isClockOut,
      fetching,
      table
    };
  }
});
