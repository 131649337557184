<template>
  <div class="pa-6">
    <div class="d-flex align-center mb-6">
      <v-select
        id="Interviewee"
        v-model="Interviewee"
        :items="intervieweeList"
        :readonly="viewOnly"
        hide-details
        item-text="name"
        return-object
        rounded
        outlined
        label="Interviewee"
      />
      <v-btn class="ml-2" :disabled="viewOnly" large icon depressed @click="clearFields">
        <v-icon class="plus-icon" color="green"> mdi-plus </v-icon>
      </v-btn>
      <v-btn
        class="ml-2"
        :disabled="viewOnly"
        :loading="isRemoving"
        large
        icon
        depressed
        @click="deleteInterviewee"
      >
        <v-icon class="delete-icon" color="red"> mdi-delete </v-icon>
      </v-btn>
    </div>
    <div>
      <div class="d-flex justify-space-around">
        <a-text-field
          v-model="intervieweeObj.name"
          :readonly="viewOnly"
          label="Enter Name"
          outlined
          rounded
        />
        <a-text-field
          v-model="intervieweeObj.roleOrg"
          class="ml-2"
          :readonly="viewOnly"
          label="Enter Role and Organization"
          outlined
          rounded
        />
      </div>
      <div v-for="(interviewee, index) in intervieweeObj.QA" :key="index">
        <div class="module-edit__inputs-video">
          <a-text-field
            v-model="interviewee.question"
            :readonly="viewOnly"
            label="Enter Question"
            prefix="Q"
            outlined
            rounded
          />
          <a-btn
            class="mt-3"
            :disabled="viewOnly"
            v-if="intervieweeObj.QA.length > 1"
            icon
            rounded
            large
            @click="removeQA(index)"
          >
            <v-icon color="red">mdi-trash-can</v-icon>
          </a-btn>
        </div>
        <div class="module-edit__inputs-video">
          <a-text-field
            v-model="interviewee.answer"
            :readonly="viewOnly"
            label="Enter Answer"
            prefix="A"
            outlined
            rounded
          />
        </div>
      </div>
      <a-btn
        x-large
        :disabled="viewOnly"
        rounded
        outlined
        class="module-edit__add-button white--text font-weight-bold"
        depressed
        color="green"
        @click="populateQA"
      >
        <v-icon large left color="green"> mdi-plus</v-icon>
      </a-btn>
    </div>
    <a-btn
      x-large
      :disabled="viewOnly || !isValid"
      :loading="isLoading"
      rounded
      class="module-edit__add-button white--text font-weight-bold mt-4"
      depressed
      color="green"
      @click="addInterviewee"
    >
      Save Interviewee
    </a-btn>
    <v-alert
      v-if="(successMsg || errorMsg) && msg.length"
      :color="successMsg ? 'green' : 'red'"
      dense
      class="mt-3 white--text presets__alert"
    >
      {{ msg }}
    </v-alert>
  </div>
</template>

<script>
import { computed, ref, watch, watchEffect } from '@vue/composition-api';
import axios from 'axios';
import { ObjectId } from 'bson';
// import ASelect from '@/components/atoms/ASelectInput.vue';
import ABtn from '@/components/atoms/AButton.vue';
import ATextField from '@/components/atoms/ATextInput.vue';

export default {
  components: {
    // ASelect,
    ATextField,
    ABtn
  },

  props: {
    viewOnly: {
      type: Boolean,
      default: false
    },
    data: {
      type: [Object, Array],
      default: () => {}
    },
    upload: {
      required: false,
      type: Function,
      default: () => {}
    }
  },

  setup(props, ctx) {
    const Interviewee = ref('');
    const intervieweeList = ref([]);
    const isLoading = ref(false);
    const successMsg = ref(false);
    const errorMsg = ref(false);
    const msg = ref('');
    const isDataAvailable = ref(false);
    const isRemoving = ref(false);

    const intervieweeObj = ref({
      id: new ObjectId(),
      name: '',
      roleOrg: '',
      QA: [
        {
          question: '',
          answer: ''
        }
      ]
    });

    const isValid = computed(() => {
      return intervieweeObj.value?.name?.length && intervieweeObj.value?.roleOrg?.length;
    });

    function populateQA() {
      const obj = {
        question: '',
        answer: ''
      };
      intervieweeObj.value.QA.push(obj);
    }

    function removeQA(index) {
      intervieweeObj.value.QA.splice(index, 1);
    }

    function setInitialData() {
      if (props.data?.userInterviews?.length) {
        intervieweeList.value = props.data.userInterviews;
        Interviewee.value = { ...props.data.userInterviews[0] };
        isDataAvailable.value = true;
      }
    }

    async function addInterviewee(val) {
      if (!val) isLoading.value = true;
      successMsg.value = false;
      msg.value = '';
      try {
        let data;
        if (val) {
          data = {
            userInterviews: val
          };
        } else {
          data = {
            userInterviews: props.data.userInterviews || []
          };
          const isAvail = data.userInterviews.filter(
            u => u.id === intervieweeObj.value.id.toString()
          );
          if (!isAvail.length) {
            data.userInterviews.push(intervieweeObj.value);
          } else {
            const index = data.userInterviews.findIndex(
              u => u.id === intervieweeObj.value.id.toString()
            );
            data.userInterviews[index] = { ...intervieweeObj.value };
          }
        }
        const ENDPOINT_API = process.env.VUE_APP_DELIVERABLE;
        const res = await axios.patch(`${ENDPOINT_API}?deliverable_id=${props.data._id}`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        });
        if (res.data.data) {
          successMsg.value = true;
          msg.value = res.data.message;
          // await setInitialData();
          isLoading.value = false;
        }
      } catch (error) {
        console.log(error);
        errorMsg.value = true;
        msg.value = 'Something went wrong';
        isLoading.value = false;
      }
    }

    watch(Interviewee, val => {
      if (val) {
        intervieweeObj.value = { ...val };
      }
    });

    setInitialData();

    watchEffect(() => {
      if (props.data?.userInterviews?.length) {
        intervieweeList.value = props.data.userInterviews;
        Interviewee.value = { ...props.data.userInterviews[0] };
      }
    });

    function clearFields() {
      Interviewee.value = '';
      successMsg.value = false;
      msg.value = '';
      intervieweeObj.value = {
        id: new ObjectId(),
        name: '',
        roleOrg: '',
        QA: [
          {
            question: '',
            answer: ''
          }
        ]
      };
      isDataAvailable.value = false;
    }

    async function deleteInterviewee() {
      isRemoving.value = true;
      const index = intervieweeList.value.findIndex(
        u => u.id === intervieweeObj.value.id.toString()
      );
      intervieweeList.value.splice(index, 1);
      clearFields();
      await addInterviewee(intervieweeList.value);
      isRemoving.value = false;
    }

    return {
      Interviewee,
      clearFields,
      intervieweeObj,
      populateQA,
      removeQA,
      intervieweeList,
      addInterviewee,
      isLoading,
      isValid,
      errorMsg,
      successMsg,
      msg,
      setInitialData,
      isDataAvailable,
      deleteInterviewee,
      isRemoving
    };
  }
};
</script>
