



















































































































/* eslint-disable no-prototype-builtins */
/* eslint-disable vue/no-mutating-props */
/* eslint-disable no-param-reassign */
import {
  computed,
  reactive,
  ref,
  toRefs,
  defineComponent,
  PropType,
  inject,
  watch
} from '@vue/composition-api';
import { getModAdk, getModMongoDoc } from 'pcv4lib/src';
import * as Realm from 'realm-web';
import '../styles/module.scss';
import Monitor from './ModuleMonitor.vue';
import Setup from './ModuleSetup.vue';
import Presets from './ModulePresets.vue';
import Default from './ModuleDefault.vue';
import { MongoDoc } from './types';

export default defineComponent({
  name: 'Welcome',

  components: {
    'module-monitor': Monitor,
    'module-setup': Setup,
    'module-presets': Presets,
    'module-preview': Default
  },

  props: {
    value: {
      required: true,
      type: Object as PropType<MongoDoc>
    },
    userType: {
      required: true,
      type: String
    },
    currentUser: {
      required: true,
      type: Object as PropType<Realm.User>
    },
    fetchProgram: {
      required: true,
      type: [Object, Function]
    },
    studentDoc: {
      required: true
    },
    operationMode: {
      type: Number
    }
  },
  setup(props, ctx) {
    const readonly = inject('readonly', false);
    const moduleName = ref('Welcome');
    const programDoc = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    const index = programDoc.value.data.adks.findIndex(obj => {
      if (obj) {
        return obj.name === 'welcome';
      }
      return false;
    });
    if (index === -1) {
      const meeting: any = { name: 'welcome', status: 'complete_setup' };
      programDoc.value.data.adks.push(meeting);
      programDoc.value.update();
    }

    const page = reactive({
      subpages: ['Setup', 'Presets'],
      currentPage: 'Setup'
    });

    if (props.userType === 'organizer' && props.operationMode === 1) {
      page.currentPage = 'Presets';
    } else if (props.userType === 'organizer' && props.operationMode === 3) {
      page.currentPage = 'monitor';
    } else {
      page.currentPage = 'preview';
      page.subpages = [];
    }
    const getComponent = computed(() => {
      return `module-${page.currentPage.toLowerCase()}`;
    });
    const color = reactive({
      pilotcityColors: [
        ['#6eba80', '#3c9dcd', '#ea6764'],
        ['#eda1bf', '#fec34b', '#bdbdbd'],
        ['#ae90b0', '#f79961', '#000000']
      ],
      // ENTER ACTIVITY COLOR
      selectedColor: '#fec34b'
    });
    const getColor = computed(() => {
      return color.selectedColor.substring(0, 7);
    });
    const config = ref({
      description: '',
      instruct: ['']
    });
    const menu = ref(false);
    // timeline
    const timelineData = reactive({
      events: [] as {
        id: number;
        text: string;
        time: string;
      }[],
      input: '',
      nonce: 0
    });
    const timeline = computed(() => {
      return timelineData.events.slice().reverse();
    });
    function comment() {
      const time = new Date().toTimeString();
      timelineData.events.push({
        id: timelineData.nonce,
        text: timelineData.input,
        time: time.replace(/:\d{2}\sGMT-\d{4}\s\((.*)\)/, (match, contents) => {
          return ` ${contents
            .split(' ')
            .map((v: string) => v.charAt(0))
            .join('')}`;
        })
      });
      timelineData.input = '';
    }

    const userRole = computed(() => {
      return props.userType;
    });

    const durationFormat = ref('');

    return {
      ...toRefs(color),
      ...toRefs(page),
      config,
      moduleName,
      menu,
      programDoc,
      getComponent,
      getColor,
      durationFormat,
      userRole,
      ...toRefs(timelineData),
      timeline,
      comment,
      readonly
    };
  }
});
