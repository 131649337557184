


















































































































































import {
  computed,
  reactive,
  ref,
  toRefs,
  defineComponent,
  inject,
  PropType
} from '@vue/composition-api';
import '../styles/module.scss';
// import { Collection } from 'mongodb';
import { getModMongoDoc } from 'pcv4lib/src';
import MDefaultAdkAutoapply from '@/components/molecules/m-default-adk-autoapply.vue';
import Setup from './ModuleSetup.vue';
import Presets from './ModulePresets.vue';
import Default from './ModuleDefault.vue';
import MongoDoc from './types';
import { Timeline } from '../../autoApply/components/types';

export default defineComponent({
  name: 'Interview',

  components: {
    'module-setup': Setup,
    'module-presets': Presets,
    'module-preview': Default,
    MDefaultAdkAutoapply
  },
  props: {
    value: {
      required: true,
      type: Object as PropType<MongoDoc>
    },
    studentDoc: {
      required: false,
      type: Object as PropType<MongoDoc>,
      default: () => {}
    },
    userType: {
      required: true,
      type: String
    },
    userDoc: {
      required: true
    },
    fetchProgram: {
      required: true
    },
    currentPageTable: {
      type: Number,
      default: 0
    },
    operationMode: {
      type: Number
    }
  },
  setup(props: any, ctx: any) {
    const readonly = inject('readonly', false);
    const autoapplyDialog = ref(false);
    //
    // props.programCollection.findOne({
    //   _id: props.programId
    // });
    // ENTER ACTIVITY NAME BELOW
    const programDoc = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    const studentDocument = computed({
      get: () => props.studentDoc,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    const index = programDoc.value.data.adks.findIndex(obj => {
      if (obj) {
        return obj.name === 'interview';
      }
      return false;
    });
    if (index === -1) {
      const initInterview = {
        name: 'interview'
      };
      programDoc.value.data.adks.push(initInterview);
    }

    if (props.userType === 'participant') {
      const stdIndex = studentDocument.value?.data?.adks?.findIndex(obj => {
        if (obj) {
          return obj.name === 'interview';
        }
        return false;
      });
      if (stdIndex === -1) {
        autoapplyDialog.value = true;
        const initInterview = {
          name: 'interview'
        };
        studentDocument.value?.data?.adks?.push(initInterview);
        studentDocument.value.update();
      }
    }
    const moduleName = ref('Interview');
    const page = reactive({
      subpages: ['Setup', 'Presets'],
      currentPage: 'Setup'
    });
    if (props.userType === 'organizer' && props.operationMode === 1) {
      page.currentPage = 'Setup';
    } else {
      page.currentPage = 'preview';
    }
    const getComponent = computed(() => {
      return `module-${page.currentPage.toLowerCase()}`;
    });
    const color = reactive({
      pilotcityColors: [
        ['#6eba80', '#3c9dcd', '#ea6764'],
        ['#eda1bf', '#fec34b', '#bdbdbd'],
        ['#ae90b0', '#f79961', '#000000']
      ],
      // ENTER ACTIVITY COLOR
      selectedColor: '#3c9dcd'
    });
    const getColor = computed(() => {
      return color.selectedColor.substring(0, 7);
    });
    const config = ref({
      description: '',
      instruct: ['']
    });
    const menu = ref(false);
    // timeline
    const timelineData = reactive({
      events: [] as {
        id: number;
        text: string;
        time: string;
      }[],
      input: '',
      nonce: 0
    });
    const timeline = computed(() => {
      return timelineData.events.slice().reverse();
    });
    function comment() {
      const time = new Date().toTimeString();
      timelineData.events.push({
        id: timelineData.nonce,
        text: timelineData.input,
        time: time.replace(/:\d{2}\sGMT-\d{4}\s\((.*)\)/, (_match, contents) => {
          return ` ${contents
            .split(' ')
            .map((v: string) => v.charAt(0))
            .join('')}`;
        })
      });
      timelineData.input = '';
    }
    return {
      ...toRefs(color),
      ...toRefs(page),
      config,
      moduleName,
      menu,
      getComponent,
      getColor,
      ...toRefs(timelineData),
      comment,
      programDoc,
      readonly,
      autoapplyDialog,
      studentDocument
    };
  }
});
