



































































































































import { defineComponent, ref, computed, onMounted } from '@vue/composition-api';
import { useDbGetters } from '@/store';
import { ValidationObserver, ValidationProvider } from '@/validation';
import axios from 'axios';
import { concatAST } from 'graphql';
import MongoDoc from './types';
import Table from './TableView.vue';
// import ACheckbox from '../../../../../components/atoms/ACheckBox.vue';
import ATextInput from '../../../../../components/atoms/ATextInput.vue';
import AButton from '../../../../../components/atoms/AButton.vue';

export default defineComponent({
  name: 'ModulePresets',
  components: {
    ValidationProvider,
    ValidationObserver,
    Table
  },
  props: {
    programDoc: {
      required: true,
      type: Object as () => MongoDoc,
      default: () => {}
    }
  },
  setup(props, ctx) {
    const programDocument = computed({
      get: () => props.programDoc,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    const index = programDocument.value.data.adks.findIndex(obj => {
      if (obj) {
        return obj.name === 'contact';
      }
      return false;
    });

    const isFormShow = ref(false);

    const obj = ref({
      contactMethod: [],
      contactPreferences: [],
      hideEmail: false,
      hidePhone: false,
      schedulingLink: '',
      userId: ''
    });
    const isSaving = ref(false);
    const validate = ref();

    const list = computed({
      get: () => obj.value,
      set: newVal => {
        obj.value = newVal;
      }
    });

    const overlay = ref(false);
    const isAbsolute = ref(true);

    const currentUser = ref();
    const isDataAvailable = ref(false);

    function saveDirectoryData() {
      isSaving.value = true;
      if (currentUser.value) {
        obj.value.userId = currentUser.value.user_id;
      }

      const API_ENDPOINT = process.env.VUE_APP_ADK_DIRECTORY_CREATE_USER;
      const id = ctx.root._route.params.programId;

      const data = {
        user_id: obj.value.userId,
        program_id: id,
        contact_method: obj.value.contactMethod,
        contact_preferences: obj.value.contactPreferences,
        hide_email: obj.value.hideEmail,
        hide_phone: obj.value.hidePhone,
        scheduling_link: obj.value.schedulingLink
      };

      axios
        .post(`${API_ENDPOINT}`, data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        })
        .then(res => {
          console.log(res, 'save-response');
          isSaving.value = false;
          isDataAvailable.value = true;
        })
        .catch(error => {
          isSaving.value = false;
        });
    }

    function updateData() {
      const API_ENDPOINT = process.env.VUE_APP_ADK_DIRECTORY_CREATE_USER;
      isSaving.value = true;

      const data = {
        user_id: currentUser.value.user_id,
        program_id: ctx.root._route.params.programId,
        contact_method: obj.value.contactMethod,
        contact_preferences: obj.value.contactPreferences,
        hide_email: obj.value.hideEmail,
        hide_phone: obj.value.hidePhone,
        scheduling_link: obj.value.schedulingLink
      };
      axios
        .patch(API_ENDPOINT, data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        })
        .then(res => {
          console.log(res, 'save-response');
          isSaving.value = false;
        })
        .catch(error => {
          console.log(error);
          isSaving.value = false;
        });
    }

    const isLoading = ref(false);
    const isActive = ref(0);
    const color = ref('');

    function getCurrentUser(val, index) {
      obj.value = {
        contactMethod: [],
        contactPreferences: [],
        hideEmail: false,
        hidePhone: false,
        schedulingLink: '',
        userId: ''
      };
      validate.value.reset();
      isActive.value = index + 1;
      currentUser.value = val;
      console.log(currentUser.value, 'current user');

      const current_user_id = currentUser.value.user_id;
      const API_ENDPOINT = process.env.VUE_APP_ADK_DIRECTORY_CREATE_USER;
      const id = ctx.root._route.params.programId;
      overlay.value = true;
      isLoading.value = true;
      axios
        .get(`${API_ENDPOINT}?program_id=${id}&user_id=${current_user_id}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        })
        .then(res => {
          if (Object.keys(res.data).length !== 0) {
            if (currentUser.value.user_id === res.data.userId) isDataAvailable.value = true;
            obj.value = res.data;
            isLoading.value = false;
            overlay.value = false;
          } else {
            isDataAvailable.value = false;
            isLoading.value = false;
            overlay.value = false;
          }
        })
        .catch(error => console.log(error));
    }

    const infoData = ref();
    const getData = () => {
      const API_ENDPOINT = process.env.VUE_APP_ADK_DIRECTORY_GET_USER;
      const id = ctx.root._route.params.programId;
      axios
        .get(`${API_ENDPOINT}?program_id=${id}&employer_only=${true}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        })
        .then(res => {
          infoData.value = res.data;
          getCurrentUser(infoData.value[0], isActive.value);
        })
        .catch(error => console.log(error));
    };
    onMounted(() => {
      getData();
    });

    return {
      programDocument,
      index,
      saveDirectoryData,
      list,
      getCurrentUser,
      infoData,
      isLoading,
      isAbsolute,
      overlay,
      isActive,
      color,
      isSaving,
      isDataAvailable,
      updateData,
      validate,
      isFormShow
    };
  }
});
