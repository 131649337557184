var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"module-edit"},[_c('validation-observer',{staticClass:"module-edit__container"},[_c('div',[_c('div',{staticClass:"headline d-flex justify-center font-weight-bold mb-6"},[_vm._v("Get started")]),_c('validation-provider',{attrs:{"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-5",attrs:{"error-messages":errors,"rounded":"","prepend-icon":"mdi-music-accidental-sharp","outlined":"","hint":"Psst... This will be your public display name","label":"Employer Name","placeholder":"Enter your organization name"},model:{value:(_vm.programDoc.data.programName),callback:function ($$v) {_vm.$set(_vm.programDoc.data, "programName", $$v)},expression:"programDoc.data.programName"}})]}}])}),_c('div',{staticClass:"d-flex justify-end mt-0"},[_c('v-btn',{staticClass:"font-weight-bold mr-3 mt-0",attrs:{"color":"grey darken-4","outlined":"","small":"","rounded":"","depressed":""},on:{"click":function($event){_vm.showScope = true}}},[_vm._v(" Build a scope ")]),_c('v-dialog',{attrs:{"persistent":"","fullscreen":"","max-width":"100%"},model:{value:(_vm.showScope),callback:function ($$v) {_vm.showScope=$$v},expression:"showScope"}},[_c('v-card',[_c('v-card-text',[_c('Scope',{on:{"update:closeScopeDialog":function($event){_vm.showScope = false},"update:useScope":function($event){return _vm.useScope($event)}}})],1)],1)],1)],1),_c('validation-provider',{attrs:{"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{staticClass:"mt-5 mb-5",attrs:{"error-messages":errors,"rounded":"","outlined":"","three-line":"","counter":"280","label":"What's your project scope?","prepend-icon":"mdi-text-subject","placeholder":"In a tweet or less, what are you asking students to do?","hint":"This will be displayed when students explore programs"},model:{value:(_vm.programDoc.data.programDesc),callback:function ($$v) {_vm.$set(_vm.programDoc.data, "programDesc", $$v)},expression:"programDoc.data.programDesc"}})]}}])}),_c('validation-provider',{attrs:{"rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-combobox',{staticClass:"module-setup__combobox mt-5 mb-5",attrs:{"items":_vm.rewardOptions,"search-input":_vm.rewardSearch,"error-messages":errors,"rounded":"","prepend-icon":"mdi-trophy","label":"Reward for winning students","multiple":"","small-chips":"","hide-details":"","outlined":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Press "),_c('kbd',[_vm._v("enter")]),_vm._v(" to add reward ")])],1)],1)]},proxy:true},{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [_c('v-chip',_vm._b({class:'start-adk-career-options',attrs:{"input-value":selected,"label":"","small":""},on:{"click":function($event){return parent.selectItem(item)}}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(item)+" ")]),_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-close ")])],1)]}}],null,true),model:{value:(_vm.programDoc.data.rewards),callback:function ($$v) {_vm.$set(_vm.programDoc.data, "rewards", $$v)},expression:"programDoc.data.rewards"}})]}}])}),_c('validation-provider',{attrs:{"rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-combobox',{staticClass:"module-setup__combobox mt-5 mb-5",attrs:{"items":_vm.pathwayOptions,"search-input":_vm.pathwaySearch,"error-messages":errors,"rounded":"","prepend-icon":"mdi-sign-direction","label":"What are the career pathways of this project & program?","multiple":"","small-chips":"","hide-details":"","outlined":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Press "),_c('kbd',[_vm._v("enter")]),_vm._v(" to add reward ")])],1)],1)]},proxy:true},{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [_c('v-chip',_vm._b({class:'start-adk-career-options',attrs:{"input-value":selected,"label":"","small":""},on:{"click":function($event){return parent.selectItem(item)}}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(item)+" ")]),_c('v-icon',[_vm._v(" mdi-close ")])],1)]}}],null,true),model:{value:(_vm.programDoc.data.pathways),callback:function ($$v) {_vm.$set(_vm.programDoc.data, "pathways", $$v)},expression:"programDoc.data.pathways"}})]}}])}),_c('div',{staticClass:"headline d-flex justify-center mt-12 font-weight-bold"},[_vm._v("Optional")]),_c('validation-provider',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-combobox',{staticClass:"module-setup__combobox mt-12 mb-12",attrs:{"error-messages":errors,"items":_vm.requiredSkillOptions,"search-input":_vm.skillSearch,"rounded":"","prepend-icon":"mdi-head-snowflake-outline","hide-selected":"","label":"Any pre-existing knowledge, tech or tools required?","multiple":"","small-chips":"","hide-details":"","outlined":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Press "),_c('kbd',[_vm._v("enter")]),_vm._v(" to add item ")])],1)],1)]},proxy:true},{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [_c('v-chip',_vm._b({class:'start-adk-career-options',attrs:{"input-value":selected,"label":"","small":""},on:{"click":function($event){return parent.selectItem(item)}}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(item)+" ")]),_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-close ")])],1)]}}],null,true),model:{value:(_vm.programDoc.data.requiredSkills),callback:function ($$v) {_vm.$set(_vm.programDoc.data, "requiredSkills", $$v)},expression:"programDoc.data.requiredSkills"}})]}}])}),_c('div',{staticClass:"startAdkAutoComplete my-id__wrapper"},[_c('validation-provider',{staticStyle:{"position":"relative"},attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('a-autocomplete',{staticStyle:{"margin-bottom":"25px"},attrs:{"error-message":errors,"is-rounded":true,"placeholder":"Search Residency","left-icon":"mdi-map-check","type":"region"},on:{"val":_vm.setRegionValue},model:{value:(_vm.programDoc.data.requiredResidency),callback:function ($$v) {_vm.$set(_vm.programDoc.data, "requiredResidency", $$v)},expression:"programDoc.data.requiredResidency"}})]}}])})],1),_c('div',{staticClass:"module-setup__question-title mt-12"},[_vm._v(" What's your preferred age range for student participants? ")]),_c('v-range-slider',{staticClass:"age-range-slider mt-10 mb-10",attrs:{"tick-labels":_vm.ages,"label":"Age","min":"13","max":"24","ticks":"always","tick-size":"1"},scopedSlots:_vm._u([{key:"thumb-label",fn:function(){return [_c('v-icon',{attrs:{"dark":""}})]},proxy:true}]),model:{value:(_vm.programDoc.data.ageRange),callback:function ($$v) {_vm.$set(_vm.programDoc.data, "ageRange", $$v)},expression:"programDoc.data.ageRange"}})],1),_c('div',{staticClass:"module-setup__license-button mt-12"},[_c('v-btn',{staticClass:"font-weight-bold setup-adk-start",attrs:{"disabled":_vm.readonly,"loading":_vm.saveLoading,"rounded":"","x-large":"","dark":"","depressed":""},on:{"click":_vm.saveProgram}},[_vm._v("Save Program")]),(_vm.status.length)?_c('v-alert',{staticClass:"mt-3 white--text presets__alert",attrs:{"type":_vm.status.includes('Saved') ? 'green' : 'green',"dense":""}},[_vm._v(_vm._s(_vm.status))]):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }