


























































































import { reactive, ref, toRefs, PropType } from '@vue/composition-api';
import { loading, getModAdk, getModMongoDoc } from 'pcv4lib/src';
// import Instruct from './ModuleInstruct.vue';
import { MongoDoc } from './types';

export default {
  name: 'ModulePresets',
  components: {
    // Instruct
  },
  props: {
    value: {
      required: true,
      type: Object as PropType<MongoDoc>
    }
  },
  setup(props, ctx) {
    const { adkData } = getModAdk(props, ctx.emit, 'forum');
    const programDoc = getModMongoDoc(props, ctx.emit);
    const maxQuestionsItems = [...Array(10).keys()].map(i => i + 1);

    const presets = reactive({
      group: ['Setup', 'Project', 'Screening', 'Internship'],
      required: ['Creator requires this activity', 'Yes', 'No'],
      lockOrder: ['Creator locked activity group and placement order', 'Yes', 'No'],
      deliverable: ['Yes', 'No'],
      notifications: ['Creator turned on by default', 'Turn on', 'Turn off'],
      accessibility: [
        'Creator has turned off accessibility anytime',
        'Creator has turned on accessibility anytime',
        'Yes',
        'No'
      ],
      endEarly: [
        'Creator has not allowed participants to end early after this activity',
        'Creator has allow end early option only at preset order placement',
        'Yes',
        'No'
      ]
    });

    // const initInteractPresets = {
    //   defaultActivity: {
    //     group: 'Project',
    //     requiredActivity: 'No',
    //     deliverableActivity: 'No',
    //     endEarlyActivity: 'No',
    //     required: false
    //   }
    // };

    const setupInstructions = ref({
      description: '',
      instructions: ['', '', '']
    });

    return {
      adkData,
      ...toRefs(presets),
      ...loading(programDoc.value.update, 'Success', 'Try again later'),
      setupInstructions,
      maxQuestionsItems
    };
  }
};
