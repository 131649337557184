
















































































































import { ref, defineComponent, inject } from '@vue/composition-api';

export default defineComponent({
  name: 'ModuleInstruct',

  setup() {
    const readonly = inject('readonly', false);

    const boilerInstructions = ref([
      'Create or connect your Discord account',
      'Chat with your team, all teams or greater PilotCity community',
      'Explore the PilotCity community via Discord'
    ]);
    const goal = ref([
      'To chat with your team, all teams or the greater PilotCity community about your project'
    ]);

    return { boilerInstructions, tutorialDialog: false, goal, readonly };
  }
});
