<template>
  <v-container class="ma-0 pa-0">
    <a-calendar />
    <br />
    <br />
    <!-- <v-row class="pl-md-6 pr-md-6 pl-0 pr-0"> -->
    <v-card-actions class="justify-center">
      <v-btn
        rounded
        class="
          perform-adk-community-create
          module-default__goto-discord
          mt-2
          pl-auto
          pr-auto
          font-weight-bold
          blue--text
        "
        width="500"
        outlined
        color="blue"
        x-large
        @click="goToCalendar()"
        >Go to Calendar</v-btn
      >
    </v-card-actions>
  </v-container>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { useDbState } from '@/store';
import ACalendar from '@/components/atoms/ACalendar.vue';

export default defineComponent({
  components: {
    ACalendar
  },
  setup(props) {
    const { user } = useDbState(['user']);
    function goToCalendar() {
      if (user.value.outlookDetails) {
        window.open('https://outlook.live.com/calendar', '_blank');
      } else {
        window.open('https://calendar.google.com/calendar', '_blank');
      }
    }
    return {
      user,
      goToCalendar
    };
  }
});
</script>
