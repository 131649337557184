





































































































































































































































































































































































































































































import { reactive, toRefs, ref, computed } from '@vue/composition-api';
// import gql from 'graphql-tag';
import draggable from 'vuedraggable';
import axios from 'axios';
// import videojs from 'video.js';
import MongoDoc from './types';
import vid from './videorec.vue';

export default {
  name: 'ModuleSetup',
  components: {
    draggable,
    vid
  },

  props: {
    userDoc: {
      required: true,
      type: Object as () => MongoDoc
    },
    programDoc: {
      required: true,
      type: Object as () => MongoDoc
    },
    fetchProgram: {
      required: true,
      type: [Object, Function]
    }
  },

  setup(props: any, ctx) {
    const setup = reactive({});

    const programDocument = computed({
      get: () => props.programDoc,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    const index = programDocument.value.data.adks.findIndex(obj => {
      if (obj) {
        return obj.name === 'interview';
      }
      return false;
    });

    // Questions Array
    const interviewQuestions = ref([]);

    const isFormId = computed(() => {
      if (programDocument.value && programDocument.value.data.adks[index].form_id) {
        return true;
      }
      return false;
    });

    // Dialog Handling
    const viewQuestionDialog = ref(false);
    const addQuestionDialog = ref(false);
    const reRecordDialog = ref(false);
    const reRecord = ref<InstanceType<typeof vid>>();
    const addQuestion = ref<InstanceType<typeof vid>>();
    const editInterviewQuestion = ref(false);
    const deleteInterviewQuestion = ref(false);
    const resetInterviewDialog = ref(false);
    const isDeleting = ref(false);
    const renameQuestion = ref('');
    const newQuestion = ref('');
    const currentQuestionId = ref('');
    const updatingQuestion = ref(false);
    const currentMediaUrl = ref('');
    const base64 = ref('');
    const submittingQuestion = ref(false);
    const isGenerating = ref(false);
    const changeOrderDialog = ref(false);
    const isChanging = ref(false);
    const observer = ref('');
    let moveEvent = reactive({
      newIndex: '',
      oldIndex: '',
      element: ''
    });

    function openAddQModal() {
      addQuestion.value?.initPlayer();
      addQuestionDialog.value = true;
    }

    function getMedia(val) {
      base64.value = val;
    }

    const isBase64 = computed(() => {
      if (base64.value) {
        return true;
      }
      return false;
    });

    function reRecordQ(val) {
      reRecord.value?.initPlayer();
      reRecordDialog.value = true;
      currentQuestionId.value = val.question_id;
    }

    function viewQuestion(val) {
      viewQuestionDialog.value = true;
      currentMediaUrl.value = val;
    }

    function change(event) {
      changeOrderDialog.value = true;
      moveEvent = { ...event.moved };
    }

    function revert() {
      if (moveEvent) {
        interviewQuestions.value[moveEvent.newIndex] = interviewQuestions.value[moveEvent.oldIndex];
        interviewQuestions.value[moveEvent.oldIndex] = moveEvent.element;
        changeOrderDialog.value = false;
        moveEvent = reactive({
          newIndex: '',
          oldIndex: '',
          element: ''
        });
      }
    }

    async function changeOrder() {
      const API_ENDPOINT = process.env.VUE_APP_ADK_INTERVIEW_REORDER;

      try {
        isChanging.value = true;
        const res = await axios.put(
          API_ENDPOINT,
          {
            question_array: interviewQuestions.value
          },
          {
            params: {
              form_id: programDocument.value.data.adks[index].form_id
            },
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );
        interviewQuestions.value = res.data.data.questions;
        changeOrderDialog.value = false;
        isChanging.value = false;
      } catch (err) {
        console.log(err);
      }
    }

    async function fetchFormData(val) {
      const API_ENDPOINT = process.env.VUE_APP_ADK_INTERVIEW_FORMDATA;

      try {
        const res = await axios.get(API_ENDPOINT, {
          params: {
            form_id: val
          },
          headers: {
            'Content-Type': 'application/json'
          }
        });
        if (res) {
          interviewQuestions.value = res.data.data.questions;
          isGenerating.value = false;
        }
      } catch (err) {
        console.log(err);
      }
    }

    async function updateQuestion() {
      const API_ENDPOINT = process.env.VUE_APP_ADK_INTERVIEW_RENAME;
      try {
        updatingQuestion.value = true;
        const data = {
          title: renameQuestion.value
        };
        const res = await axios.patch(API_ENDPOINT, data, {
          params: {
            question_id: currentQuestionId.value
          }
        });
        fetchFormData(programDocument.value.data.adks[index].form_id).then(res => {
          currentQuestionId.value = '';
          updatingQuestion.value = false;
          editInterviewQuestion.value = false;
        });
      } catch (err) {
        console.log(err);
        updatingQuestion.value = false;
        editInterviewQuestion.value = false;
      }
    }

    function editQuestion(val) {
      editInterviewQuestion.value = true;
      renameQuestion.value = val.title;
      currentQuestionId.value = val.question_id;
    }

    function openDeleteModal(val) {
      deleteInterviewQuestion.value = true;
      currentQuestionId.value = val.question_id;
    }

    if (
      programDocument.value &&
      programDocument.value.data.adks[index] &&
      programDocument.value.data.adks[index].form_id
    ) {
      fetchFormData(programDocument.value.data.adks[index].form_id);
    }
    const isQueAvailable = ref(false);
    async function generateInterview() {
      const API_ENDPOINT = process.env.VUE_APP_ADK_INTERVIEW_GENERATE;

      const data = {
        email_id: props.userDoc.data.email,
        program_id: ctx.root.$route.params.programId,
        program_name: props.programDoc.data.programName
      };
      try {
        isGenerating.value = true;
        const resp = await axios.post(API_ENDPOINT, data);

        if (resp.data) {
          const interviewForm = {
            form_id: resp.data.data.form_id,
            form_url: resp.data.data.share_url
          };
          programDocument.value.data.adks[index].form_id = interviewForm.form_id;
          programDocument.value.data.adks[index].form_url = interviewForm.form_url;
          programDocument.value.update(() => ({
            props,
            isComplete: true,
            adkIndex: index,
            status: 'complete_setup'
          }));
          fetchFormData(interviewForm.form_id);
        }
      } catch (err) {
        console.log(err);
        isGenerating.value = false;
      }
    }

    async function addNewQuestion() {
      const API_ENDPOINT = process.env.VUE_APP_ADK_INTERVIEW_ADD_QUESTION;

      const data = {
        media_url: base64.value,
        title: newQuestion.value
      };

      try {
        submittingQuestion.value = true;
        const resp = await axios.post(API_ENDPOINT, data, {
          params: {
            form_id: programDocument.value.data.adks[index].form_id
          }
        });
        fetchFormData(programDocument.value.data.adks[index].form_id).then(res => {
          addQuestion.value?.removePlayer();
          submittingQuestion.value = false;
          addQuestionDialog.value = false;
          base64.value = '';
          newQuestion.value = '';
          observer.value.reset();
        });
      } catch (err) {
        console.log(err);
        submittingQuestion.value = false;
      }
    }
    function closeAddQDialog() {
      addQuestion.value?.removePlayer();
      addQuestionDialog.value = false;
      newQuestion.value = '';
      observer.value.reset();
    }

    async function deleteQuestion() {
      const API_ENDPOINT = process.env.VUE_APP_ADK_INTERVIEW_DEL_QUESTION;

      try {
        isDeleting.value = true;
        const resp = await axios.delete(API_ENDPOINT, {
          params: {
            question_id: currentQuestionId.value
          }
        });

        if (resp) {
          fetchFormData(programDocument.value.data.adks[index].form_id).then(res => {
            currentQuestionId.value = '';
            isDeleting.value = false;
            deleteInterviewQuestion.value = false;
          });
        }
      } catch (err) {
        console.log(err);
      }
    }

    async function reRecordQuestion() {
      const API_ENDPOINT = process.env.VUE_APP_ADK_INTERVIEW_RE_QUESTION;

      const data = {
        media_url: base64.value
      };

      try {
        submittingQuestion.value = true;
        const resp = await axios.patch(API_ENDPOINT, data, {
          params: {
            question_id: currentQuestionId.value
          }
        });
        fetchFormData(programDocument.value.data.adks[index].form_id).then(() => {
          reRecord.value?.removePlayer();
          submittingQuestion.value = false;
          reRecordDialog.value = false;
          base64.value = '';
          currentQuestionId.value = '';
        });
      } catch (err) {
        console.log(err);
        submittingQuestion.value = false;
      }
    }

    function closeReRecordDialog() {
      reRecord.value?.removePlayer();
      currentQuestionId.value = '';
      reRecordDialog.value = false;
    }

    async function resetInterview() {
      const API_ENDPOINT = process.env.VUE_APP_ADK_INTERVIEW_RESET;

      try {
        isDeleting.value = true;
        const resp = await axios.patch(
          `${API_ENDPOINT}?form_id=${programDocument.value.data.adks[index].form_id}`
        );
        fetchFormData(programDocument.value.data.adks[index].form_id).then(() => {
          isDeleting.value = false;
          resetInterviewDialog.value = false;
        });
      } catch (err) {
        console.log(err);
        isDeleting.value = false;
      }
    }

    return {
      viewQuestionDialog,
      resetInterview,
      addQuestion,
      reRecordDialog,
      changeOrderDialog,
      moveEvent,
      revert,
      closeAddQDialog,
      observer,
      closeReRecordDialog,
      change,
      reRecord,
      isChanging,
      getMedia,
      openAddQModal,
      addQuestionDialog,
      resetInterviewDialog,
      editInterviewQuestion,
      currentMediaUrl,
      interviewQuestions,
      deleteInterviewQuestion,
      editQuestion,
      renameQuestion,
      generateInterview,
      reRecordQuestion,
      isDeleting,
      programDocument,
      reRecordQ,
      isGenerating,
      isQueAvailable,
      fetchFormData,
      openDeleteModal,
      isFormId,
      deleteQuestion,
      newQuestion,
      index,
      changeOrder,
      updatingQuestion,
      currentQuestionId,
      viewQuestion,
      updateQuestion,
      addNewQuestion,
      submittingQuestion,
      isBase64,
      ...toRefs(setup)
    };
  }
};
