
















































































































import { ref, defineComponent, inject } from '@vue/composition-api';

export default defineComponent({
  name: 'ModuleInstruct',

  setup() {
    const readonly = inject('readonly', false);
    const boilerInstructions = ref([
      'View contacts & preferences of your employer & intern peers',
      'Set your own contact preferences',
      'Communicate with employer & intern peers as needed'
    ]);
    const goal = ref([
      'To view contacts, preferences, set your own preferences & communicate with your employer & intern peers as needed'
    ]);
    // function populate() {
    //   boilerInstructions.value.push('');
    // }

    return { boilerInstructions, tutorialDialog: false, goal, readonly };
  }
});
