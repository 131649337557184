



































































































import { ref, defineComponent, inject } from '@vue/composition-api';

export default defineComponent({
  name: 'ModuleInstruct',

  setup() {
    const readonly = inject('readonly', false);
    const boilerInstructions = ref([
      'Complete video question & answer exit interview',
      'Await signoff from employer',
      'Once you receive a signoff text message, you have completed your internship!'
    ]);
    const goal = ref([
      'To complete your internship by completing an exit interview & awaiting your employer signoff'
    ]);

    return { boilerInstructions, tutorialDialog: false, goal, readonly };
  }
});
