var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"module-edit pa-0"},[_c('div',{staticStyle:{"width":"100%"}},[(!_vm.programDoc.data.adks[_vm.index].signInVideoAskResponse)?_c('v-container',[_c('div',[_c('iframe',{staticStyle:{"border":"none","border-radius":"0px"},attrs:{"src":("https://www.videoask.com/f9ifm2r3p#contact_name=" + (encodeURI(
            _vm.user.firstName
          )) + "&contact_email=" + (encodeURI(_vm.user.email)) + "&program_id=" + (encodeURI(
            _vm.programDoc.data._id.toString()
          ))),"allow":"camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;","width":"100%","height":"600px"}})]),_c('div',[_c('h3',{staticClass:"text-center pa-4 mt-3"},[_vm._v(" Once done with welcome, wait 1-minute, then click submit ")]),_c('div',{staticClass:"d-flex flex-column align-center justify-center"},[_c('v-btn',{staticClass:"ma-2 mt-4 white--text",attrs:{"color":"blue","x-large":"","rounded":"","depressed":"","loading":_vm.isFetching},on:{"click":_vm.fetchStatus}},[_vm._v(" Submit 1-min after completion ")])],1)])]):_c('v-container',[_c('div',[_c('iframe',{staticStyle:{"border":"none","border-radius":"0px"},attrs:{"src":_vm.programDoc.data.adks[_vm.index].signInVideoAskResponse &&
            _vm.programDoc.data.adks[_vm.index].signInVideoAskResponse.contact &&
            _vm.programDoc.data.adks[_vm.index].signInVideoAskResponse.contact.share_url,"allow":"autoplay *; encrypted-media *; fullscreen *;","width":"100%","height":"600px"}}),(_vm.programDoc.data.adks[_vm.index].signInVideoAskResponse)?_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn',_vm._g(_vm._b({staticClass:"module-default__log-btn mt-12",attrs:{"ripple":false,"rounded":"","depressed":"","x-large":"","outlined":""}},'v-btn',attrs,false),on),[_vm._v("Redo Welcome Video")])],1)]}}],null,false,1140133298),model:{value:(_vm.redoWelcome),callback:function ($$v) {_vm.redoWelcome=$$v},expression:"redoWelcome"}},[_c('v-card',[_c('v-card-title',{staticClass:"d-flex flex-column justify-center"},[_c('div',{staticClass:"overline font-weight-bold d-flex justify-center"},[_vm._v(" Are you sure you want to delete & redo your welcome video? ")])]),_c('v-divider'),_c('v-container',{staticClass:"d-flex flex-column justify-center"},[_c('div',{staticClass:"d-flex flex-row justify-center mt-4 mb-6"},[_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","x-large":"","rounded":"","depressed":""},on:{"click":function($event){_vm.redoWelcome = false}}},[_vm._v("Cancel")]),_c('v-btn',{staticClass:"ma-2",attrs:{"color":"red","x-large":"","dark":"","rounded":"","depressed":"","loading":_vm.isLoading},on:{"click":function($event){return _vm.process()}}},[_vm._v("Delete & Redo")])],1)])],1)],1):_vm._e()],1)]),_c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var invalid = ref.invalid;
return [_c('div',{staticClass:"d-flex flex-column pa-12"},[_c('div',{staticClass:"d-flex justify-center mb-4"},[_c('h3',[_vm._v("Insert get started folder (Recommended)")])]),_c('div',[_c('validation-provider',{attrs:{"rules":{
              regex: /^((?:https?:)?\/\/)?((?:www)\.)?((?:(.*)\.com))(.*)/,
              required: true
            },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{staticClass:"justify-center align-center mb-4",attrs:{"error-messages":errors,"label":"Enter link to shared drive (ie: Google Drive)","x-large":"","rounded":"","outlined":"","type":"url","hint":"Please make sure it is editable by anyone who views"},model:{value:(_vm.driveLink),callback:function ($$v) {_vm.driveLink=$$v},expression:"driveLink"}},[(!_vm.copied)?_c('v-tooltip',{attrs:{"slot":"append","top":""},slot:"append",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-auto mb-auto",attrs:{"icon":""},on:{"click":_vm.doCopy}},on),[(!invalid)?_c('v-icon',[_vm._v("mdi-content-copy")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v("Copy content")])]):_c('v-tooltip',{attrs:{"slot":"append","top":""},slot:"append",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-auto mb-auto",attrs:{"icon":""}},on),[(!invalid)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-bold")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v("Link copied")])])],1)]}}],null,true)})],1),_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"welcome__full-width-btn font-weight-bold",attrs:{"disabled":invalid,"rounded":"","dark":!invalid,"color":"yellow","x-large":"","depressed":"","loading":_vm.isVerifyLink},on:{"click":function($event){return _vm.verifyLink()}}},[_vm._v("Verify & Save Link")])],1)])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }