































































































































































































































































































































































































































































/* eslint-disable no-prototype-builtins */
/* eslint-disable vue/no-mutating-props */
/* eslint-disable no-param-reassign */
import { defineComponent, PropType, computed, ref, inject } from '@vue/composition-api';
import { getModAdk, loading } from 'pcv4lib/src';
import Instruct from './ModuleInstruct.vue';
import MongoDoc from './types';

export default defineComponent({
  name: 'ModuleDefault',

  components: {
    Instruct
  },

  props: {
    value: {
      required: true,
      type: Object as PropType<MongoDoc>
    },
    userType: {
      required: true,
      type: String
      // participant: '',
      // organizer: '',
      // stakeholder: ''
    },
    studentDoc: {
      required: true,
      type: Object as () => MongoDoc
    }
  },
  setup(props, ctx) {
    const readonly = inject('readonly', false);
    const programDoc = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    const index = programDoc.value.data.adks.findIndex(function findScopeObj(obj) {
      return obj.name === 'scope';
    });

    const initScopeSetup = {
      scope: {
        employerName: '',
        employerWebsite: '',
        projectScope: '',
        introVideo: '',
        aboutOrg: '',
        outcome: [
          'Build portfolio project',
          'Qualify to win work experiences to execute on project'
        ],
        deliverable: ['Ideate', 'Pitch', 'Make', 'Demo'],
        projectReq: '',
        resourceWeb: '',
        resourceInsta: '',
        resourceLinkedIn: '',
        resourceFacebook: '',
        resourceYouTube: '',
        resourceDrive: '',
        resourceTwitter: '',
        interviewProblem: '',
        interviewSolution: '',
        interviewOpportunity: '',
        interviewChallenge: '',
        interviewReqeust: '',
        required: false
      }
    };
    programDoc.value.data.adks[index] = {
      ...initScopeSetup,
      ...programDoc.value.data.adks[index]
    };

    const { adkData, adkIndex } = getModAdk(
      props,
      ctx.emit,
      'scope',
      {
        acknowledgeScope: false
      },
      'studentDoc',
      'inputStudentDoc'
    );
    let sampleEmployerName = ref('');
    const sampleProjectScope = ref('');
    let sampleInterviewProblem = ref('');
    let sampleInterviewSolution = ref('');
    let sampleInterviewOpportunity = ref('');
    let sampleInterviewChallenge = ref('');
    let sampleIR = ref('');
    const sampleOutcome = ref('');
    const sampleDeliverable = ref('');
    let sampleProjectReq = ref('');
    let sampleaboutOrg = ref('');
    let sampleResourceChip = ref('');
    if (programDoc.value.data.adks[index].employerName === '') {
      sampleEmployerName = ref('Your employer name goes here');
    }
    // if (programDoc.programDesc === '') {
    //   sampleProjectScope = ref('Your employer is crafting their project scope');
    // }
    if (programDoc.value.data.adks[index].interviewProblem === '') {
      sampleInterviewProblem = ref('Awaiting an answer from your employer');
    }
    if (programDoc.value.data.adks[index].interviewSolution === '') {
      sampleInterviewSolution = ref('Awaiting an answer from your employer');
    }
    if (programDoc.value.data.adks[index].interviewOpportunity === '') {
      sampleInterviewOpportunity = ref('Awaiting an answer from your employer');
    }
    if (programDoc.value.data.adks[index].interviewChallenge === '') {
      sampleInterviewChallenge = ref('Awaiting an answer from your employer');
      sampleIR = ref('Awaiting an answer from your employer');
    }
    // if (programDoc.value.data.adks[index].rfp.outcome === '') {
    //   sampleOutcome = ref('SAMPLE OUTPUT 6');
    // }
    // if (programDoc.value.data.adks[index].rfp.deliverable === '') {
    //   sampleDeliverable = ref('SAMPLE OUTPUT 7');
    // }
    if (programDoc.value.data.adks[index].projectReq === '') {
      sampleProjectReq = ref('None');
    }
    if (programDoc.value.data.adks[index].aboutOrg === '') {
      sampleaboutOrg = ref('Awaiting a blurb about your employer');
    }
    if (
      programDoc.value.data.adks[index].resourceDrive === '' &&
      programDoc.value.data.adks[index].resourceWeb === '' &&
      programDoc.value.data.adks[index].resourceInsta === '' &&
      programDoc.value.data.adks[index].resourceFacebook === '' &&
      programDoc.value.data.adks[index].resourceYouTube === '' &&
      programDoc.value.data.adks[index].resourceLinkedIn === ''
    ) {
      sampleResourceChip = ref('None');
    }

    const successmsg = ref('');
    const errormsg = ref('');
    const loadingstatus = ref(false);

    async function saveData() {
      loadingstatus.value = true;
      try {
        if (props.studentDoc.data.adks[adkIndex].hasOwnProperty('activityEndedOn')) {
          props.studentDoc.data.adks[adkIndex].activityModifiedOn = new Date();
        } else {
          props.studentDoc.data.adks[adkIndex].activityEndedOn = new Date();
        }
        await props.studentDoc?.update(() => {
          return {
            isComplete: true,
            adkIndex
          };
        });
        ctx.emit('calculate-timer');
        successmsg.value = 'Success';
        errormsg.value = '';
        loadingstatus.value = false;
      } catch (err) {
        successmsg.value = '';
        errormsg.value = 'Try again later';
        loadingstatus.value = false;
      }
    }

    return {
      index,
      adkData,
      programDoc,
      initScopeSetup,
      sampleEmployerName,
      sampleProjectScope,
      sampleInterviewProblem,
      sampleInterviewSolution,
      sampleInterviewOpportunity,
      sampleInterviewChallenge,
      sampleIR,
      sampleOutcome,
      sampleDeliverable,
      sampleProjectReq,
      sampleaboutOrg,
      sampleResourceChip,
      saveData,
      successmsg,
      errormsg,
      loadingstatus,
      readonly,
      ...loading(
        () =>
          props.studentDoc.update(() => {
            return {
              isComplete: true,
              adkIndex
            };
          }),
        'Success',
        'Try again later'
      )
      // setup
    };
  },
  data() {
    return {
      showInstructions: 'true'
    };
  }
  // setup() {
  //   const setupInstructions = ref({
  //     description: '',
  //     instructions: ['', '', '']
  //   });
  //   const showInstructions = ref(true);
  //   return {
  //     setupInstructions,
  //     showInstructions
  //   };
  // }
});
