
































































































import { reactive, ref, toRefs, computed, PropType, defineComponent } from '@vue/composition-api';
import MongoDoc from './types';
import {
  group,
  required,
  lockOrder,
  deliverable,
  notifications,
  accessibility,
  endEarly
} from './const';
// import gql from 'graphql-tag';

export default defineComponent({
  name: 'ModulePresets',
  components: {
    // Instruct
  },
  props: {
    value: {
      required: true,
      type: Object as PropType<MongoDoc>
    }
  },

  setup(props, ctx) {
    const programDoc = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    const index = programDoc.value.data.adks.findIndex(obj => {
      if (obj) {
        return obj.name === 'scope';
      }
      return false;
    });

    const initScopePresets = {
      defaultActivity: {
        groupActivity: 'Project',
        requiredActivity: 'Yes',
        deliverableActivity: 'No',
        endEarlyActivity: 'No',
        required: false
      }
    };
    programDoc.value.data.adks[index] = {
      ...initScopePresets,
      ...programDoc.value.data.adks[index]
    };

    const loading = ref(false);
    const errormsg = ref('');
    async function save() {
      loading.value = true;
      try {
        await programDoc.value.update();
        errormsg.value = '';
      } catch (err) {
        errormsg.value = 'Could not save';
      }
      loading.value = false;
    }

    const presets = reactive({
      group,
      required,
      lockOrder,
      deliverable,
      notifications,
      accessibility,
      endEarly,
      minutes: '',
      groupActivity: '',
      requiredActivity: '',
      lockOrderActivity: '',
      deliverableActivity: '',
      notificationsActivity: '',
      accessibilityActivity: '',
      endEarlyActivity: ''
    });
    const defaultActivity = reactive({
      minutes: '',
      groupActivity: '',
      requiredActivity: '',
      lockOrderActivity: '',
      deliverableActivity: '',
      notificationsActivity: '',
      accessibilityActivity: '',
      endEarlyActivity: ''
    });
    const setupInstructions = ref({
      description: '',
      instructions: ['', '', '']
    });
    return {
      ...toRefs(presets),
      setupInstructions,
      ...toRefs(defaultActivity),
      loading,
      save,
      errormsg,
      index,
      programDoc
    };
  }
  // setup() {
  //   const presets = reactive({
  //     group,
  //     required,
  //     lockOrder,
  //     deliverable,
  //     notifications,
  //     accessibility,
  //     endEarly
  //   });
  //   return {
  //     ...toRefs(presets),
  //     setupInstructions,
  //     ...toRefs(defaultActivity)
  //   };
  // }
});
