










































































































































































































































































































































































import { defineComponent, inject, ref, computed, watch } from '@vue/composition-api';
import { getModAdk, getModMongoDoc, loading } from 'pcv4lib/src';
import axios from 'axios';
import Instruct from './ModuleInstruct.vue';
import MongoDoc from './types';

export default defineComponent({
  name: 'ModuleDefault',

  components: {
    Instruct
  },
  props: {
    studentDoc: {
      required: true
    },
    programDoc: {
      required: true,
      type: Object as () => MongoDoc
    },
    userDoc: {
      required: true
    },
    userType: {
      required: true,
      type: String
    },
    timeline: {
      type: Array
    }
  },
  setup(props, ctx) {
    const index = props.programDoc.data.adks.findIndex(obj => obj && obj.name === 'interview');
    const isFetching = ref(false);
    const isFormUrl = computed(() => {
      if (
        props.programDoc.data &&
        props.programDoc.data.adks[index] &&
        props.programDoc.data.adks[index].form_url
      ) {
        return true;
      }
      return false;
    });
    const readonly = inject('readonly', false);
    const studentDocument = getModMongoDoc(props, ctx.emit, {}, 'studentDoc', 'inputStudentDoc');
    const { adkData, adkIndex } = getModAdk(
      props,
      ctx.emit,
      'interview',
      {},
      'studentDoc',
      'inputStudentDoc'
    );

    const loaded = ref(false);
    function callUpdate() {
      if (!isFormUrl.value) {
        // eslint-disable-next-line no-return-assign
        studentDocument.value.update().then(() => (loaded.value = true));
      }
    }
    callUpdate();
    const redoInterview = ref(false);
    const resetInterview = async () => {
      delete adkData.value.completed;
      delete adkData.value.videoAskResponse;
      await studentDocument.value.update();
      redoInterview.value = false;
    };

    const isInterviewPending = ref(true);
    const isInterviewCompleted = computed(() => {
      return !!props.studentDoc.data.adks[adkIndex].completed;
    });

    async function fetchStatus() {
      try {
        isFetching.value = true;
        const API_ENDPOINT = process.env.VUE_APP_VIDASK_STATUS;
        const res = await axios.get(`${API_ENDPOINT}`, {
          params: {
            student_id: studentDocument.value.data._id.toString()
          }
        });
        const setFunction = setInterval(() => {
          props.studentDoc.update();
          if (isInterviewCompleted.value) {
            clearInterval(setFunction);
          }
        }, 10000);
        isFetching.value = false;
      } catch (error) {
        isFetching.value = false;
        console.log(error);
      }
    }

    watch(isInterviewCompleted, val => {
      if (val) {
        fetchStatus();
      }
    });

    return {
      adkData,
      isInterviewPending,
      index,
      studentDocument,
      isFormUrl,
      loaded,
      ...loading(resetInterview, 'Success', 'Try again later'),
      redoInterview,
      readonly,
      callUpdate,
      fetchStatus,
      isFetching,
      isInterviewCompleted
    };
  },
  data() {
    const setupInstructions = ref({
      description: '',
      instructions: ['', '', '']
    });
    const showInstructions = ref(true);
    return {
      setupInstructions,
      showInstructions
    };
  }
});
