


























































































































/* eslint-disable no-prototype-builtins */
import {
  defineComponent,
  computed,
  PropType,
  reactive,
  toRefs,
  inject
} from '@vue/composition-api';
import { getModAdk, getModMongoDoc } from 'pcv4lib/src';
import { Db, ObjectId } from 'mongodb';
import { Question as QuestionType, MongoDoc } from './types';
import {
  questionIsBookmarked,
  questionIsDisliked,
  questionIsFlagged,
  questionIsLiked,
  commentIsLiked,
  commentIsFlagged,
  removeId
} from './helpers';
import Instruct from './ModuleInstruct.vue';
import Question from './Question.vue';

const filterOptions = [
  {
    icon: 'account-supervisor-circle-outline',
    label: 'All'
  },
  {
    icon: 'comment-question',
    label: 'My Questions'
  },
  {
    icon: 'bookmark',
    label: 'Bookmarks'
  }
];

const MAX_QUESTIONS_PER_PAGE = 10;

export default defineComponent({
  name: 'ModuleDefault',

  components: {
    Instruct,
    Question
  },
  props: {
    value: {
      required: true,
      type: Object as PropType<MongoDoc>
    },
    userType: {
      required: true,
      type: String
      // participant: '',
      // organizer: '',
      // stakeholder: ''
    },
    teamDoc: {
      required: false,
      type: Object as PropType<MongoDoc>,
      default: () => {}
    },
    studentDoc: {
      required: false,
      type: Object as PropType<MongoDoc>,
      default: () => {}
    },
    userDoc: {
      required: false,
      type: Object as PropType<MongoDoc>,
      default: () => {}
    },
    db: {
      required: false,
      type: Object as PropType<Db>,
      default: () => {}
    }
  },
  setup(props, ctx) {
    const readonly = inject('readonly', false);
    const state = reactive({
      page: 1,
      filter: 'All',
      questionInput: '',
      questions: [] as QuestionType[],
      programDoc: getModMongoDoc(props, ctx.emit),
      teamDocument: props.teamDoc
        ? getModMongoDoc(props, ctx.emit, {}, 'teamDoc', 'inputTeamDoc')
        : null,
      studentDocument: props.studentDoc
        ? getModMongoDoc(props, ctx.emit, {}, 'studentDoc', 'inputStudentDoc')
        : null,
      studentAdkData: null as null | Record<string, any>,
      teamAdkData: null as null | Record<string, any>,
      teamAdkIndex: -1,
      showInstructions: true,
      setupInstructions: {
        description: '',
        instructions: ['', '', '']
      }
    });

    const { adkData, adkIndex } = getModAdk(props, ctx.emit, 'forum');

    if (props.studentDoc) {
      const { adkData: studentAdkData } = getModAdk(
        props,
        ctx.emit,
        'forum',
        {},
        'studentDoc',
        'inputStudentDoc'
      );
      state.studentAdkData = studentAdkData.value;
    }

    if (props.teamDoc) {
      const { adkData: teamAdkData, adkIndex } = getModAdk(
        props,
        ctx.emit,
        'forum',
        {},
        'teamDoc',
        'inputTeamDoc'
      );
      state.teamAdkData = teamAdkData.value;
      state.teamAdkIndex = adkIndex;
    }

    const fetchQuestions = async () => {
      const questions = await props.db
        .collection('Question')
        .find({ program_id: props.value!.data._id });
      // ignore these warnings, the main repo returns an array instead of a Cursor
      questions.sort((a: QuestionType, b: QuestionType) => (a.likes > b.likes ? 1 : -1));
      state.questions = questions;
    };
    fetchQuestions();

    const scrollUp = () => {
      window.scrollTo(0, 300);
    };

    // Filter and Pagination logic
    // ! use db Find here
    const filteredQuestions = computed(() =>
      state.questions
        .filter(question => {
          if (state.filter === 'Bookmarks')
            return questionIsBookmarked(state.studentAdkData!, question);
          if (state.filter === 'My Questions')
            return question.author.equals(props.userDoc?.data._id);
          return true;
        })
        .reverse()
    );

    const timeline = computed(() =>
      filteredQuestions.value.slice(
        (state.page - 1) * MAX_QUESTIONS_PER_PAGE,
        (state.page - 1) * MAX_QUESTIONS_PER_PAGE + MAX_QUESTIONS_PER_PAGE
      )
    );

    const numPages = computed(() =>
      Math.ceil(filteredQuestions.value.length / MAX_QUESTIONS_PER_PAGE)
    );

    const questionsRemaining = computed(() => {
      const teamQuestions = state.teamAdkData ? state.teamAdkData.questionsAsked : [];
      return adkData.value.maxQuestions - teamQuestions.length;
    });

    const activityAddModified = () => {
      if (state.teamAdkData.hasOwnProperty('activityEndedOn')) {
        state.teamAdkData.activityModifiedOn = new Date();
      } else if (!state.teamAdkData.hasOwnProperty('activityEndedOn')) {
        state.teamAdkData.activityEndedOn = new Date();
      }
    };

    const activityModified = () => {
      if (state.teamAdkData.hasOwnProperty('activityEndedOn')) {
        state.teamAdkData.activityModifiedOn = new Date();
      }
    };

    // Question and Comments Actions

    const likeQuestion = async (_id: ObjectId) => {
      const question = await props.db.collection('Question').findOne({ _id });
      if (!questionIsLiked(state.studentAdkData!, question)) {
        if (questionIsDisliked(state.studentAdkData!, question)) question.dislikes -= 1;
        question.likes += 1;
        state.studentAdkData!.likedQuestions.push(_id.toString()); // question.liked = true
        state.studentAdkData!.dislikedQuestions = removeId(
          state.studentAdkData!.dislikedQuestions,
          _id
        );
      } else {
        question.likes -= 1;
        state.studentAdkData!.likedQuestions = removeId(state.studentAdkData!.likedQuestions, _id); // question.liked = false
      }
      activityModified();
      await props.db
        .collection('Question')
        .updateOne({ _id }, { $set: { likes: question.likes, dislikes: question.dislikes } });
      state.studentDocument!.update();
      fetchQuestions();
    };

    const dislikeQuestion = async (_id: ObjectId) => {
      const question = await props.db.collection('Question').findOne({ _id });
      if (!questionIsDisliked(state.studentAdkData!, question)) {
        if (questionIsLiked(state.studentAdkData!, question)) question.likes -= 1;
        question.dislikes += 1;
        state.studentAdkData!.likedQuestions = removeId(state.studentAdkData!.likedQuestions, _id);
        state.studentAdkData!.dislikedQuestions.push(_id.toString());
      } else {
        question.dislikes -= 1;
        state.studentAdkData!.dislikedQuestions = removeId(
          state.studentAdkData!.dislikedQuestions,
          _id
        );
      }
      activityModified();
      await props.db
        .collection('Question')
        .updateOne({ _id }, { $set: { likes: question.likes, dislikes: question.dislikes } });
      state.studentDocument!.update();
      fetchQuestions();
    };

    const bookmarkQuestion = (_id: ObjectId) => {
      if (state.studentAdkData!.bookmarkedQuestions.some((id: string) => id === _id.toString()))
        state.studentAdkData!.bookmarkedQuestions = removeId(
          state.studentAdkData!.bookmarkedQuestions,
          _id
        );
      else state.studentAdkData!.bookmarkedQuestions.push(_id.toString());
      activityModified();
      state.studentDocument!.update();
    };

    const flagQuestion = async (_id: ObjectId) => {
      const question = await props.db.collection('Question').findOne({ _id });
      if (questionIsFlagged(state.studentAdkData!, question)) {
        question.flags -= 1;
        state.studentAdkData!.flaggedQuestions = removeId(
          state.studentAdkData!.flaggedQuestions,
          _id
        );
      } else {
        question.flags += 1;
        state.studentAdkData!.flaggedQuestions.push(_id.toString());
      }
      activityModified();
      await props.db.collection('Question').updateOne({ _id }, { $set: { flags: question.flags } });
      state.studentDocument!.update();
      fetchQuestions();
    };

    const getCommentIndex = (question: QuestionType, id: ObjectId) =>
      question.comments.findIndex(comment => comment._id.equals(id));

    const flagComment = async (questionID: ObjectId, commentID: ObjectId) => {
      const question = await props.db.collection('Question').findOne({ _id: questionID });
      const commentIndex = getCommentIndex(question, commentID);
      const comment = question.comments[commentIndex];
      if (commentIsFlagged(state.studentAdkData!, comment)) comment.flags -= 1;
      else comment.flags += 1;
      await props.db
        .collection('Question')
        .updateOne(
          { _id: questionID, 'comments._id': commentID },
          { $set: { 'comments.$.flags': comment.flags } }
        );
      if (state.studentAdkData!.flaggedComments.some((id: string) => id === commentID.toString()))
        state.studentAdkData!.flaggedComments = removeId(
          state.studentAdkData!.flaggedComments,
          commentID
        );
      else state.studentAdkData!.flaggedComments.push(commentID.toString());
      activityModified();
      state.studentDocument!.update();
      fetchQuestions();
    };

    const likeComment = async (questionID: ObjectId, commentID: ObjectId) => {
      const question = await props.db.collection('Question').findOne({ _id: questionID });
      const commentIndex = getCommentIndex(question, commentID);
      const comment = question.comments[commentIndex];
      if (!commentIsLiked(state.studentAdkData!, comment)) comment.likes += 1;
      else comment.likes -= 1;
      await props.db
        .collection('Question')
        .updateOne(
          { _id: questionID, 'comments._id': commentID },
          { $set: { 'comments.$.likes': comment.likes } }
        );
      if (state.studentAdkData!.likedComments.some((id: string) => id === commentID.toString()))
        state.studentAdkData!.likedComments = removeId(
          state.studentAdkData!.likedComments,
          commentID
        );
      else state.studentAdkData!.likedComments.push(commentID.toString());
      activityModified();
      state.studentDocument!.update();
      fetchQuestions();
    };

    const postQuestion = async () => {
      if (state.questionInput.length > 0) {
        const question = {
          author: props.userDoc?.data._id,
          program_id: props.value!.data._id,
          text: state.questionInput,
          comments: [],
          likes: 0,
          dislikes: 0,
          flags: 0
        };
        const { insertedId } = await props.db.collection('Question').insertOne(question);
        fetchQuestions();
        state.teamAdkData?.questionsAsked.push(insertedId);
        activityAddModified();
        state.teamDocument!.update(() => ({
          isComplete: true,
          adkIndex: state.teamAdkIndex
        }));
        ctx.emit('calculate-timer');
        state.questionInput = '';
      }
    };

    const postComment = async (questionID: ObjectId, comment: Record<string, any>) => {
      activityModified();
      await props.db
        .collection('Question')
        .updateOne({ _id: questionID }, { $push: { comments: comment } });
      fetchQuestions();
    };

    return {
      questionsRemaining,
      ...toRefs(state),
      scrollUp,
      numPages,
      filterOptions,
      postQuestion,
      timeline,
      likeQuestion,
      dislikeQuestion,
      bookmarkQuestion,
      flagQuestion,
      postComment,
      likeComment,
      flagComment,
      questionIsFlagged,
      readonly
    };
  }
});
