
























































































































































































































import { defineComponent, reactive, toRefs, computed, inject } from '@vue/composition-api';
import ManageTable from './ManageTable.vue';
import { TeamDoc } from './types';

export default defineComponent({
  name: 'Team',
  components: { ManageTable },

  props: {
    teamDoc: {
      required: true,
      type: Object as () => TeamDoc
    },
    viewer: {
      required: true,
      type: Object
    },
    userType: {
      required: true,
      type: String
      // participant: '',
      // organizer: '',
      // stakeholder: ''
    }
  },
  setup(props, ctx) {
    const readonly = inject('readonly', false);
    const state = reactive({
      renameTeamDialog: false,
      leaveTeamDialog: false,
      changePasswordDialog: false,
      newTeamName: '',
      selectedMember: null as null | Record<string, any>,
      password: props.teamDoc.data.password,
      showPassword: false
    });

    const viewerIsOwner = computed(() => {
      return props.viewer.data._id.equals(props.teamDoc.data.owner);
    });

    const teamMembers = computed(() => {
      return props.teamDoc?.data?.members?.filter(member => {
        return !member._id.equals(props.teamDoc.data.owner);
      });
    });

    const changePassword = () => {
      ctx.emit('changePassword', state.password);
      state.changePasswordDialog = false;
      state.showPassword = false;
    };

    const renameTeam = () => {
      ctx.emit('renameTeam', state.newTeamName);
      state.renameTeamDialog = false;
      state.newTeamName = '';
    };

    const leaveTeam = () => {
      if (viewerIsOwner.value && !state.selectedMember && teamMembers.value.length > 1) return;
      const newOwner = state.selectedMember ? state.selectedMember._id : null;
      ctx.emit('leaveTeam', props.viewer.data._id, newOwner);
      state.leaveTeamDialog = false;
    };

    return {
      ...toRefs(state),
      viewerIsOwner,
      teamMembers,
      changePassword,
      renameTeam,
      leaveTeam,
      readonly
    };
  }
});
