


















































































































import { ref, computed, defineComponent, PropType } from '@vue/composition-api';

export default {
  name: 'ModuleInstruct',

  setup() {
    // const programDoc = computed({
    //   get: () => props.value,
    //   set: newVal => {
    //     ctx.emit('input', newVal);
    //   }
    // });
    const boilerInstructions = ref([
      'Create or join existing welcome',
      'Complete required welcomes with team',
      'Iterate, and finalize welcomes',
      'Browse, view & support intern peers & their welcomes'
    ]);
    const goal = ref([
      'To complete the required welcomes set by employer within the start and deadline date of internship'
    ]);
    // function populate() {
    //   boilerInstructions.value.push('');
    // }

    return { boilerInstructions, tutorialDialog: false, goal };
  }
};
