










import { ref } from '@vue/composition-api';

export default {
  name: 'ModuleSetup',
  setup() {
    const setupInstructions = ref(['']);
    // const setup = reactive({});
    return {
      setupInstructions
    };
  }
};
