import { render, staticRenderFns } from "./ModuleInstruct.vue?vue&type=template&id=7a673148&"
import script from "./ModuleInstruct.vue?vue&type=script&lang=ts&"
export * from "./ModuleInstruct.vue?vue&type=script&lang=ts&"
import style0 from "./ModuleInstruct.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VAvatar,VContainer,VTextarea})
