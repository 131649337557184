var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tableview__column mt-12"},[_c('v-btn',{staticClass:"mr-1 mb-6 mt-6",style:({
      width: _vm.$vuetify.breakpoint.xs ? 'auto !important' : ' ',
      right: _vm.$vuetify.breakpoint.xs ? '4px !important' : ' '
    }),attrs:{"disabled":_vm.readonly,"dark":_vm.filter === 'Personal' ? true : false,"outlined":_vm.filter !== 'Personal' ? true : false,"x-small":"","depressed":""},on:{"click":function($event){_vm.filter = 'Personal'}}},[_vm._v("Personal")]),_c('v-btn',{staticClass:"ml-1 mb-6 mt-6",style:({ width: _vm.$vuetify.breakpoint.xs ? 'auto !important' : ' ' }),attrs:{"disabled":_vm.readonly,"dark":_vm.filter === 'Team' ? true : false,"outlined":_vm.filter !== 'Team' ? true : false,"x-small":"","depressed":""},on:{"click":function($event){_vm.filter = 'Team'}}},[_vm._v("Team")]),_c('v-data-table',{class:'make-data-table',attrs:{"headers":_vm.header,"items":_vm.tableItems,"items-per-page":100,"hide-default-footer":true,"sort-by":"resource"},scopedSlots:_vm._u([{key:"item.avatar",fn:function(ref){
    var item = ref.item;
return [_c('v-avatar',{attrs:{"size":"30","color":"grey lighten-2"}},[_c('v-img',{attrs:{"src":item.avatar}})],1)]}},{key:"item.proof",fn:function(ref){
    var item = ref.item;
return [_c('ProofPreview',{attrs:{"images":item.proof}})]}},{key:"item.time",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.time)))])]}},{key:"item.delete",fn:function(ref){
    var item = ref.item;
return [(item.author === _vm.userId.toString())?_c('v-btn',{attrs:{"disabled":_vm.userType === 'stakeholder' || _vm.readonly || _vm.viewOnly,"loading":_vm.loading && item.id === _vm.currentId,"small":"","icon":"","depressed":""},on:{"click":function($event){_vm.$emit('removeMilestone', item.id);
          _vm.currentId = item.id;}}},[_c('v-icon',{staticClass:"delete-icon",attrs:{"color":"red"}},[_vm._v(" mdi-delete ")])],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }