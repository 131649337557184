var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"module-default pa-0"},[_c('div',{staticClass:"module-default__instructions"},[_c('v-expansion-panels',{staticClass:"module-default__instructions",attrs:{"flat":""},model:{value:(_vm.showInstructions),callback:function ($$v) {_vm.showInstructions=$$v},expression:"showInstructions"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{directives:[{name:"show",rawName:"v-show",value:(_vm.showInstructions),expression:"showInstructions"}],staticClass:"pa-0",attrs:{"hide-actions":""},on:{"click":function($event){_vm.showInstructions = true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var open = ref.open;
return [_c('v-scroll-y-transition',{attrs:{"hide-on-leave":""}},[(!open)?_c('div',{staticClass:"d-flex flex-column justify-center"},[_c('v-icon',{staticClass:"d-flex justify-center",attrs:{"color":"black"}},[_vm._v(" mdi-chevron-down ")]),_c('div',{staticClass:"module-default__collapse-title"},[_vm._v("INSTRUCTIONS")])],1):_vm._e()])]}}])}),_c('v-expansion-panel-content',[_c('Instruct',{attrs:{"readonly":""}}),_c('div',{on:{"click":function($event){_vm.showInstructions = true}}},[_c('div',{staticClass:"module-default__collapse-title"},[_vm._v("CLOSE")]),_c('v-icon',{staticClass:"d-flex justify-center",attrs:{"color":"black"}},[_vm._v(" mdi-chevron-up ")])],1)],1)],1)],1)],1),_c('v-progress-linear',{staticClass:"mt-3",attrs:{"color":"#dedede","height":"2","value":"100","buffer-value":"100","stream":""}}),_c('v-data-table',{staticClass:"module-default__data-table",attrs:{"headers":_vm.header,"items":_vm.researchProgress,"items-per-page":100,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.click",fn:function(ref){
var item = ref.item;
return [(item.completed)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-checkbox-marked-circle ")]):(item.viewed)?_c('v-icon',{attrs:{"color":"yellow"}},[_vm._v(" mdi-alert-circle ")]):_c('v-icon',{attrs:{"color":"grey"}},[_vm._v(" mdi-close-circle ")])]}},{key:"item.cta",fn:function(ref){
var item = ref.item;
return [(item.viewed)?_c('v-btn',{attrs:{"disabled":_vm.readonly,"x-small":"","outlined":"","depressed":""}},[(_vm.userType !== 'stakeholder')?_c('v-icon',{attrs:{"left":"","x-small":"","color":"green"}},[_vm._v("mdi-check-bold ")]):_vm._e(),_vm._v("Goto Link")],1):_c('v-btn',{attrs:{"disabled":_vm.readonly,"href":item.link,"x-small":"","outlined":"","depressed":"","target":"_blank"},on:{"click":function($event){item.viewed = true}}},[_vm._v(" Goto Link")])]}},{key:"item.required",fn:function(ref){
var item = ref.item;
return [(item.required)?_c('v-btn',{attrs:{"disabled":_vm.readonly,"x-small":"","color":"red","outlined":"","depressed":""}},[_vm._v("Required")]):_c('v-btn',{attrs:{"disabled":_vm.readonly,"x-small":""}},[_vm._v("Recommended")])]}},{key:"item.finish",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"readonly":!item.viewed || _vm.userType === 'stakeholder',"type":"checkbox"},on:{"click":function($event){return _vm.itemComplete()}},model:{value:(item.completed),callback:function ($$v) {_vm.$set(item, "completed", $$v)},expression:"item.completed"}})]}}])}),_c('div',{staticClass:"module-default__scope mt-12"},[_c('v-btn',{staticClass:"white--text",attrs:{"disabled":_vm.userType === 'stakeholder' || _vm.finishButtonDisabled === 1 || _vm.readonly,"loading":_vm.loading,"x-large":"","color":"#ea6764","depressed":"","rounded":""},on:{"click":function($event){return _vm.process()}}},[_vm._v("Complete")]),(_vm.success || _vm.error)?_c('v-alert',{staticClass:"mt-3 white--text presets__alert",attrs:{"color":_vm.success ? 'green' : 'red',"dense":""}},[_vm._v(_vm._s(_vm.message))]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }