import { render, staticRenderFns } from "./m-user-faq.vue?vue&type=template&id=5766d255&scoped=true&"
import script from "./m-user-faq.vue?vue&type=script&lang=ts&"
export * from "./m-user-faq.vue?vue&type=script&lang=ts&"
import style0 from "./m-user-faq.vue?vue&type=style&index=0&id=5766d255&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5766d255",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VAlert,VBtn,VChip,VDataTable,VIcon,VList,VListItem,VListItemContent,VListItemIcon,VListItemTitle,VMenu,VProgressCircular})
