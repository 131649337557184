











































































/* eslint-disable no-prototype-builtins */
/* eslint-disable vue/no-mutating-props */
/* eslint-disable no-param-reassign */
import {
  computed,
  reactive,
  ref,
  defineComponent,
  inject,
  toRefs,
  PropType,
  watch
} from '@vue/composition-api';
import Setup from './ModuleSetup.vue';
import Presets from './ModulePresets.vue';
import Default from './ModuleDefault.vue';
import Monitor from './ModuleMonitor.vue';
import Table from './TableView.vue';
import MongoDoc from './types';

export default defineComponent({
  name: 'Directory',
  components: {
    'module-presets': Presets,
    'module-setup': Setup,
    'module-monitor': Monitor,
    'module-preview': Default,
    'table-view': Table
  },
  props: {
    userType: {
      required: true,
      type: String
    },
    value: {
      required: true,
      type: Object as PropType<MongoDoc>
    },
    operationMode: {
      type: Number
    },
    studentDoc: {
      required: true
    }
  },
  setup(props, ctx) {
    const moduleName = ref('Contact');
    const readonly = inject('readonly', false);

    const programDoc = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    const index = programDoc.value.data.adks.findIndex(obj => {
      if (obj) {
        return obj.name === 'contact';
      }
      return false;
    });
    if (index === -1) {
      const initDirectory = {
        name: 'contact'
      };
      programDoc.value.data.adks.push(initDirectory);
      programDoc.value.update();
    }

    const page = reactive({
      subpages: ['Setup', 'Presets'],
      currentPage: 'Setup'
    });

    if (props.userType === 'organizer' && props.operationMode === 1) {
      page.currentPage = 'Presets';
    } else if (props.userType === 'organizer' && props.operationMode === 3) {
      page.currentPage = 'monitor';
    } else {
      page.currentPage = 'preview';
      page.subpages = [];
    }

    const color = reactive({
      pilotcityColors: [
        ['#6eba80', '#3c9dcd', '#ea6764'],
        ['#eda1bf', '#fec34b', '#bdbdbd'],
        ['#ae90b0', '#f79961', '#000000']
      ],
      // ENTER ACTIVITY COLOR
      selectedColor: '#6eba80'
    });
    const getColor = computed(() => color.selectedColor.substring(0, 7));

    const getComponent = computed(() => {
      return `module-${page.currentPage.toLowerCase()}`;
    });

    const menu = ref(false);
    // timeline
    const timelineData = reactive({
      events: [] as {
        id: number;
        text: string;
        time: string;
      }[],
      input: '',
      nonce: 0
    });
    const timeline = computed(() => {
      return timelineData.events.slice().reverse();
    });
    function comment() {
      const time = new Date().toTimeString();
      timelineData.events.push({
        id: timelineData.nonce,
        text: timelineData.input,
        time: time.replace(/:\d{2}\sGMT-\d{4}\s\((.*)\)/, (match, contents) => {
          return ` ${contents
            .split(' ')
            .map((v: string) => v.charAt(0))
            .join('')}`;
        })
      });
      timelineData.input = '';
    }

    const userRole = computed(() => {
      return props.userType;
    });

    const durationFormat = ref('');
    const { studentDoc } = toRefs(props);

    const calculateTime = (startTime: any, Time2: any) => {
      let diff = (Time2 - startTime) / 1000;
      diff = Math.abs(Math.floor(diff));

      const days = Math.floor(diff / (24 * 60 * 60));
      let leftSec = diff - days * 24 * 60 * 60;

      const hrs = Math.floor(leftSec / (60 * 60));
      leftSec -= hrs * 60 * 60;

      const min = Math.floor(leftSec / 60);
      leftSec -= min * 60;
      return `${days}d:${hrs}h:${min}m`;
    };

    function calculateTimer() {
      const adkIndex = studentDoc.value?.data.adks.findIndex(obj => obj.name === 'contact') ?? 0;
      if (
        userRole.value === 'participant' &&
        studentDoc.value?.data.adks[adkIndex].hasOwnProperty('activityEndedOn')
      ) {
        const startTime = studentDoc.value.data.adks[adkIndex].activityStartedOn;
        const endTime = studentDoc.value.data.adks[adkIndex].activityEndedOn;

        durationFormat.value = calculateTime(startTime, endTime);
      } else if (
        userRole.value === 'participant' &&
        !studentDoc.value?.data.adks[adkIndex].hasOwnProperty('activityStartedOn')
      ) {
        const startTime = new Date();
        studentDoc.value.data.adks[adkIndex].activityStartedOn = startTime;
        props.studentDoc?.update();
        durationFormat.value = calculateTime(startTime, startTime);
        const x = setInterval(() => {
          const currentTime = new Date();
          let diff = (currentTime - startTime) / 1000;
          diff = Math.abs(Math.floor(diff));

          const days = Math.floor(diff / (24 * 60 * 60));
          let leftSec = diff - days * 24 * 60 * 60;

          const hrs = Math.floor(leftSec / (60 * 60));
          leftSec -= hrs * 60 * 60;

          const min = Math.floor(leftSec / 60);
          leftSec -= min * 60;
          durationFormat.value = `${days}d:${hrs}h:${min}m`;
          if (studentDoc.value?.data.adks[adkIndex].hasOwnProperty('activityEndedOn')) {
            clearInterval(x);
          }
        }, 60000);
      } else if (
        userRole.value === 'participant' &&
        studentDoc.value?.data.adks[adkIndex].hasOwnProperty('activityStartedOn')
      ) {
        durationFormat.value = calculateTime(
          new Date(),
          studentDoc.value.data.adks[adkIndex].activityStartedOn
        );
        const x = setInterval(() => {
          const currentTime = new Date();
          let diff = (currentTime - studentDoc.value.data.adks[adkIndex].activityStartedOn) / 1000;
          diff = Math.abs(Math.floor(diff));

          const days = Math.floor(diff / (24 * 60 * 60));
          let leftSec = diff - days * 24 * 60 * 60;

          const hrs = Math.floor(leftSec / (60 * 60));
          leftSec -= hrs * 60 * 60;

          const min = Math.floor(leftSec / 60);
          leftSec -= min * 60;
          durationFormat.value = `${days}d:${hrs}h:${min}m`;
          if (studentDoc.value?.data.adks[adkIndex].hasOwnProperty('activityEndedOn')) {
            clearInterval(x);
          }
        }, 60000);
      }
    }

    if (userRole.value === 'participant' && props.studentDoc?.data.adks !== undefined) {
      if (props.studentDoc.data.adks.findIndex(obj => obj.name === 'contact') === -1) {
        props.studentDoc.data.adks.push({ name: 'contact' });
        props.studentDoc.update().then(() => {
          calculateTimer();
        });
      } else {
        calculateTimer();
      }
    } else if (userRole.value === 'participant') {
      watch(
        () => studentDoc.value,
        newData => {
          if (
            newData?.data.adks.findIndex((objdata: { name: string }) => objdata.name === 'contact')
          ) {
            calculateTimer();
          } else {
            props.studentDoc?.data.adks.push({ name: 'contact' });
            props.studentDoc?.update().then(() => {
              calculateTimer();
            });
          }
        }
      );
    }

    return {
      ...toRefs(color),
      ...toRefs(page),
      moduleName,
      getColor,
      getComponent,
      ...toRefs(timelineData),
      durationFormat,
      comment,
      timeline,
      programDoc,
      readonly,
      index
    };
  }
});
