


















import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      headers: [
        {
          text: 'Participants',
          align: 'start',
          sortable: false,
          value: 'name'
        },
        { text: 'Team', value: 'calories' },
        { text: 'Progress', value: 'fat' },
        { text: 'View', value: 'carbs' }
      ],
      desserts: [
        {
          name: 'Derick Lee',
          calories: 159,
          fat: 6.0,
          carbs: 24
        },
        {
          name: 'Prajit Saravanan',
          calories: 237,
          fat: 9.0,
          carbs: 37
        },
        {
          name: 'Akheel Shaik',
          calories: 262,
          fat: 16.0,
          carbs: 23
        },
        {
          name: 'Eric Reyes',
          calories: 305,
          fat: 3.7,
          carbs: 67
        },
        {
          name: 'Jerold Inocencio',
          calories: 356,
          fat: 16.0,
          carbs: 49
        },
        {
          name: 'Monica Willemsz',
          calories: 375,
          fat: 0.0,
          carbs: 94
        },
        {
          name: 'Stephanie Betancourt',
          calories: 392,
          fat: 0.2,
          carbs: 98
        },
        {
          name: 'Kenneth Thai',
          calories: 408,
          fat: 3.2,
          carbs: 87
        },
        {
          name: 'Kristen Chan',
          calories: 452,
          fat: 25.0,
          carbs: 51
        },
        {
          name: 'Zachary Borja',
          calories: 518,
          fat: 26.0,
          carbs: 65
        }
      ]
    };
  }
});
