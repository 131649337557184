



































































































import { ref, computed, defineComponent, PropType } from '@vue/composition-api';

export default {
  name: 'ModuleInstruct',

  setup() {
    // const programDoc = computed({
    //   get: () => props.value,
    //   set: newVal => {
    //     ctx.emit('input', newVal);
    //   }
    // });
    const boilerInstructions = ref([
      'Click & complete the clock-in process',
      'Work on your items for the day or shift (Do not forget to clock-out once you are clocked-in, and vice versa)',
      'Click & complete the clock-out process',
      'Repeat until minimum hours reached or stop when maximum hours reached'
    ]);
    const goal = ref([
      'To correctly clock-in and clock-out to share how you are feeling (PilotCity calls it a vibe check), what you are working on or worked on, and any questions or barriers you might have. The purpose of the timesheet is also to clock-in and clock-out to accumulate hours worked to reach the minimum hours for the internship'
    ]);
    // function populate() {
    //   boilerInstructions.value.push('');
    // }

    return { boilerInstructions, tutorialDialog: false, goal };
  }
};
