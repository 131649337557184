


















































































































































































































































































































































































































import {
  Ref,
  reactive,
  toRefs,
  defineComponent,
  computed,
  ref,
  onMounted,
  PropType,
  watch,
  inject
} from '@vue/composition-api';
import _, { range } from 'lodash';
import AAutocomplete from '@/components/atoms/AAutocomplete.vue';
import Scope from '../../../../Explore/Scope.vue';

// TODO: move this to @pilocity/shared
const enum ProgramActivityStatus {
  NeedSetup = 'need_setup',
  CompleteSetup = 'complete_setup',
  Standard = 'standard'
}

export default defineComponent({
  name: 'ModuleSetup',
  components: {
    Scope,
    AAutocomplete
  },
  props: {
    value: {
      required: true,
      type: Object as () => {
        data: Record<string, any>; // Gives access to Document
        update: () => Promise<any>; // Gives access to update Method
        changeStream: any; // Gives access to mongodb Collection Changestream
      }
    },
    licenseProgram: {
      required: true,
      type: Function as PropType<() => any>
    }
  },
  setup(_props, ctx) {
    const readonly = inject('readonly', false);

    // data binding
    const programDoc = computed({
      get: () => _props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    const loader: Ref<any> = ref(null);

    function setRegionValue(e) {
      if (e.target) {
        programDoc.value.data.requiredResidency = e.target.value;
      } else if (e.formatted_address) {
        programDoc.value.data.requiredResidency = e.formatted_address;
      }
    }

    onMounted(() => {
      loader.value!.process();
    });
    const options = reactive({
      reward: [
        { text: 'Paid Work Experience', color: 'grey darken-2' },
        { text: 'Unpaid Work Experience', color: 'grey darken-2' }
      ],
      pathway: [
        { text: 'Agriculture & Natural Resources', color: 'grey darken-2' },
        { text: 'Arts, Media & Entertainment', color: 'grey darken-2' },
        { text: 'Building & Construction Trades', color: 'grey darken-2' },
        { text: 'Business & Finance', color: 'grey darken-2' },
        { text: 'Education, Childhood Development & Family Services', color: 'grey darken-2' },
        { text: 'Energy, Environment & Utilities', color: 'grey darken-2' },
        { text: 'Engineering & Architecture', color: 'grey darken-2' },
        { text: 'Fashion & Interior Design', color: 'grey darken-2' },
        { text: 'Health Science & Medical Technology', color: 'grey darken-2' },
        { text: 'Hospitality, Tourism & Recreation', color: 'grey darken-2' },
        { text: 'Information & Communication Technologies', color: 'grey darken-2' },
        { text: 'Manufacturing & Product Design', color: 'grey darken-2' },
        { text: 'Marketing, Sales & Service', color: 'grey darken-2' },
        { text: 'Public Services', color: 'grey darken-2' },
        { text: 'Transportation', color: 'grey darken-2' }
      ],

      pilotcityColors: [
        '#6eba80',
        '#3c9dcd',
        '#ea6764',
        '#eda1bf',
        '#fec34b',
        '#bdbdbd',
        '#ae90b0',
        '#f79961',
        '#000000'
      ],
      requiredTechOptions: [],
      requiredSkillOptions: [],
      rewardOptions: ['Paid Work Experience', 'Unpaid Work Experience'],
      rewardPresets: ['Paid Work Experience', 'Unpaid Work Experience'],
      pathwayOptions: [
        'Agriculture & Natural Resources',
        'Arts, Media & Entertainment',
        'Building & Construction Trades',
        'Business & Finance',
        'Education, Childhood Development & Family Services',
        'Energy, Environment & Utilities',
        'Engineering & Architecture',
        'Fashion & Interior Design',
        'Health Science & Medical Technology',
        'Hospitality, Tourism & Recreation',
        'Information & Communication Technologies',
        'Manufacturing & Product Design',
        'Marketing, Sales & Service',
        'Public Services',
        'Transportation'
      ],
      ages: range(13, 25),
      residencySearch: null,
      skillSearch: null,
      techSearch: null,
      rewardSearch: null,
      pathwaySearch: null,
      employerImage: [
        (value: any) => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!'
      ]
    });

    const dataProperties = [
      'programName',
      'programDesc',
      'ageRange',
      'requiredResidency',
      'requiredSkills',
      'requiredTech',
      'rewards',
      'pathways',
      'colors'
    ];
    const writeFields = dataProperties.map(prop => {
      if (!programDoc.value.data[prop]) {
        if (prop === 'programName' || prop === 'programDesc' || prop === 'colors')
          return { [prop]: '' };
        if (prop === 'ageRange') return { [prop]: [13, 24] };

        return { [prop]: [] };
      }
      return {};
    });
    programDoc.value = {
      ...programDoc.value,
      data: {
        ...Object.assign({}, ...writeFields),
        ...programDoc.value.data
      }
    };
    // Save Function Handling
    const status = ref('');
    const saveData = reactive({
      saveLoading: false
    });
    async function saveProgram() {
      saveData.saveLoading = true;
      try {
        programDoc.value.data.start_activity_status = ProgramActivityStatus.CompleteSetup;
        await programDoc.value.update();
        status.value = 'Success';
      } catch (err) {
        console.log(err);
        status.value = `${'Uh oh, try again later'}${err}`;
      }
      saveData.saveLoading = false;
    }
    watch(programDoc, () => {
      const storedCurSavedScope = JSON.parse(localStorage.getItem('curSavedScope'));
      if (storedCurSavedScope != null) programDoc.value.data.programDesc = storedCurSavedScope;
    });
    // Checkout Function Handling
    const licenseData = reactive({
      licenseLoading: false
    });
    const color = reactive({
      pilotcityColors: [
        ['#6eba80', '#3c9dcd', '#ea6764'],
        ['#eda1bf', '#fec34b', '#bdbdbd'],
        ['#ae90b0', '#f79961', '#000000']
      ],
      // ENTER ACTIVITY COLOR
      selectedColor: '#dedede'
    });
    const getColor = computed(() => {
      return color.selectedColor.substring(0, 7);
    });
    async function licenseFun() {
      licenseData.licenseLoading = true;
      try {
        await _props.licenseProgram();
        status.value = 'Success, onward...';
      } catch (err) {
        status.value = `Uh of, try again later ${err}`;
      }
      licenseData.licenseLoading = false;
    }
    const showScope = false;

    return {
      ...toRefs(options),
      programDoc,
      ...toRefs(saveData),
      saveProgram,
      ...toRefs(licenseData),
      licenseFun,
      status,
      ...toRefs(color),
      getColor,
      readonly,
      loader,
      setRegionValue,
      showScope
    };
  },
  methods: {
    useScope(text) {
      this.programDoc.data.programDesc = text;
      this.showScope = false;
    }
  }
});
