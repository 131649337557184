










































































































































































































import { defineComponent, ref, inject, computed, watchEffect } from '@vue/composition-api';
import { useDbState } from '@/store';
import axios from 'axios';
import Table from './TableView.vue';
import MongoDoc from './types';
import Instruct from './ModuleInstruct.vue';

export default defineComponent({
  name: 'ModuleDefault',
  components: {
    Table,
    Instruct
  },
  props: {
    studentDoc: {
      required: true,
      type: Object as () => MongoDoc
    }
  },
  setup(props, ctx) {
    const showInstructions = ref(true);
    const readonly = inject('readonly', false);
    const { user } = useDbState(['user']);
    const studentPref = ref({
      contactMethod: [],
      contactPreferences: [],
      hideEmail: false,
      hidePhone: false,
      schedulingLink: ''
    });
    const isDataAvailable = ref(false);
    const isSaving = ref(false);
    const isFormShow = ref(false);
    const isPersistent = ref(false);
    const table = ref();

    const isValid = computed(() => {
      if (
        studentPref.value.contactMethod.length ||
        studentPref.value.contactPreferences.length ||
        studentPref.value.schedulingLink
      ) {
        return true;
      }
      return false;
    });

    const adkIndex = props.studentDoc?.data.adks.findIndex(a => a.name === 'contact');

    const adkData = computed(() => {
      return props.studentDoc?.data?.adks[adkIndex];
    });

    const studentPayload = computed({
      get: () => studentPref.value,
      set: newVal => {
        studentPref.value = newVal;
      }
    });

    watchEffect(() => {
      if (props.studentDoc && props.studentDoc?.data && props.studentDoc?.data?.adks) {
        const Index = props.studentDoc?.data?.adks?.findIndex(a => a.name === 'contact');
        console.log(Index);
        let data;
        if (Index !== -1) {
          data = props.studentDoc?.data?.adks[Index];
          console.log(data);
          if (data?.directoryPreferences) isDataAvailable.value = true;
          else {
            isFormShow.value = true;
            isPersistent.value = true;
          }
          studentPayload.value = data?.directoryPreferences || studentPref.value;
        }
      }
    });

    function openPrefDialog() {
      if (adkData?.value && adkData.value?.directoryPreferences) {
        studentPayload.value = adkData?.value?.directoryPreferences;
        isDataAvailable.value = true;
      }
      isFormShow.value = true;
      isPersistent.value = false;
    }

    function saveData() {
      const API_ENDPOINT = process.env.VUE_APP_ADK_DIRECTORY_CREATE_USER;
      isSaving.value = true;
      const data = {
        student_id: props.studentDoc?._id.toString(),
        program_id: ctx.root._route.params.programId,
        contact_method: studentPref.value.contactMethod,
        contact_preferences: studentPref.value.contactPreferences,
        hide_email: studentPref.value.hideEmail,
        hide_phone: studentPref.value.hidePhone,
        scheduling_link: studentPref.value.schedulingLink
      };
      axios
        .post(API_ENDPOINT, data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        })
        .then(res => {
          isSaving.value = false;
          isFormShow.value = false;
          isDataAvailable.value = true;
          table.value.getTableData();
        })
        .catch(error => {
          isSaving.value = false;
        });
    }

    function updateData() {
      const API_ENDPOINT = process.env.VUE_APP_ADK_DIRECTORY_CREATE_USER;
      isSaving.value = true;
      const data = {
        student_id: props.studentDoc?._id.toString(),
        program_id: ctx.root._route.params.programId,
        contact_method: studentPref.value.contactMethod,
        contact_preferences: studentPref.value.contactPreferences,
        hide_email: studentPref.value.hideEmail,
        hide_phone: studentPref.value.hidePhone,
        scheduling_link: studentPref.value.schedulingLink
      };
      axios
        .patch(API_ENDPOINT, data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        })
        .then(res => {
          isSaving.value = false;
          isFormShow.value = false;
          table.value.getTableData();
        })
        .catch(error => {
          isSaving.value = false;
        });
    }

    return {
      showInstructions,
      studentPayload,
      studentPref,
      readonly,
      isDataAvailable,
      isSaving,
      saveData,
      updateData,
      isFormShow,
      isValid,
      table,
      openPrefDialog,
      isPersistent
    };
  }
});
