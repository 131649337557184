var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"font-weight-bold d-flex align-center justify-center mt-4"},[_c('h1',[_vm._v(_vm._s(_vm.hours.replace('.', ':'))+" Hours")])]),_c('div',{staticClass:"pa-6"},[_c('v-progress-linear',{attrs:{"value":_vm.progressValue,"height":"50","color":"green","stream":"","dark":"","rounded":""}},[[_c('strong',[_c('h4',[_vm._v(_vm._s(_vm.hours.replace('.', ':'))+" of "+_vm._s(_vm.minHours)+" Min. Hours Met")])])]],2)],1),_c('v-data-table',{attrs:{"headers":_vm.headers.TABLE_HEADER,"items":_vm.tableContents,"loading":_vm.isFetching,"sort-by":"resource","items-per-page":5},on:{"update:page":_vm.paginate},scopedSlots:_vm._u([{key:"item.timesheetFilledAt",fn:function(ref){
var item = ref.item;
return [_c('label',{class:item.timesheetFor === 'clock_in' ? 'table-item__green' : 'table-item__red'},[_c('h4',[_vm._v(_vm._s(_vm.moment(item.timesheetFilledAt).subtract('days').calendar()))])])]}},{key:"item.timesheetFor",fn:function(ref){
var item = ref.item;
return [(item.timesheetFor == 'clock_in')?_c('div',[_c('v-chip',{staticClass:"font-weight-bold",attrs:{"color":"green","dark":""}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-check-bold")]),_vm._v("Clock-in")],1)],1):_vm._e(),(item.timesheetFor == 'clock_out')?_c('div',[_c('v-chip',{staticClass:"font-weight-bold",attrs:{"color":"red","dark":""}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-check-bold")]),_vm._v("Clock-out")],1)],1):_vm._e()]}},{key:"item.shareUrl",fn:function(ref){
var item = ref.item;
return [(!item.system_generated)?_c('v-btn',{attrs:{"depressed":"","small":"","outlined":""},on:{"click":function($event){return _vm.openReceiptDialog(item.shareUrl)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-receipt")])],1):_vm._e()]}},{key:"item.interactionId",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [(index === 0 && _vm.currentPage === 1 && !item.system_generated && !_vm.isMonitor)?_c('v-btn',{staticClass:"white--text pa-2",attrs:{"color":"red","depressed":"","icon":""},on:{"click":function($event){_vm.confirmDelete = true}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1):_vm._e(),_c('v-dialog',{attrs:{"max-width":"fit-content"},model:{value:(_vm.confirmDelete),callback:function ($$v) {_vm.confirmDelete=$$v},expression:"confirmDelete"}},[_c('v-card',{staticClass:"pa-8"},[_c('div',{staticClass:"d-flex justify-center flex-column"},[_c('div',{staticClass:"d-flex justify-center"},[_c('h2',{staticClass:"mb-4"},[_vm._v("Are you sure?")])]),_c('div',{staticClass:"d-flex flex-row justify-center"},[_c('div',[_c('v-btn',{staticClass:"ma-3",attrs:{"x-large":"","rounded":"","outlined":"","color":"grey"},on:{"click":function($event){_vm.confirmDelete = false}}},[_vm._v("Close")])],1),_c('div',[_c('v-btn',{staticClass:"ma-3 font-weight-bold",attrs:{"rounded":"","loading":_vm.isDeleting,"x-large":"","dark":"","depressed":"","color":"red"},on:{"click":_vm.deleteEntry}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-trash-can")]),_vm._v("Delete")],1)],1)])])])],1)]}},{key:"item.transcription",fn:function(ref){
var item = ref.item;
return [(item.transcription)?_c('h6',[_vm._v(_vm._s(item.transcription))]):_c('h6',[_vm._v(_vm._s(item.timesheetText))])]}}])}),_c('v-dialog',{attrs:{"width":"80vw"},model:{value:(_vm.receiptUrlDialog),callback:function ($$v) {_vm.receiptUrlDialog=$$v},expression:"receiptUrlDialog"}},[_c('v-card',[_c('iframe',{staticStyle:{"border":"none","border-radius":"0px"},attrs:{"src":_vm.receiptUrl,"allow":"camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;","width":"100%","height":"550px"}})])],1),_c('div',{staticClass:"mt-12 d-flex justify-center align-center"},[_c('v-dialog',{attrs:{"width":"80vw"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"font-weight-bold mx-4 white--text",attrs:{"color":"green","dark":"","large":"","depressed":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-supervisor-circle")]),_vm._v("View Team Clock-in ")],1)]}}]),model:{value:(_vm.peerClockIns),callback:function ($$v) {_vm.peerClockIns=$$v},expression:"peerClockIns"}},[(_vm.interactionData)?_c('v-card',[_c('iframe',{staticStyle:{"border":"none","border-radius":"0px"},attrs:{"src":_vm.interactionData.clockInForm,"allow":"camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;","width":"100%","height":"550px"}})]):_vm._e()],1),_c('v-dialog',{attrs:{"width":"80vw"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"font-weight-bold mx-4 white--text",attrs:{"color":"red","dark":"","large":"","depressed":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-supervisor-circle-outline")]),_vm._v("View Team Clock-Out ")],1)]}}]),model:{value:(_vm.peerClockOuts),callback:function ($$v) {_vm.peerClockOuts=$$v},expression:"peerClockOuts"}},[(_vm.interactionData)?_c('v-card',[_c('iframe',{staticStyle:{"border":"none","border-radius":"0px"},attrs:{"src":_vm.interactionData.clockOutForm,"allow":"camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;","width":"100%","height":"550px"}})]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }