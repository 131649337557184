























































import { ref, watchEffect, watch } from '@vue/composition-api';
import ABtn from '@/components/atoms/AButton.vue';
import ATextField from '@/components/atoms/ATextInput.vue';

export default {
  components: {
    ABtn,
    ATextField
  },

  props: {
    data: {
      type: [Object, Array],
      default: () => {}
    },
    upload: {
      type: Function,
      default: () => {}
    },
    viewOnly: {
      type: Boolean,
      default: false
    }
  },

  setup(props, ctx) {
    const documents = ref([
      {
        url: ''
      }
    ]);

    function populate() {
      const obj = { url: '' };
      documents.value.push(obj);
    }

    function removeItem(index) {
      console.log(index);
      documents.value.splice(index, 1);
    }

    watchEffect(() => {
      if (props.data?.projectDocument) {
        documents.value = props.data?.projectDocument;
      }
    });

    watch(
      documents,
      val => {
        ctx.emit('set-document-links', documents.value);
      },
      { deep: true }
    );

    return { removeItem, populate, documents };
  }
};
