
















































































































import { ref, defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ModuleInstruct',

  setup() {
    // const programDoc = computed({
    //   get: () => props.value,
    //   set: newVal => {
    //     ctx.emit('input', newVal);
    //   }
    // });
    const boilerInstructions = ref([
      'Review each item',
      'Acknowledge and agree to each item',
      'Accept or decline offer'
    ]);
    const goal = ref(['To review, acknowledge & accept offer for internship']);
    // function populate() {
    //   boilerInstructions.value.push('');
    // }

    return { boilerInstructions, tutorialDialog: false, goal };
  }
});
