

















































import {
  computed,
  reactive,
  WritableComputedRef,
  toRefs,
  defineComponent
} from '@vue/composition-api';

export default defineComponent({
  name: 'ModuleInstruct',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    readonly: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: () => {
        return {
          description: '',
          instructions: ['', '', '']
        };
      }
    }
  },
  setup(props, { emit }) {
    const description: WritableComputedRef<string> = computed({
      get: () => props.value.description,
      set: newVal => emit('input', newVal)
    });
    const instructions: WritableComputedRef<string[]> = computed({
      get: () => props.value.instructions,
      set: newVal => emit('input', instructions.value.concat(newVal))
    });
    const updateData = reactive({
      updateDesc: (e: Event) => {
        const target = e.target as HTMLElement;
        description.value = target.innerText;
      },
      updateInstruction: (e: Event) => {
        const target = e.target as HTMLElement;
        instructions.value = [target.innerText];
      }
    });
    return {
      ...toRefs(updateData as any),
      description,
      instructions
    };
  }
});
