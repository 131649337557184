var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"d-flex flex-column justify-center",staticStyle:{"width":"100%"}},[_c('div',[_c('validation-provider',{attrs:{"rules":{
          regex:
            /(?:http|https):\/\/(?:www.)?linkedin.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w\-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w\-]*)?/,
          required: true
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('a-text-field',{attrs:{"rounded":"","readonly":_vm.viewOnly,"error-messages":errors,"hide-details":"","outlined":"","append-icon":"mdi-telegram","x-large":"","label":"Enter Linkedin Profile Link","placeholder":"Enter Linkedin Profile Link","icon-click":_vm.redirect},model:{value:(_vm.resumeLink),callback:function ($$v) {_vm.resumeLink=$$v},expression:"resumeLink"}})]}}],null,true)})],1),_c('div',[_c('a-btn',{staticClass:"font-weight-bold white--text",staticStyle:{"width":"100%"},attrs:{"disabled":invalid || _vm.viewOnly,"loading":_vm.isCreating,"x-large":"","color":"green","rounded":"","depressed":""},on:{"click":_vm.saveDigitalResume}},[_vm._v(" Save LinkedIn Profile Link ")])],1)]),(_vm.successMsg || _vm.errorMsg)?_c('v-alert',{staticClass:"mt-3 white--text presets__alert",attrs:{"color":_vm.successMsg ? 'green' : 'red',"dense":""}},[_vm._v(" "+_vm._s(_vm.msg)+" ")]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }