














































































import {
  computed,
  reactive,
  ref,
  toRefs,
  defineComponent,
  inject,
  PropType
} from '@vue/composition-api';
import '../styles/module.scss';
import MDefaultAdkAutoapply from '@/components/molecules/m-default-adk-autoapply.vue';
import MongoDoc from './types';
import Setup from './ModuleSetup.vue';
import Presets from './ModulePresets.vue';
import Default from './ModuleDefault.vue';
import Monitor from './ModuleMonitor.vue';

export default defineComponent({
  name: 'SignOff',

  components: {
    'module-setup': Setup,
    'module-presets': Presets,
    'module-preview': Default,
    'module-monitor': Monitor,
    MDefaultAdkAutoapply
  },
  props: {
    value: {
      required: true,
      type: Object as PropType<MongoDoc>
    },
    studentDoc: {
      required: false,
      type: Object as PropType<MongoDoc>,
      default: () => {}
    },
    userType: {
      required: true,
      type: String
    },
    userDoc: {
      required: true
    },
    operationMode: {
      type: Number
    }
  },
  setup(props: any, ctx: any) {
    const readonly = inject('readonly', false);
    const autoapplyDialog = ref(false);

    const programDoc = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    const studentDocument = computed({
      get: () => props.studentDoc,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    const index = programDoc.value.data.adks.findIndex(obj => {
      if (obj) {
        return obj.name === 'signoff';
      }
      return false;
    });
    if (index === -1) {
      const initSignOff = {
        name: 'signoff'
      };
      programDoc.value.data.adks.push(initSignOff);
      programDoc.value.update();
    }

    if (props.userType === 'participant') {
      const stdIndex = studentDocument.value?.data?.adks?.findIndex(obj => {
        if (obj) {
          return obj.name === 'signoff';
        }
        return false;
      });
      if (stdIndex === -1) {
        autoapplyDialog.value = true;
        const initSignOff = {
          name: 'signoff'
        };
        studentDocument.value?.data?.adks?.push(initSignOff);
        studentDocument.value.update();
      }
    }

    const moduleName = ref('Signoff');
    const page = reactive({
      subpages: ['Setup', 'Presets'],
      currentPage: 'Setup'
    });
    if (props.userType === 'organizer' && props.operationMode === 1) {
      page.currentPage = 'Presets';
    } else if (props.userType === 'organizer' && props.operationMode === 3) {
      page.currentPage = 'monitor';
    } else {
      page.currentPage = 'preview';
      page.subpages = [];
    }
    const getComponent = computed(() => {
      return `module-${page.currentPage.toLowerCase()}`;
    });
    const color = reactive({
      pilotcityColors: [
        ['#6eba80', '#3c9dcd', '#ea6764'],
        ['#eda1bf', '#fec34b', '#bdbdbd'],
        ['#ae90b0', '#f79961', '#000000']
      ],
      // ENTER ACTIVITY COLOR
      selectedColor: '#f79961'
    });
    const getColor = computed(() => {
      return color.selectedColor.substring(0, 7);
    });

    const menu = ref(false);

    return {
      ...toRefs(color),
      ...toRefs(page),
      moduleName,
      menu,
      getComponent,
      getColor,
      programDoc,
      readonly,
      autoapplyDialog,
      studentDocument
    };
  }
});
