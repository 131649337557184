var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-container',{staticClass:"module-edit"},[_c('div',{staticClass:"module-edit__container"},[_c('div',{staticClass:"headline font-weight-bold text-center mb-10"},[_vm._v("Enter organization info")]),_c('validation-provider',{attrs:{"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"prepend-icon":"mdi-music-accidental-sharp","rounded":"","label":"Employer Name","hint":"Enter your organization name","outlined":""},model:{value:(_vm.programDoc.data.programName),callback:function ($$v) {_vm.$set(_vm.programDoc.data, "programName", $$v)},expression:"programDoc.data.programName"}})]}}],null,true)}),_c('div',{staticClass:"module-setup__buildscope"}),_c('validation-provider',{attrs:{"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"error-messages":errors,"rounded":"","prepend-icon":"mdi-text-subject","counter":"280","outlined":"","label":"What is the project scope?","placeholder":"In a tweet or less, define your project scope for students"},model:{value:(_vm.programDoc.data.programDesc),callback:function ($$v) {_vm.$set(_vm.programDoc.data, "programDesc", $$v)},expression:"programDoc.data.programDesc"}})]}}],null,true)}),_c('validation-provider',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.items,"error-messages":errors,"rounded":"","outlined":"","flat":"","prepend-icon":"mdi-telegram","chips":"","multiple":"","label":"Project requirements"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_c('v-chip',_vm._b({staticClass:"no-whitespace",attrs:{"input-value":selected,"close":""},on:{"click":select,"click:close":function($event){return _vm.remove(item)}}},'v-chip',attrs,false),[_c('strong',[_vm._v(_vm._s(item))]),_vm._v("  "),_c('span')])]}}],null,true),model:{value:(_vm.programDoc.data.adks[_vm.index].projectReq),callback:function ($$v) {_vm.$set(_vm.programDoc.data.adks[_vm.index], "projectReq", $$v)},expression:"programDoc.data.adks[index].projectReq"}})]}}],null,true)}),_c('validation-provider',{attrs:{"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{staticClass:"mb-3",attrs:{"error-messages":errors,"rounded":"","prepend-icon":"mdi-book-open-variant","outlined":"","hint":"Enter a blurb from your website","label":"About your organization","placeholder":"In a paragraph or less, describe your organization"},model:{value:(_vm.programDoc.data.adks[_vm.index].aboutOrg),callback:function ($$v) {_vm.$set(_vm.programDoc.data.adks[_vm.index], "aboutOrg", $$v)},expression:"programDoc.data.adks[index].aboutOrg"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":({
            regex: /(?:http|https):\/\/(?:www.)(?:\w+|\d+)(?:.com)/
          },
          { required: true }),"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"outlined":"","label":"Website","placeholder":"https://www.employername.com/","prepend-icon":"mdi-search-web","rounded":""},model:{value:(_vm.programDoc.data.adks[_vm.index].resourceWeb),callback:function ($$v) {_vm.$set(_vm.programDoc.data.adks[_vm.index], "resourceWeb", $$v)},expression:"programDoc.data.adks[index].resourceWeb"}})]}}],null,true)}),_c('v-divider',{staticClass:"presets__divider"}),_c('div',{staticClass:"headline font-weight-bold text-center mb-10"},[_vm._v(" Short answers to key questions ")]),_c('validation-provider',{attrs:{"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"outlined":"","counter":"280","label":"What is the urgency of this problem?","placeholder":"In a tweet or less, answer the question for student participants.","prepend-icon":"mdi-hexagon-slice-2","rounded":""},model:{value:(_vm.programDoc.data.adks[_vm.index].interviewProblem),callback:function ($$v) {_vm.$set(_vm.programDoc.data.adks[_vm.index], "interviewProblem", $$v)},expression:"programDoc.data.adks[index].interviewProblem"}})]}}],null,true)}),_c('validation-provider',{attrs:{"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"outlined":"","counter":"280","label":"Who could be possible users of the solution created?","placeholder":"In a tweet or less, answer the question for student participants.","prepend-icon":"mdi-hexagon-slice-3","rounded":""},model:{value:(_vm.programDoc.data.adks[_vm.index].interviewSolution),callback:function ($$v) {_vm.$set(_vm.programDoc.data.adks[_vm.index], "interviewSolution", $$v)},expression:"programDoc.data.adks[index].interviewSolution"}})]}}],null,true)}),_c('validation-provider',{attrs:{"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"outlined":"","counter":"280","label":"What are the opportunities?","placeholder":"In a tweet or less, answer the question for student participants.","prepend-icon":"mdi-hexagon-slice-4","rounded":""},model:{value:(_vm.programDoc.data.adks[_vm.index].interviewOpportunity),callback:function ($$v) {_vm.$set(_vm.programDoc.data.adks[_vm.index], "interviewOpportunity", $$v)},expression:"programDoc.data.adks[index].interviewOpportunity"}})]}}],null,true)}),_c('validation-provider',{attrs:{"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"outlined":"","counter":"280","label":"What are the known challenges?","placeholder":"In a tweet or less, answer the question for student participants.","prepend-icon":"mdi-hexagon-slice-5","rounded":""},model:{value:(_vm.programDoc.data.adks[_vm.index].interviewChallenge),callback:function ($$v) {_vm.$set(_vm.programDoc.data.adks[_vm.index], "interviewChallenge", $$v)},expression:"programDoc.data.adks[index].interviewChallenge"}})]}}],null,true)}),_c('validation-provider',{attrs:{"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"outlined":"","counter":"280","label":"Why are you requesting projects from students?","placeholder":"In a tweet or less, answer the question for student participants.","prepend-icon":"mdi-hexagon-slice-6","rounded":""},model:{value:(_vm.programDoc.data.adks[_vm.index].interviewRequest),callback:function ($$v) {_vm.$set(_vm.programDoc.data.adks[_vm.index], "interviewRequest", $$v)},expression:"programDoc.data.adks[index].interviewRequest"}})]}}],null,true)}),_c('div',{staticClass:"module-setup__buildscope"}),_c('v-divider',{staticClass:"presets__divider"}),_c('div',{staticClass:"headline font-weight-bold text-center mb-10"},[_vm._v("Share social media links")]),_c('div',{staticClass:"d-flex flex-row pa-0 justify-center",class:_vm.$vuetify.breakpoint.xs ? 'flex-wrap' : ''},[_c('div',{staticClass:"d-flex media-link-wrapper",class:_vm.$vuetify.breakpoint.xs ? 'flex-wrap' : 'flex-column'},[_c('div',[_c('validation-provider',{attrs:{"rules":{
                regex:
                  /^(?:(?:http|https):\/\/)?(?:www.)?instagram.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w\-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w\-]*)?/
              },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ma-2",attrs:{"error-messages":errors,"outlined":"","label":"Instagram URL","placeholder":"https://www.instagram.com/username","append-icon":"mdi-instagram","rounded":""},model:{value:(_vm.programDoc.data.adks[_vm.index].resourceInsta),callback:function ($$v) {_vm.$set(_vm.programDoc.data.adks[_vm.index], "resourceInsta", $$v)},expression:"programDoc.data.adks[index].resourceInsta"}})]}}],null,true)})],1),_c('div',[_c('validation-provider',{attrs:{"rules":{
                regex:
                  /(?:http|https):\/\/(?:www.)?linkedin.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w\-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w\-]*)?/
              },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ma-2",attrs:{"error-messages":errors,"outlined":"","label":"LinkedIn URL","placeholder":"https://www.linkedin.com/username","append-icon":"mdi-linkedin","rounded":""},model:{value:(_vm.programDoc.data.adks[_vm.index].resourceLinkedIn),callback:function ($$v) {_vm.$set(_vm.programDoc.data.adks[_vm.index], "resourceLinkedIn", $$v)},expression:"programDoc.data.adks[index].resourceLinkedIn"}})]}}],null,true)})],1),_c('div',[_c('validation-provider',{attrs:{"rules":{
                regex:
                  /^(?:(?:http|https):\/\/)?(?:www.)?facebook.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w\-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w\-]*)?/
              },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ma-2",attrs:{"error-messages":errors,"outlined":"","label":"Facebook URL","placeholder":"https://www.facebook.com/username","append-icon":"mdi-facebook","rounded":""},model:{value:(_vm.programDoc.data.adks[_vm.index].resourceFacebook),callback:function ($$v) {_vm.$set(_vm.programDoc.data.adks[_vm.index], "resourceFacebook", $$v)},expression:"programDoc.data.adks[index].resourceFacebook"}})]}}],null,true)})],1)]),_c('div',{staticClass:"d-flex media-link-wrapper",class:_vm.$vuetify.breakpoint.xs ? 'flex-wrap' : 'flex-column'},[_c('div',[_c('validation-provider',{attrs:{"rules":{
                regex:
                  /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/
              },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ma-2",attrs:{"error-messages":errors,"outlined":"","label":"YouTube URL","placeholder":"https://www.youtube.com/username","append-icon":"mdi-youtube","rounded":""},model:{value:(_vm.programDoc.data.adks[_vm.index].resourceYouTube),callback:function ($$v) {_vm.$set(_vm.programDoc.data.adks[_vm.index], "resourceYouTube", $$v)},expression:"programDoc.data.adks[index].resourceYouTube"}})]}}],null,true)})],1),_c('div',[_c('validation-provider',{attrs:{"rules":{
                regex:
                  /(?:http|https):\/\/(?:www.)?twitter.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w\-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w\-]*)?/
              },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ma-2",attrs:{"error-messages":errors,"outlined":"","label":"Twitter URL","placeholder":"https://twitter.com/username","append-icon":"mdi-twitter","rounded":""},model:{value:(_vm.programDoc.data.adks[_vm.index].resourceTwitter),callback:function ($$v) {_vm.$set(_vm.programDoc.data.adks[_vm.index], "resourceTwitter", $$v)},expression:"programDoc.data.adks[index].resourceTwitter"}})]}}],null,true)})],1),_c('div',[_c('validation-provider',{attrs:{"rules":{
                regex: /^((?:https?:)?\/\/)?((?:www)\.)?((?:(.*)\.com))(.*)/
              },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ma-2",attrs:{"error-messages":errors,"outlined":"","label":"Get Started Folder","placeholder":"https://drive.google.com/foldername","append-icon":"mdi-folder-google-drive","rounded":""},model:{value:(_vm.programDoc.data.adks[_vm.index].resourceDrive),callback:function ($$v) {_vm.$set(_vm.programDoc.data.adks[_vm.index], "resourceDrive", $$v)},expression:"programDoc.data.adks[index].resourceDrive"}})]}}],null,true)})],1)])]),_c('div',{staticClass:"module-default__scope mt-12"},[_c('v-btn',{staticClass:"setup-adk-scope",attrs:{"disabled":invalid || _vm.readonly,"loading":_vm.loading,"x-large":"","rounded":"","depressed":"","outlined":""},on:{"click":function($event){return _vm.process()}}},[_vm._v("Save")])],1),(_vm.success || _vm.error)?_c('v-alert',{staticClass:"mt-3 white--text presets__alert",attrs:{"color":_vm.success ? 'green' : 'red',"dense":""}},[_vm._v(_vm._s(_vm.message))]):_vm._e()],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }