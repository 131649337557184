













































import { defineComponent, ref, PropType, computed } from '@vue/composition-api';
import { ValidationProvider } from 'vee-validate';
import { HEADER } from './const';
import MongoDoc from './types';

export default defineComponent({
  name: 'TableView',
  components: {
    ValidationProvider
  },
  props: {
    value: {
      required: false,
      type: Object as PropType<MongoDoc>,
      default: () => {}
    },
    studentDoc: {
      required: true,
      type: Object as () => MongoDoc
    },
    userType: {
      required: true,
      type: String
      // participant: '',
      // organizer: '',
      // stakeholder: ''
    }
  },

  setup(props) {
    // props
    const observer = ref(null);
    const adkData = computed(() => props.studentDoc.data.adks.find(adk => adk.name === 'offer'));
    const disabled = computed(
      () => !!(adkData.value?.completed || props.userType === 'stakeholder')
    );
    const offerDetails = computed(() => props.studentDoc.data.offerDetails);

    const tableContents = ref(
      [
        {
          terms: 'Your Project / Task(s)',
          questionaries: `${offerDetails.value.internshipProject}`
        },
        {
          terms: 'Position Title',
          questionaries: `I acknowledge my position title will be: ${offerDetails.value.position}`
        },

        {
          terms: 'Compensation',
          questionaries: `I acknowledge I will receive the following compensation, custom arrangement or gift: ${offerDetails.value.compensation} `
        },

        {
          terms: 'Drivers License / Car / Transport',
          questionaries: `I acknowledge a drivers license, car and/or transport to workplace is ${
            offerDetails.value.licenseRequirement ? '' : 'not'
          } required `
        },

        {
          terms: 'Minimum Hours',
          questionaries: `I acknowledge I will work minimum: ${offerDetails.value.minHours} hours`
        },
        {
          terms: 'Maximum Hours',
          questionaries: `I acknowledge I will work maximum: ${offerDetails.value.maxHours} hours`
        },

        {
          terms: 'Start Date',
          questionaries: `I acknowledge the start date will be: ${offerDetails.value.internshipStart}`
        },
        {
          terms: 'Deadline Date',
          questionaries: `I acknowledge the deadline for my work will be: ${offerDetails.value.internshipEnd}`
        },

        {
          terms: 'Continuation Possibility',
          questionaries: `I acknowledge there will ${
            offerDetails.value.continuation ? '' : 'not'
          } be a continuation or re-hire possibility`
        },

        {
          terms: 'Employer-of-Record',
          questionaries: `I acknowledge PilotCity will ${
            offerDetails.value.employerRecord ? '' : 'not'
          } be the employer-of-record`
        }
      ].map(value => ({ ...value, complete: !!adkData?.value?.offerStatus }))
    );
    // const { adkData } = getModAdk(props, ctx.emit, 'offer', {});

    const checkedAllTerms = computed(() => tableContents.value.every((obj: any) => obj.complete));

    return {
      header: ref(HEADER),
      tableContents,
      checkedAllTerms,
      disabled,
      observer,
      offerDetails
    };
  }
});
