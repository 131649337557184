
















































































































import { ref, computed, defineComponent, PropType } from '@vue/composition-api';

export default {
  name: 'ModuleInstruct',

  setup() {
    // const programDoc = computed({
    //   get: () => props.value,
    //   set: newVal => {
    //     ctx.emit('input', newVal);
    //   }
    // });
    const boilerInstructions = ref([
      'Plan and prep your demonstration and presentation video',
      'Record video with tool of choice and upload to YouTube. Make sure video is under the maximum duration set by employer',
      'Set YouTube video to "Unlisted" or "Public" and insert link to verify and deliver video'
    ]);
    const goal = ref([
      'To demonstrate and present your final project with an uploaded YouTube video'
    ]);
    // function populate() {
    //   boilerInstructions.value.push('');
    // }

    return { boilerInstructions, tutorialDialog: false, goal };
  }
};
