






















































































import { defineComponent, ref, PropType, inject } from '@vue/composition-api';
import CommentSection from './CommentSection.vue';
import { MongoDoc, Question } from './types';
import {
  questionIsBookmarked,
  questionIsDisliked,
  questionIsLiked,
  questionIsFlagged
} from './helpers';

export default defineComponent({
  name: 'Question',
  components: { CommentSection },

  props: {
    question: {
      required: true,
      type: Object as PropType<Question>
    },
    studentAdkData: {
      required: true,
      type: Object
    },
    userType: {
      required: true,
      type: String
      // participant: '',
      // organizer: '',
      // stakeholder: ''
    },
    userDoc: {
      required: false,
      type: Object as PropType<MongoDoc>,
      default: () => {}
    }
  },
  setup(props) {
    const readonly = inject('readonly', false);
    const openPanels = ref<number | null>(null);
    const toggleComments = () => {
      if (openPanels.value) openPanels.value = null;
      else openPanels.value = 1;
    };
    return {
      questionIsLiked,
      questionIsBookmarked,
      questionIsDisliked,
      questionIsFlagged,
      openPanels,
      toggleComments,
      readonly
    };
  }
});
