































































































import { reactive, defineComponent, ref, toRefs, computed, PropType } from '@vue/composition-api';
// import Instruct from './ModuleInstruct.vue';
import { group, required, deliverable, endEarly } from './const';
import MongoDoc from './types';
// import gql from 'graphql-tag';

export default defineComponent({
  name: 'ModulePresets',
  components: {
    // Instruct
  },
  props: {
    value: {
      required: true,
      type: Object as PropType<MongoDoc>
    }
  },

  setup(props, ctx) {
    const programDoc = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    const index = programDoc.value.data.adks.findIndex(function findResearchObj(obj) {
      return obj.name === 'research';
    });

    const initResearchPresets = {
      defaultActivity: {
        groupActivity: 'Project',
        requiredActivity: 'Yes',
        deliverableActivity: 'No',
        endEarlyActivity: 'No',
        required: false
      }
    };

    programDoc.value.data.adks[index] = {
      ...initResearchPresets,
      ...programDoc.value.data.adks[index]
    };

    // Handle Save
    const loading = ref(false);
    const errormsg = ref('');
    async function save() {
      loading.value = true;
      try {
        await programDoc.value.update();
        errormsg.value = '';
      } catch (err) {
        errormsg.value = 'Could not save';
      }
      loading.value = false;
    }

    const presets = reactive({
      group,
      required,
      deliverable,
      endEarly
    });

    const setupInstructions = ref({
      description: '',
      instructions: ['', '', '']
    });
    return {
      ...toRefs(presets),
      setupInstructions,
      loading,
      save,
      errormsg,
      index,
      programDoc
    };
  }
});
