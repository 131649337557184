





































































































































import { ref, reactive, toRefs, PropType, defineComponent, computed } from '@vue/composition-api';
import axios from 'axios';
import { useDbState, useUserActions } from '@/store';
import { MongoDoc } from './types';

const API_ENDPOINT = 'https://discord.com/api/users/@me';

export default defineComponent({
  name: 'ModuleDefault',
  props: {
    value: {
      required: true,
      type: Object as PropType<MongoDoc>
    },
    userDoc: {
      required: true,
      type: Object as PropType<MongoDoc>
    },
    studentDoc: {
      required: false,
      type: Object as PropType<MongoDoc>,
      default: () => {}
    },
    teamDoc: {
      required: false,
      type: Object as PropType<MongoDoc>,
      default: () => {}
    },
    userType: {
      required: true,
      type: String
      // participant: '',
      // organizer: '',
      // stakeholder: ''
    },
    mongoUser: {
      required: false,
      type: Object,
      default: () => {}
    }
  },
  setup(props) {
    const { user }: any = useDbState(['user']);
    const { refreshDiscordAccessToken } = useUserActions(['refreshDiscordAccessToken']);
    const { createDiscordChannels } = useUserActions(['createDiscordChannels']);
    const { addMembersToDiscordRole } = useUserActions(['addMembersToDiscordRole']);
    const { sendMessageInDiscord } = useUserActions(['sendMessageInDiscord']);
    const { getAdkIndexByName } = useUserActions(['getAdkIndexByName']);
    const isDisconnected = ref(false);
    const isFetching = ref(false);
    const isJoining = ref(false);
    const DISCORD_AUTH = ref(
      `https://discord.com/api/oauth2/authorize?response_type=code&client_id=${process.env.VUE_APP_DISCORD_CLIENT_ID}&redirect_uri=${process.env.VUE_APP_DISCORD_REDIRECT}&response_type=code&scope=identify`
    );
    const DISCORD_REGISTER = ref(
      `https://discord.com/register?redirect_to=%2Foauth2%2Fauthorize%3Fclient_id%3D${process.env.VUE_APP_DISCORD_CLIENT_ID}%26redirect_uri%3D${process.env.VUE_APP_DISCORD_REDIRECT}%26response_type%3Dcode%26scope%3Didentify%2520guilds.join%2520gdm.join%2520email`
    );
    const workspaceIndex = props.value?.data?.adks?.findIndex(obj => obj.name === 'workspace');
    const workspaceObj = props.value?.data?.adks[workspaceIndex];
    const isToken = computed(() => {
      if (user.value.workspaceDiscordInfo && user.value.workspaceDiscordInfo.discordAccessToken) {
        return true;
      }
      return false;
    });

    async function storeProgramId() {
      localStorage.setItem('programId', props.value.data._id.toString());
      localStorage.setItem('userType', 'Employer');
      localStorage.setItem('adkIndexValue', await getAdkIndexByName('workspace'));
    }

    const getUser = async () => {
      isFetching.value = true;
      try {
        const res = await axios.get(API_ENDPOINT, {
          headers: {
            Authorization: `Bearer ${user.value.workspaceDiscordInfo.discordAccessToken}`
          }
        });
        isFetching.value = false;
      } catch (err: any) {
        if (err.response.status === 401) {
          const refreshToken = await refreshDiscordAccessToken({
            refreshToken: user.value.workspaceDiscordInfo.discordRefreshToken
          });
          if (!refreshToken.error) {
            isFetching.value = false;
          } else {
            isFetching.value = false;
            isDisconnected.value = true;
          }
        } else {
          isFetching.value = false;
          isDisconnected.value = true;
        }
      }
    };

    const discordData = ref();
    async function joinCommunity() {
      try {
        isJoining.value = true;
        const resp = await createDiscordChannels({
          program_id: props.value.data._id.toString(),
          program_name: props.value.data.programName,
          access_token: user.value.workspaceDiscordInfo.discordAccessToken,
          discord_id: user.value.workspaceDiscordInfo.discordId,
          user_type: 'Employer'
        });
        if (resp.data) {
          discordData.value = resp.data.data;
          await addMembersToDiscordRole({
            program_id: props.value.data._id.toString(),
            discord_access_token: user.value.workspaceDiscordInfo.discordAccessToken,
            discord_user_name: user.value.workspaceDiscordInfo.discordUsername,
            discord_id: user.value.workspaceDiscordInfo.discordId
          });
        }
        isJoining.value = false;
      } catch (err) {
        console.log(err);
        isJoining.value = false;
      }
    }

    const isSendMessage = ref(false);
    const message = ref();
    const snackbarMessge = ref();
    const snackbar = ref(false);
    async function sendMessage() {
      try {
        isSendMessage.value = true;
        await addMembersToDiscordRole({
          program_id: props.value.data._id.toString(),
          discord_access_token: user.value.workspaceDiscordInfo.discordAccessToken,
          discord_user_name: user.value.workspaceDiscordInfo.discordUsername,
          discord_id: user.value.workspaceDiscordInfo.discordId
        });
        await sendMessageInDiscord({
          channel_id: workspaceObj?.discordData?.channel?.id || discordData?.value?.channel?.id,
          access_token: user.value.workspaceDiscordInfo.discordAccessToken,
          message: message.value
        });
        message.value = '';
        isSendMessage.value = false;
        snackbar.value = true;
        snackbarMessge.value = 'Message sent Successfully';
      } catch (err) {
        console.log(err);
        isSendMessage.value = false;
      }
    }

    if (isToken.value) {
      // when a user already has an access token
      getUser();
    }

    return {
      DISCORD_AUTH,
      DISCORD_REGISTER,
      joinCommunity,
      isJoining,
      storeProgramId,
      user,
      isFetching,
      isDisconnected,
      discordData,
      workspaceObj,
      isSendMessage,
      message,
      sendMessage,
      snackbar,
      snackbarMessge
    };
  }
});
