































































































































import { defineComponent, ref, computed, PropType } from '@vue/composition-api';
import { loading } from 'pcv4lib/src';
import axios from 'axios';
import _ from 'lodash';
import MongoDoc from './types';
import loader from './loaderSvg.vue';

// import gql from 'graphql-tag';
// TODO: move this to @pilocity/shared
const enum ProgramActivityStatus {
  NeedSetup = 'need_setup',
  CompleteSetup = 'complete_setup',
  Standard = 'standard'
}

export default defineComponent({
  name: 'ModuleSetup',
  components: {
    loader
  },
  props: {
    value: {
      required: true,
      type: Object as PropType<MongoDoc>
    }
  },
  setup(props, ctx) {
    const programDoc = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    let index = programDoc.value.data.adks.findIndex(obj => {
      if (obj) {
        return obj.name === 'research';
      }
      return false;
    });
    if (index === -1)
      index =
        programDoc.value.data.adks.push({
          name: 'research'
        }) - 1;
    const initResearchSetup = {
      researchLinks: [
        {
          resource: '',
          link: '',
          required: true
        }
      ]
    };

    programDoc.value.data.adks[index] = {
      ...initResearchSetup,
      ...programDoc.value.data.adks[index]
    };

    // Fetch Metainfo
    const isSearching = ref(false);
    const currentIndex = ref(-1);
    const fetchMetaInfo = _.debounce(async (val, indx) => {
      currentIndex.value = indx;
      const API_ENDPOINT = process.env.VUE_APP_META_GRABBER_ENDPOINT;
      try {
        isSearching.value = true;
        const resp = await axios.get(API_ENDPOINT, {
          params: {
            url: val
          },
          headers: {
            'Content-Type': 'application/json'
          }
        });
        if (resp.data.data) {
          programDoc.value.data.adks[index].researchLinks[indx].resource = resp.data.data.title;
        }
        isSearching.value = false;
      } catch (err) {
        console.log(err);
        isSearching.value = false;
      }
    }, 500);

    // Handle Save
    const body = ref(0);
    function populate() {
      programDoc.value.data.adks[index].researchLinks.push({
        resource: '',
        link: '',
        required: true
      });
      body.value += 1;
    }
    function removeItem(itemIndex: number) {
      programDoc.value.data.adks[index].researchLinks.splice(itemIndex, 1);
      body.value += 1;
    }
    return {
      populate,
      fetchMetaInfo,
      isSearching,
      currentIndex,
      index,
      programDoc,
      body,
      removeItem,
      ...loading(
        () =>
          programDoc.value.update(() => ({
            isComplete: true,
            adkIndex: index,
            status: ProgramActivityStatus.CompleteSetup
          })),
        'Success',
        'Try again later'
      )
    };
  }
});
