























































import { ref, watchEffect } from '@vue/composition-api';
import ABtn from '@/components/atoms/AButton.vue';
import ATextField from '@/components/atoms/ATextInput.vue';
import axios from 'axios';

export default {
  components: {
    ABtn,
    ATextField
  },

  props: {
    data: {
      type: [Object, Array],
      default: () => {}
    },
    viewOnly: {
      type: Boolean,
      default: false
    }
  },

  setup(props, ctx) {
    const successMsg = ref(false);
    const errorMsg = ref(false);
    const msg = ref('');
    const isCreating = ref(false);

    const resumeLink = ref('');

    function redirect() {
      if (resumeLink.value) window.open(resumeLink.value, '_blank');
    }

    async function saveDigitalResume() {
      isCreating.value = true;
      try {
        const data = {
          projectDigitalResume: resumeLink.value
        };
        console.log(data);
        const ENDPOINT_API = process.env.VUE_APP_DELIVERABLE;
        const res = await axios.patch(`${ENDPOINT_API}?deliverable_id=${props.data._id}`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        });
        if (res.data.data) {
          successMsg.value = true;
          msg.value = res.data.message;
          isCreating.value = false;
        }
      } catch (error) {
        console.log(error);
        errorMsg.value = true;
        msg.value = 'Something went wrong';
        isCreating.value = false;
      }
    }

    watchEffect(() => {
      if (props.data.projectDigitalResume) {
        resumeLink.value = props.data.projectDigitalResume;
      }
    });

    return { successMsg, errorMsg, msg, resumeLink, isCreating, redirect, saveDigitalResume };
  }
};
