import { render, staticRenderFns } from "./ModulePresets.vue?vue&type=template&id=cbd16876&"
import script from "./ModulePresets.vue?vue&type=script&lang=ts&"
export * from "./ModulePresets.vue?vue&type=script&lang=ts&"
import style0 from "./ModulePresets.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VAlert,VBtn,VContainer,VSelect})
