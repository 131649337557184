










import { reactive, toRefs } from '@vue/composition-api';

export default {
  name: 'ModuleSetup',

  setup() {
    const setup = reactive({});
    return {
      ...toRefs(setup)
    };
  }
};
