
















































































































import { ref, computed, defineComponent, PropType } from '@vue/composition-api';

export default {
  name: 'ModuleInstruct',

  setup() {
    // const programDoc = computed({
    //   get: () => props.value,
    //   set: newVal => {
    //     ctx.emit('input', newVal);
    //   }
    // });
    const boilerInstructions = ref([
      'Write your answers to the following questions and topics',
      'Save your drafts and iterate',
      'When satisfied, mark one as your final draft'
    ]);
    const goal = ref(['To brainstorm, collect your ideas, and iterate on your project overview']);
    // function populate() {
    //   boilerInstructions.value.push('');
    // }

    return { boilerInstructions, tutorialDialog: false, goal };
  }
};
