var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-container',{staticClass:"module-default pa-0"},[_c('div',{staticClass:"module-default__instructions"},[_c('v-expansion-panels',{staticClass:"module-default__instructions",attrs:{"flat":""},model:{value:(_vm.showInstructions),callback:function ($$v) {_vm.showInstructions=$$v},expression:"showInstructions"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{directives:[{name:"show",rawName:"v-show",value:(_vm.showInstructions),expression:"showInstructions"}],staticClass:"pa-0",attrs:{"hide-actions":""},on:{"click":function($event){_vm.showInstructions = true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var open = ref.open;
return [_c('v-scroll-y-transition',{attrs:{"hide-on-leave":""}},[(!open)?_c('div',{staticClass:"d-flex flex-column justify-center"},[_c('v-icon',{staticClass:"d-flex justify-center",attrs:{"color":"black"}},[_vm._v(" mdi-chevron-down ")]),_c('div',{staticClass:"module-default__collapse-title"},[_vm._v("INSTRUCTIONS")])],1):_vm._e()])]}}],null,true)}),_c('v-expansion-panel-content',[_c('Instruct',{attrs:{"readonly":""}}),_c('div',{on:{"click":function($event){_vm.showInstructions = true}}},[_c('div',{staticClass:"module-default__collapse-title"},[_vm._v("CLOSE")]),_c('v-icon',{staticClass:"d-flex justify-center",attrs:{"color":"black"}},[_vm._v(" mdi-chevron-up ")])],1)],1)],1)],1)],1),_c('v-progress-linear',{staticClass:"mt-3",attrs:{"color":"#dedede","height":"2","value":"100","buffer-value":"100","stream":""}}),_c('v-container',{staticClass:"d-flex pa-5 mt-12",class:_vm.$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'},[_c('validation-provider',{staticClass:"module-default__youtube-verify-button",attrs:{"rules":{
          regex:
            /^((?:https?:)?\/\/)((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/,
          required: true
        },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-text-field',{attrs:{"readonly":!_vm.adkTeamData && _vm.userType !== 'organizer',"error-messages":errors.concat(_vm.apiErrors),"label":("Enter " + (_vm.adkData.videoMaxLength) + "-Minute or less YouTube Video"),"rounded":"","outlined":"","placeholder":"https://youtu.be/yourvideocode","prepend-inner-icon":"mdi-youtube"},on:{"input":function($event){_vm.apiErrors = []}},model:{value:(_vm.link),callback:function ($$v) {_vm.link=$$v},expression:"link"}})]}}],null,true)}),_c('v-btn',{staticClass:"module-default__youtube-verify-button ml-3 white--text perform-adk-demo-verify",attrs:{"loading":_vm.verifyLoading,"disabled":invalid || (!_vm.adkTeamData && _vm.userType !== 'organizer'),"rounded":"","x-large":"","color":"#eda1bf","depressed":""},on:{"click":function($event){return _vm.verifyLink()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-youtube")]),_vm._v("Verify Link")],1)],1),(!_vm.submittedVideo)?_c('div',{staticClass:"d-flex headline font-weight-bold justify-center align-center module-default__youtube"},[_vm._v(" No video yet ")]):_vm._e(),(_vm.submittedVideo)?_c('v-btn',{staticClass:"mb-4 mt-4 video-name",style:({
        width: _vm.$vuetify.breakpoint.xs ? '90% !important' : ' ',
        height: 'fit-content',
        fontSize: _vm.$vuetify.breakpoint.xs ? '16px !important' : ' ',
        padding: '5px 12px'
      }),attrs:{"href":("https://youtu.be/" + (_vm.submittedVideo.id)),"rounded":"","color":"pink","dark":"","depressed":"","small":"","label":""}},[_vm._v(" "+_vm._s(_vm.submittedVideo.snippet.title)+" "),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v("mdi-open-in-new")])],1):_vm._e(),(_vm.submittedVideo)?_c('iframe',{staticClass:"module-default__youtube",attrs:{"src":("https://www.youtube.com/embed/" + (_vm.submittedVideo.id)),"width":"100%","height":"330","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}}):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }