







































































































































































































import { defineComponent, reactive, ref, set, toRefs, computed } from '@vue/composition-api';
import { ObjectId } from 'bson';
import { useUserGetters, useDbGetters, useDbState } from '@/store';
import Loading from '@/components/Loading.vue';
import calculateProgramCompletion from '@/utils/calculateProgramCompletion';
import { useVideoAsk } from '@/composables/useVideoAsk';
import { CommentAuthor } from '@/@types/comment.d';
import { OperationMode } from '@/constants/operationMode';
import Bar from './Bar.vue';
import ListView from './components/TableView.vue';
import Guide from '../Guide/Guide.vue';
import OfferCard from './components/OfferCard.vue';
import SignOffIntern from './components/SignOffIntern.vue';
import { StudentStatus, getStudentStatus } from './studentStatus';

export default defineComponent({
  name: 'Monitor',
  components: {
    'guide-bar': Bar,
    ListView,
    Loading,
    Guide,
    OfferCard,
    SignOffIntern
  },
  setup(_, ctx) {
    const state = reactive({
      students: [] as Record<string, any>[],
      programs: [] as Record<string, any>[],
      selectedStudent: { adks: [], programAdks: [], teamAdks: [] } as
        | Record<string, any>
        | undefined,
      activityFilter: '',
      currentView: 'participant',
      monitorRoute: {
        page: 1,
        monitorOrganizerView: false
      }
    });
    const signOffDialog = ref(false);
    const signedOffPopup = ref(false);
    const bar = ref();

    const { user } = useDbState(['user']);
    const { getObjectId, getUser } = useUserGetters(['getObjectId', 'getUser']);
    const { collection } = useDbGetters(['collection']);
    const { functions } = useDbGetters(['functions']);
    let studentDocs;
    async function loadData() {
      const autoMonitors = await collection.value!('Monitor').find({
        owner: getObjectId.value
      });
      const autoMonitoredStudents: ObjectId[] = [];
      autoMonitors.forEach(obj => {
        autoMonitoredStudents.push(...obj.monkey.map(student => student.userID));
      });
      const studentQuery = {
        stakeholders: { $exists: true, $ne: null },
        program_id: { $exists: true, $ne: null } as Record<string, any> | ObjectId,
        $or: [
          { stakeholders: getObjectId.value },
          { stakeholders: user.value?.email },
          { participant_id: { $in: autoMonitoredStudents } }
        ]
      };
      if (ctx.root.$route.query && ctx.root.$route.query.program)
        // Organizer view of Monitor
        studentQuery.program_id = new ObjectId(ctx.root.$route.query.program as string);
      studentDocs = await collection.value!('Student').find(studentQuery);
      // collect ids
      const studentIds = studentDocs.map(doc => doc.participant_id);
      const programIds = studentDocs.map(doc => doc.program_id);
      // collect docs corresponding to those ids
      const userDocs = await collection.value!('User').find({
        _id: { $in: studentIds }
      });
      const programDocs = await collection.value!('Program').find({
        _id: { $in: programIds }
      });
      const teamDocs = await collection.value!('ProgramTeam').find({
        'members._id': { $in: studentIds }
      });
      const studentPortfolios = await collection.value!('StudentPortfolio').find({
        _id: { $in: studentIds }
      });
      const availableStds = studentDocs.filter(std => {
        const users = userDocs.map(st => st._id.toString());
        return users.includes(std.participant_id.toString());
      });
      // collect data from all docs into one object
      state.students = availableStds.map(studentDoc => {
        const userDoc = userDocs.find(doc => doc._id.equals(studentDoc.participant_id));
        const programDoc = programDocs.find(doc => doc._id.equals(studentDoc.program_id));
        const teamDoc = teamDocs.find(
          doc =>
            doc.members.some(member => member._id.equals(studentDoc.participant_id)) &&
            doc.program_id.equals(studentDoc.program_id)
        );
        const studentPortfolio = studentPortfolios.find(doc =>
          doc._id.equals(studentDoc.participant_id)
        );
        return {
          ...studentDoc,
          program: programDoc ? programDoc?.programName : 'No name',
          programAdks: programDoc ? programDoc.adks : [],
          defaultOffer: programDoc
            ? programDoc.adks.find(adk => adk.name === 'offer')?.offer?.[0]
            : 'No offer',
          newOffer: {},
          teamName: teamDoc ? teamDoc.name : 'No Team',
          teamAdks: teamDoc ? teamDoc.adks : [],
          studentStatus: getStudentStatus(studentDoc),
          // controllable if current user is organizer of the student's program
          controllable: programDoc
            ? programDoc.organizers.some(userId => userId.equals(getObjectId.value))
            : 'No data',
          // make offer stuff
          showMakeOfferForm: false,
          showMakeOfferDialog: false,
          isMakingOffer: false,
          isRejecting: false,
          signedOff: false,
          makingOfferFailed: false,
          makingOfferFailedReason: '',
          makingOfferFinishedMessage: '',
          school: studentPortfolio ? studentPortfolio.school : 'No School',
          grade: studentPortfolio ? studentPortfolio.grade : 'No Grade',
          name: `${userDoc?.firstName} ${userDoc?.lastName}`,
          email: userDoc.email,
          profile: userDoc?.profile
        }; // * add other fields from userDoc/studentPortfolio needed here
      });
      state.programs = programDocs;
      if (ctx.root.$route.params.participantId)
        // select student from route params if it was provided
        state.selectedStudent = state.students.find(
          student => student._id.toString() === ctx.root.$route.params.participantId
        );
      else [state.selectedStudent] = state.students;
    }

    const studentInfo = student => [
      `${student.grade}th Grade`,
      student.school.name,
      student.program
    ];

    const errorCode = ref(0);
    const errorMessage = '';

    const viewValue = ref(0);

    async function resolveStudentApplication(
      studentId: ObjectId,
      accept: boolean,
      newOffer?: Record<string, boolean | number | string | undefined>
    ) {
      const selectedStudent = state.students.find(student => student._id.equals(studentId));
      const offerDetails = { ...newOffer };
      const rejectedOfferDetails = { ...newOffer };
      if (!selectedStudent) return;
      selectedStudent.showMakeOfferDialog = true;
      selectedStudent.isMakingOffer = true;
      selectedStudent.makingOfferFailed = false;
      set(selectedStudent, 'loadingResolveApplication', true);
      // replace presets with custom values if they are entered
      if (accept) {
        console.log('trig');
        ['InternshipProject', 'Position', 'Compensation'].forEach(field => {
          const fieldName = field[0].toLowerCase() + field.slice(1);
          offerDetails[fieldName] =
            offerDetails[fieldName] === 'custom' ? undefined : offerDetails[fieldName];
          if (offerDetails[`custom${field}`]) {
            offerDetails[fieldName] = offerDetails[`custom${field}`];
            offerDetails[`custom${field}`] = true;
          } else {
            offerDetails[`custom${field}`] = false;
          }
        });
      }

      try {
        const result: {
          statusCode: number;
          error?: string;
        } = await functions.value?.callFunction('resolveStudentApplication', {
          studentId: selectedStudent._id.toHexString(),
          accept,
          offerDetails: accept ? offerDetails : rejectedOfferDetails
        });

        if (result.statusCode === 200) {
          set(selectedStudent, 'applicationStatus', accept ? 'accepted' : 'rejected');
          set(
            selectedStudent,
            'studentStatus',
            accept ? StudentStatus.organizerMadeOffer : StudentStatus.organizerShelvedStudent
          );
          selectedStudent.makingOfferFailed = false;

          selectedStudent.makingOfferFinishedMessage = accept
            ? 'Your offer has been sent!'
            : 'Thank you for your decision.';
        } else {
          selectedStudent.makingOfferFailedReason = result.error ?? '';
          selectedStudent.makingOfferFailed = true;
        }
        selectedStudent.isMakingOffer = false;
        if (selectedStudent.isRejecting) {
          selectedStudent.isRejecting = false;
          selectedStudent.showMakeOfferForm = false;
        }
        errorCode.value = result.statusCode;
        set(selectedStudent, 'loadingResolveApplication', false);
      } catch (error) {
        selectedStudent.showMakeOfferDialog = true;
        selectedStudent.makingOfferFailed = true;
      }
    }

    const handleMonitorButtonClick = ({ page }) => {
      state.currentView = 'monitor';
      state.monitorRoute.page = page;
      state.monitorRoute.monitorOrganizerView = true;
    };

    const handleExitMonitor = () => {
      state.currentView = 'participant';
    };

    const handleActivityNewPage = newPage => {
      state.monitorRoute.page = newPage;
    };

    const IndexStudentValue = ref(0);

    const setIndexValue = IndexValueStudent => {
      IndexStudentValue.value = IndexValueStudent;
    };

    const handleMakeOfferDialogClose = () => {
      if (state.selectedStudent) {
        state.selectedStudent.showMakeOfferForm = false;
        state.selectedStudent.showMakeOfferDialog = false;
      }
    };

    const handleMakeOfferDialogVisibleChange = (visible: boolean) => {
      if (state.selectedStudent) {
        state.selectedStudent.showMakeOfferDialog = visible;
      }
    };

    const currentUser = computed(() => {
      const userInfo: CommentAuthor = {
        _id: user.value?.data?._id,
        firstName: user.value?.data?.firstName,
        lastName: user.value?.data?.lastName,
        profile: user.value?.data?.profile
      };

      return userInfo;
    });

    const operationMode = computed(() => {
      return OperationMode.Monitor;
    });

    const userType = computed(() => {
      let userType: 'organizer' | 'stakeholder' | 'participant' | '' = 'stakeholder';

      if (ctx.root.$route.query && ctx.root.$route.query.program) {
        userType = 'organizer';
      }
      return userType;
    });

    useVideoAsk(user, operationMode, userType);

    function openSignOffDialog() {
      signOffDialog.value = true;
    }

    function openSignOffPopup() {
      signedOffPopup.value = true;
    }

    return {
      ...toRefs(state),
      loadData,
      openSignOffPopup,
      openSignOffDialog,
      signOffDialog,
      studentInfo,
      setIndexValue,
      calculateProgramCompletion,
      resolveStudentApplication,
      viewValue,
      handleMonitorButtonClick,
      handleExitMonitor,
      handleActivityNewPage,
      errorCode,
      errorMessage,
      IndexStudentValue,
      handleMakeOfferDialogClose,
      handleMakeOfferDialogVisibleChange,
      signedOffPopup,
      bar
    };
  }
});
