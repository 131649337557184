
























































































































































































































































/* eslint-disable vue/no-mutating-props */
import { reactive, toRefs, ref, PropType } from '@vue/composition-api';
import {
  teamSetting1,
  planSetting1,
  interviewSetting1,
  implementationSetting1,
  debriefSetting1,
  storySetting1,
  taskSetting1,
  presentationSetting1
} from '@/views/Guide/activities/deliverables/components/const';
import { MongoDoc } from '@/views/Guide/activities/deliverables/components/types';
import axios from 'axios';
import ASelect from '@/components/atoms/ASelectInput.vue';
import ABtn from '@/components/atoms/AButton.vue';

export default {
  name: 'ModulePreset',
  components: {
    ASelect,
    ABtn
  },

  props: {
    value: {
      required: true,
      type: Object as PropType<MongoDoc>
    }
  },

  setup(props, ctx) {
    const successMsg = ref(false);
    const errorMsg = ref(false);
    const msg = ref('');
    const presets = reactive({
      teamSetting1,
      planSetting1,
      interviewSetting1,
      implementationSetting1,
      debriefSetting1,
      storySetting1,
      taskSetting1,
      presentationSetting1
    });
    const teamSettings = ref({
      required: true,
      editViewSetting: ''
    });
    const projectPlanSettings = ref({
      required: true,
      prePopulate: ''
    });
    const projectTaskSetting = ref({ required: true, taskSetting: '' });
    const caseStudySettings = ref({ required: true, contentSetting: '' });
    const presentationSettings = ref({ required: true, maxMins: '' });
    const userInterviewSettings = ref({ required: true, totalUsers: '' });
    const projectImplementationSettings = ref({ required: true, pilots: '' });
    const digitalResumeSettings = ref(true);
    const projectFAQSettings = ref(true);

    function setInitialData() {
      if (props.value && props.value?.data) {
        const index = props.value.data.adks.findIndex(obj => {
          if (obj) {
            return obj.name === 'deliverable';
          }
          return false;
        });
        if (index !== -1) {
          const teamSettingData = props.value?.data?.adks[index]?.settings?.teamSettings;
          const projectPlanSettingData =
            props.value?.data?.adks[index]?.settings?.projectPlanSettings;
          const projectTaskSettingData =
            props.value?.data?.adks[index]?.settings?.projectTaskSettings;
          const caseStudySettingData = props.value?.data?.adks[index]?.settings?.caseStudySettings;
          const presentationSettingData =
            props.value?.data?.adks[index]?.settings?.presentationSettings;
          const userInterviewSettingData =
            props.value?.data?.adks[index]?.settings?.userInterviewSettings;
          const projectImplementationSettingData =
            props.value?.data?.adks[index]?.settings?.projectImplementationSettings;
          const digitalResume = props.value?.data?.adks[index]?.settings?.digitalResumeSettings;
          const FAQ = props.value?.data?.adks[index]?.settings?.projectFAQSettings;

          if (teamSettingData) teamSettings.value = { ...teamSettingData };
          if (projectPlanSettingData)
            projectPlanSettings.value = {
              ...projectPlanSettingData
            };
          if (projectTaskSettingData)
            projectTaskSetting.value = {
              ...projectTaskSettingData
            };
          if (caseStudySettingData)
            caseStudySettings.value = {
              ...caseStudySettingData
            };

          if (presentationSettingData)
            presentationSettings.value = {
              ...presentationSettingData
            };
          if (userInterviewSettingData)
            userInterviewSettings.value = {
              ...userInterviewSettingData
            };
          if (projectImplementationSettingData)
            projectImplementationSettings.value = {
              ...projectImplementationSettingData
            };
          digitalResumeSettings.value = digitalResume || true;
          projectFAQSettings.value = FAQ || true;
          console.log(projectFAQSettings.value, digitalResumeSettings.value);
        }
      }
    }

    setInitialData();

    const isLoading = ref(false);

    async function saveDeliverableSettings() {
      isLoading.value = true;
      try {
        const data = {
          teamSettings: teamSettings.value,
          projectPlanSettings: projectPlanSettings.value,
          projectTaskSettings: projectTaskSetting.value,
          caseStudySettings: caseStudySettings.value,
          presentationSettings: presentationSettings.value,
          userInterviewSettings: userInterviewSettings.value,
          projectImplementationSettings: projectImplementationSettings.value,
          digitalResumeSettings: digitalResumeSettings.value,
          projectFAQSettings: projectFAQSettings.value
        };
        console.log(data);
        const ENDPOINT_API = process.env.VUE_APP_DELIVERABLE_SETTING;
        const res = await axios.patch(
          `${ENDPOINT_API}?program_id=${ctx.root.$route.params.programId}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
            }
          }
        );
        if (res.data.data) {
          successMsg.value = true;
          msg.value = res.data.message;
          isLoading.value = false;
        }
      } catch (error) {
        console.log(error);
        errorMsg.value = true;
        isLoading.value = false;
        msg.value = 'Something went wrong';
      }
    }

    return {
      teamSettings,
      isLoading,
      projectPlanSettings,
      ...toRefs(presets),
      saveDeliverableSettings,
      successMsg,
      errorMsg,
      msg,
      setInitialData,
      projectTaskSetting,
      caseStudySettings,
      presentationSettings,
      userInterviewSettings,
      projectImplementationSettings,
      digitalResumeSettings,
      projectFAQSettings
    };
  }
};
