export const minHours = [
  {
    text: '100 Hours',
    value: 100
  },
  {
    text: '120 Hours',
    value: 120
  },
  {
    text: '140 Hours',
    value: 140
  },
  {
    text: '160 Hours',
    value: 160
  },
  {
    text: '180 Hours',
    value: 180
  }
];

export const maxHours = [
  {
    text: '160 Hours',
    value: 160
  },
  {
    text: '180 Hours',
    value: 180
  },
  {
    text: '200 Hours',
    value: 200
  },
  {
    text: '220 Hours',
    value: 220
  },
  {
    text: '240 Hours',
    value: 240
  }
];
