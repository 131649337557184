




































































/* eslint-disable no-prototype-builtins */
/* eslint-disable vue/no-mutating-props */
import { defineComponent, reactive, toRefs, PropType, computed } from '@vue/composition-api';
import { MongoDoc } from 'pcv4lib/src/types';
import { getModMongoDoc, getModAdk } from 'pcv4lib/src';
import { ObjectId } from 'bson';
import Instruct from './ModuleInstruct.vue';
import CreateTeam from './CreateTeam.vue';
import JoinTeam from './JoinTeam.vue';
import Team from './Team.vue';

export default defineComponent({
  name: 'ModuleDefault',
  components: {
    Instruct,
    JoinTeam,
    CreateTeam,
    Team
  },
  props: {
    value: {
      required: true,
      type: Object as PropType<MongoDoc>
    },
    userType: {
      required: true,
      type: String
      // participant: '',
      // organizer: '',
      // stakeholder: ''
    },
    teamDoc: {
      required: false,
      type: Object as PropType<MongoDoc>,
      default: () => {}
    },
    studentDoc: {
      required: false,
      type: Object as PropType<MongoDoc>,
      default: () => {}
    },
    getTeamDoc: {
      required: false,
      type: Function,
      default: () => {}
    },
    userDoc: {
      required: false,
      type: Object as PropType<MongoDoc | null>,
      default: () => {}
    },
    db: {
      required: false,
      type: Object,
      default: () => {}
    }
  },
  setup(props, ctx) {
    const state = reactive({
      setupInstructions: {
        description: '',
        instructions: ['', '', '']
      },
      showInstructions: true,
      teams: [] as MongoDoc[],
      programDoc: null as any,
      teamDocument: null as any,
      studentDocument: null as any,
      studentAdkIndex: -1
    });
    const { adkData } = getModAdk(props, ctx.emit, 'team');
    state.programDoc = getModMongoDoc(props, ctx.emit);
    if (props.teamDoc)
      state.teamDocument = getModMongoDoc(props, ctx.emit, {}, 'teamDoc', 'inputTeamDoc');
    else {
      state.teamDocument = computed({
        get: () => props.teamDoc,
        set: newVal => {
          ctx.emit('inputTeamDoc', newVal);
        }
      });
    }
    if (props.studentDoc) {
      state.studentDocument = getModMongoDoc(props, ctx.emit, {}, 'studentDoc', 'inputStudentDoc');
      const { adkData: studentAdkData, adkIndex: studentAdkIndex } = getModAdk(
        props,
        ctx.emit,
        'team',
        {},
        'studentDoc',
        'inputStudentDoc'
      );
      state.studentAdkIndex = studentAdkIndex;
    }
    const fetchTeams = async () => {
      const teams = await props.db
        .collection('ProgramTeam')
        .find({ program_id: state.programDoc?.data._id });
      state.teams = teams.map((team: any) => {
        return {
          data: team
        };
      });
    };
    fetchTeams();
    const teamChangeStream = props.db.collection('ProgramTeam').watch({
      filter: {
        'fullDocument.program_id': state.programDoc?.data._id
      }
    });
    (async () => {
      // eslint-disable-next-line no-restricted-syntax
      for await (const change of teamChangeStream) {
        const changeIndex = state.teams.findIndex(team => {
          return team.data._id.toString() === change.documentKey._id.toString();
        });
        if (change.operationType === 'delete') state.teams.splice(changeIndex, 1);
        else if (changeIndex !== -1)
          state.teams.splice(changeIndex, 1, {
            ...state.teams[changeIndex],
            data: change.fullDocument
          });
        else {
          state.teams.push({
            ...state.teams[changeIndex],
            data: change.fullDocument
          });
        }
      }
    })();

    const saveActivityEndModified = () => {
      if (
        state.studentDocument.data.adks[state.studentAdkIndex].hasOwnProperty('activityEndedOn')
      ) {
        state.studentDocument.data.adks[state.studentAdkIndex].activityModifiedOn = new Date();
      } else {
        state.studentDocument.data.adks[state.studentAdkIndex].activityEndedOn = new Date();
      }
    };

    const activityModified = () => {
      if (
        state.studentDocument.data.adks[state.studentAdkIndex].hasOwnProperty('activityEndedOn')
      ) {
        state.studentDocument.data.adks[state.studentAdkIndex].activityModifiedOn = new Date();
      }
    };

    const joinTeam = async (_id: ObjectId) => {
      saveActivityEndModified();
      await props.db.collection('ProgramTeam').updateOne(
        { _id },
        {
          $addToSet: {
            members: {
              _id: props.userDoc?.data._id,
              firstName: props.userDoc?.data.firstName,
              lastName: props.userDoc?.data.lastName,
              avatar: props.userDoc?.data.profile ? props.userDoc?.data.profile.small : ''
            }
          }
        }
      );
      const team = await props.db.collection('ProgramTeam').findOne({ _id });
      state.teamDocument = { data: team };
      state.studentDocument.update(() => ({
        isComplete: true,
        adkIndex: state.studentAdkIndex
      }));
    };
    const createTeam = async (name: string, password: string) => {
      console.log('creating team');
      const team = {
        owner: props.userDoc?.data._id,
        program_id: state.programDoc?.data._id,
        name,
        password,
        members: [],
        adks: []
      };
      const { insertedId } = await props.db.collection('ProgramTeam').insertOne(team);
      joinTeam(insertedId);
      await fetchTeams();
      await props.getTeamDoc();
    };
    const removeMember = async (_id: ObjectId) => {
      activityModified();
      state.teamDocument!.data.members.splice(
        state.teamDocument!.data.members.findIndex((member: any) => {
          return member._id.equals(_id);
        }),
        1
      );
      await props.db
        .collection('ProgramTeam')
        .updateOne({ _id: state.teamDocument!.data._id }, { $pull: { members: { _id } } });
      await state.studentDocument.update();
    };
    const changeOwner = async (_id: ObjectId) => {
      activityModified();
      state.teamDocument!.data.owner = _id;
      await props.db
        .collection('ProgramTeam')
        .updateOne({ _id: state.teamDocument!.data._id }, { $set: { owner: _id } });
      await state.studentDocument.update();
    };
    const changePassword = (password: string) => {
      activityModified();
      state.teamDocument!.data.password = password;
      props.db
        .collection('ProgramTeam')
        .updateOne({ _id: state.teamDocument!.data._id }, { $set: { password } });
      state.studentDocument.update();
    };
    const renameTeam = (name: string) => {
      activityModified();
      state.teamDocument!.data.name = name;
      props.db
        .collection('ProgramTeam')
        .updateOne({ _id: state.teamDocument!.data._id }, { $set: { name } });
      state.studentDocument.update();
    };
    const leaveTeam = async (viewerId: ObjectId, newOwnerId?: ObjectId) => {
      if (newOwnerId) changeOwner(newOwnerId);
      await removeMember(viewerId);
      // Remove team if empty
      activityModified();
      if (state.teamDocument!.data.members.length === 0) {
        state.teams.splice(
          state.teams.findIndex(team => {
            return team.data._id === state.teamDocument!.data._id;
          }),
          1
        );
        await props.db.collection('ProgramTeam').deleteOne({ _id: state.teamDocument!.data._id });
        // await fetchTeams();
      }
      state.studentDocument.update();
      state.teamDocument = null;
    };
    return {
      adkData,
      ...toRefs(state),
      joinTeam,
      createTeam,
      removeMember,
      changeOwner,
      changePassword,
      renameTeam,
      leaveTeam
    };
  }
});
