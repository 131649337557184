























































































































































/* eslint-disable no-prototype-builtins */
/* eslint-disable vue/no-mutating-props */
/* eslint-disable no-param-reassign */
import { defineComponent, ref, computed, PropType, inject } from '@vue/composition-api';
import { loading, getModAdk } from 'pcv4lib/src';
import axios from 'axios';
import MongoDoc from './types';
import { items, HEADER } from './const';
import Instruct from './ModuleInstruct.vue';

export default defineComponent({
  name: 'ModuleDefault',

  components: {
    Instruct
  },
  props: {
    value: {
      required: true,
      type: Object as PropType<MongoDoc>
    },
    userType: {
      required: true,
      type: String
    },
    studentDoc: {
      required: true,
      type: Object as () => MongoDoc
    }
  },
  setup(props, ctx) {
    // props
    const readonly = inject('readonly', false);

    const { adkData: researchAdk, adkIndex } = getModAdk(
      props,
      ctx.emit,
      'research',
      {
        researchProgress: (
          props.value.data?.adks?.find(obj => obj && obj.name === 'research')
            ?.researchLinks as any[]
        )?.map((obj: any) => ({
          ...obj,
          viewed: false,
          completed: false
        }))
      },
      'studentDoc',
      'inputStudentDoc'
    );
    // layout
    const setupInstructions = ref({
      description: '',
      instructions: ['', '', '']
    });
    const researchAdkData = ref(researchAdk.value);
    const {
      loading: saveLoading,
      process: saveProcess,
      message: saveMessage,
      error: saveError,
      success: saveSuccess
    } = loading(() => new Promise(resolve => resolve(undefined)), 'Success', 'Try again later');
    const showInstructions = ref(true);
    const finishButtonDisabled = ref(1);
    const isComplete = computed(() => {
      return researchAdk.value.researchProgress?.every(
        (item: any) => !item.required || (item.viewed && item.completed)
      );
    });
    const isLoading = ref(false);
    async function fetchFormData() {
      const API_ENDPOINT = process.env.VUE_APP_RESEARCHADK_NEW_BADGE;
      const studentId = props.studentDoc.data._id.toString();
      try {
        isLoading.value = true;
        const res = await axios.post(
          `${API_ENDPOINT}?program_id=${ctx.root.$route.params.programId}&student_id=${studentId}`,
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );
        if (res) {
          isLoading.value = false;
        }
      } catch (err) {
        console.log(err);
      }
    }
    fetchFormData();

    function goToLink(item) {
      const link = researchAdk.value.researchProgress.filter(l => l.link === item.link);
      link[0].viewed = true;
      if (link[0].newCandidate) {
        link[0].newCandidate = false;
      }
    }

    const successmsg = ref('');
    const errormsg = ref('');
    const loadingstatus = ref(false);

    async function saveData() {
      loadingstatus.value = true;
      try {
        if (props.studentDoc.data.adks[adkIndex].hasOwnProperty('activityEndedOn')) {
          props.studentDoc.data.adks[adkIndex].activityModifiedOn = new Date();
        } else {
          props.studentDoc.data.adks[adkIndex].activityEndedOn = new Date();
        }
        await props.studentDoc.update(() => {
          return {
            isComplete: true,
            adkIndex
          };
        });
        ctx.emit('calculate-timer');
        successmsg.value = 'Success';
        errormsg.value = '';
        loadingstatus.value = false;
      } catch (err) {
        successmsg.value = '';
        errormsg.value = 'Try again later';
        loadingstatus.value = false;
      }
    }

    const successdatamsg = ref('');
    const errordatamsg = ref('');
    const loadingdatastatus = ref(false);

    async function saveProcessData() {
      loadingdatastatus.value = true;
      try {
        if (props.studentDoc.data.adks[adkIndex].hasOwnProperty('activityEndedOn')) {
          props.studentDoc.data.adks[adkIndex].activityModifiedOn = new Date();
        }
        await props.studentDoc.update();
        successdatamsg.value = 'Success';
        errordatamsg.value = '';
        loadingdatastatus.value = false;
      } catch (err) {
        successdatamsg.value = '';
        errordatamsg.value = 'Try again later';
        loadingdatastatus.value = false;
      }
    }

    return {
      header: HEADER,
      items,
      setupInstructions,
      finishButtonDisabled,
      showInstructions,
      fetchFormData,
      researchAdkData,
      researchAdk,
      isComplete,
      isLoading,
      successdatamsg,
      errordatamsg,
      loadingdatastatus,
      saveProcessData,
      saveMessage,
      saveError,
      saveSuccess,
      saveLoading,
      saveData,
      successmsg,
      errormsg,
      loadingstatus,
      goToLink,
      readonly,
      ...loading(
        () =>
          props.studentDoc.update(() => ({
            isComplete: true,
            adkIndex
          })),
        'Success',
        'Try again later'
      )
    };
  }
});
