





























































































































































































































































/* eslint-disable no-prototype-builtins */
/* eslint-disable vue/no-mutating-props */
/* eslint-disable no-param-reassign */
import { defineComponent, ref, PropType, reactive, inject } from '@vue/composition-api';
import { getModAdk, loading } from 'pcv4lib/src';
import AgreementAdk from '@/components/molecules/m-default-adk-agreement.vue';
import Instruct from './ModuleInstruct.vue';
import Table from './TableView.vue';
import MongoDoc from './types';

export default defineComponent({
  name: 'ModuleDefault',

  components: {
    Instruct,
    Table,
    AgreementAdk
  },
  props: {
    value: {
      required: true,
      type: Object as PropType<MongoDoc>
    },
    studentDoc: {
      required: true,
      type: Object as PropType<MongoDoc>
    },
    getStudentDoc: {
      type: Function
    },
    userType: {
      required: true,
      type: String
    },
    fetchProgram: {
      required: true,
      type: [Object, Function]
    }
  },

  setup(props, ctx) {
    const readonly = inject('readonly', false);
    const agreementDialog = ref(false);
    const { adkData, adkIndex } = getModAdk(
      props,
      ctx.emit,
      'offer',
      {},
      'studentDoc',
      'inputStudentDoc'
    );
    const dialog = reactive({
      accept: false,
      decline: false
    });
    const invalid = ref(true);
    async function acceptOffer() {
      try {
        adkData.value.offerStatus = true;
        if (props.studentDoc.data.adks[adkIndex].hasOwnProperty('activityEndedOn')) {
          props.studentDoc.data.adks[adkIndex].activityModifiedOn = new Date();
        } else {
          props.studentDoc.data.adks[adkIndex].activityEndedOn = new Date();
        }

        await props.studentDoc.update(() => ({
          isComplete: true,
          adkIndex
        }));
        ctx.emit('calculate-timer');
      } catch (err) {
        adkData.value.offerStatus = undefined;
        throw err;
      }
    }

    async function declineOffer() {
      try {
        adkData.value.offerStatus = false;
        if (props.studentDoc.data.adks[adkIndex].hasOwnProperty('activityEndedOn')) {
          props.studentDoc.data.adks[adkIndex].activityModifiedOn = new Date();
        } else {
          props.studentDoc.data.adks[adkIndex].activityEndedOn = new Date();
        }
        await props.studentDoc.update(() => ({
          isComplete: true,
          adkIndex
        }));
        ctx.emit('calculate-timer');
      } catch (err) {
        adkData.value.offerStatus = undefined;
        throw err;
      }
    }

    const showInstructions = ref(true);
    return {
      showInstructions,
      agreementDialog,
      adkData,
      adkIndex,
      accept: loading(acceptOffer, 'Success', 'Try again later'),
      decline: loading(declineOffer, 'Success', 'Try again later'),
      dialog,
      invalid,
      readonly
    };
  }
});
