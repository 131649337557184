



















































































































































































import { reactive, toRefs, ref, computed, watch, onUnmounted } from '@vue/composition-api';
import { useDbState } from '@/store';
import { copyText } from 'vue3-clipboard';

export default {
  name: 'ModulePresets',
  props: {
    value: {
      required: true,
      type: Object
    },
    fetchProgram: {
      required: true,
      type: [Object, Function]
    }
  },
  setup(props: any, ctx) {
    const setup = reactive({});
    const { user } = useDbState(['user']);
    const programDoc: any = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    const index = programDoc.value.data.adks.findIndex(obj => {
      if (obj) {
        return obj.name === 'welcome';
      }
      return false;
    });
    const isVideoaskResponseAdded: any = computed(() => {
      return !!programDoc.value?.data?.adks[index].completed;
    });
    const driveLink = ref();
    const isFetching = ref(false);
    async function fetchStatus() {
      isFetching.value = true;
      try {
        const setFunction = setInterval(() => {
          props.fetchProgram();
          if (programDoc.value.data.adks[index].status === 'complete_setup') {
            clearInterval(setFunction);
            isVideoaskResponseAdded.value = true;
            isFetching.value = false;
          }
        }, 10000);
        setTimeout(function () {
          isFetching.value = false;
        }, 2000);
      } catch (error) {
        console.log(error);
        isFetching.value = false;
      }
    }
    if (programDoc.value.data.adks[index].driveLink) {
      driveLink.value = programDoc.value.data.adks[index].driveLink;
    }
    const isVerifyLink = ref(false);
    async function verifyLink() {
      isVerifyLink.value = true;
      programDoc.value.data.adks[index].driveLink = driveLink.value;
      programDoc.value.update();
      props.fetchProgram();
      setTimeout(function () {
        isVerifyLink.value = false;
      }, 2000);
    }
    const redoWelcome = ref(false);
    const isLoading = ref(false);
    async function process() {
      isLoading.value = true;
      setTimeout(function () {
        delete programDoc.value.data.adks[index].signInVideoAskResponse;
        delete programDoc.value.data.adks[index].completed;
        programDoc.value.update();
        props.fetchProgram();
        isLoading.value = false;
        redoWelcome.value = false;
      }, 2000);
    }
    watch(isVideoaskResponseAdded, val => {
      if (val) {
        fetchStatus();
      }
    });
    const intervalId = window.setInterval(() => {
      if (props.value?.data?.adks[index].completed) {
        clearInterval(intervalId);
      } else {
        props.fetchProgram();
      }
    }, 10000);

    onUnmounted(() => {
      clearInterval(intervalId);
    });
    const copied = ref(false);
    const doCopy = () => {
      copyText(driveLink.value, undefined, (error, event) => {
        if (error) {
          console.log(error);
        } else {
          copied.value = true;
          setTimeout(() => {
            copied.value = false;
          }, 5000);
        }
      });
    };
    return {
      ...toRefs(setup),
      redoWelcome,
      programDoc,
      user,
      index,
      driveLink,
      fetchStatus,
      isVideoaskResponseAdded,
      process,
      verifyLink,
      isVerifyLink,
      isFetching,
      isLoading,
      doCopy,
      copied
    };
  }
};
