















































































































































































/* eslint-disable no-param-reassign */
/* eslint-disable vue/no-mutating-props */
/* eslint-disable no-prototype-builtins */
import { defineComponent, PropType, ref, computed } from '@vue/composition-api';
import { getModAdk } from 'pcv4lib/src';
import Instruct from './ModuleInstruct.vue';
import MongoDoc from './types';

export default defineComponent({
  name: 'ModuleDefault',
  components: {
    Instruct
  },
  props: {
    teamDoc: {
      required: true,
      type: Object as PropType<MongoDoc>
    },
    readonly: {
      required: false,
      default: false
    },
    userType: {
      required: true,
      type: String
    }
  },
  setup(props, ctx) {
    const initPitchDefault = {
      valueDrafts: []
    };
    const { adkData: teamAdkData, adkIndex } = getModAdk(
      props,
      ctx.emit,
      'Pitch',
      initPitchDefault,
      'teamDoc',
      'inputTeamDoc'
    );

    const isValue = computed(() => {
      if (props.teamDoc.data?.adks[adkIndex]?.valueDrafts?.length) {
        return true;
      }
      return false;
    });

    const isValueDraft = computed(() => {
      if (props.teamDoc.data?.adks[adkIndex]?.valueDrafts) {
        return true;
      }
      return false;
    });

    const totalDrafts = computed(() => {
      return (
        props.teamDoc.data?.adks[adkIndex] &&
        props.teamDoc.data?.adks[adkIndex]?.valueDrafts?.length
      );
    });

    const adkData = ref(teamAdkData.value);
    const IndexVal = ref(totalDrafts.value);
    const display = ref(IndexVal.value);
    const finalDraftSaved = ref('Draft');
    const disabledPastDraft = ref(0);
    const unmakeFD = ref(0);
    const loading = ref(false);
    const success = ref(false);
    const errorMsg = ref(false);
    const finalDraftMsg = ref(false);
    const unmakeFDMsg = ref(false);
    const onePitch = ref('');
    const elevatorPitch = ref('');
    if (adkData.value.valueDrafts.length > 0) {
      onePitch.value = adkData.value.valueDrafts[IndexVal.value - 1].onePitch;
      elevatorPitch.value = adkData.value.valueDrafts[IndexVal.value - 1].elevatorPitch;
      if (adkData.value.valueDrafts[IndexVal.value - 1].finalDraft === true) {
        disabledPastDraft.value = 1;
        unmakeFD.value = 1;
        finalDraftSaved.value = 'Final Draft';
      }
    }
    const saveActivityEndModified = () => {
      if (props.teamDoc.data.adks[adkIndex].hasOwnProperty('activityEndedOn')) {
        props.teamDoc.data.adks[adkIndex].activityModifiedOn = new Date();
      } else {
        props.teamDoc.data.adks[adkIndex].activityEndedOn = new Date();
      }
    };
    const activityModified = () => {
      if (props.teamDoc.data.adks[adkIndex].hasOwnProperty('activityEndedOn')) {
        props.teamDoc.data.adks[adkIndex].activityModifiedOn = new Date();
      }
    };
    async function draftSave() {
      const teamIndex = props.teamDoc?.data.adks.findIndex(function findOfferObj(obj) {
        return obj.name === 'Pitch';
      });

      if (teamIndex === -1) {
        const initPractice = {
          name: 'Pitch'
        };
        props.teamDoc.data.adks.push(initPractice);
      }
      if (!isValueDraft.value) {
        props.teamDoc.data.adks[adkIndex].valueDrafts = [];
      }
      loading.value = true;
      success.value = false;
      errorMsg.value = false;
      setTimeout(() => {
        loading.value = false;
      }, 3000);
      const draftNum = adkData.value.valueDrafts.length - 1;
      const draft = ref({
        onePitch: onePitch.value,
        elevatorPitch: elevatorPitch.value,
        finalDraft: false
      });
      if (onePitch.value.length !== 0 || elevatorPitch.value.length !== 0) {
        if (adkData.value.valueDrafts.length - 1 < 0) {
          props.teamDoc.data?.adks[adkIndex]?.valueDrafts?.push(draft.value);
          // eslint-disable-next-line no-plusplus
          IndexVal.value++;
          // eslint-disable-next-line no-plusplus
          display.value++;
          activityModified();
          await props.teamDoc.update();
          success.value = true;
        } else if (
          onePitch.value !== adkData.value.valueDrafts[draftNum].onePitch ||
          elevatorPitch.value !== adkData.value.valueDrafts[draftNum].elevatorPitch
        ) {
          props.teamDoc.data?.adks[adkIndex]?.valueDrafts?.push(draft.value);
          // eslint-disable-next-line no-plusplus
          IndexVal.value++;
          // eslint-disable-next-line no-plusplus
          display.value++;
          activityModified();
          await props.teamDoc.update();
          success.value = true;
        } else if (
          onePitch.value === adkData.value.valueDrafts[draftNum].onePitch ||
          elevatorPitch.value === adkData.value.valueDrafts[draftNum].elevatorPitch
        ) {
          errorMsg.value = true;
        }
      } else {
        errorMsg.value = true;
      }
    }
    const indexNum = '';
    const finalDraftIndex = ref('');
    async function finalDraft() {
      loading.value = true;
      disabledPastDraft.value = 1;
      setTimeout(() => {
        loading.value = false;
        unmakeFD.value = 1;
      }, 3000);
      const draft = ref({
        onePitch: onePitch.value,
        elevatorPitch: elevatorPitch.value,
        finalDraft: true
      });
      props.teamDoc.data?.adks[adkIndex]?.valueDrafts?.push(draft.value);
      finalDraftSaved.value = 'Final Draft';
      display.value = totalDrafts.value - 1;
      saveActivityEndModified();
      await props.teamDoc.update(() => ({
        isComplete: true,
        adkIndex
      }));
      ctx.emit('calculate-timer');
      finalDraftMsg.value = true;
      IndexVal.value = totalDrafts.value - 1;
    }
    function showDraft(draft: number) {
      IndexVal.value = totalDrafts.value - draft;
      display.value = IndexVal.value;
      onePitch.value = props.teamDoc.data.adks[adkIndex].valueDrafts[IndexVal.value].onePitch;
      elevatorPitch.value =
        props.teamDoc.data.adks[adkIndex].valueDrafts[IndexVal.value].elevatorPitch;
      if (totalDrafts.value - draft !== totalDrafts.value - 1) {
        disabledPastDraft.value = 1;
      } else {
        disabledPastDraft.value = 0;
      }
      if (props.teamDoc.data.adks[adkIndex].valueDrafts[IndexVal.value].finalDraft === true) {
        finalDraftSaved.value = 'Final Draft';
        unmakeFD.value = 1;
        disabledPastDraft.value = 1;
      } else {
        finalDraftSaved.value = 'Draft';
      }
      return draft;
    }
    async function unmakeFinalDraft() {
      loading.value = true;
      unmakeFD.value = 0;
      disabledPastDraft.value = 0;
      finalDraftSaved.value = 'Draft';
      setTimeout(() => {
        loading.value = false;
      }, 3000);
      adkData.value.valueDrafts[adkData.value.valueDrafts.length - 1].finalDraft = false;
      activityModified();
      await props.teamDoc!.update();
      unmakeFDMsg.value = true;
    }
    const setupInstructions = ref({
      description: '',
      instructions: ['', '', '']
    });
    return {
      onePitch,
      totalDrafts,
      isValue,
      isValueDraft,
      elevatorPitch,
      setupInstructions,
      showInstructions: 'true',
      draftSave,
      IndexVal,
      finalDraft,
      showDraft,
      finalDraftSaved,
      indexNum,
      finalDraftIndex,
      display,
      adkData,
      disabledPastDraft,
      unmakeFD,
      unmakeFinalDraft,
      loading,
      errorMsg,
      finalDraftMsg,
      unmakeFDMsg,
      success
    };
  }
});
