const group = ['Setup', 'Project', 'Screening', 'Internship'];
const required = ['Creator requires this activity', 'Yes', 'No'];
const lockOrder = ['Creator locked activity group and placement order', 'Yes', 'No'];
const deliverable = ['Yes', 'No'];
const notifications = ['Creator turned on by default', 'Turn on', 'Turn off'];
const accessibility = [
  'Creator has turned off accessibility anytime',
  'Creator has turned on accessibility anytime',
  'Yes',
  'No'
];
const endEarly = [
  'Creator has not allowed participants to end early after this activity',
  'Creator has allow end early option only at preset order placement',
  'Yes',
  'No'
];
const maxMinutes = ['2', '3', '5'];

const timePreset = [0, 1, 2, 3, 4, 5, 6, 7];

export {
  group,
  required,
  lockOrder,
  deliverable,
  notifications,
  accessibility,
  endEarly,
  maxMinutes,
  timePreset
};
