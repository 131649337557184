

































































































































































































































/* eslint-disable no-param-reassign */
/* eslint-disable vue/no-mutating-props */
/* eslint-disable no-prototype-builtins */
import { defineComponent, PropType, ref, computed } from '@vue/composition-api';
import { getModAdk } from 'pcv4lib/src';
// import Swal from 'sweetalert2';
import Instruct from './ModuleInstruct.vue';
import MongoDoc from './types';

export default defineComponent({
  name: 'ModuleDefault',
  components: {
    Instruct
  },
  props: {
    value: {
      required: true,
      type: Object as PropType<MongoDoc>
    },
    userType: {
      required: true,
      type: String
      // participant: '',
      // organizer: '',
      // stakeholder: ''
    },
    teamDoc: {
      required: true,
      type: Object as PropType<MongoDoc>,
      default: () => {}
    },
    readonly: {
      required: false,
      default: false
    }
  },
  setup(props, ctx) {
    const programDoc = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    const index = programDoc.value.data.adks.findIndex(obj => {
      if (obj) {
        return obj.name === 'ideate';
      }
      return false;
    });
    const initIdeateDefault = {
      valueDrafts: []
    };
    const { adkData: teamAdkData, adkIndex } = getModAdk(
      props,
      ctx.emit,
      'Ideate',
      initIdeateDefault,
      'teamDoc',
      'inputTeamDoc'
    );

    const isValue = computed(() => {
      if (props.teamDoc.data?.adks[adkIndex]?.valueDrafts?.length) {
        return true;
      }
      return false;
    });

    const isValueDraft = computed(() => {
      if (props.teamDoc.data?.adks[adkIndex]?.valueDrafts) {
        return true;
      }
      return false;
    });

    const totalDrafts = computed(() => {
      return (
        props.teamDoc.data?.adks[adkIndex] &&
        props.teamDoc.data?.adks[adkIndex]?.valueDrafts?.length
      );
    });

    const adkData = ref(teamAdkData.value);
    if (programDoc.value.data.adks[index].maxCharacters === undefined) {
      const initIdeatePresets = {
        maxCharacters: '280',
        defaultActivity: {
          groupActivity: 'Screening',
          requiredActivity: 'Yes',
          deliverableActivity: 'No',
          endEarlyActivity: 'Yes',
          required: false
        }
      };
      programDoc.value.data.adks[index] = {
        ...initIdeatePresets,
        ...programDoc.value.data.adks[index]
      };
    }
    const disabledPastDraft = ref(0);
    const IndexVal = ref(totalDrafts.value);
    const problem = ref('');
    const solution = ref('');
    const innovation = ref('');
    const user = ref('');
    const finalDraftSaved = ref('Draft');
    const loading = ref(false);
    const unmakeFD = ref(0);
    if (adkData.value.valueDrafts.length > 0) {
      problem.value = adkData.value.valueDrafts[IndexVal.value - 1].problem;
      solution.value = adkData.value.valueDrafts[IndexVal.value - 1].solution;
      innovation.value = adkData.value.valueDrafts[IndexVal.value - 1].innovation;
      user.value = adkData.value.valueDrafts[IndexVal.value - 1].user;
      if (adkData.value.valueDrafts[IndexVal.value - 1].finalDraft === true) {
        disabledPastDraft.value = 1;
        unmakeFD.value = 1;
        finalDraftSaved.value = 'Final Draft';
      }
    }
    const display = ref(IndexVal.value);
    const success = ref(false);
    const errorMsg = ref(false);
    const finalDraftMsg = ref(false);
    const unmakeFDMsg = ref(false);
    const saveActivityEndModified = () => {
      if (props.teamDoc.data.adks[adkIndex].hasOwnProperty('activityEndedOn')) {
        props.teamDoc.data.adks[adkIndex].activityModifiedOn = new Date();
      } else {
        props.teamDoc.data.adks[adkIndex].activityEndedOn = new Date();
      }
    };
    const activityModified = () => {
      if (props.teamDoc.data.adks[adkIndex].hasOwnProperty('activityEndedOn')) {
        props.teamDoc.data.adks[adkIndex].activityModifiedOn = new Date();
      }
    };
    async function draftSave() {
      const teamIndex = props.teamDoc?.data.adks.findIndex(function findOfferObj(obj) {
        return obj.name === 'Ideate';
      });

      if (teamIndex === -1) {
        const initPractice = {
          name: 'Ideate'
        };
        props.teamDoc.data.adks.push(initPractice);
      }
      if (!isValueDraft.value) {
        props.teamDoc.data.adks[adkIndex].valueDrafts = [];
      }
      loading.value = true;
      success.value = false;
      errorMsg.value = false;
      setTimeout(() => {
        loading.value = false;
      }, 3000);

      const draftNum = adkData.value.valueDrafts.length - 1;
      const draft = ref({
        problem: problem.value,
        solution: solution.value,
        innovation: innovation.value,
        user: user.value,
        finalDraft: false
      });
      if (
        problem.value.length !== 0 ||
        solution.value.length !== 0 ||
        innovation.value.length !== 0 ||
        user.value.length !== 0
      ) {
        if (adkData.value.valueDrafts.length - 1 < 0) {
          props.teamDoc.data.adks[adkIndex].valueDrafts.push(draft.value);
          IndexVal.value++;
          // eslint-disable-next-line no-plusplus
          display.value++;
          activityModified();
          await props.teamDoc.update();
          success.value = true;
        } else if (
          problem.value !== adkData.value.valueDrafts[draftNum].problem ||
          solution.value !== adkData.value.valueDrafts[draftNum].solution ||
          innovation.value !== adkData.value.valueDrafts[draftNum].innovation ||
          user.value !== adkData.value.valueDrafts[draftNum].user
        ) {
          props.teamDoc.data.adks[adkIndex].valueDrafts.push(draft.value);
          // eslint-disable-next-line no-plusplus
          IndexVal.value++;
          // eslint-disable-next-line no-plusplus
          display.value++;
          activityModified();
          await props.teamDoc.update();
          success.value = true;
        } else {
          errorMsg.value = true;
        }
      } else {
        errorMsg.value = true;
      }
    }
    const indexNum = '';
    const finalDraftIndex = ref('');
    async function finalDraft() {
      loading.value = true;
      setTimeout(() => {
        loading.value = false;
        disabledPastDraft.value = 1;
        unmakeFD.value = 1;
      }, 3000);
      const draft = ref({
        problem: problem.value,
        solution: solution.value,
        innovation: innovation.value,
        user: user.value,
        finalDraft: true
      });
      props.teamDoc.data.adks[adkIndex].valueDrafts.push(draft.value);
      finalDraftSaved.value = 'Final Draft';
      display.value = totalDrafts.value - 1;
      saveActivityEndModified();
      await props.teamDoc.update(() => ({
        isComplete: true,
        adkIndex
      }));
      finalDraftMsg.value = true;
    }
    function showDraft(draft: number) {
      IndexVal.value = totalDrafts.value - draft;
      display.value = IndexVal.value;
      problem.value = props.teamDoc.data.adks[adkIndex].valueDrafts[IndexVal.value].problem;
      solution.value = props.teamDoc.data.adks[adkIndex].valueDrafts[IndexVal.value].solution;
      innovation.value = props.teamDoc.data.adks[adkIndex].valueDrafts[IndexVal.value].innovation;
      user.value = props.teamDoc.data.adks[adkIndex].valueDrafts[IndexVal.value].user;
      if (totalDrafts.value - draft !== totalDrafts.value - 1) {
        disabledPastDraft.value = 1;
      } else {
        disabledPastDraft.value = 0;
      }
      if (props.teamDoc.data.adks[adkIndex].valueDrafts[IndexVal.value].finalDraft === true) {
        finalDraftSaved.value = 'Final Draft';
        unmakeFD.value = 1;
        disabledPastDraft.value = 1;
      } else {
        finalDraftSaved.value = 'Draft';
      }
      return draft;
    }
    async function unmakeFinalDraft() {
      loading.value = true;
      setTimeout(() => {
        loading.value = false;
        unmakeFD.value = 0;
        disabledPastDraft.value = 0;
      }, 3000);
      adkData.value.valueDrafts[adkData.value.valueDrafts.length - 1].finalDraft = false;
      finalDraftSaved.value = 'Draft';
      activityModified();
      await props.teamDoc!.update();
      unmakeFDMsg.value = true;
    }
    const setupInstructions = ref({
      description: '',
      instructions: ['', '', '']
    });
    return {
      status: 'true',
      isValue,
      isValueDraft,
      setupInstructions,
      showInstructions: 'true',
      draftSave,
      IndexVal,
      finalDraft,
      showDraft,
      finalDraftSaved,
      indexNum,
      finalDraftIndex,
      display,
      adkData,
      success,
      problem,
      solution,
      innovation,
      user,
      programDoc,
      index,
      disabledPastDraft,
      unmakeFD,
      unmakeFinalDraft,
      loading,
      errorMsg,
      finalDraftMsg,
      unmakeFDMsg,
      totalDrafts
    };
  }
});
