













































































import { defineComponent, reactive, ref, toRefs, computed } from '@vue/composition-api';
// import Instruct from './ModuleInstruct.vue';
import {
  group,
  required,
  lockOrder,
  deliverable,
  notifications,
  accessibility,
  endEarly
} from './const';
import MongoDoc from './types';
// import gql from 'graphql-tag';

export default defineComponent({
  name: 'ModulePresets',
  components: {
    // Instruct
  },
  props: {
    value: {
      required: true,
      type: Object as () => MongoDoc
    }
  },
  setup(props, ctx) {
    const errormsg = ref('');
    const successmsg = ref('');
    // const typeTime = ref('');

    const programDoc = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    const index = programDoc.value.data.adks.findIndex(obj => obj.name === 'train');

    const initTrainPresets = {
      defaultActivity: {
        groupActivity: 'Project',
        requiredActivity: 'Yes',
        deliverableActivity: 'No',
        endEarlyActivity: 'No',
        required: false
      }
    };

    programDoc.value.data.adks[index] = {
      ...initTrainPresets,
      ...programDoc.value.data.adks[index]
    };

    /* if(!programDoc.value.data.adks[index].hasOwnProperty('is_deadline')){
      programDoc.value.data.adks[index].daysLimit = false;
    }    
    
    if(!programDoc.value.data.adks[index].hasOwnProperty('daysLimit')){
      programDoc.value.data.adks[index].daysLimit = null;
    }

    if(!programDoc.value.data.adks[index].hasOwnProperty('hoursLimit')){
      programDoc.value.data.adks[index].hoursLimit = null;
    }

    if(!programDoc.value.data.adks[index].hasOwnProperty('minutesLimit')){
      programDoc.value.data.adks[index].minutesLimit = null;
    }

    if(!programDoc.value.data.adks[index].hasOwnProperty('type_time')){
      programDoc.value.data.adks[index].type_time = null;
    }
    else if (programDoc.value.data.adks[index].hasOwnProperty('type_time')) {
      typeTime.value = programDoc.value.data.adks[index].type_time;
    } */

    const loading = ref(false);
    async function save() {
      loading.value = true;
      try {
        /* if(typeTime.value == 'days'){
          programDoc.value.data.adks[index].hoursLimit = null;
          programDoc.value.data.adks[index].minutesLimit = null;

        }
        else if(typeTime.value == 'hours') {
          programDoc.value.data.adks[index].minutesLimit = null;
          programDoc.value.data.adks[index].daysLimit = null;
        }
        else if(typeTime.value == 'minutes') {
          programDoc.value.data.adks[index].daysLimit = null;
          programDoc.value.data.adks[index].hoursLimit = null;

        }

        programDoc.value.data.adks[index].type_time = typeTime.value; */
        await programDoc.value.update();
        successmsg.value = 'Saved';
      } catch (err) {
        errormsg.value = 'Could not save';
      }
      loading.value = false;
    }
    const presets = reactive({
      group,
      required,
      lockOrder,
      deliverable,
      notifications,
      accessibility,
      endEarly
    });

    // const daysItems = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20];
    // const hoursItems = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24];
    // const minutesItems = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,56,57,58,59,60];
    /* const deadlineData = [
      {value: true, text: 'Yes'},
      {value: false, text: 'No'}
    ];
    const type = [
      { value: 'days', text:'Days'},
      { value: 'hours', text:'Hours'},
      { value: 'minutes', text:'Minutes'},
    ]; */
    const setupInstructions = ref({
      description: '',
      instructions: ['', '', '']
    });
    return {
      ...toRefs(presets),
      setupInstructions,
      loading,
      save,
      errormsg,
      successmsg,
      index,
      /* type,
      hoursItems,
      deadlineData, 
      minutesItems,
      daysItems, */
      programDoc
    };
  }
});
