












































































































































































































































































































































































































































































































































































































































































































































































































































import { ref, reactive, toRefs, computed, defineComponent, Ref, watch } from '@vue/composition-api';
import { ObjectId } from 'bson';
import * as Realm from 'realm-web';
import { Configuration, OpenAIApi } from 'openai';

import { useUserGetters, useDbGetters } from '@/store';
import ScopeBar from './ScopeBar.vue';

export default defineComponent({
  name: 'ExploreGuide',

  components: {
    'guide-bar': ScopeBar
  },

  setup(_props, ctx) {
    const REALM_APP_ID = process.env.VUE_APP_REALM_ID;
    const realmApp: Realm.App = new Realm.App({ id: REALM_APP_ID! });
    const credentials = Realm.Credentials.anonymous();

    const { getUser, getObjectId } = useUserGetters(['getUser', 'getObjectId']);
    // console.log(getUser.value?.providerType);
    // console.log(getObjectId.value);
    const userType = getUser.value?.providerType;
    const userId = getObjectId.value;

    const { collection } = useDbGetters(['collection']);
    const employerData: Ref<any[]> = ref([]);
    const state = reactive({
      programFilter: 'All' as 'All' | 'Bookmarked',
      ageFilter: null as any,
      residenceFilter: null,
      pathwaysFilter: [{} as any],
      bookmarked: [] as ObjectId[],
      residenceOptions: [] as string[]
    });

    const savedBookmarks = localStorage.getItem('bookmarkedPrograms'); // * grab bookmarks from localStorage
    if (savedBookmarks) state.bookmarked = JSON.parse(savedBookmarks).map(id => new ObjectId(id));

    collection.value!('Program')
      .find({
        published: true
      })
      .then(programs => {
        programs.sort((a, b) => (a.programName || '').localeCompare(b.programName || ''));
        employerData.value = programs;
        programs.forEach(program => {
          if (program.requiredResidency) state.residenceOptions.push(...program.requiredResidency);
        });
      });

    if (ctx.root.$route.query) {
      let query = ctx.root.$route.query.pathway;
      if (!Array.isArray(query)) query = [query];
      query = query.filter(word => !!word);
      if (!query.length)
        state.pathwaysFilter = [
          {
            text: 'All',
            color: 'grey darken-4'
          }
        ];
      else
        state.pathwaysFilter = query.map(pathway => ({
          text: pathway as string,
          color: 'grey darken-4'
        }));
    }

    // const currentUnit = ref(ListView);

    const filteredPrograms = computed(() => {
      let visiblePrograms;
      if (state.programFilter === 'Bookmarked') {
        visiblePrograms = employerData.value.filter((program: Record<string, any>) =>
          state.bookmarked.some((id: ObjectId) => id.equals(program._id))
        );
      } else {
        visiblePrograms = employerData.value;
      }

      if (state.residenceFilter && state.residenceFilter !== 'None') {
        visiblePrograms = visiblePrograms.filter(
          program =>
            program.requiredResidency && program.requiredResidency.includes(state.residenceFilter)
        );
      }

      if (state.ageFilter && state.ageFilter !== 'All') {
        visiblePrograms = visiblePrograms.filter(
          program =>
            program.ageRange[0] <= parseInt(state.ageFilter, 10) &&
            program.ageRange[1] >= parseInt(state.ageFilter, 10)
        );
      }

      if (state.pathwaysFilter.length && !state.pathwaysFilter.some(obj => obj.text === 'All')) {
        const filters = state.pathwaysFilter.map(obj => obj.text);
        visiblePrograms = visiblePrograms.filter(
          program => program.pathways && program.pathways.some(pathway => filters.includes(pathway))
        );
      }

      return visiblePrograms;
    });

    const bookmarkProgram = (programId: ObjectId) => {
      if (state.bookmarked.some((id: ObjectId) => id.equals(programId)))
        state.bookmarked = state.bookmarked.filter((id: ObjectId) => !id.equals(programId));
      else state.bookmarked.push(programId);
      localStorage.setItem('bookmarkedPrograms', JSON.stringify(state.bookmarked)); //* save bookmarks to localStorage
    };

    const configuration = new Configuration({
      apiKey: process.env.VUE_APP_OPENAI_API_KEY
    });
    const openai = new OpenAIApi(configuration);
    const collectionProgram = collection.value!('Program');
    watch(newpass => {
      window.videoask.loadEmbed({
        kind: 'widget',
        url: 'https://www.videoask.com/fs2y00xq3',
        options: {
          widgetType: 'VideoThumbnailExtraLarge',
          text: 'Be a PilotCity Employer',
          backgroundColor: '#ffffff',
          position: 'bottom-right'
        }
      });
    });

    return {
      ...toRefs(state),
      filteredPrograms,
      bookmarkProgram,
      // currentUnit,
      snackbar: true,
      priorityItems: ['High', 'Medium', 'Low'],
      difficultyItems: ['Hard', 'Medium', 'Easy'],
      collection,
      collectionProgram,
      realmApp,
      credentials,
      openai,
      userType,
      userId
    };
  },

  data() {
    return {
      // userId: null,
      realmUser: null,
      stepId: 1,
      tmpObjective: {
        id: null,
        text: null,
        priority: null
      },
      tmpProject: {
        id: null,
        objectiveId: null,
        text: null,
        priority: null,
        difficulty: null,
        action: null
      },
      tmpUser: {
        id: null,
        objectiveId: null,
        projectId: null,
        text: null,
        priority: null
      },
      objectives: [],
      projects: [],
      users: [],
      tmp: [],
      curObjectiveId: null,
      curProjectId: null,
      curUserId: null,
      // lastObjectiveId: null,
      // lastProjectId: null,
      // lastUserId: null,
      priorityOrder: { Low: 0, Medium: 1, High: 2 },
      difficultyOrder: { Easy: 0, Medium: 1, Hard: 2 },
      newAction: null,
      actionItems: [
        'Create',
        'Make',
        'Produce',
        'Develop',
        'Code',
        'Plan',
        'Research',
        'Build',
        'Prototype'
      ],
      confirmDelete: false,
      deleteFunc: null,
      deleteArg: null,

      // screen 4
      tmpScope: {
        id: null,
        objectiveId: null,
        projectId: null,
        userId: null,
        text: null
      },
      results: {
        processedObjectives: [],
        processedProjects: [],
        processedUsers: [],
        scopes: []
      },
      objectiveOption: null,
      actionOption: null,
      projectOption: null,
      userOption: null,
      numScopes: 0,
      displayScopes: [],
      actionSynonyms: null,
      projectSynonyms: null,
      userSynonyms: null,
      objectiveSynonyms: null,

      // screen 5
      tmpScopeText: '',
      tmpScopeId: null,
      savedScopes: [],
      expand: true,

      // others
      autoGrowHack: false
    };
  },

  computed: {
    loadingObject() {
      return {
        loading: true,
        'loading-rounded': true,
        'loading-animated': true
      };
    },
    showCloseBtn() {
      return !window.location.pathname.includes('/scope'); //! this.$route.query.page.includes('/scope');
    }
  },

  beforeMount() {
    this.getScopeState();
  },

  methods: {
    async getScopeState() {
      const user: Realm.User = await this.realmApp.logIn(this.credentials);
      this.realmUser = user;

      const scopeState: Promise<any[]> = this.realmUser.functions.getScopeState(this.userId);
      // console.log(this.userType);
      // console.log(this.userId);

      let storedObjectives = JSON.parse(localStorage.getItem('objectives'));
      let storedProjects = JSON.parse(localStorage.getItem('projects'));
      let storedUsers = JSON.parse(localStorage.getItem('users'));
      let storedActions = JSON.parse(localStorage.getItem('actions'));
      let storedSavedScopes = JSON.parse(localStorage.getItem('savedScopes'));

      if (
        storedObjectives != null &&
        storedProjects != null &&
        storedUsers != null &&
        storedActions != null &&
        storedSavedScopes != null
      ) {
        // retrieve items from LocalStorage for new user
        this.objectives = storedObjectives;
        this.sortPriorityObjective();
        this.projects = storedProjects;
        this.sortPriorityProject();
        this.projects.forEach(project => {
          this.$set(project, 'actionTmp', null);
        });
        this.actionItems = storedActions;
        this.users = storedUsers;
        this.sortPriorityUser();
        this.savedScopes = storedSavedScopes;
        if (this.objectives.length === 0) this.addObjective();
        if (this.savedScopes.length > 0) this.stepId = 5;

        // remove all items from Local Storage
        localStorage.removeItem('objectives');
        localStorage.removeItem('projects');
        localStorage.removeItem('users');
        localStorage.removeItem('actions');
        localStorage.removeItem('savedScopes');
        localStorage.removeItem('curSavedScope');
      } else {
        scopeState.then(resp => {
          if (resp != null) {
            storedObjectives = JSON.parse(resp.objectives);
            storedProjects = JSON.parse(resp.projects);
            storedActions = JSON.parse(resp.actions);
            storedUsers = JSON.parse(resp.users);
            storedSavedScopes = JSON.parse(resp.savedScopes);

            if (storedObjectives != null) {
              this.objectives = storedObjectives;
              this.sortPriorityObjective();
            }
            if (storedProjects != null) {
              this.projects = storedProjects;
              this.sortPriorityProject();
            }
            this.projects.forEach(project => {
              this.$set(project, 'actionTmp', null);
            });

            if (storedActions != null) this.actionItems = storedActions;

            if (storedUsers != null) {
              this.users = storedUsers;
              this.sortPriorityUser();
            }
            if (storedSavedScopes != null) this.savedScopes = storedSavedScopes;

            if (this.objectives.length === 0) this.addObjective();

            if (this.savedScopes.length > 0) this.stepId = 5;
          } else console.log('Error retrieving scopeState');
        });
      }
    },

    syncDb() {
      if (this.userType !== 'anon-user') {
        const scopeState: Promise<any[]> = this.realmUser.functions.setScopeState(
          // this.userId,
          this.userId,
          JSON.stringify(this.objectives),
          JSON.stringify(this.projects),
          JSON.stringify(this.actionItems),
          JSON.stringify(this.users),
          JSON.stringify(this.savedScopes)
        );

        scopeState.then(resp => {
          // console.log(resp);
        });
      } else {
        // save anon user data into local storage
        localStorage.setItem('objectives', JSON.stringify(this.objectives));
        localStorage.setItem('projects', JSON.stringify(this.projects));
        localStorage.setItem('users', JSON.stringify(this.users));
        localStorage.setItem('actions', JSON.stringify(this.actionItems));
        localStorage.setItem('savedScopes', JSON.stringify(this.savedScopes));
      }
    },

    // screen 1->3
    addObjective() {
      const newObjective = this.tmpObjective;
      newObjective.id = `id${new Date().getTime()}`; // this.lastObjectiveId == null ? 0 : this.lastObjectiveId + 1;
      // this.lastObjectiveId = newObjective.id;
      this.objectives.push({ ...newObjective });
    },
    selectObjective(objectiveId) {
      this.objectives = this.objectives.filter(item => item.text != null && item.text.length > 0);
      this.curObjectiveId = objectiveId;
      this.stepId = 2;

      const childProjects = this.projects.filter(item => item.objectiveId === objectiveId);
      if (childProjects.length === 0) this.addProject(objectiveId);

      this.syncDb();
    },
    confirmDeleteObjective(objectiveId) {
      this.confirmDelete = true;
      this.deleteFunc = this.deleteObjective;
      this.deleteArg = objectiveId;
    },
    deleteObjective(objectiveId) {
      this.objectives = this.objectives.filter(item => item.id !== objectiveId);
      this.projects = this.projects.filter(item => item.objectiveId !== objectiveId);
      this.users = this.users.filter(item => item.objectiveId !== objectiveId);
      this.results.scopes = this.results.scopes.filter(item => item.objectiveId !== objectiveId);

      this.syncDb();
    },
    sortPriorityObjective() {
      this.objectives.sort((a, b) => {
        return (
          (a.priority == null && b.priority != null) ||
          this.priorityOrder[a.priority] < this.priorityOrder[b.priority]
        );
      });
    },
    addProject(objectiveId) {
      const newProject = this.tmpProject;
      newProject.id = `id${new Date().getTime()}`; // this.lastProjectId == null ? 0 : this.lastProjectId + 1;
      // this.lastProjectId = newProject.id;
      newProject.objectiveId = objectiveId;
      this.projects.push({ ...newProject });
    },
    selectProject(projectId) {
      this.projects = this.projects.filter(item => item.text != null && item.text.length > 0);
      this.curProjectId = projectId;
      this.stepId = 3;

      const childUsers = this.users.filter(item => item.projectId === projectId);
      if (childUsers.length === 0)
        this.addUser(this.projects.find(item => item.id === projectId).objectiveId, projectId);

      this.syncDb();
    },
    confirmDeleteProject(projectId) {
      this.confirmDelete = true;
      this.deleteFunc = this.deleteProject;
      this.deleteArg = projectId;
    },
    deleteProject(projectId) {
      this.projects = this.projects.filter(item => item.id !== projectId);
      this.users = this.users.filter(item => item.projectId !== projectId);
      this.results.scopes = this.results.scopes.filter(item => item.projectId !== projectId);

      this.syncDb();
    },
    sortPriorityProject() {
      this.projects.sort((a, b) => {
        return (
          (a.priority == null && b.priority != null) ||
          this.priorityOrder[a.priority] < this.priorityOrder[b.priority]
        );
      });
    },
    sortDifficultyProject() {
      this.projects.sort((a, b) => {
        return (
          (a.difficulty == null && b.difficulty != null) ||
          this.difficultyOrder[a.difficulty] < this.difficultyOrder[b.difficulty]
        );
      });
    },
    updateAction(newAction) {
      this.actionItems.push(newAction);

      this.syncDb();
    },
    removeAction(item) {
      this.actionItems = this.actionItems.filter(action => action !== item);

      this.syncDb();
    },
    addUser(objectiveId, projectId) {
      const newUser = this.tmpUser;
      newUser.id = `id${new Date().getTime()}`; // this.lastUserId == null ? 0 : this.lastUserId + 1;
      // this.lastUserId = newUser.id;
      newUser.objectiveId = objectiveId;
      newUser.projectId = projectId;
      this.users.push({ ...newUser });
    },
    selectUser(userId) {
      this.users = this.users.filter(item => item.text != null && item.text.length > 0);
      this.curUserId = userId;

      this.syncDb();

      this.autoGrowHack = !this.autoGrowHack;
      this.generateScopes();
    },
    confirmDeleteUser(userId) {
      this.confirmDelete = true;
      this.deleteFunc = this.deleteUser;
      this.deleteArg = userId;
    },
    deleteUser(userId) {
      this.users = this.users.filter(item => item.id !== userId);
      this.results.scopes = this.results.scopes.filter(item => item.userId !== userId);

      this.syncDb();
    },
    sortPriorityUser() {
      this.users.sort((a, b) => {
        return (
          (a.priority == null && b.priority != null) ||
          this.priorityOrder[a.priority] < this.priorityOrder[b.priority]
        );
      });
    },
    confirmDeleteFunc() {
      if (this.deleteFunc != null && this.deleteArg != null) this.deleteFunc(this.deleteArg);
      this.confirmDelete = false;
    },

    // screen 4

    async generateScopes() {
      this.stepId = 4;

      this.numScopes = 0;
      this.displayScopes = [];

      const objectiveText = this.objectives.find(item => item.id === this.curObjectiveId).text;
      const actionText = this.projects.find(item => item.id === this.curProjectId).action;
      const projectText = this.projects.find(item => item.id === this.curProjectId).text;
      const userText = this.users.find(item => item.id === this.curUserId).text;

      // generate general scopes

      const dummyScope = [
        'We are requesting you to create ',
        actionText,
        ' a ',
        projectText,
        ' for ',
        userText,
        ' to ',
        objectiveText
      ].join('');

      const generatedScopes = [];

      // 1st sentence: raw
      // generatedScopes.push(dummyScope.replace('We are requesting you to create ', ''));

      // 2nd sentence: grammar(raw)
      let response = await this.openai.createCompletion('text-davinci-001', {
        prompt: [
          'Correct this to grammatically correct English:\n\n',
          dummyScope.replace('We are requesting you to create ', ''),
          '.'
        ].join(''),
        temperature: 0,
        max_tokens: 150,
        top_p: 1.0,
        frequency_penalty: 0.0,
        presence_penalty: 0.0
      });

      const result = response.data.choices[0];
      // console.log(response);
      generatedScopes.push(result.text.replace(/(^:+|^\s+)/gm, ''));

      // 3rd-14th: summarize
      response = await this.openai.createCompletion('text-davinci-001', {
        prompt: [dummyScope, 'Summarize this for a second-grade student:\n\n'].join(''),
        temperature: 0.9, // 0.7,
        max_tokens: 250, // 64,
        top_p: 1.0, // 1.0,
        frequency_penalty: 0.0,
        presence_penalty: 0.0,
        n: 14 // CHANGE number of generated scopes here
      });

      let results = response.data.choices;

      // remove duplicates
      results = results.filter((value, index, self) => self.indexOf(value) === index);

      results.forEach(item => {
        generatedScopes.push(item.text.replace(/(^:+|^\s+)/gm, ''));
      });

      this.displayScopes = generatedScopes;
      this.numScopes = generatedScopes.length;
    },
    addScope(objectiveId, projectId, userId, scopeText) {
      const newScope = this.tmpScope;
      newScope.id = this.results.scopes.length;
      newScope.objectiveId = objectiveId;
      newScope.projectId = projectId;
      newScope.userId = userId;
      newScope.text = scopeText;
      this.results.scopes.push({ ...newScope });
    },
    updateObjectiveGuideBar({ text, objectiveId }) {
      this.objectives.find(item => item.id === objectiveId).text = text;
    },
    updateActionGuideBar({ action, projectId }) {
      this.projects.find(item => item.id === projectId).action = action;
    },
    updateProjectGuideBar({ text, projectId }) {
      this.projects.find(item => item.id === projectId).text = text;
    },
    updateUserGuideBar({ text, userId }) {
      this.users.find(item => item.id === userId).text = text;
    },

    selectScope(text) {
      if (text != null) {
        this.stepId = 5;
        this.tmpScopeText = text;
        this.tmpScopeId = null;
      } else {
        const objectiveText = this.objectives.find(item => item.id === this.curObjectiveId).text;
        const actionText = this.projects.find(item => item.id === this.curProjectId).action;
        const projectText = this.projects.find(item => item.id === this.curProjectId).text;
        const userText = this.users.find(item => item.id === this.curUserId).text;

        this.stepId = 5;
        this.tmpScopeText = [actionText, ' ', projectText, ' ', userText, ' ', objectiveText].join(
          ''
        );
        this.tmpScopeId = null;
      }
    },

    // screen 5
    saveScopeText(id, text) {
      if (id == null) {
        const newScope = this.tmpScope;
        newScope.id = `id${new Date().getTime()}`; // this.savedScopes.length;
        newScope.text = text;
        this.savedScopes.push({ ...newScope });
      } else {
        this.savedScopes.find(item => item.id === id).text = text;
      }
      this.syncDb();
    },
    editScopeText(id, text) {
      this.tmpScopeText = text;
      this.tmpScopeId = id;
    },
    confirmDeleteSavedScope(scopeId) {
      this.confirmDelete = true;
      this.deleteFunc = this.deleteSavedScope;
      this.deleteArg = scopeId;
    },
    deleteSavedScope(id) {
      this.savedScopes = this.savedScopes.filter(item => item.id !== id);

      this.syncDb();
    },
    createProgramWithScope(scopeText) {
      localStorage.setItem('curSavedScope', JSON.stringify(scopeText));
      this.$router.push({
        name: 'signup',
        query: { redirect: 'guide' }
      });
    },
    async insertProgramWithScope(scopeText) {
      if (window.location.pathname.includes('/scope')) {
        localStorage.setItem('curSavedScope', JSON.stringify(scopeText));
        await this.collectionProgram
          .insertOne({
            organizers: [this.userId],
            participants: [],
            dateCreated: new Date(),
            licensed: false
          })
          .then(result => {
            this.$router.push({
              name: 'guide',
              params: { programId: result.insertedId, page: '0' }
            });
          });
      } else this.$emit('update:useScope', scopeText);
    }
  }
});
