












































































































import { ref, computed, defineComponent, PropType } from '@vue/composition-api';

export default defineComponent({
  name: 'ModuleInstruct',

  setup() {
    // const programDoc = computed({
    //   get: () => props.value,
    //   set: newVal => {
    //     ctx.emit('input', newVal);
    //   }
    // });
    const boilerInstructions = ref([
      'Ask questions up to the limit',
      'Review, answer or make comments to community questions',
      'Upvote, downvote, bookmark & await answers to your questions'
    ]);
    const goal = ref([
      'To ask & answer questions with your student peers in the program to collectively advance your projects'
    ]);
    // function populate() {
    //   boilerInstructions.value.push('');
    // }

    return { boilerInstructions, tutorialDialog: false, goal };
  }
});
