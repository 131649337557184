
















































































































import { ref, defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ModuleInstruct',

  setup() {
    // const programDoc = computed({
    //   get: () => props.value,
    //   set: newVal => {
    //     ctx.emit('input', newVal);
    //   }
    // });
    const boilerInstructions = ref([
      'Click resource link',
      'Review, complete or understand resource contents',
      'Mark completed until all finished'
    ]);
    const goal = ref([
      'To research employer-provided resources, links, examples, possibilities & direction for projects'
    ]);
    // function populate() {
    //   boilerInstructions.value.push('');
    // }

    return { boilerInstructions, tutorialDialog: false, goal };
  }
});
