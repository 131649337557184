




































































































































































































































































































































































































































































































































































































































































































































































































































































































import {
  toRefs,
  reactive,
  PropType,
  computed,
  defineComponent,
  onBeforeUnmount,
  ref,
  watchEffect
} from '@vue/composition-api';
import { ObjectId } from 'bson';
import _, { functions } from 'lodash';
import moment from 'moment';
import calculateProgramCompletion from '@/utils/calculateProgramCompletion';
import { ACTIVITIES } from '@/constants/monitor';
import { useDbGetters } from '@/store';
import rangePicker from '@/components/atoms/ADateRangePicker.vue';
import axios from 'axios';
import { StudentStatus } from './studentStatus';

export default defineComponent({
  name: 'Bar',
  components: {
    rangePicker
  },
  props: {
    value: {
      required: true,
      type: Object as PropType<Record<string, any>>
    },
    students: {
      required: true,
      type: Array as PropType<Array<Record<string, any>>>
    },
    programs: {
      required: true,
      type: Array as PropType<Array<Record<string, any>>>
    },
    errorCode: {
      required: true,
      type: Number
    }
  },
  setup(props, ctx) {
    const state = reactive({
      activator: null,
      attach: null,
      expand: window.innerWidth >= 760,
      makeOffer: false,
      sortByStudentData: 'Completion',
      colors: ['blue'],
      editing: null,
      activityFilter: '',
      editingIndex: -1,
      sortitems: [
        { title: 'Name' },
        { title: 'School' },
        { title: 'Team' },
        { title: 'Grade' },
        { title: 'Completion' }
      ],
      programFilter: [
        {
          text: 'All Programs',
          color: 'blue'
        }
      ],
      programFilterItems: [
        {
          text: 'All Programs',
          color: 'blue'
        }
      ],
      organizerProgram: null as Record<string, any> | null, // * used only for Organizer view of Monitor
      search: null,
      unlockInterViewKey: 1,
      intervalId: 0
    });
    const { collection } = useDbGetters(['collection']);
    const filters = ['Cycle', 'Year', 'Custom'];
    const yearRangeList = ['2020-2021', '2021-2022', '2022-2023'];
    const studentFilter = ref('');
    const selectedYear = ref('');
    const selectedRange = ref('');
    const dateRange = ref([]);
    const filterDialog = ref(false);
    const monitorType = ref('');
    const studentList = ref([]);
    const localStoredVal = ref('');
    const watchInterviewDialog = ref(false);
    const ansUrl = ref('');
    const terminationReason = ref('');
    const isTerminating = ref(false);
    const terminationDialog = ref(false);

    const isIntern = std => {
      const index = std?.adks?.findIndex(a => a.name === 'offer');
      return !!std?.adks[index]?.offerStatus;
    };

    const selectedStudent = computed<Record<string, any>>({
      get: () => props.value,
      set: newValue => ctx.emit('input-value', newValue)
    });

    function openInterviewDialog(val) {
      watchInterviewDialog.value = true;
      ansUrl.value = val.videoAskResponse.contact.share_url;
    }

    watchEffect(() => {
      if (!watchInterviewDialog.value) {
        ansUrl.value = '';
      }
    });

    const yearList = computed(() => {
      const rangeOfYears = (start, end) =>
        Array(end - start + 1)
          .fill(start)
          .map((year, index) => year + index);
      return rangeOfYears(2020, moment().year());
    });

    const isValidDate = computed(() => {
      if (dateRange.value[0] < dateRange.value[1]) {
        return true;
      }
      return false;
    });

    function setDateRange(val) {
      dateRange.value = val;
    }

    const turnOn = ref(1);
    const programToMonitor = computed(() =>
      ctx.root.$route.query ? ctx.root.$route.query.program : null
    );

    // if programId provided in query, must be Organzier view. Set filter to the program
    if (programToMonitor.value) {
      (async () => {
        let program = props.programs.find(obj => obj._id.toString() === programToMonitor.value)!;
        // Edge case (program has no participants): query for this specfic program
        if (!program) {
          program = await collection.value!('Program').findOne({
            _id: new ObjectId(programToMonitor.value as string)
          });
          ctx.emit('input', null);
        }
        state.organizerProgram = program;
        state.programFilter = [
          {
            text: program.programName,
            color: 'blue'
          }
        ];
      })();
    }

    props.programs
      .map(doc => doc.programName)
      .forEach((name: string) => {
        state.programFilterItems.push({
          text: name,
          color: _.sample(state.colors) as string
        });
      });

    const sortAlphabetically = (a, b, key) => {
      const textA = key === 'school' ? a.school.name?.toUpperCase() : a[key]?.toUpperCase();
      const textB = key === 'school' ? b.school.name?.toUpperCase() : b[key]?.toUpperCase();
      if (textA < textB) return -1;
      if (textA > textB) return 1;
      return 0;
    };

    const filteredStudents = computed(() => {
      const filters = state.programFilter.map(obj => obj.text);
      return props.students.filter(
        student =>
          filters.some(filter => filter === 'All Programs' || filters.includes(student.program)) &&
          (student.adks.length === 0 ||
            student.adks.some(
              adk =>
                state.activityFilter.length === 0 ||
                (adk.name &&
                  adk.name.toLowerCase() === state.activityFilter?.toLowerCase() &&
                  adk.isComplete)
            ))
      );
    });

    const filterbyDateRange = computed(() => {
      const students: any = [];
      for (let j = 0; j < filteredStudents.value.length; j++) {
        const element = filteredStudents.value[j];
        const d = moment(element.created).format('YYYY-MM-DD');
        element.created = d;
        students.push(element);
      }
      return students;
    });

    watchEffect(() => {
      if (studentFilter.value !== 'Custom') {
        dateRange.value = [];
      }
      if (studentFilter.value !== 'Year') {
        selectedYear.value = '';
      }
      if (studentFilter.value !== 'Cycle') {
        selectedRange.value = '';
      }
    });

    function getFilter() {
      localStoredVal.value = ctx.root.$route.query.program
        ? JSON.parse(localStorage.getItem(`setFilter_${ctx.root.$route.query.program}`))
        : JSON.parse(localStorage.getItem(`setFilter_general`));
      if (localStoredVal.value) {
        const preference: any = ctx.root.$route.query.program
          ? JSON.parse(localStorage.getItem(`setFilter_${ctx.root.$route.query.program}`))
          : JSON.parse(localStorage.getItem(`setFilter_general`));

        studentFilter.value = preference.type;

        if (preference.type === 'Cycle') {
          selectedRange.value = preference.filterValue;
        } else if (studentFilter.value === 'Year') {
          selectedYear.value = preference.filterValue;
        } else {
          dateRange.value = preference.filterValue;
        }
      }
    }

    if (ctx.root.$route.query.program) {
      monitorType.value = 'Student';
      getFilter();
    } else {
      monitorType.value = 'General';
      getFilter();
    }

    function dateFilteredStudents() {
      const f = filterbyDateRange.value;

      studentList.value = f;

      if (studentFilter.value === 'Custom' && dateRange.value.length === 2) {
        studentList.value = f.filter(
          d => d.created >= dateRange.value[0] && d.created <= dateRange.value[1]
        );
      }

      if (studentFilter.value === 'Year' && selectedYear.value) {
        studentList.value = f.filter(
          d => d.created.split('-')[0] === JSON.stringify(selectedYear.value)
        );
      }

      if (studentFilter.value === 'Cycle' && selectedRange.value) {
        studentList.value = f.filter(
          d =>
            d.created.split('-')[0] >= selectedRange.value.split('-')[0] &&
            d.created.split('-')[0] <= selectedRange.value.split('-')[1]
        );
      }

      if (state.sortByStudentData === 'Name') {
        studentList.value = studentList.value
          .slice()
          .sort((a, b) => sortAlphabetically(a, b, 'name'));
      }

      if (state.sortByStudentData === 'Team')
        studentList.value = studentList.value
          .slice()
          .sort((a, b) => sortAlphabetically(a, b, 'teamName'));
      if (state.sortByStudentData === 'School')
        studentList.value = studentList.value
          .slice()
          .sort((a, b) => sortAlphabetically(a, b, 'school'));
      if (state.sortByStudentData === 'Grade')
        studentList.value = studentList.value
          .slice()
          .sort((a, b) => parseInt(a.grade, 10) - parseInt(b.grade, 10));
      if (state.sortByStudentData === 'Completion') {
        studentList.value = studentList.value.slice().sort((a, b) => {
          if (a?.studentStatus !== 0 || b?.studentStatus !== 0)
            return b?.studentStatus - a?.studentStatus;

          return (
            calculateProgramCompletion(b?.adks, b?.teamAdks) -
            calculateProgramCompletion(a?.adks, a?.teamAdks)
          );
        });
      }

      getFilter();
    }

    dateFilteredStudents();

    function setFilter() {
      const filter = {
        type: studentFilter.value
      };

      if (studentFilter.value === 'Cycle') {
        filter.filterValue = selectedRange.value;
      } else if (studentFilter.value === 'Year') {
        filter.filterValue = selectedYear.value;
      } else {
        filter.filterValue = dateRange.value;
      }

      if (monitorType.value === 'Student') {
        localStorage.setItem(`setFilter_${ctx.root.$route.query.program}`, JSON.stringify(filter));
      } else {
        localStorage.setItem(`setFilter_general`, JSON.stringify(filter));
      }
      dateFilteredStudents();
      filterDialog.value = false;
    }

    function removeFilter() {
      if (monitorType.value === 'Student') {
        localStorage.removeItem(`setFilter_${ctx.root.$route.query.program}`);
      } else {
        localStorage.removeItem(`setFilter_general`);
      }

      selectedYear.value = '';
      selectedRange.value = '';
      dateRange.value = [];
      studentFilter.value = '';
      filterDialog.value = false;
      dateFilteredStudents();
    }

    const formatStudentGrade = grade => `${grade}th Grade`;

    const studentInfo = student => {
      const info = {
        School: student.school.name,
        Team: student.teamName,
        Grade: formatStudentGrade(student.grade)
      };
      const filtered = Object.keys(info)
        .filter(key => key !== state.sortByStudentData)
        .reduce((obj, key) => {
          return {
            ...obj,
            [key]: info[key]
          };
        }, {});
      return [
        ...Object.values(filtered),
        student.program,
        student.lastSaved ? student.lastSaved.toLocaleString() : 'N/A'
      ];
    };

    const handleProgramFilterInput = () => {
      if (
        state.programFilter.length > 1 &&
        state.programFilter[state.programFilter.length - 1].text === 'All Programs'
      )
        state.programFilter = state.programFilter.filter(filter => filter.text === 'All Programs');
      else if (state.programFilter.length > 1)
        state.programFilter = state.programFilter.filter(filter => filter.text !== 'All Programs');
    };

    const edit = (index, item) => {
      if (!state.editing) {
        state.editing = item;
        state.editingIndex = index;
      } else {
        state.editing = null;
        state.editingIndex = -1;
      }
    };

    const filter = (item, queryText, itemText) => {
      if (item.header) return false;

      const hasValue = val => (val != null ? val : '');

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) > -1;
    };

    state.intervalId = window.setInterval(() => {
      state.unlockInterViewKey += 1;
    }, 1000);

    const getTimeToInterview = student => {
      const autoapplyAdk = student.adks.find(adk => adk.name === 'autoapply');

      const now = moment();
      const startTime = moment(autoapplyAdk?.startTime);
      const timeToInterview = moment.duration(startTime.diff(now));

      const format =
        timeToInterview.asSeconds() >= 86400 ? 'w [weeks], d [days]' : 'hh [hrs]:mm [min]';
      const diff = timeToInterview.format(format);
      return `${diff}`;
    };

    onBeforeUnmount(() => {
      window.clearInterval(state.intervalId);
    });

    function showMakeOfferForm(studentId: ObjectId) {
      const selectedStudent = props.students.find(student => student._id.equals(studentId));
      if (selectedStudent) {
        selectedStudent.showMakeOfferForm = true;
        ctx.emit('input-value', selectedStudent);
      }
    }

    const showProgressBar = (sorting, student) => {
      return (
        (sorting === 'Name' || sorting === 'Completion') &&
        student.studentStatus === StudentStatus.notAutoApplied
      );
    };

    const airtableEmbed = ref(false);
    const currentProgram = ref();

    async function setCurrentProgram() {
      currentProgram.value = await collection.value!('Program').findOne({
        _id: new ObjectId(programToMonitor.value as string)
      });
    }

    if (programToMonitor.value) {
      setCurrentProgram();
    }

    const airtableLink = computed(() => {
      return currentProgram?.value?.airtable_link;
    });

    async function signOffIntern() {
      try {
        isTerminating.value = true;
        const API = process.env.VUE_APP_SIGNOFF_SERVICE;
        const data = {
          type: 'terminate',
          student_id: selectedStudent.value._id.toString(),
          reason: terminationReason.value
        };
        const res = await axios.post(`${API}/signoff-intern`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        });
        if (res.data.data) {
          isTerminating.value = false;
          terminationDialog.value = false;
          selectedStudent.value.offerDetails = res.data.data?.offerDetails;
        }
      } catch (error) {
        console.log(error);
        isTerminating.value = false;
      }
    }

    return {
      ...toRefs(state),
      isIntern,
      terminationReason,
      signOffIntern,
      terminationDialog,
      isTerminating,
      currentProgram,
      airtableLink,
      filters,
      openInterviewDialog,
      watchInterviewDialog,
      ansUrl,
      studentList,
      filterDialog,
      monitorType,
      localStoredVal,
      isValidDate,
      getFilter,
      removeFilter,
      setFilter,
      filterbyDateRange,
      dateFilteredStudents,
      selectedRange,
      setDateRange,
      selectedYear,
      yearList,
      yearRangeList,
      studentFilter,
      programToMonitor,
      filteredStudents,
      // sortedStudents,
      showMakeOfferForm,
      studentInfo,
      formatStudentGrade,
      calculateProgramCompletion,
      edit,
      filter,
      activities: ACTIVITIES,
      handleProgramFilterInput,
      getTimeToInterview,
      turnOn,
      StudentStatus,
      showProgressBar,
      airtableEmbed,
      selectedStudent
    };
  },
  methods: {
    openGuideBar() {
      this.expand = !this.expand;
    }
  }
});
