var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('v-container',{staticClass:"module-instruct"},[_c('div',{staticClass:"module-instruct__container"},[_c('div',{staticClass:"module-instruct__description"},[_c('div',{staticClass:"module-instruct__description-label"},[_c('span',[_vm._v("Goal")]),_c('v-dialog',{attrs:{"width":"516"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"grey darken-2"},on:{"click":function($event){_vm.tutorialDialog = true}}},[_vm._v("mdi-youtube")])],1)]}}],null,true)},[_c('span',[_vm._v("Watch video overview")])])]},proxy:true}],null,true),model:{value:(_vm.tutorialDialog),callback:function ($$v) {_vm.tutorialDialog=$$v},expression:"tutorialDialog"}},[_c('v-card',{staticClass:"login__dialog",attrs:{"dark":""}},[_c('div',[_c('iframe',{attrs:{"width":"100%","height":"400","src":"https://www.youtube.com/embed/JUPTl6tvGQA","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}})])])],1)],1),_c('v-textarea',{attrs:{"rows":"3","outlined":"","hide-details":"","dense":"","auto-grow":"","readonly":""},model:{value:(_vm.goal),callback:function ($$v) {_vm.goal=$$v},expression:"goal"}})],1),_c('div',{staticClass:"module-instruct__instructions"},[_c('div',{staticClass:"module-instruct__description-label"},[_c('span',[_vm._v("Instructions")]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":"https://discord.gg/cHMbHTKNyY","target":"_blank"}},[_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"grey darken-2"}},[_vm._v("mdi-face-agent")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Ask questions & get advice")])])],1),_vm._l((_vm.boilerInstructions),function(i,index){return _c('div',{key:index,staticClass:"module-instruct__instructions-item"},[_c('v-avatar',{staticClass:"module-instruct__instructions-av font-weight-bold",attrs:{"size":"35","color":"white"}},[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('validation-provider',{attrs:{"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"error-messages":errors,"rows":"1","outlined":"","hide-details":"","dense":"","auto-grow":"","readonly":""},model:{value:(_vm.boilerInstructions[index]),callback:function ($$v) {_vm.$set(_vm.boilerInstructions, index, $$v)},expression:"boilerInstructions[index]"}})]}}],null,true)})],1)})],2)])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }